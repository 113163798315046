import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import {
    getAllSubscribes,
    getRouteLrnTrgtGrpMstSn,
} from '@/assets/js/modules/ssl/ssl-common';
import {
    ACT_GET_MY_SOC_BOARD_LIST, ACT_GET_SOC_BOARD_LIST,
    ACT_GET_SOC_PAGE_LIST,MUT_SET_POSTSN_LIST,
} from '@/store/modules/ssl/ssl-index';
import {
    getItems,
    lengthCheck,
    setParams,
    timestampToDateFormat,
    numberComma, getPaging,
} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';

export const sslTotalViewSetup = () => {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const {showMessage} = useAlert();

    const session = computed(() => store.state.auth.session ? store.state.auth.session : {});
    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const socLrnPostSn = ref(route.params.socLrnPostSn || 0);

    const isReady = ref(false);
    const commentComp = ref(null);
    const postSns = computed(()=> store.state.ssl.socBoardTotList.postSns);
    const getIdx=(list, val)=>{
        let i = 0;
        for(const sn of list.value){
            if(parseInt(sn) == parseInt(val)){
                console.log(parseInt(sn)+' = ' + parseInt(val)+ '==>' + i);
                return i;
            }
            i ++;
        }
        return 0;
    }

    const goPage = (div) =>{
        //현재글의 idx

        const crtIdx = ref(getIdx(postSns, route.params.socLrnPostSn)); //  computed(()=> postSns.value.findIndex(e => e === route.params.socLrnPostSn));
        const paging = computed(()=> store.state.ssl.socBoardTotList.socPaging);
        const opt = computed(() => store.state.ssl.socBoardTotList.opt);
        const no=ref(0);

        console.log('현재 글 IDX : ' + (crtIdx.value));
        console.log('총건수 : ' + paging.value.totalCount);
        console.log('route.params.socLrnPostSn : ' + route.params.socLrnPostSn);
        console.log('postSns : ' + postSns.value.toString());
        console.log('postSns length : ' + postSns.value.length);
        console.log('-------------------------------------------------');


        if(div == 'prv'){
            if (crtIdx.value <= 0){
                console.log('prv ----------- 1');
                showMessage('이전 글이 없습니다.');
            }else{
                console.log('prv ----------- 2');
                no.value = postSns.value[crtIdx.value-1];
                router.push({name: 'SSLTotalView', params: {socLrnPostSn: no.value}}).then();
            }
        }else{
            if (crtIdx.value+1 >= paging.value.totalCount){ //&& crtIdx.value+1 ==  postSns.value.length
                console.log('next ----------- 1');
                showMessage('다음 글이 없습니다.');
            }else if((crtIdx.value+1 >= postSns.value.length) && (crtIdx.value+1 < paging.value.totalCount)){

                console.log('next ----------- 2');
                paging.value.pageNo = paging.value.pageNo+1;

                if(opt.value.div == 'my'){
                    console.log('next ----------- 3');
                    store.dispatch(`ssl/${ACT_GET_MY_SOC_BOARD_LIST}`, {
                        lrnTrgtGrpMstSn: route.params.lrnTrgtGrpMstSn,
                        stt: '00',
                        pageNo: paging.value.pageNo,
                        pageSize: paging.value.pageSize,
                    }).then(res => {
                        if (lengthCheck(res)) {
                            for(let i=0; i < getItems(res).length; i++){
                                postSns.value.push(getItems(res)[i].socLrnPostSn);
                            }
                        }
                        paging.value.hasMore = getPaging(res).hasMore;

                        store.commit(`ssl/${MUT_SET_POSTSN_LIST}`, {paging : paging.value, items:postSns.value, opt:opt.value}); // 이전,다음페이지 이동을 위해 추가함.

                        // console.log('1. 추가조회 후 list length : ' + postSns.value.toString());
                        no.value = postSns.value[crtIdx.value+1];
                        router.push({name: 'SSLTotalView', params: {socLrnPostSn: no.value}}).then();

                    }).catch(e => {
                        console.error(e);
                    });

                }else{

                    console.log('next ----------- 4');
                    store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`, {
                        lrnTrgtGrpMstSn: route.params.lrnTrgtGrpMstSn,
                        distCrseCd: route.params.distCrseCd,
                        relationYn: (route.params.distCrseCd === 'book' ? 'Y' : 'N'),
                        title: opt.value.title,
                        sortCd: opt.value.sortCd,
                        pageNo: paging.value.pageNo,
                        pageSize: paging.value.pageSize,
                    }).then(res => {
                        if (lengthCheck(res)) {

                            for(let i=0; i < getItems(res).length; i++){
                                postSns.value.push(getItems(res)[i].socLrnPostSn);
                            }
                        }
                        paging.value.hasMore = getPaging(res).hasMore;

                        store.commit(`ssl/${MUT_SET_POSTSN_LIST}`, {paging : paging.value, items:postSns.value, opt:opt.value}); // 이전,다음페이지 이동을 위해 추가함.

                        // console.log('2. 추가조회 후 list : ' + postSns.value.toString());
                        postSns.value = postSns.value.toString().split(',');
                        // console.log('3. 추가조회 후 list length : ' + postSns.value.length);
                        // console.log('4. 현재 글 IDX : ' + crtIdx.value);
                        // console.log('5. 다음 글 IDX : ' + (parseInt(crtIdx.value)+1));
                        no.value = postSns.value[parseInt(crtIdx.value)+1];
                        router.push({name: 'SSLTotalView', params: {socLrnPostSn: no.value}}).then();
                    }).catch(e => {
                        console.error(e);
                    });
                }

            }else{
                console.log('next ----------- 5');
                no.value = postSns.value[crtIdx.value+1];
                router.push({name: 'SSLTotalView', params: {socLrnPostSn: no.value}}).then();
            }
        }

        window.scrollTo(0, 0);
    }


    const socBoard = reactive({
        socLrnPostSn: 0,
        title: '',
        thumb: '',
        regDt: 0,
        pblcnDt: 0,
        inqcnt: 0,
        lrnerId: '',
        lrnerNm: '',
        deptNm: '',
        deptCd: '',
        lrnTrgtGrpMstSn: 0,
        lrnTrgtGrpNm: '',
        likeCnt: 0,
        refTyNm: null,
        refUrl: '',
        refPost: '',
        sharedCnt: 0,
        crseMstSn: 0,
    });


    const shareToggle = ref(false);

    const hashTags = ref([]);


    const socPages = ref([]);
    const currentPageIdx = ref(0);
    const currentPage = computed(() => {
        if(socPages.value.length > 0 && socPages.value[currentPageIdx.value]){
            return socPages.value[currentPageIdx.value];
        }
        return {};
    });


    const isLike = ref(false);
    const isShare = ref(false);
    const isPut = ref(false);

    const getSocsPageList = () => {
        if (socLrnPostSn.value > 0) {
            store.dispatch(`ssl/${ACT_GET_SOC_PAGE_LIST}`, socLrnPostSn.value).then(res => {
                // SocBoard
                if (res.socBoard) {
                    setParams(socBoard, res.socBoard);
                    // myLikeCnt: 0
                    if (res.socBoard.hashTag) {
                        hashTags.value = res.socBoard.hashTag.split(',');
                    } else {
                        hashTags.value = '';
                    }

                    isLike.value = (res.socBoard.myLikeCnt > 0);
                    isPut.value = (res.socBoard.myPutCnt > 0);
                    isShare.value = (res.socBoard.mySharedCnt > 0);
                    // SocPage
                    if (lengthCheck(res)) {
                        socPages.value = getItems(res);
                        isReady.value = true;
                    } else {
                        socPages.value = [];
                    }
                    // insertSocAct(actTyCdDcds.ACT_TY_CD_READ, socBoard.socLrnPostSn, null, () => {
                    //     readSocBoard(socBoard.lrnerId);
                    // });
                } else {
                    socLrnPostSn.value = "0";
                    isLike.value = false;
                    isPut.value = false;
                    isShare.value = false;
                    socPages.value = [];
                    isReady.value = false;

                }
            }).catch(e => {
                console.error(e);
            })
        }
    }

    const dateToFormat = (regDt) => {
        if(regDt){
            return timestampToDateFormat(regDt, 'yyyy.MM.dd hh:mm')
        }
        return '';
    }

    const goList = () => {
        router.push({name: 'SSLTotalMy'}).then();
    }

    const closeAllToggle = () => {
        if (commentComp.value && commentComp.value.closeCommentToggles) {
            commentComp.value.closeCommentToggles();
        }
    }

    const goLearner = target => {
        if (target && target.lrnerId) {
            router.push({name: 'SSLLearnerBoard', params: {lrnerId: target.lrnerId}}).then(() => {});
        }
    }

    watch(() => route.query, () => {
        socLrnPostSn.value = route.params.socLrnPostSn;


        getSocsPageList();
        getAllSubscribes();

    });

    onMounted(() => {
        document.oncontextmenu = function () {return false};
        document.onselectstart = function () {return false};
        document.ondragstart = function () {return false};
    });

    onBeforeUnmount(() => {
        document.oncontextmenu = function () {return true};
        document.onselectstart = function () {return true};
        document.ondragstart = function () {return true};
    });

    getSocsPageList();
    getAllSubscribes();

    return {
        isReady,
        session,
        lrnTrgtGrpMstSn,

        socBoard,
        socPages,
        currentPageIdx,
        currentPage,

        isPut,
        isLike,
        shareToggle,

        dateToFormat,
        hashTags,

        numberComma,

        // PC
        goList,

        // Mobile
        commentComp,
        closeAllToggle,
        goPage,
        postSns,
        goLearner,
    }

}
