<template>
  <ul class="square-list">
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <!--        <router-link  :to="{name: 'GroupLearning'}" class="square-link"></router-link>-->
        <router-link  :to="'prep/hottip/main'"  class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_07.png`)"   alt=""></div>
        <div class="square-content"><h4 class="title">핫팁(Hot Tip)</h4></div>
      </div>
    </li>
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <!--        <a href="javascript:" class="square-link" @click="underConstruction" v-if="sslMenu.indexOf('ssl/home') > 0"></a>-->
        <router-link :to="`${sslMenu}`" class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_01.png`)" alt=""></div>
        <div class="square-content"><h4 class="title">쓸</h4></div>
      </div>
    </li>
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <router-link :to="'prep/hrdcloud'" class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_02.png`)" alt=""></div>
        <div class="square-content"><h4 class="title">HRD 클라우드</h4></div>
      </div>
    </li>
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <router-link :to="'train/regular'" class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_03.png`)"   alt=""></div>
        <div class="square-content"><h4 class="title">판매자격연수</h4></div>
      </div>
    </li>
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <!--        <router-link :to="{name : 'TrainProceeding', query: {classCateSn: 42}}" class="square-link"></router-link>-->
        <router-link :to="'dept/duty-lrn-stt'" class="square-link"></router-link>
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_04.png`)" alt=""></div>
        <div class="square-content"><h4 class="title">통합법정교육</h4></div>
      </div>
    </li>
    <!-- square-item -->
<!--    <li class="square-item">-->
<!--      <div class="square">-->
<!--        <router-link  :to="{name: 'GroupLearning'}" class="square-link"></router-link>-->
<!--        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_05.png`)"   alt=""></div>-->
<!--        <div class="square-content"><h4 class="title">그룹러닝</h4></div>-->
<!--      </div>-->
<!--    </li>-->
    <!-- square-item -->
    <li class="square-item">
      <div class="square">
        <a href="javascript:" class="square-link" @click="goHunet"></a>
<!--        <router-link :to="{name: 'MyAbility'}" class="square-link"></router-link>-->
        <div class="square-image"><img :src="require(`../../assets/lxp/images/main/link_banner_08.png`)"  alt=""></div>
        <div class="square-content"><h4 class="title">KB 스타디움</h4></div>
      </div>
    </li>
  </ul>
  <CIAgreementModal
      v-if="ciAgreeModal"
      v-model="ciAgreeModal"
  />
</template>

<script>
import {ref} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {customPageMove, moveSSL} from '@/assets/js/modules/common/common';
import {useStore} from 'vuex';
import {ACT_GET_MENU} from '@/store/modules/menu/menu';
import {getItems, lengthCheck, winLearnOpen} from '@/assets/js/util';
import CIAgreementModal from "@/components/ciagreement/CIAgreementModal.vue";
import navigationUtils from "@/assets/js/navigationUtils";
import {useRouter} from "vue-router";
import {CHK_AGREEMENT_CI} from "@/store/modules/live/live";
import {ACT_GET_SSO_CP_URL} from "@/store/modules/mypage/mypage";


export default {
  name: "SquareList",
  components: {CIAgreementModal},
  setup() {

    const {showMessage} = useAlert();
    const store = useStore();
    const ciAgreeModal = ref(false);
    const router = useRouter();

    // 2022-11-22 : 2023년 3월 오픈 [김보희 대리 요청]
    const underConstruction = () => {
      return showMessage('학습기간이 아닙니다.');
    }

    const goHunet = () => {

      const session = store.state.auth.session;

      store.dispatch(`mypage/${ACT_GET_SSO_CP_URL}`, '2219021').then(res => {
        if (session.itn) {
          if(confirm('은행내에서 외부과정을 접속하시려면 KB인터넷PC의\n[인터넷PC-연수시스템]바로가기 아이콘을 이용하시기 바랍니다\n\n가상인터넷PC 실행화면으로 이동하시겠습니까?')) location.href="https://kbintpc.kbstar.com";
        }else {
          var item = {
            prvdrCd: '2219021',
            etnLrnUrl: res
          }
          console.log(item)
          winLearnOpen(item);
        }
      });
    };

    const chkCIAgreeModal = () => {
      store.dispatch(`live/${CHK_AGREEMENT_CI}`, {
      }).then(res=> {
        if(res.status && res.result.number == 200) {
          if (navigationUtils.any()) {
            router.push({name: 'CIAgreement'});
          } else {
            ciAgreeModal.value = true;
          }
        } else if(res.status && res.result.number == 401) {
          showMessage('이미 동의서 작성 이력이 존재합니다.');
        } else {
          showMessage('서버 문제가 발생했습니다. 관리자에게 문의하세요.');
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const sslMenu = ref('');

    const getMenu = () => {
      store.dispatch(`menu/${ACT_GET_MENU}`).then(res=>{
        if(lengthCheck(res)) {
          const items = getItems(res).filter(x => x.menuSn === 9);

          items.forEach((item) => {
            if(item.children.filter(y => y.menuSn === 11)[0]) {
              sslMenu.value = `${item.children.filter(y => y.menuSn === 11)[0].menuUrl}`;
            }
          })
        }


      })
    }

    getMenu();

    return {
      underConstruction,
      customPageMove,
      moveSSL,
      sslMenu,
      chkCIAgreeModal,
      goHunet,
      ciAgreeModal
    }

  }
}
</script>
