<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-tuition-support">
    <lxp-main-header
        :show-breadcrumb="true"
        :show-title="true"
        :title="`학원비 지원 신청`"
        :file-down-key="guideFile"
    />
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- page-buttons -->
      <div class="page-buttons">
        <a href="javascript:" class="kb-btn kb-btn-primary" @click="openAppPage"><span class="text">지원신청</span></a>
      </div>
      <!-- section: 학원비 지원안내 -->
      <article class="content-section">
        <header class="section-header">
          <h4 class="title">지원대상</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td><span class="td-text">지급일 현재 재직중인 직원 (일반/계약)<br>※ 지급일 현재 정직자 및 파트타이머 제외(단, 인천국제공항지점 환전매니저의 경우 외국어 분야에 한해 지원)</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 신청기한</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td><span class="td-text"><span class="fw-bolder">과정 종료일</span>로부터 <span class="fw-bolder text-red">2개월 이내</span></span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 지원절차 및 시기</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td class="d-flex justify-content-evenly">
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.1</div>
                    <div class="mt-lg-4 text-center">과정 종료 후<br>필요서류 준비</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.2</div>
                    <div class="mt-lg-4 text-center">스타런에서<br>신청</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.3</div>
                    <div class="mt-lg-4 text-center">인재개발부<br>심사</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.4</div>
                    <div class="mt-lg-4 text-center">급여계좌로 지급<br><span class="font-body12">(전산 신청일 기준<br><span class="fw-bolder">익월 15일</span>)</span></div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>
      <!-- section: 지원 내용 -->
      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title">지원내용</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table  kb-table-bordered-v2">
            <table>
              <colgroup>
                <col style="width:80px;" />
                <col style="width:100px;" />
                <col style="width:250px;" />
                <col style="width:270px;" />
              </colgroup>
              <thead>
              <tr>
                <th class="text-center" colspan="2"><span class="th-title">구분</span></th>
                <th class="text-center"><span class="th-title">외국어</span></th>
                <th class="text-center"><span class="th-title">IT<sup class="text-red">주3)</sup> </span></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="text-center" colspan="2"><span class="td-text">학습방법</span></td>
                <td class="text-center" colspan="2"><span class="td-text">학원, 인터넷, 전화, App구독 등</span></td>
              </tr>
              <tr>
                <td class="text-center" rowspan="2"><span class="td-text">지원 한도<sup class="text-red">주1)</sup></span></td>
                <td class="text-center"><span class="td-text">과정 시작일<br>2024.11.22이후</span></td>
                <td class="text-center"><span class="td-text">연간 최대 144만원<br>(96만원+IT미사용 한도 내 48만원)</span></td>
                <td class="text-center"><span class="td-text">연간 96만원</span></td>
              </tr>
              <tr>
                <td class="text-center"><span class="td-text">과정 시작일<br>2024.11.21이전</span></td>
                <td class="text-center"><span class="td-text">연간 96만원</span></td>
                <td class="text-center"><span class="td-text">연간 96만원</span></td>
              </tr>
              <tr>
                <td class="text-center" colspan="2"><span class="td-text">지원 범위<sup class="text-red">주2)</sup></span></td>
                <td colspan="2" class="text-center"><div class="td-text" style="height:50px; wdth:250px;margin-left:0; border:2px solid #fff; border-radius: 30px;line-height:50px">학원비의 80%</div></td>
              </tr>
              <tr>
                <td class="text-center" colspan="2"><span class="td-text">지원제외 과정</span></td>
                <td  colspan="2"><div class="td-text">- 수강기간 6개월 초과 과정</div>
                  <div class="td-text">- 사은품(스마트폰, 태블릿PC, 블루투스 이어폰, 상품권 등)이 제공되는 과정</div>
                  <div class="td-text">- 과정 시작일이 입행일 이전인 과정</div>
                  <div class="td-text">- 개인과외 등 수강기관/과정 등을 명확히 확인할 수 없는 과정</div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class ="font-body14 mt-2">주1) - <span class="text-red font-body16B">연간 지원 한도는 신청일이 아닌 '과정 시작일'을 기준으로 산정</span>하며, <span class="text-red font-body16B"> 과정 시작일이 2024.11.22 이후인 건에 대해 확대된 외국어 한도 적용</span></div>
            <div class ="font-body14 mt-2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;※ 관련문서:학원비 지원제도 변경 안내(HR부(인재개발)811, 2024.11.22)</div>
            <div class ="font-body14 mt-1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 외국어/IT를 합산하여 개인당 총 지원 금액은 192만원(예시:'외국어 96만원 + IT 96만원' 또는 '외국어 144만원 + IT 48만원')</div>
            <div class ="font-body14 mt-2">주2) 교재비 지원 제외/외화 결제의 경우 학원비 결제일 기준 1회차 전신환 매도율 적용</div>
            <div class ="font-body14 mt-2">주3) 본 지원 제도로 IT 분야 학원비를 지원받은 후 등급 자격증을 취득한 경우, 자격증 취득 지원시 응시료만 80% 지원</div>
          </div>
        </div>
      </article>
      <!-- section: 지원절차 -->

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 필요서류</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td>
                  <div style="width:20%; float:left">1. 수강증</div>
                  <div style="width:80%; float:left">- 필수기재사항 : 수강자명, 학원명, 과정명, 수강기간, 수강료</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div style="width:20%; float:left">2. 결제영수증 </div>
                    <div style="width:80%; float:left">- 필수기재사항 : 카드번호, 승인번호, 승인일, 승인금액, 가맹점명<br>- 본인 명의 KB신용・체크카드(가족愛복지 포함) 결제분만 인정<br>&nbsp;&nbsp;&nbsp;※ 신용카드 미가맹점인 경우에만 납입영수증 대체 가능</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>3. 수료증, 출석표, 진도율표 中 택1</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

    </div>
    <!-- //main-content -->

    <!-- 학원비 지원 신청 모달 -->
    <SupportClassFee202309Modal v-model="isClassFeeModalActive"/>

  </main>
  <!-- end::kb-main -->
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {computed, ref} from 'vue';

import {getItems, lengthCheck} from '@/assets/js/util';
import {ACT_GET_RST_REG_CHK} from '@/store/modules/support/support';
import {
  downloadGuideFiles,
  getClassDsjChk,
} from '@/assets/js/modules/support/support-common'; //, getRefundChk
import SupportClassFee202309Modal from "@/components/support/SupportClassFee202309Modal";
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from "vuex";
import {checkRefundConsult} from '@/assets/js/modules/consult/consult-common';

export default {
  name: 'SupportClass',
  components: {SupportClassFee202309Modal, LxpMainHeader},
  setup() {
    const store = useStore();
    const {showMessage} = useAlert();
    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'class').fileKey);
    const isClassFeeModalActive = ref(false);
    const chkCnt = ref(0);

    const openAppPage = async() => {
        //지원가능 대상자 체크
      let chkVal = await getClassDsjChk();
      if (getItems(chkVal) > 0){
        showMessage('학원비 지원 신청 대상이 아닙니다. ');
        isClassFeeModalActive.value = false;
        return false;
      }else{
        checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 인재개발부<br>업무담당자(☎7880)에게<br>문의 부탁드립니다.`, rstRegChk );
      }
    };

    const rstRegChk = () =>{
      chkCnt.value=0;
      // 이전 신청건에 대한 결과등록 여부 조회
      store.dispatch(`support/${ACT_GET_RST_REG_CHK}`).then(res => {
        if (lengthCheck(res)) {
          chkCnt.value = getItems(res);
        } else {
          chkCnt.value = 0;
        }

        if (chkCnt.value > 0){
          showMessage('기존 신청건 결과등록 후 신청하시기 바랍니다.');
          isClassFeeModalActive.value = false;
        }else{
          isClassFeeModalActive.value = true;
          // showConfirm({text: changeNotice, callback: () => {isClassFeeModalActive.value = true;}});
        }

      }).catch(e => {
        console.error(e);
      });
    }

    return {
      guideFile,
      isClassFeeModalActive, openAppPage
    }
  }
};
</script>