<template>
  <div class="myssl-container">
<!--    <SSLMyTop/>-->
    <SSLBoardList
        :is-loading="isLoading"
        :items="items"
        :paging="paging"
        :more-func="moreFunc"
    />
  </div>
</template>

<script>
// import SSLMyTop from '@/components/ssl/my/SSLMyTop';

import SSLBoardList from '@/components/ssl/SSLBoardList';
import {sslMyBoardSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMyBoard',
  components: {
    SSLBoardList,
    // SSLMyTop,
  },
  props: {
    lrnTrgtGrpMstSn: Number,
  //   year: Number,
  },
  setup: sslMyBoardSetup
}
</script>
