import store from '@/store';
import {
    ACT_GET_MY_SOC_STAT,
    ACT_GET_SOC_UV_LIST,
} from '@/store/modules/ssl/ssl-index';
import {
    currentYmd,
    // enableExperiencePeriod,
    enrollSSL,
    getLrnTrgtGrpMstSn,
    getMateCount,
    getMyLearnTargetList,
    noneRewardCds,
    showAttendance,
    sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {
    dateToDateFormat,
    getItem,
    getItems,
    initParams,
    lengthCheck,
    setParams,
} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {bookOpen} from '@/assets/js/modules/ssl/ssl-message';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {computed, onMounted, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import Swiper from 'swiper';

export const getSSLUVList = (uv, lrnTrgtGrpMstSn) => {
    store.dispatch(`ssl/${ACT_GET_SOC_UV_LIST}`, {
        lrnTrgtGrpMstSn,
        actTyCdDcd: lrnTrgtGrpMstSn > 0 ? actTyCdDcds.ACT_TY_CD_NOTE_HOME : actTyCdDcds.ACT_TY_CD_LEARN_HOME
    }).then(res => {
        if(lengthCheck(res) && res.items.length === 2){
            const items = getItems(res);
            uv.totalCount = items[0].totalCount
            uv.todayCount = items[0].todayCount
            uv.totalBoardCount = items[1].totalCount
            uv.todayBoardCount = items[1].todayCount
        }else{
            initParams(uv);
        }
    }).catch(() => {
        initParams(uv);
    });
}

export const homeLinkSetup = () => {
    const store = useStore();
    const router = useRouter();
    const {showMessage, showLoading, hideLoading} = useAlert();

    const enroll = reactive({
        key: null,
        toggle: false,
    });

    const myLrnTrgtGrpMstSns = computed(() => store.state.ssl.myLearnTargets.map(x => x.lrnTrgtGrpMstSn));

    const sslMoveLink = computed(() => {
        return Object.values(sslRoutes).filter(y=>y.key != 'bestNote' && y.key != 'total').map(x => {
            let targetLrnTrgtGrpMstSn = 0;
            const targetLrnTrgtGrpMstSns = x.lrnTrgtGrpMstSns.filter(x => myLrnTrgtGrpMstSns.value.includes(x));
            if (targetLrnTrgtGrpMstSns.length > 0) targetLrnTrgtGrpMstSn = targetLrnTrgtGrpMstSns[0];

            return {
                name: x.name.replace('셀프러닝-', ''),
                key: x.key,
                lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn,
                firstLrnTrgtGrpMstSn: x.lrnTrgtGrpMstSns[0],
            }
        })

    });

    // 셀프 러닝
    const isNotMyLrn = (distCrseSns) => {
        return getLrnTrgtGrpMstSn(distCrseSns) === 0;
    }

    const alertMenus = (distCrseCd) => {
        if(distCrseCd === 'book'){
            if(dateToDateFormat(new Date(), 'yyyyMMddhhmm') < 202407011700){
                showMessage(bookOpen);
            }else{
                showMessage('입과되지 않는 과정입니다.');
            }
        }else{
            showMessage('입과되지 않는 과정입니다.');
        }
    }

    const goMain = (key, lrnTrgtGrpMstSn)  => {
        router.push({name: 'SSLMain', params: {distCrseCd: key, lrnTrgtGrpMstSn}}).then(() => {});
    }


    const myGroup = computed(() => store.state.ssl.group);


    const goLearn = moveRoute => {
        // const nowDate = dateToDateFormat(new Date(), 'yyyyMMddhhmm');
        // if (moveRoute.key === 'book' && enableExperiencePeriod[moveRoute.key] > nowDate){
        //         showMessage({
        //             title: '북러닝',
        //             text: '도서 신청 완료 후 7.1(월) 17시 오픈 예정'
        //         });
        // } else
        if (noneRewardCds.includes(moveRoute.key)) {
            goMain(moveRoute.key, moveRoute.firstLrnTrgtGrpMstSn);
        } else {
            // 이미 입과 되어 있다.
            if (moveRoute.lrnTrgtGrpMstSn > 0) {
                goMain(moveRoute.key, moveRoute.lrnTrgtGrpMstSn);
            } else {
                // 그룹은 있지만 SSL 그룹러닝에 입과되지 않는 경우 강제 입과를 한다.
                if (moveRoute.key === 'group' && myGroup.value.distCrseSn > 0) {
                    showLoading();
                    enrollSSL(moveRoute.firstLrnTrgtGrpMstSn, result => {
                        if (result > 0) {
                            getMyLearnTargetList(() => {
                                hideLoading();
                                goMain(moveRoute.key, moveRoute.firstLrnTrgtGrpMstSn);
                            }).then(() => {});
                        } else {
                            hideLoading()
                            showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
                        }
                    });
                } else {
                    // if (moveRoute.key === 'book'){
                    //     showMessage('입과되지 않는 과정입니다.');
                    // }else {

                        // enroll.key = moveRoute.key;
                        // enroll.toggle = true;
                        goMain(moveRoute.key, moveRoute.lrnTrgtGrpMstSn);
                    // }
                }
            }
        }
    }



    return {
        enroll,
        sslMoveLink,
        sslRoutes,
        bookOpen,
        showMessage,
        isNotMyLrn,
        alertMenus,
        goLearn,
    }
}

export const sslHomeActivitySetup = () => {
    const store = useStore();
    const router = useRouter();

    const isMobile = computed(() => store.state.auth.isMobile);

    const myBadges = computed(() => store.state.ssl.myBadges);
    const mateCount = computed(() => getMateCount());
    const attendanceCnt = computed(() => Array.from(new Set(store.state.ssl.calendar.myAttends.map(x => x.datePart.ymd))).length);
    const todayAttended = computed(() => store.state.ssl.calendar.myAttends.some(x => x.datePart.ymd === currentYmd));
    const actFeedModal = ref(null);
    const isFeedModal= ref(false);
    const shareModal = ref(null);
    const isShareModal= ref(false);

    const socStat = reactive({
        boardCnt: 0,
        cmntWriteCnt: 0,
        cmntReceiveCnt: 0,
        cmntTodayReceiveCnt: 0,
        likeGiveCnt: 0,
        likeGetCnt: 0,
        likeTodayGetCnt:0,
        boardInqCnt: 0,
        // 내 노트 공유된 수
        sharedCnt: 0,
        //내가 공유한 노트 수(내 노트미포함)
        shareGivCnt:0,
        //내가 공유한 노트 수(내 노트포함)
        shareGivCnt2:0
    });

    const getSocStat = () => {
        store.dispatch(`ssl/${ACT_GET_MY_SOC_STAT}`).then(res => {
            if(lengthCheck(res)){
                setParams(socStat, getItem(res));
            }
        }).catch(() => {});
    }

    getSocStat();

    // PC
    const slider = ref(null);

    // Mobile
    const isOpen = ref(false);
    const toggleOpen = () => {
        isOpen.value = !isOpen.value;
    }

    if (!isMobile.value) {
        onMounted(() => {
            new Swiper(slider.value, {
                slidesPerView: "auto",
                speed:750,
                navigation: {
                    nextEl: '.kb-btn-activity-next',
                    prevEl: '.kb-btn-activity-prev',
                }
            });
        });
    }

    const showActFeed = (div) => {
        if (socStat.cmntReceiveCnt > 0 || socStat.likeGetCnt > 0) {
            if(isMobile.value){
                router.push({name: 'SSLActFeed', params: {div: div}});
            }else {
                    isFeedModal.value = true;
                    actFeedModal.value.getActFeedList(div);
            }
        }
    }

    const showShareList=()=>{
        if(socStat.shareGivCnt2 > 0){
            if(isMobile.value){
                router.push({name: 'SSLShare'});
            }else {
                isShareModal.value = true;
                shareModal.value.getList();
            }
        }

    }

    return {

        todayAttended,
        mateCount,
        attendanceCnt,
        socStat,
        myBadges,
        showAttendance,
        showActFeed,
        showShareList,
        actFeedModal,
        isFeedModal,
        shareModal,
        isShareModal,

        // PC
        slider,

        // Mobile
        isOpen,
        toggleOpen

        //

    }
}
