<template>
  <article  class="home-section section-learning" >
    <div class="learning-box" v-for="(moveRoute, index) in sslMoveLink" :key="index">
<!--      <router-link :to="{name: 'SSLMain', params: {distCrseCd: moveRoute.key, lrnTrgtGrpMstSn: moveRoute.lrnTrgtGrpMstSn}}" class="learning-link"></router-link>-->
      <a href="javascript:" class="learning-link" @click="goLearn(moveRoute)"></a>
      <div class="learning-image">
        <img v-if="moveRoute.key === 'selfGa'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_1.png" alt="">
        <img v-else-if="moveRoute.key === 'selfGi'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_2.png" alt="">
        <img v-else-if="moveRoute.key === 'selfJa'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_3.png" alt="">
        <img v-else-if="moveRoute.key === 'selfIct'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_4.png" alt="">
        <img v-else-if="moveRoute.key === 'selfDvl'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_8.png" alt="">
        <img v-else-if="moveRoute.key === 'group'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_5.png" alt="">
<!--        <img v-else-if="moveRoute.key === 'knowledge'" src="@/assets/lxp/images/ssl/home/quick/2024/img_quick_e.jpg" alt="">-->
<!--        <img v-else-if="moveRoute.key === 'hrd'" src="@/assets/lxp/images/ssl/home/quick/2024/img_quick_hrd.jpg" alt="">-->
        <img v-else-if="moveRoute.key === 'book'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_7.png" alt="">
        <img v-else-if="moveRoute.key === 'trivia'" src="@/assets/lxp/images/ssl/home/quick/2024/artboard_bk_6.png" alt="">
<!--        <img v-else-if="moveRoute.key === 'live'" src="@/assets/lxp/images/ssl/home/quick/2024/img_quick_review.jpg" alt="">-->
<!--        <img v-else-if="moveRoute.key === 'college'" src="@/assets/lxp/images/ssl/home/quick/2024/img_quick_college.jpg" alt="">-->
      </div>
      <div class="learning-contents text-black" style="justify-content:unset;color:#545045 !important;flex-direction: column-reverse;padding-bottom:20px;">{{ moveRoute.name }}</div>
    </div>
  </article>

  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :dist-crse-cd="enroll.key"
  />



</template>
<script>
import {homeLinkSetup} from "@/assets/js/modules/ssl/ssl-home";
import SSLEnroll from '@/components/ssl/common/SSLEnroll';

export default {
  name: 'SSLMobileLink',
  components: {SSLEnroll},
  setup: homeLinkSetup
}
</script>
