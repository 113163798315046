<template>
  <main class="kb-main" id="kb-support">
    <LxpMobileHeader title="강사 신청하기">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="closeModal">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
    </LxpMobileHeader>
    <div class="main-content main-component">
      <div class="support-view-container">
        <div class="view-contents" style="margin-top:20px;">

          <section class="content-section" >
            <header class="section-header">
              <h4 class="title">강사 신청</h4>
            </header>
            <div class="section-content" >
              <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
                <!--                <RowInputText v-model="param.inqTitle" class-lst="column-whole" title="제목" placeholder="강사 신청하기" :disabled="true" />-->
                <div class="kb-form-field">
                <MobileInputSelect
                    v-model:value1="param.inqTyCdDcd"
                    v-model:toggle1="toggles.inqTyCdDcds"
                    title="강의타입"
                    :options1="inqTyCdDcds"
                    :useSubText="false"
                    sequence-key1="cd"
                    nameKey1="cdNm"
                    @update:toggle1="closeToggles(toggles, 'inqTyCdDcds')"
                />
                  </div>
                <div class="kb-form-item">
                  <div class="kb-form-row-wrap">
                      <p class="kb-form-label-text">- 스튜디오 Live : 여의도 KB국민은행 신관 스튜디오 생방송</p>
                      <p class="kb-form-label-text">- 자택 Live : 자택 생방송(필요시 지원기기 발송) </p>
                      <p class="kb-form-label-text">- 혼합형(녹화&Live) : 강의(사전녹화) + Q&A(생방송)</p>

                  </div>
                </div>
                <RowCustomDatepicker
                    title="희망시기"
                    v-model="param.tuitFeePayDd"
                    v-model:toggle="toggles.tuitFeePayDd"
                />
                <div class="kb-form-item">
                  <div class="kb-form-row">
                    <div class="kb-form-label">
                      <strong class="kb-form-label-text"> ※ 희망시기의 경우 담당자와 일정조정 후 변동할 수 있음</strong></div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="content-section" style="margin-top:20px;">
            <header class="section-header">
              <h6 class="title">강의주제</h6>
            </header>
            <div class="kb-form-fields kb-form-fields-v2 kb-form-fields-divider">
              <div class="kb-form-row">
                <textarea v-model="param.inqCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="강의 주제를 입력해주세요."></textarea>

              </div>
              <div class="kb-form-row ">
                <div class="bottom-column">
                  <span class="counter">{{ cnSz }} / 500</span>
                </div>
              </div>
            </div>

          </section>
          <section class="content-section" style="margin-top:20px;">
            <div class="kb-form-fields ">
              <div class="kb-form-row">
                <button class="kb-btn kb-btn-outline  kb-btn-content-item" @click="clickBoardAdd">저장</button>
                <button class="kb-btn kb-btn-outline kb-btn-content-item" @click="closeModal">닫기</button>

              </div>
            </div>
          </section>
        </div>

      </div>

    </div>
  </main>
</template>

<script>
import {computed, reactive, ref, toRefs} from 'vue';
// import RowInputText from '@/components/support/common/RowInputText';
// import Upload from '@/components/support/common/Upload';
import MobileInputSelect from '@/components/support/common/mobile/MobileInputSelect';
import {closeToggles} from '@/assets/js/ui.init';
import {initParams, isSuccess, isUploadSuccess} from '@/assets/js/util';
import {cttTableNm, insertFileAtch, insertQa} from '@/assets/js/modules/hrd/hrd-common';
import {useAlert} from '@/assets/js/modules/common/alert';
import store from '@/store';
import {MUT_SHOW_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';
import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';
import RowCustomDatepicker from '@/components/support/common/RowCustomDatepicker';
import {ACT_INSERT_LRN_BOARD} from "@/store/modules/board/board";
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {MUT_SHOW_MAIN} from "@/store/modules/support/support";
// import RowCustomDatepicker from "@/components/support/common/RowCustomDatepicker";

export default {
  name: 'MobileSumgoApplyModal',
  components: {MobileInputSelect, RowCustomDatepicker,LxpMobileHeader},
  props: {
    modelValue: Boolean,
    title: String,
    cttMstSn: Number,
    disabled: { type:Boolean,  default: false },
  },
  setup(props) {
    // const {showMessage} = useAlert();
    const {showConfirm, showMessage} = useAlert();
    const {cttMstSn} = toRefs(props);
    const paging = reactive({
      pageNo: 1,
      pageSize: 8,
      // pageSize: 1,
      totalCount: 0
    });

    const inqTyCdDcds = [{ cd: '2113001', cdNm: '스튜디오Live' }, { cd: '2113002', cdNm: '자택Live' }, { cd: '2113003', cdNm: '혼합형(녹화&Live)' }];

    const payToggles = reactive({
      cardKinds: false,
      cardAprvDt: false
    });

    const param = reactive({
      tblNm: cttTableNm,
      tblSn: (cttMstSn.value ? cttMstSn.value : 0),
      comInqSn: 0,
      // inqTitle: '강사 신청하기',
      inqTyCdDcd: '2113001',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: '',
      tuitFeePayDd: '',
    });

    const file = ref({ files: [], removed: [], binaries: [] });

    const toggles = reactive({
      inqTyCdDcds: false
    });

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const closeModal = () => {
      init();
      store.commit(`support/${MUT_SHOW_MAIN}`);
    };

    const validate = () => {
      if (!param.tuitFeePayDd) {
        showMessage('희망 시기를 선택해주세요.');
        return false;
      }
      if (!param.inqCn) {
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.inqCn.length > 500) {
        showMessage('내용을 500자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    const init = () => {
      initParams(param);
      initParams(file.value);
      param.tblNm = cttTableNm;
      param.tblSn = (cttMstSn.value ? cttMstSn.value : 0);
      param.inqTyCdDcd = '2091010';
    };

    const insertQaAndFiles = async () => {
      if (validate()) {
        let qa = await insertQa(param);
        if (isSuccess(qa)) {
          if (file.value.files.length > 0) {
            let fileRes = await insertFileAtch({comInqSn: qa.comInqSn}, file.value.files);
            if (isUploadSuccess(fileRes)) {
              showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
                closeModal();

                if (qa.badges && qa.badges.length > 0) {
                  store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
                  getHrdBadgeList();
                }
              });
            } else {
              showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
            }
          } else {
            showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
              closeModal();
            });

            if (qa.badges && qa.badges.length > 0) {
              store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
              getHrdBadgeList();
            }
          }
        } else {
          showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
        }
      }
    };

    // const selectedValue1 = computed(() => {
    //   if (props.options1) {
    //     const target = props.options1.find(x => {
    //       if (x[props.sequenceKey1] === props.value1) return x;
    //     });
    //     if (target) {
    //       return target[props.nameKey1];
    //     }
    //   }
    //   return (props.placeholder1 ? props.placeholder1 : '');
    // });param.inqTyCdDcd

    const addBoard = () => {
      store.dispatch(`board/${ACT_INSERT_LRN_BOARD}`, {
        distCrseSn: '203057',
        distCrseGrpSeqSn: '14',
        cn: param.inqCn,
        files: [],
        title: '강사신청',
        divCdDcd: '2013012',
        divNm: '강사신청',
        refTyCdDcd: param.inqTyCdDcd,
        refTyNm: inqTyCdDcds.find(e => e.cd === param.inqTyCdDcd).cdNm,
        refPost: param.tuitFeePayDd,
      }).then(res => {
        if(isSuccess(res)) {
          param.inqCn = '';
          showMessage('신청이 완료되었습니다.');
          closeModal();
          // getBoardWithCommentList();
        } else {
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    // const getBoardWithCommentList = (isInit) => {
    //   getListFunc(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST}`, {distCrseGrpSeqSn: '178', ...paging}, items, paging, () => {
    //     if(isInit) loadings.board = true;
    //     items.value.forEach(x => {refineBoard(x);})
    //   });
    // }

    const clickBoardAdd = () => {
      if(validate()) {
        showConfirm({
          title: '강사신청',
          text: '작성된 내용을 등록하시겠습니까?',
          callback: addBoard
        });
      }
    }

    return {
      inqTyCdDcds, param, file, toggles, cnSz, payToggles,
      closeModal, closeToggles, insertQaAndFiles, clickBoardAdd, paging,
    }
  }
};
</script>