<template>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <slot name="top" />
      <article v-if="learningRoadmapList" class="content-section">
        <header class="section-header">
          <h4 class="title">학습 로드맵</h4>
          <div class="header-side"><a class="sub-text text-dark kb-mouse-cursor" @click.stop="$router.push({name:'HrdCloudRoadmapMain'})">전체보기</a></div>
        </header>
        <div class="section-body">
          <swiper v-if="learningRoadmapList.length > 0"
                  class="hrdcloud-swiper"
                  :modules="modules"
                  slides-per-view="auto"
                  effect="coverflow"
                  :coverflow-effect="{'rotate':0, 'stretch':0, 'depth':100, 'modifier':1, 'slideShadows':false}"
                  :centered-slides="true" >
            <swiper-slide  v-for="(roadmap,idx) in learningRoadmapList" :key="idx" class="roadmap-swiper">
              <HrdCloudRoadmapCardV2 :data="roadmap.data" />
            </swiper-slide>
          </swiper>
          <LoadingDiv v-else />
        </div>
      </article>
      <template v-for="(item, idx) in mainList" :key="idx">
        <article class="content-section">
          <header class="section-header"><h4 class="title">{{ item.title }}</h4></header>
          <template v-if="!item.isLoading && item.items && item.items.length > 0">
            <HrdCloudMobileSwiper :items="item.items" :session="session" :show-put="true" />
            <div class="section-bottom">
              <router-link :to="{ path: `hrdcloud/${item.name}`}" custom v-slot="{ navigate }">
                <button class="kb-btn kb-btn-outline kb-btn-content-item" @click="navigate" @keypress.enter="navigate" role="link">
                  전체보기
                </button>
              </router-link>
            </div>
          </template>
          <template v-else-if="(item.items && item.items.length === 0) && !item.isLoading">
            <div class="search-container">
              <div class="result-empty" style="margin-top:50px;margin-bottom:30px;">
                <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                <p v-if="item.name === 'recommend'" class="text">해당 직무 추천 콘텐츠가 없습니다.</p>
                <p v-else-if="item.name === 'latest'" class="text">신규 콘텐츠가 없습니다.</p>
                <p v-else-if="item.name === 'recent'" class="text">최근 학습 콘텐츠가 없습니다.</p>
                <p v-else-if="item.name === 'latestPopularDuty' || item.name === 'latestPopularKnowledge'" class="text">최근 콘텐츠가 없습니다.</p>
              </div>
            </div>
          </template>
          <template v-else-if="item.isLoading">
            <LoadingDiv v-model="item.isLoading" />
          </template>
          <template v-if="idx === (mainList.length - 1)">
            <HrdCloudVisitor />
          </template>
        </article>
      </template>
    </div>
  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, onMounted, ref} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {EffectCoverflow} from "swiper";
import HrdCloudMobileSwiper from "@/components/hrdcloud/mobile/HrdCloudMobileSwiper";
import LoadingDiv from '@/components/common/LoadingDiv';
import HrdCloudVisitor from '@/components/hrdcloud/HrdCloudVisitor';
import {getHrdCloudLearningRoadmapSortedList} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";
import HrdCloudRoadmapCardV2 from "@/components/hrdcloud/HrdCloudRoadmapCardV2.vue";

export default {
  name: 'HrdCloudMobileMainList',
  components: {
    HrdCloudRoadmapCardV2,
    HrdCloudVisitor, LoadingDiv, HrdCloudMobileSwiper, Swiper, SwiperSlide},
  props: {
    mainList: Array
  },
  setup() {
    const store = useStore();
    const session = computed(() => store.state.auth.session);
    const learningRoadmapList = ref([])

    onMounted(() => {
      getHrdCloudLearningRoadmapSortedList(learningRoadmapList);
    })

    return {
      session,
      learningRoadmapList,
      modules: [EffectCoverflow]
    }
  }
};
</script>