<template>
  <template v-if="step === 'share'">
    <HrdCloudMobileHeader
        title="HRD 클라우드 공유"
        :show-back="true"
        :go-back-func="closeModal"
    />
  </template>
  <template v-else-if="step === 'search'">
    <HrdCloudMobileHeader
        placeholder="부서명 및 직원번호 / 직원명 검색"
        :show-back="true"
        :show-search="true"
        :search-by-query="false"
        :go-back-func="closeModal"
        :active-search="true"
        v-model:keyword="userInfo"
        @update:keyword="getUserList"
    />
  </template>
  <template v-else-if="step === 'select'">
    <HrdCloudMobileHeader
        title="HRD 클라우드 공유"
        :show-back="true"
        :go-back-func="closeModal"
    />
  </template>
  <main class="kb-main" id="kb-hrdcloud">
    <div class="main-content main-component">
      <template v-if="step === 'share'">
        <article class="content-section">
          <div class="kb-form-fields">
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">공유대상</span></label>
              </div>
              <div class="kb-form-row row-buttons">
                <button class="kb-btn kb-btn-outline kb-btn-content-item" @click="step='search'"><span class="text text-silver">부서명 및 직원번호 / 직원명 검색</span></button>
              </div>
            </div>
          </div>
        </article>
        <div class="page-buttons mt-0">
          <button class="kb-btn kb-btn-primary" disabled>공유하기</button>
        </div>
      </template>
      <template v-if="step === 'search'">
        <article class="content-section mt-0">
          <div v-if="items && items.length > 0" class="kb-form-check-group">
            <div v-for="(item, idx) in items" class="kb-form-check" :key="idx">
              <input type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_1" v-model="checkUsers" :value="item" @click="checkLength">
              <label for="chk_01_1" class="kb-form-check-label">
                <p class="text"><span class="text-wl">{{ item.lrnerId }}</span> {{ item.deptNm }} ・ {{ item.jbgdNm }} ・ {{ item.lrnerNm }} </p>
              </label>
            </div>
          </div>
        </article>
        <LoadingDiv v-if="isLoading"/>
        <div class="page-buttons mt-0">
          <button class="kb-btn kb-btn-primary" :disabled="checkUsers.length === 0" @click="step='select'">다음</button>
        </div>
      </template>
      <template v-if="step === 'select'">
        <article class="content-section">
          <div class="kb-form-fields">
            <div class="kb-form-field field-border-0">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">공유대상</span></label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-check-group">
                  <div v-for="(chk, idx) in checkUsers"  class="kb-form-check" :key="idx">
                    <input type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_1" v-model="checkUsers" :value="chk">
                    <label for="chk_01_1" class="kb-form-check-label">
                      <p class="text">{{ chk.lrnerId }} ・ {{ chk.deptNm }} ・ {{ chk.jbgdNm }} ・ {{ chk.lrnerNm }}</p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">공유 메시지</span></label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="notiCn" class="kb-form-control" placeholder="전송할 메시지를 입력해주세요."></textarea>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">공유 방법</span></label>
              </div>
              <div class="kb-form-row">
                <div class="kb-form-check-group">
                  <div class="kb-form-check">
                    <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_02" id="chk_02_1" :value="notiPfmCds.liiv">
                    <label for="chk_02_1" class="kb-form-check-label"><p class="text">리브똑똑</p></label>
                  </div>
                  <div class="kb-form-check">
                    <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_02" id="chk_02_2" :value="notiPfmCds.wiseNet">
                    <label for="chk_02_2" class="kb-form-check-label"><p class="text">메신저 쪽지</p></label>
                  </div>
                  <div class="kb-form-check">
                    <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_02" id="chk_02_3" :value="notiPfmCds.wiseEml">
                    <label for="chk_02_3" class="kb-form-check-label"><p class="text">내부메일</p></label>
                  </div>
<!--                    <div class="kb-form-check">-->
<!--                      <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_02" id="chk_02_4" :value="notiPfmCds.sms" >-->
<!--                      <label for="chk_02_4" class="kb-form-check-label"><p class="text">SMS</p></label>-->
<!--                    </div>-->
                </div>
              </div>
            </div>
          </div>
        </article>
        <div class="page-buttons mt-0">
          <button class="kb-btn kb-btn-primary" :disabled="checkUsers.length < 1" @click.stop="clickShare">공유하기</button>
        </div>
      </template>
    </div>
  </main>
</template>

<script>
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {computed, onBeforeUnmount, onMounted, reactive, ref} from 'vue';
import {notiPfmCds} from '@/assets/js/modules/noti/noti-common';
import {ACT_GET_USERS_LIST} from '@/store/modules/user/user';
import {compareArrays, getItems, initParams, isSuccess, lengthCheck, setPaging, setParams} from '@/assets/js/util';
import {insertCourseAct} from '@/assets/js/modules/course/course-common';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import LoadingDiv from '@/components/common/LoadingDiv';
import {lightMode, toggleConcentrationMode} from '@/assets/js/modules/hrd/hrd-common';
import {useRoute} from 'vue-router';

export default {
  name: 'HrdCloudMobileShare',
  components: {LoadingDiv, HrdCloudMobileHeader},
  props: {
    modelValue: Boolean,
    data: Object
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const {showConfirm, showMessage} = useAlert();
    const session = computed(() => store.state.auth.session);
    const isLoading = ref(false),
          step = ref('share'),
          userInfo = ref(''),
          notiCn = ref(''),
          items = ref([]),
          checkUsers = ref([]),
          paging = reactive({pageNo: 1, pageSize: 99999, totalCount: 0, hasMore: false}),
          notiPfmCd = ref([notiPfmCds.liiv , notiPfmCds.wiseNet])
    ;

    const {showLoading, hideLoading} = useAlert();

    const notiParams = reactive({
      notiMngSn: 0,
      notiTitle: '[HRD 클라우드] 콘텐츠 공유',
      notiSbjt: '',
      notiDesc: 'HRD 클라우드 공유',
      sndrNm: '인재개발부',
      notiTrprCdDcd : '2039001',
      notiTyCdDcd: '2032001', //'2032002',
      retrInclYn: 'N', // 퇴직자 포함 여부
      rsvtTrsmYn: 'N', // 예약발송여부
      rsvtTrsmDt: 0,
      aprvrId: '',
      aprvrNm: '',
      smsNotiYn: 'Y',
      insdMemoYn: 'Y',
      pushNotiYn: 'N',
      wiseEmlNotiYn: 'Y',
      emlNotiYn: 'N',
      kakaoNotiYn: 'N',
      chatBotNotiYn: 'N',
      liivNotiYn: 'Y',
      trnNotiYn: 'N',
      tmplYn: 'N',
      stt: '00',
      division: 'HrdCloud',
      channelName: 'ehrd',
      lrnObjDtlDistSn: 0,
      notiMsgDomains : [],
      notiTrgtDomains: [],
    });

    const crseActParam = reactive({
      crseMstSn: 0,
      distCrseSn: 0,
      distCrseNm: '',
      distChapSn: 0,
      distChapNm: '',
      lrnObjDtlDistSn: 0,
      objSn: 0,
      objNm: '',
      shareTrgtLrnerIdLst: '',
      shareTrgtLrnerNmLst: '',
    });

    const checkAll = computed(() => {
      let cnt = 0;
      items.value.map(item => {
        checkUsers.value.filter(user => {
          if (item.lrnerId === user.lrnerId) cnt++;
        });
      });

      if (cnt == items.value.length) return true;
      return false;
    });

    const clickCheckAll = () => {
      items.value.map(x => {
        if (checkUsers.value < 10 && checkUsers.value.filter(y => x.lrnerId === y.lrnerId).length === 0) checkUsers.value.push(x);
      });
    };

    const clickUnCheckAll = () => {
      checkUsers.value = [];
    };

    const unCheck = (idx) => {
      checkUsers.value.splice(idx, 1);
    };

    const getUserList = () => {
      if (isLoading.value) return;

      if (userInfo.value.length === 0) {
        showMessage('검색할 키워드를 입력해주세요.');
        isLoading.value = false;
        return;
      } else if (userInfo.value.length < 2) {
        showMessage('키워드를 2자 이상 입력해주세요.');
        isLoading.value = false;
        return;
      }

      isLoading.value = true;
      store.dispatch(`auth/${ACT_GET_USERS_LIST}`, {
        userInfo: userInfo.value,
        pageNo: paging.pageNo,
        pageSize: paging.pageSize
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res).filter(x => x.lrnerId != session.value.lrnerId);
          setPaging(paging, res);
        } else {
          items.value = [];
          paging.totalCount = 0;
        }
        isLoading.value = false;
      }).catch(e => {
        items.value = [];
        isLoading.value = false;
        console.error(e);
      });
    };

    const validate = () => {
      if(notiPfmCd.value.length < 1){
        showMessage('공유방법을 1개이상 선택해주세요.');
        return false;
      } else if(!notiCn.value) {
        showMessage('공유메시지를 입력해주세요.');
        return false;
      } else{
        return true;
      }
    };

    const clickShare = () => {
      if(validate()) {
        if (checkUsers.value.length > 0) {
          checkUsers.value.forEach(item => {
            crseActParam.shareTrgtLrnerIdLst = (crseActParam.shareTrgtLrnerIdLst !== '' ? crseActParam.shareTrgtLrnerIdLst += `,${item.lrnerId}` : item.lrnerId);
            crseActParam.shareTrgtLrnerNmLst = (crseActParam.shareTrgtLrnerNmLst !== '' ? crseActParam.shareTrgtLrnerNmLst += `,${item.lrnerNm}` : item.lrnerNm);

            notiParams.notiTrgtDomains.push({
              trprId: item.lrnerId,
              trprSn: item.userSn,
              trprNm: item.lrnerNm,
              trprTelno: item.mblTelno
            });
          });
        }

        if (notiPfmCd.value.length > 0) {
          notiPfmCd.value.forEach(x => {
            notiParams.notiMsgDomains.push({
              notiTitle: '[HRD 클라우드] 콘텐츠 공유',
              notiPfmCdDcd: x,
              notiCn: notiCn.value
            })
          });
        }

        if (notiParams.notiTrgtDomains.length > 0 && notiPfmCd.value.length > 0) {
          notiParams.lrnObjDtlDistSn = crseActParam.lrnObjDtlDistSn;

          showConfirm('공유 하시겠습니까?',async () => {
            showLoading();
            insertCourseAct(actTyCdDcds.ACT_TY_CD_SHARE, {
              ...crseActParam,
              notiMngDomain: {...notiParams}
            }, res => {
              hideLoading();
              if (isSuccess(res)) {
                showMessage({
                  text: '공유 완료 되었습니다.',
                  callback: () => {
                    isLoading.value = false;
                    step.value = 'success';
                    closeModal();
                  }
                });
              } else {
                isLoading.value = false;
                showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
              }
            }, () => {
              hideLoading();
              showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            });
          },
          () => {
            isLoading.value = false;
            initParams(crseActParam);
            notiParams.notiMsgDomains = [];
            notiParams.notiTrgtDomains = [];
          })
        }
      }
      isLoading.value = false;
    };

    const checkLength = (event) => {
      if (event.target.checked && checkUsers.value.length === 10) {
        showMessage({
          text: '최대 10명까지 공유할 수 있습니다.'
        });
        event.preventDefault();
      }
    };

    const closeModal = () => {
      if (step.value === 'search') {
        step.value = 'share';
      } else if (step.value === 'select') {
        step.value = 'search';
      } else {
        step.value = 'share';
        emit('update:modelValue', false);
      }
    };

    onMounted(() => {
      lightMode();
      setParams(crseActParam, props.data);
    });

    const route = useRoute();
    onBeforeUnmount(() => {
      if (route.name === 'HrdCloudContentBridge') toggleConcentrationMode();
    });

    return {
      step, isLoading,
      notiPfmCds, notiPfmCd, notiParams, notiCn,
      userInfo, items, paging, checkAll, checkUsers,
      unCheck, getUserList, checkLength,
      clickCheckAll, clickUnCheckAll,
      clickShare, closeModal, compareArrays
    }
  }
};
</script>