<template>
  <div :class="[ isMobile ? 'content-section section-banner' : 'intro-column intro-swiper']">
    <swiper
        :modules="modules"
        effect="fade"
        :pagination="{
            el: '.swiper-pagination',
            clickable: true
        }"
        :autoplay="{
            delay: 15000,
            disableOnInteraction: false,
            stopOnLast: true,
        }"
        :navigation="{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next'
        }"
        @swiper="setControlledSwiper"
        :style="slideStyle"
    >
      <swiper-slide v-for="(item, index) in banners" :key="index">
        <a href="javascript:" @click="goTo(item)">
          <div class="banner-background">
            <template v-if="item.type === 'video'" >
              <HrdCloudBannerVideo :video-src="getSrc(item)" />
            </template>
            <template v-else>
              <CommonLazyImage :img-src="getSrc(item)" :class="{ 'slide-image' : isMobile }" />
            </template>
            <div class="slide-content">
              <h3 class="title">{{ item.banerCn }}<div v-if="item && item.banerUrl && item.banerUrl.length > 0" class="hrd-right">
                <!--              <img src="../../assets/lxp/images/hrdcloud/hrdcloud_click.svg">-->
              </div></h3>
            </div>
          </div>
        </a>
      </swiper-slide>
      <div class="swiper-pagination"></div>
      <div v-if="!isMobile" class="swiper-buttons">
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </swiper>
  </div>
</template>
<style scoped>
.section-banner .banner-background img,
.section-banner .banner-background video {width:100%;height:100%;object-fit:cover;object-position:center;}
.section-banner .swiper-slide {position:relative;padding-top:0!important;}
.section-banner .banner-background {position:relative;height:100%;width:100%;}
</style>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
import CommonLazyImage from '@/components/common/CommonLazyImage';
import {Autoplay, Pagination} from 'swiper';
import {banerDivCdDcds, getBanerList} from '@/assets/js/modules/baner/common-baner';
import HrdCloudBannerVideo from '@/components/hrdcloud/HrdCloudBannerVideo.vue';
import {goLink} from "@/assets/js/modules/common/common";

export default {
  name: 'CommonBanner',
  components: {HrdCloudBannerVideo, CommonLazyImage, Swiper, SwiperSlide},
  props:{
    bannerCode: String,
  },
  setup(props) {

    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const banners = ref([]);

    const controlledSwiper = ref(null);
    const setControlledSwiper = (swiper) => {
      controlledSwiper.value = swiper;
    };

    const slideHeight = ref(0);
    const slideStyle = computed(() => {
      if(isMobile.value){
        return {'height': `${slideHeight.value}px`}
      }
      return {}
    });

    const tryCount = ref(0);

    const tryGetGripSize = () => {
      if(tryCount.value > 10) {
        slideHeight.value = 0;
        return;
      }
      if(controlledSwiper.value && controlledSwiper.value.slidesSizesGrid && controlledSwiper.value.slidesSizesGrid.length > 0){
        slideHeight.value = controlledSwiper.value.slidesSizesGrid[0];
      }else{
        tryCount.value++;
        setTimeout(() => {
          tryGetGripSize();
        }, 300);
      }
    };

    const getBanners = () => {
      getBanerList({banerDivCdDcd: banerDivCdDcds.baner, banerExpsrLocCdDcd: props.bannerCode },banners,() => {
        if (banners.value && banners.value.length > 0) banners.value.reverse();
        if(isMobile.value){
          tryGetGripSize();
        }
      });
    };

    const getSrc = (item) => {
      if (isMobile.value) return item.mobiThumb;
      return item.thumb;
    };

    const goTo = (item) => {
      if (item.banerUrl && item.banerUrl.length > 0) {
        goLink(item.banerUrl);
      }
    };

    getBanners();

    return {
      isMobile,
      slideStyle, setControlledSwiper,
      banners, getSrc, goTo,
      modules: [Pagination, Autoplay],
    }
  }
};
</script>