import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {closeToggles, getDcdOptions} from "@/assets/js/ui.init";
import {
    // getItems,
    getTodayDate,
    initParams,
    isSuccess,
    // lengthCheck,
    nullEmpty,
    numberComma,
    paramCheck,
    removeComma,
    setParams,
    dateToDateFormat
} from "@/assets/js/util";
import {
    applyBtnEnable,
    cancelSupport,
    checkUploadBinaries,
    getFileUploadFailResults,
    getLrnCostFld,
    getLrnCostTy,
    // getMyAcademyTotal,
    getSupportAcademyYearlyLimit,
    paymentDomain,
    paymentInfoValidation,
    proofDivCdDcds,
    saveSupport,
    setCostProof,
    showComplete,
    SUPPORT_ACADEMY_YEARLY_LIMIT,
    uploadCostproof,
    addDate,
    extensions,
} from "@/assets/js/modules/support/support-common";
import {
    ACT_DELETE_CANCEL_LRN_SPRT_ACAD,
    ACT_INSERT_LRN_SPRT_ACAD,
    ACT_UPDATE_LEARN_SUPPORT_ACADEMY, MUT_SHOW_MAIN ,MUT_HIDE_CLASS_MAIN
} from "@/store/modules/support/support";
import {useStore} from "vuex";

export const supportClassFeeVupCommon = (props, {emit}) =>  {
    const {showMessage, showLoading, hideLoading} = useAlert();
    const userAgreement = ref(false);
    const isLoading = ref(false), isInit = ref(false);
    const lrnCostDivs = ref([]), lrnCostFldLst = ref([]);
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);
    const isReadOnly = computed(() => (props.data && props.data.aplySttCdDcd !== '2106001'));
    const lrnCostFlds = computed(() => lrnCostFldLst.value.filter(x => x.lrnCostDivSn === param.lrnCostDivSn && x.lrnCostFldCn != 'V' ));

    const lrnMthdCdDcds = computed(() => getDcdOptions('2059'));

    const myTotalSprtExptAmt = ref(0); // 해당 년도(내가 지원 신청 한 누계액)
    const myTotalSprtAmt = ref(0); // 해당 년도 (내가 지원 받은 총 금액)
    const fixSprtExptAmt = ref(0); // 해당 년도(내가 지원 신청 한 누계액-지급처리중 금액)
    const fixSprtAmt = ref(0); // 해당 년도 (내가 지원 받은 총 금액-지급처리중 금액)

    const showSearchModal = ref(false);
    const isClassMain = ref(false);
    const license = ref({
        lcsnCd: null,
        lcsnNm: null,
        grd1Cd: null
    });

    // const extensions = ref(['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif']);
    //
    // if (prop.data.costAcdmFeeSn == null || prop.data.costAcdmFeeSn == 0 || )

    // 지원신청 누계액
    const totalSprtExptAmt = computed(() => {
        return Number(myTotalSprtExptAmt.value);
    });

    const toggles = reactive({
        lrnCostDivs: false,
        lrnCostFlds: false,
        lrnMthdCdDcd: false,
        cardKinds: false,
        lrnBgngDd: false,
        lrnEndDd: false,
        cardAprvDt: false,
        dpstYmd: false
    });

    const param = reactive({
        costAcdmFeeSn: 0,
        lrnCostDivSn: 0, // 학습구분키
        lrnCostFldSn: 0, // 학습분야키
        lrnSprtNm: '', // 과정명
        lrnMthdCdDcd: '', // 학습방법
        acadNm: '', // 학원명
        acadHmpg: '', // 학원 홈페이지
        acadCt: '', // 학원 연락처
        acadPic: '', // 학원 담당자
        acadAddr: '', // 학원 주소
        acadBzmnNo: '', // 학원 사업자번호
        lrnBgngDd: null, // 학습 시작일
        lrnEndDd: null, // 학습 종료일
        schlFee: 0, // 수강료
        sprtExptAmt: 0, // 지원 예상 금액
        sprtAmt: 0, //지원 금액
        rtnYn:'N',
        rtnAmt:0,
        addAmtYn:'N',
        addAmt:0,
        lcsnCd:'',
        lcsnNm:'',
        lcsnGrd:''
    });

    const paymentInfo = reactive({...paymentDomain});

    const lrnCertFiles = ref({ files: [], removed: [], binaries: [] }),
        receiptFiles = ref({ files: [], removed: [], binaries: [] }),
        acadCertFiles = ref({ files: [], removed: [], binaries: [] });

    // const totalItems = ref([]);

    // const lrnCostDivTotalFilter = computed(() => totalItems.value.filter(x => x.lrnCostDivSn === (param.lrnCostDivSn==1?1:(param.lrnCostDivSn==3?3:2))));

    const supportAcademyYearlyLimit = ref(SUPPORT_ACADEMY_YEARLY_LIMIT);

    const changeFld = () => {
        if(license.value != null) {
            param.lcsnCd = license.value.lcsnCd;
            param.lcsnNm = license.value.lcsnNm;
            param.lcsnGrd = license.value.grd1Cd;
        }
    };

    const dateRange = computed(() => {
        let str = '';
        if (param.lrnBgngDd && param.lrnBgngDd.ymdformat) {
            str = param.lrnBgngDd.ymdformat;
        }
        if (param.lrnEndDd && param.lrnEndDd.ymdformat) {
            str += `~${param.lrnEndDd.ymdformat}`;
        }
        return str;
    });

    const usrAgrmntRef = ref(null);
    const initLocalParams = () => {
        initParams(param);
        initParams(paymentInfo);
        initParams(lrnCertFiles.value);
        initParams(receiptFiles.value);
        initParams(acadCertFiles.value);
        userAgreement.value = false;
        if(props.data == null) {
            usrAgrmntRef.value.allUnChk();
        }
        isLoading.value = false;
    };

    const closeModal = () => {
        if (props.reList instanceof Function) props.reList();
        initLocalParams();

        if(isMobile.value) {
            store.commit(`support/${MUT_SHOW_MAIN}`);
        }else {
            emit('update:modelValue', false);
        }
    };

    const uploadLearnCertificate = () => {
        if (checkUploadBinaries(lrnCertFiles)) {
            return uploadCostproof({
                params: {costAcdmFeeSn: param.costAcdmFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_ACAD_REG_CERT},
                files: lrnCertFiles.value.files,
            });
        }
    };

    const uploadReceipt = () => {
        if (checkUploadBinaries(receiptFiles)) {
            return uploadCostproof({
                params: {costAcdmFeeSn: param.costAcdmFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_ACAD_RECEIPT},
                files: receiptFiles.value.files,
            });
        }
    };

    const uploadAcadCert = () => {
        if (checkUploadBinaries(acadCertFiles)) {
            return uploadCostproof({
                params: {costAcdmFeeSn: param.costAcdmFeeSn, proofDivCdDcd: proofDivCdDcds.PROOF_DIV_CD_ACAD_CERT},
                files: acadCertFiles.value.files,
            });
        }
    }

    const apply = async () => {
        param.schlFee = removeComma(param.schlFee);
        param.sprtExptAmt = removeComma(param.sprtExptAmt);
        param.sprtAmt = removeComma(param.sprtAmt);

        nullEmpty(param);
        nullEmpty(paymentInfo);
        showLoading();

        let applyRes = null, certUpRes = null, receUpRes = null, acadUpRes = null;
        let payload = {
            ...param, ...paymentInfo,
            lrnBgngDd: (param.lrnBgngDd && param.lrnBgngDd.ymdformat ? param.lrnBgngDd.ymdformat : null),
            lrnEndDd: (param.lrnEndDd && param.lrnEndDd.ymdformat ? param.lrnEndDd.ymdformat : null),
            removedFiles: lrnCertFiles.value.removed.concat(receiptFiles.value.removed).concat(acadCertFiles.value.removed)
        };

        if (param.costAcdmFeeSn > 0) {
            applyRes = await saveSupport(isLoading, `support/${ACT_UPDATE_LEARN_SUPPORT_ACADEMY}`, { costAcdmFeeSn: param.costAcdmFeeSn, params: {...payload}}, userAgreement, validate);
        } else {
            applyRes = await saveSupport(isLoading, `support/${ACT_INSERT_LRN_SPRT_ACAD}`, { ...payload }, userAgreement, validate);
            if (applyRes) {
                if(applyRes.result && applyRes.result.number === 301) {
                    showMessage(applyRes.result.message);
                } else {
                    param.costAcdmFeeSn = applyRes.costAcdmFeeSn;
                }
            }
        }

        if (param.costAcdmFeeSn > 0) {
            certUpRes = await uploadLearnCertificate();
            receUpRes = await uploadReceipt();
            acadUpRes = await uploadAcadCert();
        }

        // let failStr = getFileUploadFailResults({title: '수강증', res: certUpRes}, {title: '결제 영수증', res: receUpRes});

        let failed = getFileUploadFailResults(certUpRes, receUpRes, acadUpRes);

        if (isSuccess(applyRes)) {
            showComplete(isLoading, applyRes, failed, ()=>{
                closeModal();
            });
        } // 위에 validate 함수가 있어서 else 조건 넣으면 안됨.
        hideLoading();
    }

    const validate = () => {
        if (paramCheck(param.lrnCostDivSn)) {
            showMessage('학습 구분을 선택해주세요.');
            return false;
        }
        if (paramCheck(param.lrnCostFldSn)) {
            showMessage('학습 분야를 선택해주세요.');
            return false;
        }
        if (paramCheck(param.lrnSprtNm)) {
            showMessage('과정명을 입력해주세요.');
            return false;
        }
        if (paramCheck(param.lrnMthdCdDcd)) {
            showMessage('학습방법을 입력해주세요.');
            return false;
        }
        if (paramCheck(param.acadNm)) {
            showMessage('학원명을 입력해주세요.');
            return false;
        }
        if (paramCheck(param.acadHmpg)) {
            showMessage('학원 홈페이지를 입력해주세요.');
            return false;
        }

        if (paramCheck(param.lrnBgngDd.ymd)) {
            showMessage('학습 시작일 입력해주세요.');
            return false;
        }
        if (paramCheck(param.lrnEndDd.ymd)) {
            showMessage('학습 종료일을 입력해주세요.');
            return false;
        }

        let toDay = dateToDateFormat(new Date,'yyyyMMdd'); //).toISOString().substring(0,10);
        //if(toDay >= '20230901'){ // 2023.09.01부로 수료증등록완료, 학습종료후 지원신청 가능하게 변경됨
        if(toDay < param.lrnEndDd.ymd.replace("-", "")){
            showMessage('수강 과정 종료 후 신청해 주세요.<br>※ 학원비 신청기한 : 과정 종료일로부터 2개월 이내');
            return false;
        }

        if(dateToDateFormat(addDate(param.lrnEndDd.ymd, 62),'yyyyMMdd') < toDay){
            showMessage('학습 종료일로부터 2개월이상 경과된 경우<br>지원신청을 하실 수 없습니다.');
            return false;
        }
        if(param.lrnEndDd.ymd > dateToDateFormat(addDate(param.lrnBgngDd.ymd, 186),'yyyyMMdd')){
            showMessage('학습기간이 6개월이상인 경우<br>지원신청을 하실 수 없습니다.');
            return false;
        }

        if (param.lrnBgngDd.ymd === param.lrnEndDd.ymd) {
            showMessage('학습 시작일과 종료일이 같습니다.');
            return false;
        }

        if(lrnCertFiles.value.files.length == 0){
            showMessage('수강증을 등록해주세요');
            return false;
        }

        if(param.sprtExptAmt > 0 && receiptFiles.value.files.length == 0){
            showMessage('결제영수증을 등록해주세요');
            return false;
        }

        if(acadCertFiles.value.files.length == 0){
            showMessage('수료증을 등록해주세요');
            return false;
        }

        //}

        if (!param.schlFee) {
            param.schlFee = 0;
        }
        if (paramCheck(param.sprtExptAmt)) {
            showMessage('지원 예상 금액을 입력해주세요.');
            return false;
        }

        // console.log('supportAcademyYearlyLimit.value : ' + supportAcademyYearlyLimit.value);
        // console.log('myTotalSprtAmt.value : ' + myTotalSprtAmt.value);
        if(myTotalSprtAmt.value >= supportAcademyYearlyLimit.value){
        // if (Number(removeComma(totalSprtExptAmt.value)) > supportAcademyYearlyLimit.value) {
            showMessage('지원 한도액을 초과했습니다.');
            return false;
        }

        let result = paymentInfoValidation(paymentInfo);
        if (!result.isValid) {
            showMessage(result.txt);
            return result.isValid;
        }
        return true;
    };
    const searchModalFunc = () => {
        if (paramCheck(param.lrnCostDivSn)) {
            showMessage('학습 구분을 선택해주세요');
            return false;
        }
        if (paramCheck(param.lrnCostFldSn)) {
            showMessage('학습 분야를 선택해주세요');
            return false;
        }
        showSearchModal.value=true;
    };

    const searchMobModalFunc=()=>{
        isClassMain.value = true;
        store.commit(`support/${MUT_HIDE_CLASS_MAIN}`);
    }

    // const lrnYear = computed(() => props.data ? props.data.lrnBgngDd.year : getTodayDate('yyyy'));

    const updatePaymentInfo = (emitted) => {
        setParams(paymentInfo, emitted);
    };

    getLrnCostTy({acadUseYn: 'Y'}, lrnCostDivs);
    getLrnCostFld({acadUseYn: 'Y'}, lrnCostFldLst);

    const cancel = () => {
        cancelSupport(ACT_DELETE_CANCEL_LRN_SPRT_ACAD,param.costAcdmFeeSn,'학원비 지원', () => {
            closeModal();
        });
    }

    // const getMyAcademyTotalFun = () => {
    //     if (param.lrnCostDivSn == null || param.lrnCostDivSn == ""){
    //         return;
    //     }
    //
    //     getMyAcademyTotal({year: lrnYear.value, lrnCostDivSn:param.lrnCostDivSn}).then(res => {
    //         if(lengthCheck(res)) {
    //             totalItems.value = getItems(res);
    //             myTotalSprtExptAmt.value = lrnCostDivTotalFilter.value[0] && lrnCostDivTotalFilter.value[0].totalSprtExptAmt > 0 ? Number(lrnCostDivTotalFilter.value[0].totalSprtExptAmt) : 0;
    //             myTotalSprtAmt.value = lrnCostDivTotalFilter.value[0] && lrnCostDivTotalFilter.value[0].totalSprtAmt > 0 ? Number(lrnCostDivTotalFilter.value[0].totalSprtAmt) : 0;
    //         } else {
    //             myTotalSprtExptAmt.value = 0;
    //             myTotalSprtAmt.value = 0;
    //         }
    //     });
    // }

    const lrnYear = ref(props.data ? props.data.lrnBgngDd.year : getTodayDate('yyyy'));

    const getAmt=async()=>{
        let amt = reactive({
            limitAmt: 0,
            sprtExptAmt:0,
            sprtAmt:0,
            fixSprtAmt:0,
            fixSprtExptAmt:0
        });

        if(param.lrnBgngDd) lrnYear.value = param.lrnBgngDd.year;
        // await getMyAcademyTotalFun();
        if(param.lrnBgngDd) {
            // supportAcademyYearlyLimit.value = await getSupportAcademyYearlyLimit(param.lrnBgngDd.ymd, param.lrnCostDivSn, lrnYear.value);
            amt = await getSupportAcademyYearlyLimit(param.lrnBgngDd.ymd, param.lrnCostDivSn, lrnYear.value);
        }else{
            // supportAcademyYearlyLimit.value = await getSupportAcademyYearlyLimit(getTodayDate('yyyyMMdd'), param.lrnCostDivSn, lrnYear.value);
            amt = await getSupportAcademyYearlyLimit(getTodayDate('yyyy-MM-dd'), param.lrnCostDivSn, lrnYear.value);
        }
        supportAcademyYearlyLimit.value = amt.limitAmt;
        // myTotalSprtExptAmt.value = (lrnCostDivTotalFilter.value[0] && lrnCostDivTotalFilter.value[0].totalSprtExptAmt > 0) ? Number(lrnCostDivTotalFilter.value[0].totalSprtExptAmt) : 0;
        // myTotalSprtAmt.value = (lrnCostDivTotalFilter.value[0] && lrnCostDivTotalFilter.value[0].totalSprtAmt > 0) ? Number(lrnCostDivTotalFilter.value[0].totalSprtAmt) : 0;
        myTotalSprtExptAmt.value = amt.sprtExptAmt;
        myTotalSprtAmt.value = amt.sprtAmt;
        fixSprtExptAmt.value = amt.fixSprtExptAmt;
        fixSprtAmt.value = amt.fixSprtAmt;

        // console.log('supportAcademyYearlyLimit : ' + supportAcademyYearlyLimit.value);
    }

    watch(() => [param.costAcdmFeeSn, param.lrnCostDivSn, param.lrnBgngDd], async () => {
        // console.log(param.lrnCostDivSn)
        if (session.value.jbpsNm ==='사무직원' && (param.lrnCostDivSn !== 1 && param.lrnCostDivSn !== null)){
            showMessage("해당 구분 신청 대상자가 아닙니다.");
            param.lrnCostDivSn = null;
            return;
        }
        await getAmt();
    });

    const calAmt=()=>{
        let limitSprtExptAmt = 0;
        // console.log('schlFee : '+param.schlFee+'/supportAcademyYearlyLimit : '+supportAcademyYearlyLimit.value+'/totalSprtExptAmt : ' + myTotalSprtExptAmt.value);
        if(props.data && (props.data.aplySttCdDcd == '2106003' || props.data.aplySttCdDcd == '2106006')) { //지원금액 확정
            param.sprtExptAmt = param.sprtAmt;
        }else{
            // 최대 지원금액 : 수강료의 80%
            const calculatedFee = (Math.floor(param.schlFee * 0.8) / 10) * 10;
            // 건별 지원 한도 : 지원 한도액 - 지원신청 누계액
            limitSprtExptAmt = supportAcademyYearlyLimit.value - myTotalSprtAmt.value; //- totalSprtExptAmt.value;


            // 지원금액 <= 건별 지원 한도
            if (calculatedFee > limitSprtExptAmt) {
                param.sprtExptAmt = (limitSprtExptAmt > 0 ? limitSprtExptAmt : 0);
            } else {
                param.sprtExptAmt = calculatedFee;
            }
            // console.log('sprtExptAmt : ' + param.sprtExptAmt);
        }
    }

    watch(() => [param.costAcdmFeeSn, param.schlFee, supportAcademyYearlyLimit.value, myTotalSprtExptAmt.value], () => {
        calAmt();
        //console.log('param.sprtExptAmt : ' + param.sprtExptAmt);
    });

    onMounted(() => {
        //getMyAcademyTotalFun();
        if (props.data != null) {
            // setCostFld('');
            setParams(param, props.data);
            setCostProof(props.data.proofs, lrnCertFiles, proofDivCdDcds.PROOF_DIV_CD_ACAD_REG_CERT); // 수강증
            setCostProof(props.data.proofs, receiptFiles, proofDivCdDcds.PROOF_DIV_CD_ACAD_RECEIPT); // 수강료
            setCostProof(props.data.proofs, acadCertFiles, proofDivCdDcds.PROOF_DIV_CD_ACAD_CERT); // 수료증
            setParams(paymentInfo, props.data);
            setParams(license.value, props.data);
            license.value.grd1Cd = props.data.lcsnGrd;
            userAgreement.value = true;
            // await getAmt();
            // console.log('sprtAmt : ' + param.sprtAmt);
        }
        isInit.value = true;
    });

    return {
        isReadOnly, lrnCostDivs, lrnCostFlds, acadCertFiles, lrnMthdCdDcds, isInit,
        param, paymentInfo, toggles, lrnCertFiles, receiptFiles,
        fixSprtExptAmt, fixSprtAmt, totalSprtExptAmt, myTotalSprtAmt,
        userAgreement, validate, apply, dateRange,
        applyBtnEnable, closeToggles, closeModal, numberComma,
        updatePaymentInfo,cancel, SUPPORT_ACADEMY_YEARLY_LIMIT,
        supportAcademyYearlyLimit, extensions, usrAgrmntRef, searchModalFunc, showSearchModal, license, changeFld, searchMobModalFunc, isClassMain
    }
}