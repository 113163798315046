<template>
  <div class="kb" id="kb">
    <header class="kb-header" id="kb-header">
      <div class="header-component">
        <!-- header-title -->
        <div class="header-column header-title">
          연계정보(CI) 동의
        </div>
        <!-- //header-title -->
        <!-- header-util -->
        <div class="header-column header-util left">
          <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
              <i class="icon-history-back"></i>
            </a>
          </div>
        </div>
        <!-- //header-util -->
      </div>
    </header>
    <main class="kb-main" id="kb-educourse">
      <div class="main-content main-component">
        <div class="educourse-view-container">
          <div class="view-contents">
            <section class="content-section">
              <header class="section-header gold">
                <h4 class="title">[필수] 개인(신용)정보 수집ㆍ이용 동의서(임직원 연수용)</h4>
              </header>
            </section>
            <!-- popup-section:시간외 근무과정안내 -->
            <CourseConsent
                v-model="checked"
                :consents="consentsCI"
                :is-view="isView"
            />
          </div>

          <p class="title" style="display:block; font-weight:bold; margin: 10px; text-align: right;">{{ currentDateStr }} 성명 :
            <input v-model="param.text1" class="kb-form-control reg_text" id="" required="" type="text" placeholder="" data-parsley-id="1" style="padding-right:0px;width:50px;">
          </p>
          <div class="page-buttons">
            <button v-if="!isView" class="kb-btn kb-btn-primary" @click="clickCIAgreement"><span class="text">동의완료</span></button>
          </div>
          <p class="title" style="display:block; font-weight:bold; margin: 10px; text-align: left;">※ 본 동의는 인재개발부 포인트리 지급 목적에 한해 사용됩니다.
          </p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

import {reactive, ref} from "vue";
import {useAlert} from "@/assets/js/modules/common/alert";
import {useStore} from "vuex";
const {showConfirm, showMessage} = useAlert();
import {ACT_INSERT_AGREEMENT_CI} from "@/store/modules/live/live";
import CourseConsent from "@/components/common/CourseConsent.vue";
import {goBack} from "@/assets/js/modules/common/common";
import {timestampToDateFormat} from "@/assets/js/util";
// import {getItems} from "@/assets/js/util";

export default {
  name: 'MobileCIAgreementModal',
  components: {CourseConsent},
  // components: {InputSelect, RowIsPastDatepicker},
  props: {
    modelValue: Boolean,
    distCrseSn: String,
    lrnerId: String,
    lrnerNm: String
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {

    const store = useStore();
    const checked = ref([]);
    const consentsCI = ['ci1', 'ci2'];
    const isView = ref(false);

    const currentDateStr = timestampToDateFormat(new Date(), 'full-kr');

    const clickBack = () => {
      goBack();
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const param = reactive({
      text1: '',
      text2: ''
    });

    const clickCIAgreement = () => {
      if (param.text1 === store.state.auth.session.lrnerNm.trim() && checked.value.length === 1) {
        showConfirm({
          text: '동의서를 등록하시겠습니까?',
          callback: saveCIAgreement
        })
      } else if(checked.value.length < 1) {
        showMessage("동의여부를 확인해주시기 바랍니다.<br> (미동의 시 포인트리 지급이 제한됩니다.)");
      } else if (param.text1 === "") {
        showMessage("성명을 입력 바랍니다.");
      } else {
        showMessage("로그인한 직원의 성명과 일치하지 않습니다.");
      }
    };

    const saveCIAgreement = () => {
      store.dispatch(`live/${ACT_INSERT_AGREEMENT_CI}`, {
        lrnerId: props.lrnerId,
        lrnerNm: props.lrnerNm
      }).then(res=> {
        if(res.status && res.status.code == 200) {
          showMessage({
            text: 'CI 동의서 작성을 완료했습니다.',
            okBtnTitle: '확인',
            callback: () => {
              clickBack();
            },
          });
        } else {
          showMessage('CI 동의서 작성을 실패했습니다.<br/>관리자에게 문의바랍니다.');
        }
      }).catch(e => {
        console.error(e);
      });
    }

    return {
      closeModal,
      clickCIAgreement,
      param,
      checked,
      consentsCI,
      isView,
      currentDateStr,
      clickBack,
    }
  }
};
</script>