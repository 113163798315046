<template>
  <footer v-if="headerShow" class="kb-footer d-print-none" id="kb-footer">
    <div v-if="session.lrnerId" class="footer-row footer-divider">
      <div class="footer-notice footer-component">
        <ul class="notice-list swiper-wrapper" style="width: 80%;">
          <swiper

              :slides-per-view="1"
              :modules="modules"
              :navigation="{
                nextEl: '.kb-btn-notice-kb-nav-next',
                prevEl: '.kb-btn-notice-kb-nav-prev'
              }"
          >
            <swiper-slide v-for="(item, idx) in items" :key="idx" class="notice-item swiper-slide" style="margin: 0;">
              <div class="notice-date">
                <a href="javascript:" class="link">{{ item.regDt }}</a>
              </div>
              <div class="notice-title">
                <router-link :to="{name: 'HelpNoticeView', params: {ntcSn: item.comNtcSn}}" class="link">{{item.ntcTitle}}</router-link>
              </div>
            </swiper-slide>
            <!--            </swiper-slide>-->
          </swiper>
        </ul>
        <div class="notice-nav">
          <router-link to="/help/notice" class="more-link">공지 전체보기</router-link>
          <div class="nav">
            <button class="kb-btn-nav kb-btn-kb-nav-prev kb-btn-notice-kb-nav-prev"><i class="icon-arrow-prev"></i></button>
            <button class="kb-btn-nav kb-btn-kb-nav-next kb-btn-notice-kb-nav-next"><i class="icon-arrow-next"></i></button>
          </div>
        </div>
      </div>
    </div>
    <lxp-footer-menu/>
    <div class="footer-row footer-component">
      <div class="footer-copyright">
        <p class="copyright text-center mb-2">(주)국민은행 인재개발부</p>
        <p class="copyright text-center">Copyright (C) KB스타런. All Rights Reserved</p>
      </div>
    </div>
  </footer>
</template>

<script>

import LxpFooterMenu from "@/views/layout/lxp/footer/LxpFooterMenu";
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {expsrLocCds} from "@/assets/js/modules/help/help-common";
import {useRoute} from 'vue-router';
import {Pagination} from "swiper";

export default {
  name: 'LxpFooter',
  components: {
    LxpFooterMenu, Swiper, SwiperSlide
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const items = ref([]);

    const session = computed(() => store.state.auth.session);
    const headerShow = ref(false);

    if( route.query.nohead && route.query.nohead === 'only' ) {
      store.state.auth.isHaderShow = 1
    } else {
      if(store.state.auth.isHaderShow !== 1) {
        headerShow.value = true
      }
    }

    const getHelpNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_MAIN,
        pageSize: 5
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res).map(x => ({
            ...x,
            regDt: timestampToDateFormat(x.regDt, 'yyyy.MM.dd')
          }));
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getHelpNoticeList();

    return{
      session,
      headerShow,
      items,
      modules: [Pagination]
    }
  }
}
</script>
