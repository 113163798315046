<template>
  <main class="kb-main" id="kb" @click="closeAllToggle">
    <div class="main-content">
      <div class="ssl-body pb-0">
        <SSLMobileSlotHeader>
          <template v-slot:left>
            <div class="util util-history-back" @click="goList">
              <a href="javascript:" class="util-actions util-actions-history-back">
                <i class="icon-history-back"></i>
              </a>
            </div>
          </template>

          <template v-slot:right>
          </template>
        </SSLMobileSlotHeader>

        <div v-if="isReady" class="page-container">
          <div class="board-view-container">
            <div class="board-view">
              <div class="board-view-header view-component">
                <img v-if="socBoard.thumb" :src="socBoard.thumb" alt="">
                <div class="blur"></div>
                <div class="page-header-wrap">
                  <div>
                    <h2 class="title">{{ socBoard.title }}</h2>
                    <p class="page-editor">{{ socBoard.lrnerNm }}<span> ・ </span>{{ isGroup ? socBoard.refTyNm : socBoard.deptNm }}</p>
                  </div>
                  <div class="page-info">
                    <span class="text">{{ dateToFormat(socBoard.pblcnDt) }}</span>
                    <span class="text">{{ numberComma(socBoard.inqcnt) }}조회</span>
                    <span class="text">{{ numberComma(socBoard.sharedCnt) }}공유</span>
                  </div>
                </div>
              </div>

              <div class="board-view-body view-component" style="margin-top: 32px;">
                <div class="editor-area">
                  <div class="editor-contents" v-html="currentPage.cn"></div>
                </div>
                <SSLViewRel
                    :hash-tags="hashTags"
                    :ref-url="socBoard.refUrl"
                    :ref-post="socBoard.refPost"
                    :page-type="`main`"
                    :modal-dist-crse-cd="getCrseSnToDistCrseCd(socBoard.crseMstSn)"
                />

                <div class="like-container">
                  <div class="like">
                    <img src="@/assets/lxp/images/ssl/view/ic_like.png" alt="">
                    <div class="is-active">
                      <span>좋아요</span>&nbsp;<span class="like-num">{{ socBoard.likeCnt  }}</span>
                    </div>
                  </div>
                  <template v-if="postSns.length > 0">
                    <div class="move">
                      <button @click="goPage('prv')">
                        <span>◀</span>
                      </button>
                      <button @click="goPage('nxt')" style="margin-left: 20px">
                        <span>▶</span>
                      </button>
                    </div>
                  </template>
                </div>


              </div>




<!--              <div class="divider"></div>-->

              <div class="divider"></div>
              <SSLMobileComment ref="commentComp" :reg-dt="socBoard.pblcnDt"/>

            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>
<script>
import SSLViewRel from '@/components/ssl/view/SSLViewRel';
import SSLMobileComment from '@/components/ssl/view/mobile/SSLMobileComment';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import {sslTotalViewSetup} from "@/assets/js/modules/ssl/setup/ssl-total-view-setup";
import {getCrseSnToDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";

export default {
  name: 'SSLMobileTotalView',
  methods: {getCrseSnToDistCrseCd},
  components: {
    SSLMobileSlotHeader,
    SSLMobileComment,
    SSLViewRel
  },
  emits: ['acted'],
  setup: sslTotalViewSetup
}
</script>
