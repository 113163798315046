<template >
  <template v-if="isChecked">
      <Authentication v-if="isAuthentication"
                      header-title="로그인 2차 인증"
                      :user-info="userInfo"
                      @goSignIn="goSignIn"
                      :is-authentication="isAuthentication"
      />
      <!-- begin::kb-login -->
      <main v-else class="kb-login">
        <div class="login-cover">
          <img v-if="isMobile" :src="bgImageName">
          <video v-else src="../../assets/lxp/videos/login.mp4" autoplay="" muted="muted" loop=""></video>
        </div>
        <div class="login-component">
          <div class="logo">
            <img src="../../assets/lxp/images/login/kb_logo.svg">
          </div>
          <div class="auth-body">
            <div class="auth-forms">
              <div class="field-area">
                <div class="kb-form-input-group">
                  <input autocomplete="username" type="text" class="kb-form-input" placeholder="직원번호 입력"
                         v-model.trim="info.lrnerId"
                         @keyup.enter="enterPassword"
                  >
                  <div class="input-box"></div>
                </div>
              </div>
              <div class="field-area">
                <div class="kb-form-input-group">
                  <input autocomplete="current-password" type="password" class="kb-form-input" placeholder="비밀번호 입력"
                         v-model.trim="info.password"
                         @keyup.enter="signin"
                         id="passwordEle"
                  >
                  <div class="input-box"></div>
                </div>
              </div>
            </div>
            <button class="kb-btn kb-btn-primary kb-btn-lg kb-btn-login"
                    :class="{'gray-bg-Btn': isLoading, 'white-bg-Btn': isLoading}"
                    @click="signin"
            ><span class="text">로그인</span>
            </button>
<!--            <button v-if="isInside == 1" class="kb-btn kb-btn-primary kb-btn-lg kb-btn-login"-->
<!--                    :class="{'gray-bg-Btn': isLoading, 'white-bg-Btn': isLoading}"-->
<!--                    @click="ssoLogin"-->
<!--            ><span class="text">SSO 로그인</span>-->
<!--            </button>-->
            <div class="auth-footer">
              <div class="links">
                <!-- is-active -->
<!--                <a v-if="!isInApp" href="javascript:" :class="{ 'is-active' : isSaveIdActive }" class="link"-->
<!--                   @click="handleSaveId()"><span class="icon-check"></span>직원번호 저장</a>-->
<!--                -->
<!--                <span v-if="!isInApp" class="bar"></span>-->
                <router-link :to="{name: 'PasswordReissue'}" class="link">비밀번호 재발급</router-link>
              </div>
            </div>

          </div>

          <div v-if="isMobile && !isInApp" class="login-footer">
            <div class="login-btn-container">
<!--              <button class="kb-btn-etest" @click="showUnderconstruction()"><span class="text">eTest 응시</span></button>-->
<!--              <div class="divider"></div>-->
              <router-link :to="{name: 'EhrdAppInstall'}" class="kb-btn-etest"><span class="text">KB스타런 앱 설치</span></router-link>
            </div>
            <p class="footer-copyright mb-2">(주)국민은행 인재개발부</p>
            <p class="footer-copyright">Copyright (C) KB스타런. All Rights Reserved</p>
          </div>
        </div>

        <div v-if="!isMobile && !isInApp" class="footer-btns">
          <ol>
<!--            <li><a href="javascript:" @click="showUnderconstruction()"><span>eTest 응시</span></a></li>-->
            <li><router-link :to="{name: 'EhrdAppInstall'}"><span>KB스타런 앱 설치</span></router-link></li>
          </ol>
        </div>
        <!--        <button class="kb-btn kb-btn-white kb-btn-lg kb-btn-etest" @click="showUnderconstruction()"><span-->
        <!--            class="text">eTest 응시</span>-->
        <!--        </button>-->
      </main>
      <!-- end::kb-login -->
      <!-- begin::kb-footer -->
      <footer v-if="!isMobile" class="kb-footer" id="kb-footer">
        <div class="footer-row footer-component">
          <div class="footer-copyright">
            <p class="copyright mb-2">(주)국민은행 인재개발부</p>
            <p class="copyright">Copyright (C) KB스타런. All Rights Reserved</p>
          </div>
        </div>
      </footer>
      <!-- end::kb-footer -->
  </template>
</template>
<script>
import {onBeforeMount, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_SIGNIN_AUTH, TOKEN_KEY} from '@/store/modules/auth/auth';
import {getItem, isSuccess, setParams} from '@/assets/js/util';
import Authentication from '@/components/auth/Authentication';
import {useAlert} from '@/assets/js/modules/common/alert';
import ApiService from '@/assets/js/api.service';
import {saveToken} from '@/assets/js/localstorage.service';
import {useRoute, useRouter} from 'vue-router';
import {setInside, setLogout} from '@/assets/js/modules/auth/auth-common';
import navigationUtils, {isInApp} from "@/assets/js/navigationUtils";
// import {getToken} from '@/assets/js/sessionstorage.service';

export default {
  name: 'SigninDirect',
  components: {Authentication},
  setup() {
    const store = useStore();
    const isLoading = ref(false);

    const isMobile = navigationUtils.any();

    const {showMessage} = useAlert();

    const info = reactive({
      lrnerId: '',
      password: '',
    });

    const userInfo = reactive({
      lrnerNm : '',
      lrnerId : '',
      brdt : '',
      mblTelno : '',
      emlAddr : '',
      deptNm: ''
    });

    const isChecked = ref(false);
    const isInside = ref(0);

    const isSaveIdActive = ref(false);
    const isAuthentication = ref(false);

    const validate = () => {
      let result = false;
      isLoading.value = false;

      if(!info.lrnerId) {
        showMessage('직원번호를 입력해주세요.');
      } else if(!info.password) {
        showMessage('비밀번호를 입력해주세요.');
      } else{
        result = true;
        isLoading.value = true;
      }

      return result;
    }

    const route = useRoute();
    const router = useRouter();

    const enterPassword = () =>{
        document.getElementById("passwordEle").focus();
    }

    const signin = () => {
      document.getElementById("passwordEle").blur();
      if (isLoading.value) return;
      isLoading.value = true;

       if(validate()) {
        store.dispatch(`auth/${ACT_SIGNIN_AUTH}`, info).then(res => {

          console.log(res)
          if(process.env.NODE_ENV === 'local'){
            if(isSuccess(res)) {
              saveToken(TOKEN_KEY, res.authToken);
              if (route.query.redirect && !route.query.redirect.includes('/signin')) {
                if(route.query.q ) {
                  router.push({path: route.query.redirect, query: JSON.parse(window.decodeURIComponent(route.query.q))})
                } else {
                  router.push({path: route.query.redirect});
                }
              }else {
                router.push('/main');
              }
            } else {
              showMessage('아이디 또는 비밀번호를 다시 확인해주세요.');
            }
          }else{
            if(isSuccess(res)) {
              setParams(userInfo,  getItem(res));
              isAuthentication.value = true;
            } else if(res.result.number === 301) {
              const msg = 'ID 또는 비밀번호가 일치하지 않습니다. <br/> 로그인 정보를 확인하시고 다시 시도하여 주십시오.';
              let msgText = '';

              if(res.lgnFailCnt > 2 && res.lgnFailCnt < 4) {
                msgText = `${msg} 로그인 정보를 확인하시고 다시 시도하여 주십시오. 부여된 권한에 위반되는 행위는 <정보통신망 이용촉진 및 정보보호등에 관한 법률>에 의하여 형사상 책임등의 조치가 있을 것입니다! (오류횟수 : ${res.lgnFailCnt}회)`;
              } else if(res.lgnFailCnt > 5){
                msgText = `비밀번호 오류입력 횟수가 5회 이상입니다. <br/> 보안카드 로그인 또는 비밀번호 찾기를 통해 로그인 하시면 초기화 됩니다. 부여된 권한에 위반되는 행위는 <br/> <정보통신망 이용촉진 및 정보보호등에 관한 법률>에 의하여 형사상 책임등의 조치가 있을 것입니다!`;
              } else {
                msgText = `${msg} (오류횟수 : ${res.lgnFailCnt}회)`;
              }

              showMessage(msgText);
            } else if(res.result.number === 302) {
              showMessage({
                text: res.result.message,
                callback: () => {
                  router.push({name: 'PasswordReissue'});
                }
              });
            } else {
              showMessage('아이디 또는 비밀번호를 다시 확인해주세요.');
            }
          }
          isLoading.value = false;
        }).catch((e) => {
          console.log(e);
          alert('error');
          isLoading.value = false;
        });
      }

    };

    const showUnderconstruction = () => {
      return alert('준비중입니다.');
    };

    const handleSaveId = () => {
      isSaveIdActive.value = !isSaveIdActive.value;
      showUnderconstruction();
    };

    const goSignIn = () => {
      info.lrnerId = '';
      info.password = '';

      isAuthentication.value = false;
    }

    const ssoLogin = () => {
      // 내부 sso 는 한번만..
      const ssoCount = window.localStorage.getItem('kbssofailno');
      if ( ssoCount ) {
        window.localStorage.removeItem('kbssofailno')
        return
      }
      window.localStorage.setItem('kbssofailno', '0');

      if (route.query.redirect && !route.query.redirect.includes('/signin')) {
        if(route.query.q ) {
          window.location.href = '/sso/business?redirect=' + route.query.redirect + '&q=' + route.query.q;
        } else {
          window.location.href = '/sso/business?redirect=' + route.query.redirect;
        }
      } else {
        window.location.href = '/sso/business';
      }
    }

    onBeforeMount(() => {
      // SSO 연동 후 로그아웃 했을 때 직번, 비밀번호 로그인 할 수 있게 처리
      const isLogout = store.state.auth.isLogout // getToken('isLogout');

      setLogout(false)

      if(isLogout) {
        isChecked.value = true;
        if( !store.state.auth.isInside) {
          ApiService.post('/v1/app/ips/mngs', {}).then(res => {
            if(isSuccess(res)) {
              setInside(0)
              isInside.value = 0
            } else {
              setInside(0);
            }
          }).catch(e => {
            console.error(e);
          });
        } else {
          isInside.value = store.state.auth.isInside
        }
      } else {
        isChecked.value = true
        // 내부망 IP 체크 하여 SSO 연동 또는 로그인 페이지 이동
        if( !store.state.auth.isInside) {
          ApiService.post('/v1/app/ips/mngs', {}).then(res => {
            if(isSuccess(res)) {
              setInside(0)
              // ssoLogin()
            } else {
              setInside(0)
              isChecked.value = true
            }
          }).catch(e => {
            isChecked.value = false
            console.error(e)
          })
        } else {
          isInside.value = 0
        }
    }
  })

    /* 매번 들어올때마다 이미지 변경 bg_0~3.jpg*/
    const randomNumber = Math.floor(Math.random() * 4);
    const bgImageName = ref(require(`../../assets/lxp/mobile/images/login/bg_${randomNumber}.jpg`));

    return {
      isChecked,
      isInside,
      isLoading,
      info,
      userInfo,
      isSaveIdActive,
      isAuthentication,
      isMobile,
      bgImageName,
      enterPassword,
      signin,
      showUnderconstruction,
      handleSaveId,
      goSignIn,
      ssoLogin,
      isInApp
    };
  },
};
</script>
<style scoped>
.kb-login .footer-btns ol li:first-child{}
.kb-login .footer-btns ol li{width: 100% !important;}
</style>