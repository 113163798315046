<template>
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-util -->
      <div class="header-column header-util left">
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
        <div class="util util-search-input">
          <input v-model="search.lrnCostFldNm" type="text" placeholder="자격증명 검색" v-if="lrnCostDivSn == '4' || lrnCostDivSn == '2'">
          <input v-model="search.lrnCostFldNm" type="text" placeholder="평가명 검색" v-else>
        </div>
      </div>
      <!-- //header-util -->
      <!-- header-util -->
      <div class="header-column header-util right">
        <div class="util util-search">
          <a href="javascript:" class="util-actions util-actions-search" @click="clickSearch">
            <i class="icon-search"></i>
          </a>
        </div>
      </div>
      <!-- //header-util -->
    </div>
  </header>
  <main class="kb-main" id="kb-support">
    <!-- main-content -->
    <div class="main-content main-component">
      <div class="search-result">
        <div class="view-contents">

          <!-- content-section -->
          <section v-for="(item, idx) in items" :key="idx" class="content-section">
            <div class="content-item bd-clear pd-clear">
              <div class="desc" v-if="lrnCostDivSn == '2'">
                <p><b>{{ item.lcsnNm }}(등급 : {{item.grd1Cd}})</b></p>
              </div>
              <div class="desc" v-else>
                <p><b>{{ item.lrnCostFldNm }}</b></p>
              </div>
              <div class="kb-btn-actions">
                <button class="kb-btn-support" @click="selectedLicense(idx)">선택</button>
              </div>
            </div>

          </section>
        </div>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import {useStore} from "vuex";
import {onMounted, reactive, ref} from "vue";
import {initParams, numberComma} from "@/assets/js/util";
import {
  ACT_GET_ACAD_GRD_LCSN,
  ACT_GET_LRN_COST_FLD,
  MUT_SHOW_EVL_MAIN,
  MUT_SHOW_CLASS_MAIN,
} from "@/store/modules/support/support";
import {getListFunc} from "@/assets/js/ui.init";

export default {
  name: "MobileEvlSearch",
  props: {
    modelValue: Boolean,
    lrnCostDivSn: Number,
    selectedValue: Object,
    keyword: String
  },
  emits:['update:modelValue', 'update:selectedValue'],
  setup(props, {emit}) {
    const store = useStore();

    const isLoading = ref(false);
    const search = reactive({
      lrnCostDivSn : props.lrnCostDivSn,
      lrnCostFldNm : props.selectedValue.lrnCostFldNm
    });
    const items = ref([]);
    const paging = reactive({
      pageNo: 1,
      pageSize: 999999,
      totalCount: 0
    });

    const clickSearch = () => {

      if(search.lrnCostDivSn == '2'){
        getListFunc(`support/${ACT_GET_ACAD_GRD_LCSN}`, search, items);
      }else {
        getListFunc(`support/${ACT_GET_LRN_COST_FLD}`, search, items);
      }
    }

    const goBack = () => {
      initParams(search);

      if(props.lrnCostDivSn == '2'){
        store.commit(`support/${MUT_SHOW_CLASS_MAIN}`);
      }else{
        store.commit(`support/${MUT_SHOW_EVL_MAIN}`);
      }
      emit('update:modelValue', false);
    }



    const selectedLicense = (idx) => {
      emit('update:selectedValue', items.value[idx]);

      goBack();
    }

    onMounted(()=>{
      console.log(props.selectedValue);
      if(search) {
        clickSearch();
      }
    })

    return {
      isLoading,
      search,
      items,
      paging,

      clickSearch,
      selectedLicense,
      goBack,
      numberComma
    }
  }
}
</script>

<style scoped>

</style>