import store from '../../../../store';
import {MUT_SHOW_MESSAGE} from '@/store/modules/common/common-index';

import {
  ACT_GET_DSJ_CHK,
  ACT_GET_CLASS_DSJ_CHK,
  ACT_GET_LEARN_SUPPORT_ACADEMY,
  ACT_GET_LEARN_SUPPORT_ACADEMY_TOTAL,
  ACT_GET_LEARN_SUPPORT_EVALUATION,
  ACT_GET_LEARN_SUPPORT_LICENSE,
  ACT_GET_LEARN_SUPPORT_OFFICER,
  ACT_GET_LEARN_SUPPORT_SCHOOL,
  ACT_GET_LRN_COST_FLD,
  ACT_GET_LRN_COST_TY,
  ACT_GET_LRN_SPRT_LCSN_RENEW_LIST, ACT_GET_REFUND_CHK,
  ACT_GET_LRN_SPRT_LCSN_RENEW_LIST2, ACT_GET_ACAD_LMT_AMT //, ACT_SEND_TMP_MSG
} from '@/store/modules/support/support';
import {getListFunc} from '@/assets/js/ui.init';
import {
  getCheckItem,
  getItem,
  isSuccess,
  lengthCheck,
  paramCheck,
  setYmdObject,
  timestampToDateFormat
} from '@/assets/js/util';
import {useAlert} from '@/assets/js/modules/common/alert';
import {ACT_GET_COLLEGE_LIST, ACT_GET_MAJOR_LIST,} from '@/store/modules/common/school';
import {ACT_GET_LRN_LCSN_LIST} from '@/store/modules/license/license';
import {ACT_DOWNLOAD_COST_PROOF, ACT_INSERT_LRN_COST_PROOF,} from '@/store/modules/costproof/costproof';
import {reactive} from "vue";
// import {reactive} from "vue";

export const supportTableNms = {
 ACADEMY: 'LMS_LRN_SPRT_ACAD',
 EVALUATION: 'LMS_LRN_SPRT_EVL',
 SCHOOL: 'LMS_LRN_SPRT_SCHL',
 OFFICER: 'LMS_LRN_SPRT_OFFCR',
 LICENSE: 'LMS_LRN_SPRT_LCSN'
};
export const stlmMthdCdDcds = {
  STLM_MTHD_CD_CARD   : '2058001',
  STLM_MTHD_CD_DEPOSIT: '2058002'
};

export const schlDivCdDcds = {
  SCHL_DIV_CD_KNOU  : '2061001', //방송통신 대학・대학원
  SCHL_DIV_CD_COMM  : '2061002', //전문대
  SCHL_DIV_CD_PHD   : '2061003', //대학교(학사)
  SCHL_DIV_CD_MD    : '2061004', //대학원(석사)
  SCHL_DIV_CD_CREDIT: '2061005' //학점은행제
};

export const schlInDivCdDcds = {
  SCHL_IN_DIV_CD_NEW: '2062001',
  SCHL_IN_DIV_CD_OLD: '2062002'
};

export const termDivCdDcds = {
  TERM_DIV_CD_FRESHMEN_1  : '2063001',
  TERM_DIV_CD_FRESHMEN_2  : '2063002',
  TERM_DIV_CD_SOPHOMORE_1 : '2063003',
  TERM_DIV_CD_SOPHOMORE_2 : '2063004',
  TERM_DIV_CD_JUNIOR_1    : '2063005',
  TERM_DIV_CD_JUNIOR_2    : '2063006',
  TERM_DIV_CD_SENIOR_1    : '2063007',
  TERM_DIV_CD_SENIOR_2    : '2063008'
};

export const proofDivCdDcds = {
  PROOF_DIV_CD_LCSN_COPY              :"2060001", // 자격증 사본
  PROOF_DIV_CD_LCSN_INV_PROT_EDU_CERT :"2060002", // 투자자보호교육 수료증
  PROOF_DIV_CD_LCSN_CERT              :"2060003", // 수강증 및 학원비영수증
  PROOF_DIV_CD_LCSN_EXAM_FEE          :"2060004", // 응시료 영수증
  PROOF_DIV_CD_LCSN_BOOK_FEE          :"2060005", // 도서주문내역 및 영수증
  PROOF_DIV_CD_LCSN_UDT_COPY          :"2060006", // 갱신증명서 사본
  PROOF_DIV_CD_LCSN_UDT_COST_PROOF    :"2060007", // 납부증빙서류
  PROOF_DIV_CD_ACAD_REG_CERT          :"2060008", // 수강증
  PROOF_DIV_CD_ACAD_RECEIPT           :"2060009", // 결제영수증
  PROOF_DIV_CD_ACAD_CERT              :"2060010", // 수료증
  PROOF_DIV_CD_EVL_TRANSCRIPT         :"2060011", // 성적증명서 등록
  PROOF_DIV_CD_EVL_RECEIPT            :"2060012", // 결제영수증
  PROOF_DIV_CD_SCHL_LAST_SEM_TRANS    :"2060013", // 직전학기 성적증명서 사본
  PROOF_DIV_CD_SCHL_TUIT_CERT         :"2060014", // 등록금 납입증명서
  PROOF_DIV_CD_SCHL_SCHOLAR_CERT      :"2060015", // 장학금수혜증명서
  PROOF_DIV_CD_OFFCR_RECEIPT          :"2060016", // 등록금 납입증명서
};

export let SUPPORT_EVL_YEARLY_LIMIT = Number(800000);

export let SUPPORT_ACADEMY_YEARLY_LIMIT = Number(960000);

export const getSupportAcademyYearlyLimit = async (lrnBgngDd, lrnDivision, year) => {
    // let retVal = SUPPORT_ACADEMY_YEARLY_LIMIT;
  console.log('getSupportAcademyYearlyLimit('+lrnBgngDd+', '+lrnDivision+', '+year+') : ');
  let bgngDd = lrnBgngDd.replaceAll("-", "").replaceAll(".", "");
  console.log('getSupportAcademyYearlyLimit('+bgngDd+', '+lrnDivision+', '+year+') : ');

    let retVal = reactive({
      limitAmt: 960000,
      sprtExptAmt:0,
      sprtAmt:0,
      fixSprtExptAmt:0,
      fixSprtAmt:0
    });

    await store.dispatch(`support/${ACT_GET_ACAD_LMT_AMT}`, {
      year: year
    }).then(res => {
      if (lrnDivision == '1') {
        retVal.sprtExptAmt = res.frSprtAmt;
        retVal.sprtAmt = res.frSprtAmt;
        retVal.fixSprtExptAmt = res.frFixSprtExptAmt;
        retVal.fixSprtAmt = res.frFixSprtAmt;
      } else {
        retVal.sprtExptAmt = res.itSprtAmt;
        retVal.sprtAmt = res.itSprtAmt;
        retVal.fixSprtExptAmt = res.itFixSprtExptAmt;
        retVal.fixSprtAmt = res.itFixSprtAmt;
      }

      if(lrnBgngDd != null && lrnBgngDd != '' && bgngDd >= '20241122') {
        if (lrnDivision == '1') {
          retVal.limitAmt = res.frLmtAmt;
        }else{
          retVal.limitAmt = res.itLmtAmt;
        }
      }

    }).catch(e => {
      console.error(e);
    });

    return retVal;
}

export const grdTots = [
  {nm: '3.0', val: 3.0},
  {nm: '3.5', val: 3.5},
  {nm: '4.0', val: 4.0},
  {nm: '4.5', val: 4.5},
  {nm: '100', val: 100}
];

export const grdTots2 = [
  {nm: '4.5', val: 4.5},
  {nm: '100', val: 100}
];

export const eduInstDivCds = [
  { nm: '대학교(원)', val: 1 },
  { nm: '협회 ・ 신문사 ・ 단체 등', val: 2 },
];

export const sprtQlfcDivCds = [
  { nm: '부점장 기간 중 최초 신청', val: 1 },
  { nm: '동일과정 2학기 신청 (1년 이내 과정의 2학기)', val: 2 },
  { nm: '전국부점장전략회의 포상 부점장', val: 3 },
  { nm: '국은인상 ∙ RM챔피언 ∙ 올해의PB (L3이상 팀원∙팀장)', val: 4 },
];

export const sprtRsnDivCds = [
  { nm: '영업(업무) 관련', val: 1 },
  { nm: '자기계발', val: 2 },
];


export const extensions = ['bmp','gif','jpe','jpeg','jpg','svg', 'png', 'tif'];

// 디비 만들어짐
export const updatableLicenses = [
  'WR70','WA01','WR9A','AA06','WR3I','WR7L','WR84','WR71','WR53','WR58','WR7M','WR9C','WR4D','WR3A','WR68','WR0J','NR04','WR2Y','WR67','WR3L','WR4C','WR0W','WR3J','WR0C','HR18','WR6U','WR9Q','WR6T','NR95','WR95','WR85'
];

// 투자자보호 자격증
// export const invProtLcsn = () => ['WR7A','WR7G','WR6Z','WR7H'];
// 등급 자격증
// export const grdLcsn = () => ['AA01','AA02','AA03','AA04','AA05','AA06','AA07','BA01','FA01','HA01','HA02','HA03','HA04','HA05','HA06','HA07','HA08','HB02','HB05','HD01','HR01','HR18','IA01','J211','J220','J221','JA01','JM10','JM11','JM12','JM1A','JM20','JM21','JM22','JM30','JM31','JM32','JM40','JM41','JM42','JM50','JM51','JM52','JM60','JM61','JM62','JM70','JM71','JM72','JM80','JM81','JM82','JM8A','JM92','NR01','NR02','NR04','VQ00','VQ01','VQ02','VQ03','VQ06','VR08','VR09','WA01','WB02','WR00','WR01','WR02','WR05','WR06','WR0C','WR0H','WR0I','WR0J','WR0M','WR0W','WR14','WR19','WR1D','WR1G','WR1H','WR22','WR23','WR29','WR2H','WR2I','WR2J','WR2Y','WR31','WR34','WR36','WR38','WR3A','WR3I','WR3J','WR3K','WR3L','WR3M','WR3N','WR40','WR41','WR42','WR43','WR46','WR47','WR4C','WR4D','WR4H','WR4O','WR53','WR54','WR56','WR57','WR58','WR5H','WR5K','WR5L','WR5T','WR60','WR61','WR66','WR67','WR68','WR69','WR6T','WR6U','WR70','WR71','WR79','WR7E','WR7F','WR7K','WR7L','WR7M','WR80','WR83','WR84','WR85','WR86','WR8J','WR8K','WR8L','WR8M','WR95','WR96','WR9A','WR9B','WR9C','WR9L','WR9M','WR9N','WR9O','WR9P','WR9Q','WR9Z','WRA2','WRA4'];
export const downloadGuideFiles = [
  { page: 'class', fileKey: 'SupportClassGuide', files: [ { title: '학원비 지원 안내', fileKey: 'SupportClassGuide' } ] },
  { page: 'evaluation', fileKey: 'SupportEvaluationGuide', files: [ { title: '평가비 지원 안내', fileKey: 'SupportEvaluationGuide' } ] },
  { page: 'school', fileKey: 'SupportSchoolGuide', files: [  { title: '부점장 최고경영자과정 지원 안내', fileKey: 'SupportSchoolGuide_Vice' }, { title: '학비 지원 안내', fileKey: 'SupportSchoolGuide' } ] },
  { page: 'license', fileKey: 'SupportLicenseGuide', files: [ { title: '보험대리점 등록절차 안내', fileKey: 'SupportLicenseWorkGuide' }, { title: '자격증 취득비용 지원 안내', fileKey: 'SupportLicenseGuide' }, { title: '자격증 갱신비용 지원 안내', fileKey: 'SupportLicenseRenewal' }] },
  // { page: 'dityLicense', files: [ { title: 'DITY 지원자격증 목록', fileKey: 'SupportDityLicenseGuide' } ] },
  { page: 'mobileEval', fileKey: 'SupportEvaluationGuide_MB', files: [  { title: '평가비 지원 안내', fileKey: 'SupportEvaluationGuide' }, { title: 'DITY 지원자격증 목록', fileKey: 'SupportDityLicenseGuide' } ] },
];

export const downloadAbilityGuideFiles = [
  { page: 'ability', files: [ { title: 'hrd100_2011', fileKey: 'AbilityCredit_HRD100' },
                            { title: 'hrd652_2006', fileKey: 'AbilityCredit_HRD652' },
                            { title: 'hrd627_2014', fileKey: 'AbilityCredit_HRD627' } ] },
];

// 갱신 가능한 자격증
export const lcns1YearExpr = [ 'WR70', 'WR7L', 'WR84', 'NR04', 'WR0C' ];
export const lcns2YearExpr = [ 'WR71','WR58','WR3A','WR68','WR2Y','WR67','WR85' ];
export const lcns3YearExpr = [ 'WR9A','WR3I','WR53','WR7M','WR9C','WR4D','WR3L','WR0W','WR3J','HR18','WR6U','WR9Q','WR95' ];
export const lcns5YearExpr = [ 'WA01','AA06','WR0J','WR4C','WR6T','NR95' ];

export const stockFundLcsn = [ 'WR2B','WR0X','WR2M','WR6Z','WR2Z' ];
export const derivationFundLcsn = [ 'WR7B','WR3D','WR2O','WR3O','WR6Z','WR2Z','WR7D','WR3B' ];
export const realEstateFundLcsn = [ 'WR7C','WR3C','WR2N','WR6Z','WR2Z','WR7D','WR3B' ];
export const derivationLcsn = [ 'WR7A','WR2X','WR2J','WR86','WR47','WR36' ];
export const lifeInsuranceLcsn = [ 'WR72','WR74' ];
export const generalInsuranceLcsn = [ 'WR73','WR75' ];
export const thirdInsuranceLcsn = [ 'WR77','WR78' ];
export const variableInsuranceLcsn = [ 'WR76','WR9D' ];

export const getExprYear = (lcsnCd) => {
  if (lcns1YearExpr.includes(lcsnCd)) {
    return 1;
  } else if (lcns2YearExpr.includes(lcsnCd)) {
    return 2;
  } else if (lcns3YearExpr.includes(lcsnCd)) {
    return 3;
  } else if (lcns5YearExpr.includes(lcsnCd)) {
    return 5;
  }
  return 0;
};

export const invProtEduYear = [
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '01', year: 2024, nm: '사이버(2024~)', amt: 138000 },
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '02', year: 2023, nm: '사이버(2023~)', amt: 130000 },
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '03', year: 2022, nm: '사이버(2022~)', amt: 136000 },
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '04', year: 2021, nm: '사이버(2021~)', amt: 110000 },
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '05', year: 2020, nm: '사이버(2020~)', amt: 96000 },
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '06', year: 2018, nm: '사이버(2018~)', amt: 92000 },
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '07', year: 2017, nm: '사이버(2017)', amt: 90000 },
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '08', year: 2016, nm: '사이버(~2016)', amt: 84000 },
  { lcsnCds: ['WR7A', 'WR7G'], invProtEduDiv: '09', nm: '집합', amt: 180000 },

  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '10', year: 2023, nm: '사이버(2024)', amt: 93000 },
  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '11', year: 2023, nm: '사이버(2023)', amt: 87000 },
  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '12', year: 2022, nm: '사이버(2022~)', amt: 84000 },
  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '13', year: 2020, nm: '사이버(2020~)', amt: 80000 },
  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '14', year: 2020, nm: '사이버(2020~)', amt: 78000 },
  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '15', year: 2018, nm: '사이버(2018~)', amt: 74000 },
  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '16', year: 2017, nm: '사이버(2017)', amt: 72000 },
  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '17', year: 2016, nm: '사이버(~2016)', amt: 67000 },
  { lcsnCds: ['WR6Z', 'WR7H'], invProtEduDiv: '18', nm: '집합', amt: 144000 },
];

export const examFeeYear = [
  { lcsnCds: ['WR7A', 'WR6Z'], year: 2023, nm: '2023~', amt: 50000 },
  { lcsnCds: ['WR7A', 'WR6Z'], year: 2022, nm: '2022~', amt: 45000 },
  { lcsnCds: ['WR7A', 'WR6Z'], year: 2017, nm: '2017~', amt: 40000 },
  { lcsnCds: ['WR7A', 'WR6Z'], year: 2016, nm: '2016~', amt: 35000 },
  { lcsnCds: ['WR7A', 'WR6Z'], year: 2015, nm: '~2015', amt: 25000 },

  { lcsnCds: ['WR7C', 'WR7B'], year: 2022, nm: '2022~', amt: 18000 },
  { lcsnCds: ['WR7C', 'WR7B'], year: 2017, nm: '2017~', amt: 16000 },
  { lcsnCds: ['WR7C', 'WR7B'], year: 2016, nm: '2016~', amt: 14000 },
  { lcsnCds: ['WR7C', 'WR7B'], year: 2015, nm: '~2015', amt: 10000 },

  { lcsnCds: ['WR7D'], year: 2022, nm: '2022~', amt: 27000 },
  { lcsnCds: ['WR7D'], year: 2017, nm: '2017~', amt: 24000 },
  { lcsnCds: ['WR7D'], year: 2016, nm: '2016~', amt: 21000 },
  { lcsnCds: ['WR7D'], year: 2015, nm: '~2015', amt: 15000 },
];

export const bookCostYear = [
  { lcsnCds: ['WR4I'], year: 2020, nm: '2020~', amt: 48000},
  { lcsnCds: ['WR4I'], year: 2019, nm: '~2019', amt: 35000}
];

export const getInvProtEduByLcsnCd = (code) => {
  return invProtEduYear.filter(x => x.lcsnCds.includes(code));
};

export const getInvProtEdu = (invProtEduDiv) => {
  let invProtEdu = invProtEduYear.filter(x => x.invProtEduDiv === invProtEduDiv);
  if (invProtEdu && invProtEdu.length > 0) {
    return invProtEdu[0].nm;
  }
  return '';
}

export const getExamFee = (code, year) => {
  let options = examFeeYear.filter(x => {
    if (x.lcsnCds.includes(code)) {
      if (year >= 2023) { return x.year >= 2023; }
      else if (year >= 2017 && year <= 2022) { return x.year >= 2017 && x.year <= 2022; }
      else if (year === 2016) { return x.year === 2016; }
      else { return x.year <= 2015; }
    }
  });
  return options;
};

export const getbookCostYear = (code, year) => {
  let options = bookCostYear.filter(x => {
    if (x.lcsnCds.includes(code)) {
      if (year >= 2020) { return x.year >= 2020; }
      else { return x.year < 2020; }
    }
  });
  return options;
}

// 학원비 + 응시료 + 교재비
//export const
// 응시료 + 교재비
//export const
// 투자자보호교육비
//export const
// 투자자보호교육비 + 응시료 + 교재비
//export const
// 없는 경우
//export const

// 삭제
export const applyBtnEnable = (agreement) => {
  return !agreement;
};

export const paymentDomain = {
  stlmMthdCdDcd: stlmMthdCdDcds.STLM_MTHD_CD_CARD, // 결제방법
  stlmMthdNm: '', // 결제방법
  cardKind: '', // 카드종류
  cardNo: '', // 카드번호
  cardAprvNo: '', // 카드승인번호
  cardAprvDt: '', // 카드 승인일
  frcsNm: '',// 가맹점명
  dpstrNm: '', // 예금주
  dpstBnkNm: '', // 입금은행명
  dpstActno: '', // 입금계좌번호
};

export const paymentInfoValidation = (paymentInfo) => {
  const setResult = (txt, isValid) => {
    return {
      txt: txt,
      isValid: isValid
    };
  };
  if (paymentInfo.stlmMthdCdDcd === stlmMthdCdDcds.STLM_MTHD_CD_CARD) {
    // if (paramCheck(paymentInfo.cardKind)) {
    //   return setResult('카드종류를 입력해주세요.', false);
    // }
    if (paramCheck(paymentInfo.cardNo)) {
      return setResult('카드번호를 입력해주세요.', false);
    }
    if (paramCheck(paymentInfo.cardAprvNo)) {
      return setResult('승인번호를 입력해주세요.', false);
    }
    if (paramCheck(paymentInfo.cardAprvDt)) {
      return setResult('승인/입금일을 입력해주세요.', false);
    }
    if (paramCheck(paymentInfo.frcsNm)) {
      return setResult('가맹점명을 입력해주세요.', false);
    }
  } else {
    if (paramCheck(paymentInfo.dpstrNm)) {
      return setResult('예금주를 입력해주세요.', false);
    }
    if (paramCheck(paymentInfo.dpstBnkNm)) {
      return setResult('입금은행명을 입력해주세요.', false);
    }
    if (paramCheck(paymentInfo.dpstActno)) {
      return setResult('입금계좌번호를 입력해주세요.', false);
    }
  }
  return setResult('', true);
};

// eslint-disable-next-line no-unused-vars
export const applyConfirm = (url, params, agreement, validate, cb) => {
  const {showMessage} = useAlert();
  if (agreement) {
    if (validate instanceof Function) {
      if (validate()) {
        store.dispatch(url, params).then(res => {
          if (res && res.data && isSuccess(res.data)) {
            store.commit(`common/${MUT_SHOW_MESSAGE}`,{
              title:'신청 완료되었습니다.',
              callback: () => {
                if (cb instanceof Function) cb();
              }
            });
          } else {
            showMessage('오류가 발생했습니다. 관리자에게 문의하세요.');
          }
        });
      }
    } else {
      console.error("문제가 발생했습니다.");
    }
  }else {
    alert('동의해주세요.');
  }
};

export const saveSupport = (isLoading, url, payload, userAgreement, validate) => {
  try {
    if (!isLoading.value) {
      isLoading.value = true;
      if (validate instanceof Function && validate()) {
        if (userAgreement.value) {
          return store.dispatch(url, payload).then(res => {
            isLoading.value = false;
            return res;
          });
        } else {
          alert('동의해주세요.');
          isLoading.value = false;
        }
      } else {
        isLoading.value = false;
      }
    }
  } catch {
    isLoading.value = false;
  }
};

export const uploadCostproof = (payload) => {
  return store.dispatch(`costproof/${ACT_INSERT_LRN_COST_PROOF}`, payload);
};

// export const showComplete = (isLoading, result, str, cb) => {
export const showComplete = (isLoading, result, failed, cb) => {
  let msg = '신청 완료되었습니다.';
  // if (str && str.length > 0) {
  //   msg += str;
  // }
  if (failed) {
    msg += '<br>(첨부파일 등록에 실패했습니다.)';
  }
  store.commit(`common/${MUT_SHOW_MESSAGE}`,{
    title: msg,
    callback: () => {
      if (cb instanceof Function) cb(result);
      isLoading.value = false;
    }
  });
};

// 파일명 모아서
// 업로드 실패 항목:
// '자격증: {파일명}'
// 하려 했으나 알럿창이 너무 작음
// true false 만 남겨서 업로드 실패 문구만 추가
export const getFileUploadFailResults = (...params) => {
  let failed = false;
  // let result = '<br>업로드 실패: ';
  if (params && params.length > 0) {
    params.forEach(item => {
      if (item && item.res && item.res.data && item.res.data.items && item.res.data.items.length > 0) {
        // if (item.title && item.title.length > 0) {
        //   result += '';
        //   result += `${item.title}:${item.res.data.items.join(',')}`;
        // }
        if (item.data.items.length > 0) {
          failed = true;
        }
      }
    });
  }
  return failed;
};

export const addDate=(ymd, num)=>{
  let strDt = ymd.substr(0,4)+'-'+ymd.substr(4,2)+'-'+ymd.substr(6,2); // 'yyyy-mm-dd'
  let retDt = new Date(strDt);

  retDt.setDate(retDt.getDate()+num);

  return retDt; //'yyyy-mm-dd' 형태로 리턴
}

export const getLrnCostTy = (params, items, callback, catchCallback) => {
  getListFunc(`support/${ACT_GET_LRN_COST_TY}`, params, items, null, callback, catchCallback);
};

export const getLrnCostFld = (params, items, callback, catchCallback) => {
  getListFunc(`support/${ACT_GET_LRN_COST_FLD}`, params, items, null, callback, catchCallback);
};

export const getLcsnRenewList = (items, paging, callback) => {
  getListFunc(`support/${ACT_GET_LRN_SPRT_LCSN_RENEW_LIST}`, {}, items, paging, callback);
};

export const getLcsnRenewList2 = (items, paging, callback) => {
  getListFunc(`support/${ACT_GET_LRN_SPRT_LCSN_RENEW_LIST2}`, {}, items, paging, callback);
};

export const getCollegeList = (params, items, paging) => {
  getListFunc(`school/${ACT_GET_COLLEGE_LIST}`,params,items, paging);
};

export const getMajorList = (params, items, paging) => {
  getListFunc(`school/${ACT_GET_MAJOR_LIST}`, params,items, paging);
};

export const getLcsnList = (params, items, paging, cb, ccb) => {
  getListFunc(`license/${ACT_GET_LRN_LCSN_LIST}`,params, items, paging, cb, ccb);
};

//신청가능 대상자 여부 조회(파트타이머 지원제외)
export const getDsjChk = () => {
  return store.dispatch(`support/${ACT_GET_DSJ_CHK}`);
};

export const getClassDsjChk = () => {
  return store.dispatch(`support/${ACT_GET_CLASS_DSJ_CHK}`);
};


//과정 미수료등 환급금 미처리내역 확인
export const getRefundChk = () => {
  return store.dispatch(`support/${ACT_GET_REFUND_CHK}`);
};

export const fillPaymentParam = (param) => {
  param.stlmMthdCdDcd = stlmMthdCdDcds.STLM_MTHD_CD_CARD;   // 결제 방법
  param.cardKind = 'kB국민 카드';                             // 카드 종류
  param.cardNo= '0147025803690147';                         // 카드번호
  param.cardAprvNo= '33333333';                             // 카드승인번호
  param.cardAprvDt= '2022-09-14';                           // 카드 승인일
  param.frcsNm= '온상주식회사';                               // 가맹점명
  param.dpstrNm= '김온상';                                   // 예금주
  param.dpstBnkNm= 'KB국민은행';                             // 입금은행명
};

export const checkUploadBinaries = (files) => {
  return !!(files.value && files.value.binaries && files.value.binaries.length > 0);
};

export const checkUploadFiles = (files) => {
  return !!(files.value && files.value.files && files.value.files.length > 0);
};

export const supportMenu = [
  {name: '학원비 신청이력', view: 'academy'},
  {name: '평가비 신청이력', view: 'eval'},
  {name: '학비 신청이력', view: 'school'},
  {name: '자격증 신청이력', view: 'license'}
];

export const getFuncRef = (type, payload, params, callback) => {
  store.dispatch(type, payload)
  .then(res => {
    const isLen = lengthCheck(res);
    if(params && isLen) params.value = getItem(res);
    if(callback instanceof Function) callback(res);
  }).catch(e => {
    console.error(e);
  });
};

export const setCostProof = (proofs, target, proofDivCdDcd) => {
  if (proofs && proofs.length > 0) {
    proofs.map(proof => {
      if (proof.proofDivCdDcd === proofDivCdDcd) {
        let file = {
          proofDataSn: proof.proofDataSn,
          name: proof.fileNm,
          size: proof.fileSz
        }
        target.value.files.push(file);
      }
    });
  }
};

export const getAcademy = (costAcdmFeeSn, academy, modal) => {
  getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_ACADEMY}`,{costAcdmFeeSn: costAcdmFeeSn}, null, res => {
    if (lengthCheck(res)) {
      academy.value = res.items.map(x => {
        x.cardAprvDt = timestampToDateFormat(x.cardAprvDt, 'yyyy-MM-dd');
        x.lrnBgngDd = setYmdObject(x.lrnBgngDd);
        x.lrnEndDd = setYmdObject(x.lrnEndDd);
        return x;
      })[0];

      modal.value = true;
    }
  });
};

export const getEvaluation = (costEvlSn, evaluation, modal) => {
  getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_EVALUATION}`,{costEvlSn: costEvlSn}, null, res => {
    if (lengthCheck(res)) {
      evaluation.value = res.items.map(x => {
        x.cardAprvDt = timestampToDateFormat(x.cardAprvDt, 'yyyy-MM-dd');
        return x;
      })[0];
    }
    modal.value = true;
  });
};

export const getSchool = (costTuitFeeSn, school, modal, modal202309) => {
  getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_SCHOOL}`,{costTuitFeeSn: costTuitFeeSn}, null, res => {
    school.value = getCheckItem(res);

    if ((school.value.sprtYear != '' && school.value.sprtYear != null)){ //2023.09이후 신청분
      modal202309.value = true;
      modal.value = false;
    }else{
      modal202309.value = false;
      modal.value = true;
    }
  });
};

export const getOfficer = (costOffcrSn, officer, modal) => {
  getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_OFFICER}`,{costOffcrSn: costOffcrSn}, null, res => {
    officer.value = getCheckItem(res);
    modal.value = true;
  });
};

export const getLicenseApplication = (costLcsnSn, license, modal) => {
  getFuncRef(`support/${ACT_GET_LEARN_SUPPORT_LICENSE}`,{costLcsnSn: costLcsnSn}, null, res => {
    license.value = getCheckItem(res);
    modal.value = true;
  });
};

export const getMyAcademyTotal = (payload) => {
  return store.dispatch(`support/${ACT_GET_LEARN_SUPPORT_ACADEMY_TOTAL}`, payload);
};

// 한도 체크 로직 없음
// export const getMyEvalTotal = (payload) => {
//   return store.dispatch(`support/${ACT_GET_LEARN_SUPPORT_EVAL_TOTAL}`, payload);
// };

export const downloadCostProof = (key, fileNm) => {
  store.dispatch(`costproof/${ACT_DOWNLOAD_COST_PROOF}`, {proofDataSn: key, fileName: fileNm});
};

const {showMessage, showConfirm} = useAlert();
export const cancelSupport = (storeNm, payload, msgText, cb) => {
  showConfirm({
    text: '해당 신청건을 취소하시겠습니까?',
    callback: () => {
      store.dispatch(`support/${storeNm}`, payload).then(res => {
        if(isSuccess(res)) {
          showMessage({
            text: `${msgText}신청이 취소되었습니다.`,
            callback: () => {
              if (cb instanceof Function) cb();
            }
          });
        }
      }).catch(e => {
        console.error(e);
      });
    }
  });
}

export const changeNotice = `<p class="fw-bolder">2023년 9월 1일부터<br>자기주도학습비(학비·학원비) 지원제도가<br>일부 변경되었습니다.<br>관련문서를 확인하시고, 지원 신청하시기 바랍니다.</p><br><br><p class="text-start">※관련문서<br>자기주도학습비(학비·학원비) 지원제도 변경 안내<br>HR부(인재개발) 431, 2023-08-04</p>`;

// export const sendMsgToMng=(items, costSn, sprtDivNm)=>{
//
//   const params = reactive({
//     notiTrgtDomains: []
//   });
//
//   for(let i=0; i<items.length; i++){
//     params.notiTrgtDomains.push({
//       trprId: items[0].mngrId,
//       col1: sprtDivNm,
//       col2: costSn
//     });
//   }
//
//   // items.forEach(x => {
//   //   params.notiTrgtDomains.push({
//   //     trprId: x.mngrId,
//   //     col1: sprtDivNm,
//   //     col2: costSn
//   //   });
//   // });
//
//   store.dispatch(`support/${ACT_SEND_TMP_MSG}`, {templateSn:'selfSupportApp', params:params.notiTrgtDomains}).then();

// }