import LxpLayout from "@/views/layout/lxp/LxpLayout";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout.vue";
import navigationUtils from "@/assets/js/navigationUtils";
import unify from "@/views/pages/uniSearch/uniSearchMain";

const isMobile = navigationUtils.any();

const uniSearchRoutes = [
    {
        path: '/unify',
        component: isMobile ? LxpMobileLayout : LxpLayout,
        children: [
            {
                path: 'search',
                name: "uniSearchMain",
                component: unify
            },
        ]
    }
];

export const setUniSearchRoutes = (routes) => {
    routes.push(...uniSearchRoutes);
}
