<template>
  <!-- page-intro -->
<!--    <SSLView-->
<!--        v-if="currentName === 'SSLView'"-->
<!--        @acted="acted"-->
<!--    />-->
<!--    <template v-else>-->
      <div class="page-intro">
        <div class="intro-content">
          <div class="page-component">
            <h2 class="intro-title black-title">우수 노트 모아보기</h2>


            <div class="intro-links black">
              <SSLTrial
                  trial-class="intro-link"
                  text-class="text"
                  :show-arrow="true"
              />
              <SSLManual
                  manual-class="intro-link"
                  text-class="text"
                  :show-arrow="true"
              />
            </div>
          </div>
        </div>
        <div class="intro-background"></div>
      </div>
      <!-- //page-intro -->
      <!-- page-container -->
      <div class="page-container">

        <div class="page-body">

          <div class="myssl-container">
            <div class="page-component">
              <SSLTotBoardList
                  :is-loading="isLoading"
                  :items="boardItems"
                  :paging="paging"
                  :more-func="moreFunc">
                <template v-slot:selectYear>
                  <SortButtonSelect
                      v-model="filters.year"
                      v-model:toggle="toggles.year"
                      title="년도"
                      :options="yearOptions"
                      sequence-key="value"
                      name-key="display"
                      :is-num="true"
                      :is-all="false"
                      @update:toggle="closeToggles(toggles, 'year')"
                      @selected="getOptions()"
                  />
                </template>
                <template v-slot:selectSubj>
                  <SortButtonSelect
                      v-model="filters.distCrseSn"
                      v-model:toggle="toggles.distCrseSn"
                      title="과정"
                      :options="distOptions"
                      sequence-key="distCrseSn"
                      name-key="crseNm"
                      :is-all="true"
                      :width="300"
                      @update:toggle="closeToggles(toggles, 'distCrseSn')"
                      @selected="initSearch()"
                  />
                </template>
                <template v-slot:search>
                  <div class="kb-form-field field-search">
                    <input v-model.trim="keyword" type="text" class="kb-form-control" name="" placeholder="검색" @keyup.enter="initSearch()"/>
                    <button class="kb-btn-search" @click="initSearch()">
                      <i class="icon-search"></i>
                    </button>
                    <button class="kb-btn-search-delete" @click="deleteKeyword">
                      <i class="icon-delete"></i>
                    </button>
                  </div>
                </template>
              </SSLTotBoardList>
            </div>
          </div>
        </div>
        <!-- page-footer -->
        <div class="page-footer">
          <div class="page-component"></div>
        </div>
      </div>
      <!-- //page-container -->
    </template>

<!--</template>-->

<script>
import SSLTrial from '@/components/ssl/common/SSLTrial';
import SSLManual from '@/components/ssl/common/SSLManual';
// import SSLView from '@/views/ssl/SSLView';
import {sslBestNoteSetup} from "@/assets/js/modules/ssl/setup/ssl-best-note-setup";
import SortButtonSelect from "@/components/common/SortButtonSelect.vue";
import SSLTotBoardList from "@/components/ssl/SSLTotBoardList.vue";

export default {
  name: 'SSLBestNotes',
  components: {
    SSLTotBoardList, SortButtonSelect,
    // SSLView,
    SSLManual,
    SSLTrial,
  },
  setup: sslBestNoteSetup
}
</script>
