<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-support">
    <LxpMobileHeader title="학원비 지원신청">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
      <template v-slot:right>
        <div v-if="guideFile.length > 0 && isMainActive" class="util util-download">
          <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </template>
    </LxpMobileHeader>
    <!-- main-content -->
    <div v-if="isMainActive" class="main-content main-component">
      <div class="support-view-container">
        <div class="page-buttons">
          <button class="kb-btn kb-btn-primary" @click="applyPrice"><span class="text">지원신청</span></button>
        </div>
        <div class="view-contents">

          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">학원비 지원안내</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <div class="title">지원대상</div>
                <div class="desc">
                  <p>지급일 현재 재직중인 직원 (일반/계약)</p>
                  <p class="sub-text">※ 지급일 현재 정직자 및 파트타이머 제외(단, 인천국제공항지점 환전매니저의 경우 외국어 분야에 한해 지원)</p>
                </div>
              </div>
            </div>
          </section>
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청기한</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <div class="desc">
                  <span><span style="font-weight: bold">과정 종료일</span>로부터 <span class="text-red"> 2개월 이내</span></span>
                </div>
              </div>
            </div>
          </section>

          <section class="content-section">
            <header class="section-header">
              <h4 class="title">지원 절차 및 시기</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <h5 class="b-title">Step1.</h5>
                <p class="desc">과정 종료 후 필요서류 준비</p>
              </div>
              <div class="content-item">
                <h5 class="b-title">Step2.</h5>
                <p class="desc">스타런에서 신청</p>
              </div>
              <div class="content-item">
                <h5 class="b-title">Step3.</h5>
                <p class="desc">인재개발부 심사</p>
              </div>
              <div class="content-item">
                <h5 class="b-title">Step4.</h5>
                <p class="desc">급여계좌로 지급(전산 신청일 기준 <span class="text-red">익월 15일경</span>)</p>
              </div>
            </div>
          </section>

          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">지원내용</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <h5 class="b-title">외국어</h5>
                <ul class="desc-type-title">
                  <li>
                    <h6>학습방법</h6>
                    <p>학원・인터넷・전화・App 구독</p>
                  </li>
                  <li>
                    <h6>지원한도</h6>
                    <p></p>
                  </li>
                  <li class="desc">
                    <p>- 과정 시작일 2024.11.22 이후: 연간 최대 144만<br>&nbsp;&nbsp;&nbsp;원(96만원+IT 미사용 한도 내 48만원)</p>
                  </li>
                  <li class="desc">
                    <p>- 과정 시작일 2024.11.21 이전: 연간 96만원</p>
                  </li>
                  <li>
                    <h6>지원범위</h6>
                    <p>학원비의 80%</p>
                  </li>
                </ul>
              </div>
              <div class="content-item">
                <h5 class="b-title">IT</h5>
                <ul class="desc-type-title">
                  <li>
                    <h6>학습방법</h6>
                    <p>학원・인터넷・App 구독</p>
                  </li>
                  <li>
                    <h6>지원한도</h6>
                    <p>연간 96만원</p>
                  </li>
                  <li>
                    <h6>지원범위</h6>
                    <p>학원비의 80%</p>
                  </li>
                </ul>
              </div>

              <div class="section-content">
                <div class="content-item">
                  <div class="b-title">지원제외과정</div>
                  <div class="desc">
                    <p>- 수강기간 6개월 초과 과정</p>
                    <p>- 사은품(스마트폰, 태블릿PC, 블루투스 이어폰, <br>&nbsp;&nbsp;&nbsp;상품권 등)이 제공되는 과정</p>
                    <p>- 과정 시작일이 입행일 이전인 과정</p>
                    <p>- 개인과외 등 수강기관/과정 등을 명확히 확인할 수 &nbsp;&nbsp;&nbsp;없는 과정</p>
                  </div>
                </div>
              </div>

              <div class="content-item">
                <ul class="desc-context">
                  <li>
                    <p class="mt-2">- <span class="text-red font-body16B">연간 지원한도는 지원 신청일이 아닌 '과정 시작일'을 기준으로 산정</span>하며,<span class="text-red font-body16B">과정 시작일이 2024.11.22 이후인 건에 대해 확대된 외국어 한도 적용</span> ※ 관련문서:학원비 지원제도 변경 안내(HR(인재개발)811, 2024.11.22)</p>
                    <p class="mt-2">&nbsp;&nbsp;&nbsp;- 외국어/IT를 합산하여 개인당 총 지원 금액은 192만원(예시:'외국어 96만원 + IT 96만원' 또는 '외국어 144만원 + IT 48만원')</p>
                    <p class="mt-2">교재비 지원 제외/외화 결제의 경우 학원비 결제일 기준 1회차 전신환 매도율 적용</p>
                    <p class="mt-2">본 지원 제도로 IT 분야 학원비를 지원받은 후 등급 자격증을 취득한 경우, 자격증 취득 지원시 응시료만 80% 지원</p>
                  </li>
                </ul>
              </div>

            </div>
          </section>

          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">필요서류</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <div class="desc">
                  <p>1. 수강증</p>
                  <p class="mb-2">- 필수기재사항 : 수강자명, 학원명, 과정명,<br>&nbsp;&nbsp;&nbsp;수강기간, 수강료</p>
                  <p>2. 결제 영수증</p>
                  <p>- 필수기재사항 : 카드번호, 승인번호, 승인일,<br>&nbsp;&nbsp;&nbsp;승인금액, 가맹점명</p>
                  <p>- 본인 명의 KB신용・체크카드(가족愛복지 포함)<br>&nbsp;&nbsp;&nbsp;결제분만 인정</p>
                  <p class="mb-2">※ 신용카드 미가맹점인 경우에만 납입영수증 대체 가능</p>
                  <p>3. 수료증, 출석표, 진도율표 中 택1</p>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
    <!-- //main-content -->

    <!-- 학원비 지원 신청 모달 -->
    <SupportClassFee202309MobileModal v-if="!isMainActive"/>

  </main>
  <SupportMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :file-key="guideFile"/>
  <!-- end::kb-main -->
</template>

<script>
import {computed, ref, onMounted} from 'vue';
import {downloadGuideFiles, getClassDsjChk} from '@/assets/js/modules/support/support-common'; //, getRefundChk
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import SupportMobileFileAtch from "@/views/pages/support/mobile/SupportMobileFileAtch";
import SupportClassFee202309MobileModal from "@/components/support/mobile/SupportClassFee202309MobileModal";
import {ACT_GET_RST_REG_CHK, MUT_CLOSE_MAIN, MUT_SHOW_MAIN} from "@/store/modules/support/support";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {getItems, lengthCheck} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";

export default {
  name: 'SupportClass',
  components: {SupportClassFee202309MobileModal, SupportMobileFileAtch, LxpMobileHeader},
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage} = useAlert();
    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'class').fileKey);
    const isFileAtchListShow = ref(false);
    const chkCnt = ref(0);
    
    const isMainActive = computed(() => store.state.support.main.show);
    if( !isMainActive.value ) {
      store.commit(`support/${MUT_SHOW_MAIN}`);
    }
    const applyPrice = async() => {

      let chkVal = await getClassDsjChk();
      if (getItems(chkVal) > 0){
        showMessage('학원비 지원 신청 대상이 아닙니다. ');
        return false;
      }else{
        // chkVal = await getRefundChk();
        // if(getItems(chkVal) > 0){
        //   // showMessage('과정미수료 환입비용 미처리내역이 있습니다.<br>환입비용 처리 후 신청해주시기 바랍니다.');
        //   showMessage('미지급 또는 미환입 내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
        //   return false;
        // }else{
        //   rstRegChk();
        // }
        checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 인재개발부<br>업무담당자(☎7880)에게<br>문의 부탁드립니다.`, rstRegChk );
      }
    }

    const rstRegChk = () =>{
      chkCnt.value=0;
      // 이전 신청건에 대한 결과등록 여부 조회
      store.dispatch(`support/${ACT_GET_RST_REG_CHK}`).then(res => {
        if (lengthCheck(res)) {
          chkCnt.value = getItems(res);
        } else {
          chkCnt.value = 0;
        }

        if (chkCnt.value > 0){
          showMessage('기존 신청건 결과등록 후 신청하시기 바랍니다.');
        }else{
          store.commit(`support/${MUT_CLOSE_MAIN}`);
          // showConfirm({text: changeNotice, callback: () => {store.commit(`support/${MUT_CLOSE_MAIN}`);}});
        }

      }).catch(e => {
        console.error(e);
      });
    }

    const goBack = () => {
      if(! store.state.support.main.show) {
        store.commit(`support/${MUT_SHOW_MAIN}`);
      } else {
        router.go(-1);
      }
    }

    onMounted(() => {
      store.commit(`support/${MUT_SHOW_MAIN}`);
    });

    return {
      guideFile, goBack,
      isFileAtchListShow,applyPrice,isMainActive
    }
  }
};
</script>