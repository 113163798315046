<template>
  <div v-if="isReady && enableWrite" class="top-writing ssl-pc mb-2" :class="writeClass">
    <template v-if="distCrseCd === 'selfGi' || distCrseCd === 'selfGa' || distCrseCd === 'selfJa' || distCrseCd === 'selfIct' || distCrseCd === 'selfDvl'">
      <div class="writing-bg">
        <img v-if="distCrseCd === 'selfGa'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_0.jpg" alt="" />
        <img v-if="distCrseCd === 'selfGi'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_1.jpg" alt="" />
        <img v-if="distCrseCd === 'selfJa'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_2.jpg" alt="" />
        <img v-if="distCrseCd === 'selfIct'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_v2_2.jpg" alt="" />
        <img v-if="distCrseCd === 'selfDvl'" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_v2_3.jpg" alt="" />
<!--        <img v-if="groupIdx === 0" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_0.jpg" alt="" />-->
<!--        <img v-if="groupIdx === 1" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_1.jpg" alt="" />-->
<!--        <img v-if="groupIdx === 2" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_2.jpg" alt="" />-->
<!--        <img v-if="groupIdx === 3" src="@/assets/lxp/images/ssl/myssl/bg_writing_self_v2_2.jpg" alt="" />-->
      </div>
      <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}}" class="kb-btn-writing">
        <span class="text">글쓰기</span>
        <i class="icon-writing"></i>
      </router-link>
    </template>
    <template v-if="distCrseCd === 'group'">
      <swiper
          class="studygroup-container js-swiper-group"
          :modules="modules"
          :slides-per-view="4"
          :space-between="24"
          :speed="600"
          :navigation="{
            nextEl: '.kb-btn-group-next',
            prevEl: '.kb-btn-group-prev'
          }"
      >
        <swiper-slide v-for="(item, idx) in items" :key="idx" class="studygroup-item">
          <div class="studygroup">
            <div class="studygroup-image">
              <img v-if="idx % 4 === 0" src="@/assets/lxp/images/ssl/myssl/bg_studygroup_01.jpg" alt="">
              <img v-else-if="idx % 4 === 1" src="@/assets/lxp/images/ssl/myssl/bg_studygroup_02.jpg" alt="">
              <img v-else-if="idx % 4 === 2" src="@/assets/lxp/images/ssl/myssl/bg_studygroup_03.jpg" alt="">
              <img v-else-if="idx % 4 === 3" src="@/assets/lxp/images/ssl/myssl/bg_studygroup_04.jpg" alt="">
            </div>
            <div class="studygroup-content">
              <span class="text text-skip ssl-group-attend-text" :title="item.relNm">{{ item.relNm }}</span>
              <span class="text ssl-group-attend-time-text">{{ getTimeText(item.relBgngDt, item.relEndDt) }}</span>
            </div>
          </div>
          <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.relSn}}" class="kb-btn-writing" :class="!isWritten(item.relSn) ? 'kb-force-primary' : ''">
            <span class="text">글쓰기</span>
            <i class="icon-writing"></i>
          </router-link>
        </swiper-slide>
      </swiper>
      <button class="kb-btn-swiper-prev kb-btn-group-prev">
        <i class="icon-arrow"></i>
      </button>
      <button class="kb-btn-swiper-next kb-btn-group-next">
        <i class="icon-arrow"></i>
      </button>
    </template>
    <template v-if="distCrseCd === 'knowledge'">
      <swiper
          class="jisike-container js-swiper-jisike"
          :modules="modules"
          :slides-per-view="4"
          :space-between="24"
          :speed="600"
          :navigation="{
            nextEl: '.kb-btn-group-next',
            prevEl: '.kb-btn-group-prev'
          }"
      >
        <swiper-slide v-for="(item, idx) in items"  class="jisike-item" :key="idx">
          <div class="jisike">
            <div class="jisike-box" @click="openContent(item)">
              <div class="box-image kb-mouse-cursor">
                <template v-if="item.relThumb && item.relThumb.length > 0">
                  <img :src="item.relThumb" alt="" />
                </template>
                <template v-else>
                  <img v-if="idx % 4 === 0" src="@/assets/lxp/images/ssl/myssl/@img_jisike.png" alt="" />
                  <img v-else-if="idx % 4 === 1" src="@/assets/lxp/images/ssl/myssl/@img_jisike_02.jpg" alt="" />
                  <img v-else-if="idx % 4 === 2" src="@/assets/lxp/images/ssl/myssl/@img_jisike_03.jpg" alt="" />
                  <img v-else-if="idx % 4 === 3" src="@/assets/lxp/images/ssl/myssl/@img_jisike_04.jpg" alt="">
                </template>
              </div>
              <div class="box-content kb-mouse-cursor">
                <span class="content-year">{{ currentYear }}</span>
                <span class="content-text text-skip" style="width: 150px;text-align: left" :title="item.relNm">{{ item.relNm }}</span>
              </div>
            </div>
            <div class="jisike-date">
            </div>
          </div>
          <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.relSn}}" class="kb-btn-writing">
            <span class="text">글쓰기</span>
            <i class="icon-writing"></i>
          </router-link>
        </swiper-slide>
      </swiper>
      <button class="kb-btn-swiper-prev kb-btn-group-prev">
        <i class="icon-arrow"></i>
      </button>
      <button class="kb-btn-swiper-next kb-btn-group-next">
        <i class="icon-arrow"></i>
      </button>
    </template>
    <template v-if="distCrseCd === 'hrd'">
      <swiper
          class="cloud-container"
          :modules="modules"
          :slides-per-view="4"
          :space-between="24"
          :speed="600"
          :navigation="{
            nextEl: '.kb-btn-group-next',
            prevEl: '.kb-btn-group-prev'
          }"
      >
        <swiper-slide v-for="(item, idx) in items"  class="cloud-item" :key="idx">
          <div class="cloud">
            <div class="cloud-image kb-mouse-cursor" @click="goToHrd(item, $event)">
              <CommonTypeImage
                  :sequence="item.relSn"
                  :img-src="getThumbImg(item.relThumb)"
              />
            </div>
            <div class="cloud-content">
              <span class="content-title">{{ item.relCateNm }}</span>
              <span class="content-text text-skip" :title="item.relNm">{{ item.relNm }}</span>
            </div>
          </div>
          <router-link v-if="showWriteButton" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.relSn}}" class="kb-btn-writing">
            <span class="text">글쓰기</span>
            <i class="icon-writing"></i>
          </router-link>
        </swiper-slide>
      </swiper>
      <button class="kb-btn-swiper-prev kb-btn-group-prev">
        <i class="icon-arrow"></i>
      </button>
      <button class="kb-btn-swiper-next kb-btn-group-next">
        <i class="icon-arrow"></i>
      </button>
    </template>
    <template v-if="distCrseCd === 'book'">
      <swiper
          class="book-container"
          :modules="modules"
          :slides-per-view="3"
          :space-between="5"
          :speed="600"
          :navigation="{
            nextEl: '.kb-btn-book-next',
            prevEl: '.kb-btn-book-prev'
          }"
      >
        <swiper-slide v-for="(item, idx) in items"  class="book-item" :key="idx">
          <span v-if="!item.isFree" style="font-family:var(--kb-font-KBFGTextB);">p.
            <input v-if="lrner == null" name="inputPage" v-model="item.inputPage" :placeholder="item.currentPage" onfocus="placeholder = ''" @blur="updatePercentage(idx)" @keyup.enter="updatePercentage(idx)" style="width: 40px; text-align: right; color: #84888b;" />
            <text v-else>{{item.currentPage}}</text>
            / {{item.totalPage}}
          </span>
          <div v-if="!item.isFree" style="width: 200px; position: absolute; z-index: 3; margin-left: 50px; transition: left 0.6s ease;" :style="{ left: item.percentage * 6 / 10 - 30 + '%'}">
            <img src="@/assets/lxp/images/ssl/myssl/bookmark.png" style="width: 20px; height: 25px;"/>
          </div>
          <div v-if="!item.isFree" class="progress mb-4" style="width: 200px; margin-left: 50px; position: absolute; z-index: 2;">
            <div class="progress-bar" style="background-color: rgb(101 162 255)" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" :style="{ width: item.percentage + '%'}"></div>
            <!--            <input v-model="inputPercentage" @blur="updatePercentage" @keyup.enter="updatePercentage" placeholder="Enter percentage" />-->
<!--            <img src="@/assets/lxp/images/ssl/myssl/bookmark.png" style="width: 25px; height: 25px; position: absolute; z-index: 3;"/>-->
          </div>

<!--          <div style="width: 200px; position: absolute; z-index: 3; margin-left: 50px; left: 70px; top: 290px;">-->
<!--            <button style="width: 40px; height: 40px;" @click="openTimerModal(idx)"/>-->
<!--            <button style="width: 40px; height: 40px;" @click="openMemoModal(idx)"/>-->
<!--          </div>-->

<!--          <div style="width: 200px; position: absolute; z-index: 2; margin-left: 50px; left: 70px; top: 290px;">-->
<!--            <img src="@/assets/lxp/images/ssl/myssl/time_note.png" style="width: 80px; height: 40px;"/>-->
<!--          </div>-->

          <div v-if="item.isFree" class="book" style="margin-top: 23px;">
            <a href="javascript:" class="book-link"></a>
            <div class="book-cover">
              <img src="@/assets/lxp/images/ssl/myssl/img_book_kb.jpg" alt="" style="width: 200px; height: 300px;"/>
            </div>
            <div class="book-content">
              <span class="content-author">-</span>
              <span class="content-title">자유도서</span>
            </div>
          </div>
          <div v-else class="book" style="margin-top: 7px;">
            <a href="javascript:" class="book-link"></a>
            <div class="book-cover" :title="item.bookNm">
              <div class="book-img">
                <img :src="item.thumb" alt="" style="width: 200px; height: 300px;"/>
              </div>
              <div v-if="lrner == null" class="record-book">
                <i @click="openTimerModal(idx)" class="icon-progress"></i>
                <i @click="openMemoModal(idx)" style="border-left:2px solid #454138;" class="icon-symbol"></i>
              </div>

            </div>
            <div class="book-content">
              <span class="content-author">{{ item.bookDesc }}</span>
              <span class="content-title text-skip" :title="item.bookNm">{{ item.bookNm }}</span>
            </div>
          </div>
          <router-link v-if="showWriteButton && lrner == null" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.bookIsbn}}" class="kb-btn-writing">
            <span class="text">글쓰기</span>
            <i class="icon-writing"></i>
          </router-link>
        </swiper-slide>
      </swiper>
      <button class="kb-btn-swiper-prev kb-btn-book-prev">
        <i class="icon-arrow"></i>
      </button>
      <button class="kb-btn-swiper-next kb-btn-book-next">
        <i class="icon-arrow"></i>
      </button>

    </template>
    <template v-if="distCrseCd === 'trivia'">
      <div class="writing-bg">
        <img src="@/assets/lxp/images/ssl/myssl/bg_alssl.jpg" alt="">
      </div>
      <router-link v-if="showWriteButton && year == currentYear" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}}" class="kb-btn-writing">
        <span class="text">글쓰기</span>
        <i class="icon-writing"></i>
      </router-link>
    </template>
    <template v-if="distCrseCd === 'college'">
      <div class="writing-bg">
        <img src="@/assets/lxp/images/ssl/myssl/bg_college.jpg" alt="">
      </div>
      <router-link v-if="showWriteButton && year == currentYear" :to="{name: 'SSLWrite', params: {socLrnPostSn: 0}}" class="kb-btn-writing">
        <span class="text">글쓰기</span>
        <i class="icon-writing"></i>
      </router-link>
    </template>
    <template v-if="distCrseCd === 'live'">
      <swiper
          class="learninglive-container"
          :modules="modules"
          :slides-per-view="4"
          :space-between="24"
          :speed="600"
          :navigation="{
            nextEl: '.kb-btn-group-next',
            prevEl: '.kb-btn-group-prev'
          }"
      >
        <swiper-slide v-for="(item, idx) in items"  class="learninglive-item" :key="idx">
          <div class="learninglive">
            <div class="learninglive-image">
              <CommonTypeImage
                  :sequence="item.relSn"
                  theme="live"
              />
            </div>
            <div class="learninglive-content">
              <span class="text text-skip" :title="item.relNm">{{ item.relNm }}</span>
            </div>
          </div>
          <router-link v-if="showWriteButton && year == currentYear" :to="{name: 'SSLWriteSSLWrite', params: {socLrnPostSn: 0}, query: {relSn: item.relSn}}" class="kb-btn-writing">
            <span class="text">글쓰기</span>
            <i class="icon-writing"></i>
          </router-link>
        </swiper-slide>
      </swiper>
      <button class="kb-btn-swiper-prev kb-btn-group-prev">
        <i class="icon-arrow"></i>
      </button>
      <button class="kb-btn-swiper-next kb-btn-group-next">
        <i class="icon-arrow"></i>
      </button>
    </template>
    <SSLMyMemoModal v-if="memoModal" v-model="memoModal" :data="memoItem"/>
    <SSLMyTimerModal v-if="timerModal" v-model="timerModal" :data="timerItem"/>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import CommonTypeImage from '@/components/common/CommonTypeImage';
import {sslMyWriteSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';
import SSLMyMemoModal from "@/components/ssl/my/SSLMyMemoModal.vue";
import SSLMyTimerModal from "@/components/ssl/my/SSLMyTimerModal.vue";

export default {
  name: 'SSLMyWrite',
  props: {
    lrner: Object,
  },
  components: {
    SSLMyTimerModal,
    SSLMyMemoModal,
    CommonTypeImage,
    Swiper,
    SwiperSlide,
  },
  setup: sslMyWriteSetup
}
</script>

<style>
.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s ease-in-out;
}
</style>