<template>
  <article class="content-section">
    <header class="section-header" v-if="!isMobile">
      <h4 class="title">연수이력</h4>
    </header>
    <header class="section-header d-flex" v-else>
      <h4 class="title">연수이력</h4>
      <div class="header-side" v-if="isMobile">
<!--        <button class="kb-btn-search" @click="searchKeyword"><i class="icon-search"></i></button>-->
      </div>
    </header>
    <!-- training-list-container -->
    <div class="training-list-container">
      <!-- list-top -->
      <div class="list-top">
        <div class="top-column">
          <!-- list-top > list-sort -->
          <div class="list-sort">
            <div class="sort">
              <!-- kb-form-dropdown -->
              <SortButtonSelect3
                  v-if="isMobile"
                  v-model="filters.year"
                  v-model:toggle="toggles.year"
                  title="년도"
                  :options="yearOptions"
                  sequence-key="value"
                  name-key="display"
                  :is-num="true"
                  :is-all="true"
                  @update:toggle="closeToggles(toggles, 'year')"
                  @selected="pagingFunc(1)"
              />
              <SortButtonSelect
                  v-else
                  v-model="filters.year"
                  v-model:toggle="toggles.year"
                  title="년도"
                  :options="yearOptions"
                  sequence-key="value"
                  name-key="display"
                  :is-num="true"
                  :is-all="true"
                  @update:toggle="closeToggles(toggles, 'year')"
                  @selected="pagingFunc(1)"
              />
              <!-- //kb-form-dropdown -->
            </div>

            <div class="sort">
              <!--                <div class="kb-form-dropdown is-active">-->
              <SortButtonSelect3
                  v-if="isMobile"
                  v-model="filters.mediaCateCd"
                  v-model:toggle="toggles.mediaCateCd"
                  title="매체"
                  :options="cateOptions"
                  sequence-key="mediaCateCd"
                  name-key="mediaCateNm"
                  :is-all="true"
                  :width="200"
                  @update:toggle="closeToggles(toggles, 'mediaCateCd')"
                  @selected="pagingFunc(1)"
              />
              <SortButtonSelect
                  v-else
                  v-model="filters.mediaCateCd"
                  v-model:toggle="toggles.mediaCateCd"
                  title="매체"
                  :options="cateOptions"
                  sequence-key="mediaCateCd"
                  name-key="mediaCateNm"
                  :is-all="true"
                  :width="200"
                  @update:toggle="closeToggles(toggles, 'mediaCateCd')"
                  @selected="pagingFunc(1)"
              />
            </div>

            <div class="sort">
              <!-- kb-form-dropdown -->
              <SortButtonSelect3
                  v-if="isMobile"
                  v-model="filters.finish"
                  v-model:toggle="toggles.finish"
                  title="수료결과"
                  :options="finishOptions"
                  sequence-key="value"
                  name-key="display"
                  :is-num="true"
                  :is-all="true"
                  @update:toggle="closeToggles(toggles, 'finish')"
                  @selected="pagingFunc(1)"
              />
              <SortButtonSelect
                  v-else
                  v-model="filters.finish"
                  v-model:toggle="toggles.finish"
                  title="수료결과"
                  :options="finishOptions"
                  sequence-key="value"
                  name-key="display"
                  :is-num="true"
                  :is-all="true"
                  @update:toggle="closeToggles(toggles, 'finish')"
                  @selected="pagingFunc(1)"
              />
<!--              <SortButtonSelect-->
<!--                  v-model="cpListCdDcds"-->
<!--                  v-model:toggle="cpListCdDcds"-->
<!--                  title="복습하기"-->
<!--                  :options="cpListCdDcdOpt"-->
<!--                  sequence-key="value"-->
<!--                  name-key="display"-->
<!--                  :is-num="false"-->
<!--                  :is-all="false"-->
<!--                  @selected="goCP()"-->
<!--              />-->
              <!-- //kb-form-dropdown -->
            </div>

<!--            <div class="item-row row-buttons" style="padding: 5px">-->
            <InputSelect
                class="item-row row-buttons kb-btn kb-btn-outline"
                v-model:value1="cpListCdDcds"
                v-model:toggle1="toggles_cp.cpListCdDcd"
                :options1="cpListCdDcdOpt"
                sequence-key1="value"
                name-key1="display"
                :use-sub-text="false"
                title="복습하기 :"
                :width="250"
                placeholder1="주관처선택"
                @update:toggle1="goCP()"
            />
<!--            </div>-->

<!--            <div class="item-row row-buttons" style="padding: 5px">-->
<!--              <button class="kb-btn kb-btn-outline" @click="goKgEduone()">-->
<!--                <span class="text">복습하기 (KG 에듀원) </span>-->
<!--              </button>-->
<!--            </div>-->
<!--            <div class="item-row row-buttons" style="padding: 5px">-->
<!--              <button class="kb-btn kb-btn-outline" @click="goFastCampus()">-->
<!--                <span class="text">복습하기 (패스트캠퍼스)</span>-->
<!--              </button>-->
<!--            </div>-->

          </div>
        </div>

        <div class="top-column" v-if="!isMobile">
          <div>
            <div class="kb-form-search">
              <div class="kb-form-search-status">
                <span class="text">총 {{ filteredItems.length }}건</span>
              </div>
              <div class="kb-form-search-field">
                <input v-model.trim="keyword" type="text" class="kb-form-search-input" placeholder="검색" @keyup.enter="searchKeyword">
                <button class="kb-form-search-btn" @click="searchKeyword"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //list-top -->
      <div v-if="isMobile" class="symbol-item-wrap symbol-learning-wrap">
        <template v-if="isMobile">
          <template v-if="filteredPagedItems.length > 0">
          <div class="symbol-item"  v-for="(item, index) in filteredPagedItems" :key="index">
            <div class="item-icon"><i class="symbol-type-cyber"></i></div>
            <div class="item-learning">
              <div class="content-subtitle">{{ item.mediaCateNm }} ・ {{ item.orgnzNm }} {{ (item.orgnzNm!=="HR부(인재개발)" && item.orgnzNm!=="인재개발부") ? " ・ " + item.prvdrNm : ""}}</div>
              <div class="content-title">{{ item.crseNm }}</div>
              <div class="content-texts">
                <span class="text">
                  <div class="item-status">
                    <strong class="text" :class="{'text-primary': item.learningStatus === 1, 'text-muted': item.learningStatus === 2}">{{ getLearnStatusText(item.learningStatus) }}</strong>
                  </div>
                </span>
                <span class="text">-</span>
                <span class="text">
                  {{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }}-{{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}
                </span>
              </div>
            </div>
          </div>
          </template>
          <template v-else>
            <div class="item-learning">
            <div class="content-texts">
              <br/>
              <div class="text-center"><span class="text">연수이력이 존재하지 않습니다.</span></div>
            </div>
            </div>
          </template>
        </template>
      </div>

      <div v-else class="kb-table kb-table-bordered learning-tablelist">
        <table>
          <colgroup>
            <col style="width:auto">
            <col style="width:213px">
            <col style="width:120px">
            <col style="width:120px">
            <col style="width:162px">
          </colgroup>
          <tbody>
          <tr v-if="filteredPagedItems.length === 0">
            <td colspan="5"><div class="text-truncate text-center"><strong class="td-text">연수이력이 존재하지 않습니다.</strong></div></td>
          </tr>
          <template v-else>
            <tr v-for="(item, index) in filteredPagedItems" :key="index">
              <td>
                <div class="item-content">
                  <div class="content-details">
                    <h5 class="content-title"><a href="javascript:" class="title-link">{{ item.crseNm }}</a></h5>
                    <p class="content-text"><span class="text">{{ item.mediaCateNm }}</span><span class="text">{{ item.orgnzNm }}  {{ (item.orgnzNm!=="HR부(인재개발)" && item.orgnzNm!=="인재개발부") ? " ・ " + item.prvdrNm : ""}}</span></p>
                  </div>
                </div>
              </td>
              <td><div class="item-date"><span class="text">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }}-{{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</span></div></td>
              <td><div class="item-evaluation"><span class="text">{{ getLearningScore(item) }}</span></div></td>
              <td>
                <div class="item-status">
                  <strong class="text" :class="{'text-primary': item.learningStatus === 1,
                                                  'text-hazard': item.learningStatus === 2,
                                                  'text-muted': item.learningStatus > 2}">
                    {{ getLearnStatusText(item.learningStatus) }}
                  </strong>
                </div>
              </td>
              <!-- 복습의 경우 추후 논의 -->
              <td><div class="item-actions"><span class="text">-</span></div></td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>

      <CommonPaginationFront :paging="{
        pageNo: paging.pageNo,
        pageSize: paging.pageSize,
        totalCount: filteredItems.length
      }" :page-func="pagingFunc"/>

    </div>
    <!-- //training-list-container -->
  </article>
</template>

<script>

import {computed, reactive, ref, watch} from 'vue';
import {closeToggles} from '@/assets/js/ui.init';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {paginate, stringCheck, timestampToDateFormat, timestampToDatePart, winLearnOpen} from '@/assets/js/util';
import navigationUtils from '@/assets/js/navigationUtils';
import SortButtonSelect3 from '@/components/common/SortButtonSelect3';
import {ACT_GET_SSO_CP_URL, ACT_GET_SSO_FCAMPUS_URL, ACT_GET_SSO_KGEDU_URL} from '@/store/modules/mypage/mypage';
import InputSelect from '@/components/support/common/InputSelect';

import store from '@/store';

export default {
  name: "MyPageLearn",
  components: {CommonPaginationFront, SortButtonSelect, SortButtonSelect3, InputSelect},
  props: {
    items: Array,
    effected: Number
  },
  emits: ['lrnHistoryPrint:lrnObjPrint'],
  setup(props, {emit}) {
    const currentDate = new Date();
    const currentTs = currentDate.getTime();
    //const store = useStore();
    const isMobile = navigationUtils.any();

    const keyword = ref('');

    const filters = reactive({
      year: currentDate.getFullYear(),
      mediaCateCd: '',
      finish: 0,
      keyword: '',
    });
    const toggles = reactive({
      year: false,
      mediaCateCd: false,
      finish: false
    });


    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
    });

    const yearOptions = (() => {
      const options = [];
      for(let i = currentDate.getFullYear(); i >= 1978 ; i--){
        options.push({value: i, display: `${i}년`});
      }
      return options;
    })();
    const cateOptions = computed(() => store.state.code.cates);
    const selectedCateSns = computed(() => {
      const target = cateOptions.value.find(x => x.mediaCateCd === filters.mediaCateCd)
      if(target){
        const result = [target.mediaCateSn];
        releaseArr(target.children, result, (t) => t.mediaCateSn);
        return result;
      }
      return [];
    });

    const releaseArr = (arr, result, getFunc) => {
      if(arr && arr.length > 0){
        arr.forEach(a => {
          result.push(getFunc(a));
          releaseArr(a.children, result, getFunc);
        });
      }
    }

    const finishOptions = [
      {value: 1, display: '수료'},
      {value: 2, display: '미수료'},
      {value: 3, display: '결과처리중'},
      {value: 4, display: '학습진행중'},
      {value: 5, display: '-'},
      {value: 6, display: '참여'},
    ]

    const cpListCdDcds = ref('');

    const toggles_cp = reactive({
      cpListCdDcd: false
    });

    // {value: "10800", display: '알파코'},
    const cpListCdDcdOpt = [
      {value: "3", display: '한국금융연수원'},
      {value: "10799", display: '알파코DT'},
      {value: "10812", display: 'KG에듀원'},
      {value: "C000025", display: '패스트캠퍼스'},
      {value: "2219021", display: 'KB 스타디움'},
    ]

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      const pItems = computed(() => filteredItems.value);
      emit('lrnHistoryPrint:lrnObjPrint', pItems);
    }

    const searchKeyword = () => {
      filters.keyword = keyword.value;
      pagingFunc(1);
    }

    const getLearningStatus = (item) => {
      // 학습 시작전
      if(currentTs < item.bgngDt){
        return 5;
      }
      // 학습중
      else if(currentTs >= item.bgngDt && currentTs <= item.endDt){
        return 4;
      }
      // 학습 종료
      else if(currentTs > item.endDt){
        //SSL 강제처리, 북러닝 제외
        if (item.splCrseTyCdDcd === '2066001' && item.crseMstSn !== 100342 && item.fnshYn !=='Y') {
          return 6;
        }

        if (item.auditCfmtnCd === 'Y') {
          if (item.fnshYn === 'Y') {
            return 1;
          } else if (item.fnshYn === 'N') {
            return 2;
          }
        } else {
          if (stringCheck(item.splCrseTyCdDcd) && item.fnshYn !== 'Y') return 5;
          return 3;
        }
      }
      return 3;

      // 수료/미수료 조건만 노출
      // if(item.fnshYn === 'Y'){
      //   return 1;
      // }else{
      //   return 2;
      // }
    }

    const getLearnStatusText = (status) => {
      const target = finishOptions.find(x => x.value === status);
      if(target){
        return target.display;
      }
      return '-';
    }

    const getLearningScore = (item) => {
      return item.getScore !== null && item.getScore !== undefined ? (item.fnshYn ? item.getScore : '-') : '-';
    }



    const goKgEduone = () => {
      const session = store.state.auth.session;

      store.dispatch(`mypage/${ACT_GET_SSO_KGEDU_URL}`, session.lrnerId).then(res => {
        if (session.itn) {
          if(confirm('은행내에서 외부과정을 접속하시려면 KB인터넷PC의\n[인터넷PC-연수시스템]바로가기 아이콘을 이용하시기 바랍니다\n\n가상인터넷PC 실행화면으로 이동하시겠습니까?')) location.href="https://kbintpc.kbstar.com";
        }else {
          var item = {
            prvdrCd: '10812',
            etnLrnUrl: 'http://www.kbwith.com/enterclass.asp?user_id=' + res
          }
          winLearnOpen(item);
        }
      });
    };



    const goFastCampus = () => {
      const session = store.state.auth.session;
      store.dispatch(`mypage/${ACT_GET_SSO_FCAMPUS_URL}`, session.lrnerId).then(res => {
        if (session.itn) {
          if(confirm('은행내에서 외부과정을 접속하시려면 KB인터넷PC의\n[인터넷PC-연수시스템]바로가기 아이콘을 이용하시기 바랍니다\n\n가상인터넷PC 실행화면으로 이동하시겠습니까?')) location.href="https://kbintpc.kbstar.com";
        }else {
          var item = {
            prvdrCd: '2219096',
            etnLrnUrl: 'https://kbstar.fastcampus.app/sso/kbstar?token=' + res
          }
          winLearnOpen(item);
        }
      });
    };
    const goCP = () => {
      if(toggles_cp.cpListCdDcd) return;

      const session = store.state.auth.session;


      store.dispatch(`mypage/${ACT_GET_SSO_CP_URL}`, cpListCdDcds.value).then(res => {
        if (session.itn) {
          if(confirm('은행내에서 외부과정을 접속하시려면 KB인터넷PC의\n[인터넷PC-연수시스템]바로가기 아이콘을 이용하시기 바랍니다\n\n가상인터넷PC 실행화면으로 이동하시겠습니까?')) location.href="https://kbintpc.kbstar.com";
        }else {
          var item = {
            prvdrCd: cpListCdDcds.value,
            etnLrnUrl: res
          }
          console.log(item)
          winLearnOpen(item);
        }
      });
    };

    const filteredItems = computed(() => props.items
        .filter(x => x.endDt <= currentTs)
        .filter(x => {
          // 연도 필터
          if(filters.year){
            if(x.bgngDt){
              const bgngYear = timestampToDatePart(x.bgngDt).year;
              return (bgngYear === filters.year);
            }
            return false;
          }else{
            return true;
          }
        })
        .filter(x => {
          if(!['2066006','2066008','2066009','2066017','2066013','2066014','2066015','2066016','2066007','2066010','2066029','2066031','2066030'].includes(x.splCrseTyCdDcd) && x.crseMstSn !== 15224) return true;
        })
        .filter(x => {
          // 매체 필터
          if(filters.mediaCateCd) {
            return selectedCateSns.value.includes(x.mediaCateSn);
          }
          return true;
        })
        .filter(x => {
          // 검색어 필터
          if(filters.keyword) return x.crseNm.indexOf(filters.keyword) > -1;
          return true;
        })
        .filter(x => {
          if([1844, 8347, 17090, 17089, 17088, 17087].includes(x.distCrseSn)) {
            return x.fnshYn === 'Y';
          }
          return true;
        })
        .map(x => ({...x, learningStatus: getLearningStatus(x)}))
        .filter(x => {
          // 학습현황 필터
          if(filters.finish > 0) return x.learningStatus === filters.finish;
          return true;
        })
        .filter(x => {
          //직무수행교육, 포상여행 점수 제거
          if(['2066013','2066028'].includes(x.splCrseTyCdDcd)) {
            x.getScore = '-';
          }
          return true;
        })
        .sort((a,b) => {if(a.bgngDt > b.bgngDt){ return -1} return 0;})
    );
    const filteredPagedItems = computed(() => paginate(filteredItems.value, paging.pageNo, paging.pageSize));

    watch(() => props.effected ,() => {
      closeToggles(toggles);

      const lrnHistoryItems = computed( () => props.items.filter(x => timestampToDatePart(x.bgngDt).year === filters.year));
      emit('lrnHistoryPrint:lrnObjPrint', lrnHistoryItems);
    });

    return {

      keyword,
      filters,
      toggles,
      paging,
      isMobile,

      yearOptions,
      cateOptions,
      finishOptions,

      filteredItems,
      filteredPagedItems,

      closeToggles,
      pagingFunc,
      searchKeyword,

      getLearnStatusText,
      getLearningScore,

      goKgEduone,
      goFastCampus,

      timestampToDateFormat,
      cpListCdDcdOpt,
      cpListCdDcds,
      toggles_cp,
      goCP,
    }
  }
}
</script>

<style scoped>

.lxp-layout .text-hazard {
  --bs-text-opacity: 1;
  color: var(--kb-red) !important;
}

</style>