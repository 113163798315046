<template>
  <div class="board-list-container">
    <!-- board-list-wrapper -->
    <div class="board-list-wrapper">

      <div v-if="!isLoading && items.length === 0" class="board-search-wrapper">
        <div class="search-empty">
          <img src="@/assets/lxp/images/ssl/main/empty_image.png" alt>
        </div>
      </div>

      <!-- board-list-type -->
      <ul v-else class="board-list-type">
        <!-- board-item -->
        <li v-for="(item, idx) in items" class="board-item" :class="{'type-text': !item.thumb}" :key="idx">
          <SSLMobileCard
              :item="item"
              :is-group="(getCrseSnToDistCrseCd(item.crseMstSn) == 'group')"
              @moveBoard="moveBoard"
          />
        </li>
      </ul>
      <!-- //board-list-type -->
      <!-- board-list-actions -->
      <div v-if="paging.hasMore" class="board-list-actions" >
        <button class="kb-btn-more" @click="moreFunc">
          <span class="text">더보기</span>
        </button>
      </div>
      <!-- //board-list-actions -->
    </div>
    <!-- //board-list-wrapper -->
  </div>
</template>
<script>

import SSLMobileCard from "@/components/ssl/main/mobile/SSLMobileCard";
import {getCrseSnToDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";

export default {
  name: 'SSLMobileTotBoardList',
  components: {SSLMobileCard},
  methods: {getCrseSnToDistCrseCd},
  props: {
    isLoading: Boolean,
    lrnTrgtGrpMstSn: Number,
    items: Array,
    paging: Object,
    moreFunc: Function,
    showFilter: Boolean,
    viewModes: Array,
    viewModeIdx: Number,
    openSearch: Function
  },
  emits: ['moveBoard'],
  setup(props, {emit}) {


    // console.log('item.length : ' + props.items.length);
    const moveBoard = (item) => {
      emit('moveBoard', item);
    }

    return {
      moveBoard
    }
  }
}
</script>
