<template>
<!--  <footer class="ssl-footer" :class="{'intro': isFill, 'no-margin': isNoMargin}" :data-bg="isFill ? 'fill' : ''">-->
<!--  <footer class="kb-footer" id="kb-footer" :class="{'intro': isFill, 'no-margin': isNoMargin}" :data-bg="isFill ? 'fill' : ''">-->
<!--    <p class="copyright">Copyright (C) KB E-HRD. All Rights Reserved</p>-->
<!--  </footer>-->
  <footer class="kb-footer" id="kb-footer">
    <div class="footer-copyright"><p class="copyright text-center mb-2">(주)국민은행 인재개발부</p>
      <p class="copyright text-center">Copyright (C) KB스타런. All Rights Reserved</p>
    </div>
  </footer>
</template>
<script>
import {useRoute} from 'vue-router';
import {computed} from 'vue';

export default {
  name: 'SSLFooter',
  setup() {
    const route = useRoute()
    const isFill = computed(() => {
      return route.name === 'SSLHome' || route.name === 'SSLWrite';
    });

    const isNoMargin = computed(() => {
      return route.name === 'SSLHome';
    });

    return {
      isFill,
      isNoMargin
    }
  }

}
</script>

