import ApiService from "@/assets/js/api.service";


//action
export const ACT_GET_CLOUD_LIST = 'actGetCloudList';




const actions = {

    [ACT_GET_CLOUD_LIST](context, keyword) {
        return new Promise(resolve => {
            ApiService.get('/v1/app/unify/search', keyword)
                .then(response => resolve(response))
        });
    },
};


export default {
    namespaced: true,
    actions
};