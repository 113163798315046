<template>
  <SSLMobileView
      v-if="currentName === 'SSLView'"
      @acted="acted"
  />

  <main v-else class="kb-main" id="kb-prep" >
    <div class="main-content">
      <div class="ssl-body pb-0" :id="mainId">
        <SSLMobileSlotHeader>
          <template v-slot:title>
<!--            <SSLMobileNav />-->
          </template>
          <template v-slot:left>
            <div v-if="!isSSLSearch && !searchMode" class="util util-back">
              <router-link :to="{name: 'SSLHome'}" class="util-actions util-actions-history-back">
                <i class="icon-history-back"></i>SSL 홈
              </router-link>
            </div>
            <template v-else>
              <div v-if="searchMode" class="util util-back">
                <a href="javascript:" class="util-actions util-actions-back" @click="cnclSearch"><i class="icon-history-back"></i></a>
              </div>
            </template>
            <div v-if="searchMode" class="util util-search-input">
              <input ref="keywordEl" v-model.trim="keyword" type="text" placeholder="학습노트 검색" @keyup.prevent.stop.enter="goSearch" />
            </div>
          </template>
          <template v-slot:right>
            <div class="util util-search">
              <a href="javascript:"
                 class="util-actions util-actions-search"
                 @click="goSearch"
                 @keyup.prevent.stop.enter="goSearch"
              ><i class="icon-search"></i></a>
            </div>
              <div class="util util-more">
                <div class="dropdown" :class="{'is-active': toggles.nav}" data-offset="header-left">
                  <button class="dropdown-btn" @click="toggles.nav = !toggles.nav"><i class="icon-more"></i></button>
                  <div class="dropdown-target" style="width: 144px;left: -104px;">
                    <div class="dropdown-box">
                      <ul class="dropdown-option-list">
                        <li class="dropdown-option-item">
                          <SSLTrial
                              trial-class="dropdown-option-link"
                              text-class="dropdown-option-text"
                          />
                        </li>
                        <li class="dropdown-option-item">
                          <SSLManual
                              manual-class="dropdown-option-link"
                              text-class="dropdown-option-text"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
          </template>
        </SSLMobileSlotHeader>

        <div class="page-intro page-intro-black">
          <div class="intro-content">
            <div class="page-component">
              <h2 class="intro-title">우수 노트 모아보기</h2>
            </div>
          </div>
          <div class="intro-background"></div>
        </div>

        <div class="contents">
          <div class="list-top">
            <div class="top-column">
              <!-- list-top > list-sort -->
              <div class="sort" style="margin-right: 15px">
                <SortButtonSelect3
                    v-model="filters.year"
                    v-model:toggle="toggles.year"
                    title="년도"
                    :options="yearOptions"
                    sequence-key="value"
                    name-key="display"
                    :is-num="true"
                    :is-all="false"
                    @update:toggle="closeToggles(toggles, 'year')"
                    @selected="getOptions()"
                />
              </div>
              <div class="sort" style="margin-right: 15px">
                <SortButtonSelect3
                    v-model="filters.distCrseSn"
                    v-model:toggle="toggles.distCrseSn"
                    title="과정"
                    :options="distOptions"
                    sequence-key="distCrseSn"
                    name-key="crseNm"
                    :is-all="true"
                    :width="300"
                    @update:toggle="closeToggles(toggles, 'distCrseSn')"
                    @selected="initSearch()"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="page-container">
          <SSLMobileTotBoardList
              :is-loading="isLoading"
              :items="boardItems"
              :paging="paging"
              :more-func="moreFunc"
          />
        </div>
      </div>
    </div>
  </main>

</template>
<script>
import SSLMobileView from '@/views/ssl/mobile/SSLMobileView';
import {sslBestNoteSetup} from '@/assets/js/modules/ssl/setup/ssl-best-note-setup';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import SSLTrial from '@/components/ssl/common/SSLTrial';
import SSLManual from '@/components/ssl/common/SSLManual';
import SSLMobileTotBoardList from "@/components/ssl/main/mobile/SSLMobileTotBoardList.vue";
import SortButtonSelect3 from "@/components/common/SortButtonSelect3.vue";

export default {
  name: 'SSLMobileBestNotes',
  components: {
    SortButtonSelect3,
    // SSLMobileNav,
    SSLManual,
    SSLTrial,
    SSLMobileSlotHeader,
    SSLMobileView,
    SSLMobileTotBoardList
  },
  setup: sslBestNoteSetup
}
</script>
