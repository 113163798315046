<template>
  <!-- main-content -->
  <div class="main-content min-component">
    <article class="content-section section-01">
      <header class="section-header">
        <h4 class="title">갱신 가능 자격증</h4>
        <p class="subtext">총 {{ items.length }}건</p>
      </header>
      <div v-if="!isLoading && (items && items.length > 0)" class="contact-table">
        <div class="kb-table kb-table-bordered">
          <table>
            <colgroup>
              <col style="width:170px;" />
              <col style="width:122px;" />
              <col style="width:122px;" />
              <col style="width:auto;" />
              <col style="width:150px;" />
              <col style="width:150px;" />
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">주관처</span></th>
              <th><span class="th-title">자격증코드</span></th>
              <th><span class="th-title">자격증등급</span></th>
              <th><span class="th-title">자격증명</span></th>
              <th><span class="th-title">취득일</span></th>
              <th><span class="th-title">최종 갱신 유효일</span></th>
              <th><span class="th-title"> </span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,idx) in items" :key="idx">
              <td><span class="td-text">{{ item.pblcnInstNm }}</span></td>
              <td><span class="td-text">{{ item.lcsnCd }}</span></td>
              <td><span class="td-text">{{ item.grd1Cd }}</span></td>
              <td><span class="td-text">{{ item.lcsnNm }}</span></td>
              <td><span class="td-text">{{ item.passAnnonYmd ? ymdStrToFormat(item.passAnnonYmd, '-') : '-' }}</span></td>
              <td><span class="td-text">{{ item.updtVldYmd ? ymdStrToFormat(item.updtVldYmd, '-') : '-' }}</span></td>
              <td class="td-end">
                <button class="kb-btn kb-btn-link kb-btn-primary" @click="renewLicense(idx)">
                  <span class="text">갱신하기</span>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <div class="bottom-notice">
            <i class="icon-notice"></i>
            <strong class="subtext">상품 판매자격은 단말에서의 권한에 의해 판매가 가능하며 EHRD대시보드에는 배치작업으로 인하여 반영이 늦어 질 수 있습니다.</strong>
          </div>
        </div>
      </div>
      <div v-else-if="!isLoading && (items && items.length === 0)">
        <div class="search-container">
          <div class="result-empty">
            <img src="../../assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">갱신대상 자격증이 없습니다.</p>
          </div>
        </div>
      </div>
    </article>
    <!-- section: 지원대상 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">지원대상</h4>
        </div>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
              <tr>
                <td><span class="td-text">지급일 현재 재직중인 직원 (일반/계약)<br>※ 지급일 현재 정직자 및 파트타이머 제외</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 신청기한 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">신청기한</h4>
        </div>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
            <tr>
              <td>
                <span class="td-text">갱신비 납부일로부터 <span class="fw-bolder">1년 이내</span></span><br>
                <span class="td-text">※ 입행 전 발생한 갱신비용은 신청기한과 관계없이 지원 불가</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>


    <!-- section: 지원절차 및 시기 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">지원절차 및 시기</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
            <tr>
              <td class="d-flex justify-content-evenly">
                <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                  <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.1</div>
                  <div class="mt-lg-4 text-center">자격증 갱신 후<br>필요서류 준비</div>
                </div>
                <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                  <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                </div>
                <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                  <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.2</div>
                  <div class="mt-lg-4 text-center">스타런에서<br>신청</div>
                </div>
                <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                  <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                </div>
                <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                  <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.3</div>
                  <div class="mt-lg-4 text-center">인재개발부<br>심사</div>
                </div>
                <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                  <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                </div>
                <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                  <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.4</div>
                  <div class="mt-lg-4 text-center">급여계좌로 지급<br><span class="font-body12">(전산 신청일 기준<br><span class="fw-bolder">익월 15일</span>)</span></div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>

    <!-- section: 지원내용 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">지원내용</h4>
        </div>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
            <tr>
              <td>
                <span class="td-text fw-bolder">갱신비용<sup class="text-red">주1)</sup> 지원대상 등급 자격증 현황 (2023.08 기준)</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table kb-table-bordered-v2 mt-4">
          <table>
            <colgroup>
              <col />
              <col style="width:17%;" />
              <col style="width:17%;" />
              <col style="width:17%;" />
              <col style="width:17%;" />
            </colgroup>
            <thead>
            <tr>
              <th><span class="th-title">자격증명</span></th>
              <th><span class="th-title">자격증코드</span></th>
              <th><span class="th-title">등급</span></th>
              <th><span class="th-title">갱신주기(년)</span></th>
              <th><span class="th-title">갱신비용(원)</span></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td><span class="td-text">국제공인재무분석사(CFA)</span></td>
              <td><span class="td-text">WR70</span></td>
              <td><span class="td-text">A</span></td>
              <td><span class="td-text">1</span></td>
              <td><span class="td-text">USD350</span></td>
            </tr>
            <tr>
              <td><span class="td-text">기술사</span></td>
              <td><span class="td-text">WA01</span></td>
              <td><span class="td-text">A</span></td>
              <td><span class="td-text">5</span></td>
              <td><span class="td-text">50,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">CAMS</span></td>
              <td><span class="td-text">WR9A</span></td>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD250</span></td>
            </tr>
            <tr>
              <td><span class="td-text">건축사</span></td>
              <td><span class="td-text">AA06</span></td>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">5</span></td>
              <td><span class="td-text">100,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">공인국제제재전문가(CGSS)</span></td>
              <td><span class="td-text">WR3I</span></td>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD250</span></td>
            </tr>
            <tr>
              <td><span class="td-text">공인대체투자분석사(CAIA)</span></td>
              <td><span class="td-text">WR7L</span></td>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">1</span></td>
              <td><span class="td-text">USD100</span></td>
            </tr>
            <tr>
              <td><span class="td-text">국제공인내부감사사(CIA)</span></td>
              <td><span class="td-text">WR84</span></td>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">1</span></td>
              <td><span class="td-text">USD120</span></td>
            </tr>
            <tr>
              <td><span class="td-text">국제공인재무설계사(CFP)</span></td>
              <td><span class="td-text">WR71</span></td>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">2</span></td>
              <td><span class="td-text">220,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">美공인회계사(AICPA)</span></td>
              <td><span class="td-text">WR53</span></td>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD200</span></td>
            </tr>
            <tr>
              <td><span class="td-text">美변호사(LL.M)</span></td>
              <td><span class="td-text">WR58</span></td>
              <td><span class="td-text">B</span></td>
              <td><span class="td-text">2</span></td>
              <td><span class="td-text">USD375</span></td>
            </tr>
            <tr>
              <td><span class="td-text">국제거래용 은행보증서 실무전문가(CSDG)</span></td>
              <td><span class="td-text">WR7M</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">GBP200</span></td>
            </tr>
            <tr>
              <td><span class="td-text">국제공인 무역금융 컴플라이언스(CTFC)</span></td>
              <td><span class="td-text">WR9C</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">GBP200</span></td>
            </tr>
            <tr>
              <td><span class="td-text">국제공인신용장전문가(CDCS)</span></td>
              <td><span class="td-text">WR4D</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">GBP200</span></td>
            </tr>
            <tr>
              <td><span class="td-text">금융투자분석사</span></td>
              <td><span class="td-text">WR3A</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">2</span></td>
              <td><span class="td-text">34,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">재무위험관리사(FRM,금융투자협회)</span></td>
              <td><span class="td-text">WR68</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">2</span></td>
              <td><span class="td-text">34,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">정보시스템관리사</span></td>
              <td><span class="td-text">WR0J</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">5</span></td>
              <td><span class="td-text">12,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">정보시스템감사사(CISA)</span></td>
              <td><span class="td-text">NR04</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">1</span></td>
              <td><span class="td-text">USD85</span></td>
            </tr>
            <tr>
              <td><span class="td-text">투자자산운용사</span></td>
              <td><span class="td-text">WR2Y</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">2</span></td>
              <td><span class="td-text">38,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">한국재무설계사(AFPK,한국 FPSB)</span></td>
              <td><span class="td-text">WR67</span></td>
              <td><span class="td-text">C</span></td>
              <td><span class="td-text">2</span></td>
              <td><span class="td-text">66,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">AWS Solution Archict-Professional</span></td>
              <td><span class="td-text">WR3L</span></td>
              <td><span class="td-text">D</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD150</span></td>
            </tr>
            <tr>
              <td><span class="td-text">경영지도사</span></td>
              <td><span class="td-text">WR4C</span></td>
              <td><span class="td-text">D</span></td>
              <td><span class="td-text">5</span></td>
              <td><span class="td-text">50,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">공인기능점수전문가(CFPS)</span></td>
              <td><span class="td-text">WR0W</span></td>
              <td><span class="td-text">D</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">EUR235</span></td>
            </tr>
            <tr>
              <td><span class="td-text">애자일전문가(ACP)</span></td>
              <td><span class="td-text">WR3J</span></td>
              <td><span class="td-text">D</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD150</span></td>
            </tr>
            <tr>
              <td><span class="td-text">정보시스템보안전문가(CISSP)</span></td>
              <td><span class="td-text">WR0C</span></td>
              <td><span class="td-text">D</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD125</span></td>
            </tr>
            <tr>
              <td><span class="td-text">프로젝트관리전문가(PMP/P.M.I)</span></td>
              <td><span class="td-text">HR18</span></td>
              <td><span class="td-text">D</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD150</span></td>
            </tr>
            <tr>
              <td><span class="td-text">AWS Solution Archict-Associate</span></td>
              <td><span class="td-text">WR6U</span></td>
              <td><span class="td-text">E</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD75</span></td>
            </tr>
            <tr>
              <td><span class="td-text">CCNA</span></td>
              <td><span class="td-text">WR9Q</span></td>
              <td><span class="td-text">E</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">USD295</span></td>
            </tr>
            <tr>
              <td><span class="td-text">LPIC-1</span></td>
              <td><span class="td-text">WR6T</span></td>
              <td><span class="td-text">E</span></td>
              <td><span class="td-text">5</span></td>
              <td><span class="td-text">USD188</span></td>
            </tr>
            <tr>
              <td><span class="td-text">기술지도사</span></td>
              <td><span class="td-text">NR02</span></td>
              <td><span class="td-text">E</span></td>
              <td><span class="td-text">5</span></td>
              <td><span class="td-text">50,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">신용관리사</span></td>
              <td><span class="td-text">WR95</span></td>
              <td><span class="td-text">E</span></td>
              <td><span class="td-text">3</span></td>
              <td><span class="td-text">3,000</span></td>
            </tr>
            <tr>
              <td><span class="td-text">증권투자권유자문인력</span></td>
              <td><span class="td-text">WR85</span></td>
              <td><span class="td-text">E</span></td>
              <td><span class="td-text">2</span></td>
              <td><span class="td-text">34,000</span></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <dl class="bottom-dl">
            <dd class="dl-dd subtext">주1) 갱신과 직접적인 관련이 없는 연회비, 연체료, 계속학점취득비(세미나 참석으로 대체가능한 학점취득비) 등은 지원금액에서 제외됨</dd>
          </dl>
        </div>
      </div>
    </article>
<!--    &lt;!&ndash; section: 신청방법 &ndash;&gt;-->
<!--    <article class="content-section section-divider">-->
<!--      <header class="section-header">-->
<!--        <h4 class="title">신청방법</h4>-->
<!--      </header>-->
<!--      <div class="guide-table">-->
<!--        <div class="kb-table table-striped">-->
<!--          <table>-->
<!--            <tbody>-->
<!--            <tr>-->
<!--              <td>-->
<!--                <span class="td-text">스타런</span><i class="icon-arrow-next"></i>-->
<!--                <span class="td-text">자기주도학습지원</span><i class="icon-arrow-next"></i>-->
<!--                <span class="td-text">자격증 지원 신청</span><i class="icon-arrow-next"></i>-->
<!--                <span class="td-text">갱신지원 안내</span>-->
<!--              </td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->
<!--      </div>-->
<!--    </article>-->
    <!-- section: 필요서류 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">필요서류</h4>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <colgroup>
              <col style="width:40%" />
              <col />
            </colgroup>
            <tbody>
            <tr>
              <td colspan="2"><span class="td-text">1. 갱신 자격증, 청구서, 수료증 등(자격증별 상이)</span></td>
            </tr>
            <tr>
              <td><span class="td-text">2. 결제 영수증</span></td>
              <td><span class="td-text">- 본인 명의 KB신용・체크카드(가족愛복지 포함) 결제분만 인정</span><br><span class="td-text fw-bolder">※ AFPK 및 CFP는 납부증빙서류 첨부 불필요</span></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: 유의사항 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <div class="header-column">
          <h4 class="title">유의사항</h4>
        </div>
      </header>
      <div class="guide-table">
        <div class="kb-table kb-table-striped">
          <table>
            <tbody>
            <tr>
              <td>
                <span class="td-text">- 갱신비용 지원은 투명하고 정당한 절차에 의한 방법으로 지원받으시기 바랍니다.</span><br>
                <span class="td-text">- 지급된 갱신비는 기소득 처리 대상으로 인재개발부에서 일괄 과표 처리합니다.</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </article>
    <!-- section: AFPK/CFP 윤리교육 -->
    <article class="content-section section-divider">
      <header class="section-header">
        <h4 class="title">AFPK/CFP 윤리교육</h4>
      </header>
      <div class="kb-table-bottom">
        <div class="bottom-notice">
          <i class="icon-notice"></i>
          <strong class="subtext">해당 자격증 갱신은 한국FPSB의 윤리교육/계속교육(무료수강) 이수 후 신청 가능합니다. 자세한 사항은 아래 링크(외부망에서만 접속가능)를 참고하시기 바랍니다.('22.12.27~)</strong>
        </div>
      </div>
      <br/>
      <div class="contact-table">
        <div class="kb-table kb-table-bordered">
          <table>
            <colgroup>
              <col style="width:112px;" />
              <col style="width:117px;" />
              <col />
              <col style="width:186px;" />
            </colgroup>
            <tbody>
            <tr>
              <td><strong class="td-text text-gold">한국FPSB</strong></td>
              <td><strong class="td-text"></strong></td>
              <td><span class="td-text">AFPK 자격갱신안내</span></td>
              <td class="td-end"><a href="https://fpsbkorea.org/?mnu_usn=53" target="_blank" class="kb-btn kb-btn-link kb-btn-sm kb-btn-secondary"><span class="text">바로가기</span></a></td>
            </tr>
            <tr>
              <td><strong class="td-text text-gold">한국FPSB</strong></td>
              <td><strong class="td-text"></strong></td>
              <td><span class="td-text">CFP 자격갱신안내</span></td>
              <td class="td-end"><a href="https://fpsbkorea.org/?mnu_usn=54" target="_blank" class="kb-btn kb-btn-link kb-btn-sm kb-btn-secondary"><span class="text">바로가기</span></a></td>
            </tr>
            <tr>
              <td><strong class="td-text text-gold">한국FPSB</strong></td>
              <td><strong class="td-text"></strong></td>
              <td><span class="td-text">계속교육학점인정기준</span></td>
              <td class="td-end"><a href="https://fpsbkorea.org/?mnu_usn=124" target="_blank"  class="kb-btn kb-btn-link kb-btn-sm kb-btn-secondary"><span class="text">바로가기</span></a></td>
            </tr>
            <tr>
              <td><strong class="td-text text-gold">한국FPSB</strong></td>
              <td><strong class="td-text"></strong></td>
              <td><span class="td-text">교육센터</span></td>
              <td class="td-end"><a href="https://on.fpsbkorea.org/course/course_list.jsp" target="_blank" class="kb-btn kb-btn-link kb-btn-sm kb-btn-secondary"><span class="text">바로가기</span></a></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="kb-table-bottom">
          <div class="bottom-notice">
            <strong class="subtext">※기존 HRD클라우드에서 제공되던 윤리교육 영상은 미제공</strong>
          </div>
        </div>
      </div>
    </article>
    <LoadingDiv v-if="isLoading" />
  </div>
  <!-- //main-content -->
  <SupportLicenseRenewModal
      v-if="renewModal && param"
      v-model="renewModal"
      v-model:data="param"
      mode="renewal"
      @update:modelValue="getList"
  />
</template>

<script>
import {reactive, ref, watch} from 'vue';
import SupportLicenseRenewModal from '@/components/support/SupportLicenseRenewModal';
import {getDsjChk, getLcsnRenewList2, getRefundChk} from '@/assets/js/modules/support/support-common';
import {getItems, timestampToDateFormat, ymdStrToFormat} from '@/assets/js/util';
import LoadingDiv from '@/components/common/LoadingDiv';
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'SupportLcnsRenewal',
  components: {LoadingDiv, SupportLicenseRenewModal},
  setup() {
    const renewModal = ref(false), isLoading =ref(true);
    const items = ref([]);
    const paging = reactive({pageNo: 1, pageSize: 10, totalCount: 0, hasMore: false});
    const param = ref(null);
    const {showMessage} = useAlert();
    const appDsjYn = ref(0);
    const refndYn = ref(0);

    const chkAppDsj = () => {
      let chkVal = getDsjChk();
      appDsjYn.value = getItems(chkVal);
    }
    const chkRefund = () => {
      let chkVal = getRefundChk();
      refndYn.value = getItems(chkVal);
    }

    const convertTime = (timestamp) => {
      if (timestamp) {
        return timestampToDateFormat(timestamp, 'yyyy.MM.dd');
      } else {
        return '';
      }
    };

    const renewLicense = (idx) => {
      if (appDsjYn.value > 0){
        showMessage('자격증 갱신비 지원 신청 대상이 아닙니다. ');
        return false;
      }else {
        if (chkRefund.value > 0) {
          showMessage(`연수과정 미수료에 의한 <br>교육훈련비 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 인재개발부<br>업무담당자(☎7880)에게<br>문의 부탁드립니다.`);
          return false;
        }else{
          if(items.value[idx].costLcsnSn > 0) {
            showMessage('동일 자격증으로 종료되지 않은 신청내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
            return false;
          }else{

            param.value = items.value[idx];
            param.value.stt = null;
            param.value.updtVldYmd = null;
            renewModal.value = true;

          }
        }
      }
    };

    const getList = () => {
      try {
        getLcsnRenewList2(items, paging, () => {
          items.value = items.value.map(x => {
            return x;
          });
          isLoading.value = false;
        });
      } catch {
        isLoading.value = false;
      }
    };

    chkAppDsj();
    chkRefund();
    getList();

    watch(() => renewModal.value, () => {
      getList();
    });

    return {
      isLoading, items, paging, param,
      renewModal, renewLicense, ymdStrToFormat,
      convertTime, getList, chkAppDsj

    }
  }
};
</script>