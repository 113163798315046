<template>
  <article class="myactivity-box myactivity-status">
    <template v-if="distCrseCd === 'group'">
      <header class="status-title">
        <h4 class="title">스터디그룹 <br>참여현황</h4>
      </header>
      <div class="status-content">
        <span class="content-text">{{ params.secondCnt }}회</span>
        <span class="content-small">모임 횟수</span>
      </div>
      <div class="status-content">
        <span class="content-text">{{ params.firstCnt > 0 ? (params.secondCnt/params.firstCnt * 100).toFixed(1) : 0 }}%</span>
        <span class="content-small">평균 출석률</span>
      </div>
    </template>
    <template v-else-if="distCrseCd === 'knowledge'">
      <header class="status-title">
        <h4 class="title">연간평가현황</h4>
      </header>
      <div class="status-content">
        <span class="content-text">{{ params.secondCnt }}회</span>
        <span class="content-small">연간참여횟수</span>
      </div>
      <div class="status-content">
        <span class="content-text">{{ params.thirdCnt }}점</span>
        <span class="content-small">평가총점</span>
      </div>
      <div class="status-content">
        <span class="content-text">{{ params.secondCnt > 0 ? (params.thirdCnt / params.secondCnt).toFixed(1) : 0 }}점</span>
        <span class="content-small">평가평균점수</span>
      </div>
    </template>
  </article>

</template>

<script>

import {sslMyActivityRelSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMyActivityRel',
  props:{
    distCrseSn : Number,
  },
  setup: sslMyActivityRelSetup
}
</script>
