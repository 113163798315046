<template>
  <div class="kb" id="kb">
    <header class="kb-header" id="kb-header">
      <div class="header-component">
        <!-- header-title -->
        <div class="header-column header-title">
          수강신청
        </div>
        <!-- //header-title -->
        <!-- header-util -->
        <div class="header-column header-util left">
          <div class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="clickBack">
              <i class="icon-history-back"></i>
            </a>
          </div>
        </div>
        <!-- //header-util -->
      </div>
    </header>
    <main v-if="!capabilityModal" class="kb-main" id="kb-educourse">
      <div class="main-content main-component">
        <div class="educourse-view-container">
          <div class="view-contents">
            <section class="content-section">
              <header class="section-header gold">
                <h4 class="title">{{ params.crseNm }}</h4>
              </header>
              <div class="section-content">
                <div class="content-item">
                  <div class="title">연수기간</div>
                  <div class="desc">
                    <p>{{ timestampToDateFormat(params.bgngDt, 'yyyy.MM.dd.w') }} -
                      {{ timestampToDateFormat(params.endDt, 'yyyy.MM.dd.w') }}</p>
                  </div>
                </div>
                <div class="content-item">
                  <div class="title">마일리지</div>
                  <div class="desc">
                    <p>{{ params.crseMlg > 0 ? `마일리지 ${params.crseMlg}` : '-' }}</p>
                  </div>
                </div>
              </div>
            </section>
            <!-- popup-section:시간외 근무과정안내 -->
            <CourseConsent
                v-if="consents.length > 0"
                v-model="checked"
                :consents="consents"
                :is-view="isView"
                :con-params="params"
            />
          </div>
          <div class="page-buttons">
            <button v-if="!isView" class="kb-btn kb-btn-primary" @click="confirmApply"><span class="text">신청</span></button>
          </div>
        </div>
      </div>
    </main>
    <main v-else class="kb-main" id="kb-adc2">
      <div class="main-content main-component">
        <div class="educourse-view-container">
          <div class="view-contents">
<!--            <section class="content-section">-->
<!--              <header class="section-header gold">-->
<!--                <h4 class="title">현재 신청하시는 과목은 직무 필수과정 [{{ courseDivCd }}]의 [{{ params.crseNm }}] 입니다.<br/><br/>-->
<!--                  아래 본인의 직무 필수과정 이수 이력을 확인하신 후 수강신청 확정하시길 바랍니다.<br/><br/>-->
<!--                  이미 직무 필수과정으로 인정된 내역이 있는 부문의 과정을 이수할 경우-->
<!--                  직무 필수과정이 아닌 일반 직무연수 이력으로 인정됩니다.</h4>-->
<!--              </header>-->
<!--            </section>-->
            <section v-if="courseDivCd.includes('舊직무필수')" class="content-section">
              <div class="section-content">
                <div class="content-item">
                  <div class="desc">
                    <div>
                      <span style="font-weight: bold">현재 신청하시는 과목은 직무 필수과정<br/> <span class="fw-bolder text-blue">[{{ courseDivCd }}]</span>의 <span class="fw-bolder text-red">[{{ params.crseNm }}]</span> 입니다.<br/><br/>

                        해당 과목은 舊.직무 필수과정 과목으로<br/>
                        <span class="fw-bolder text-green">동일 과목 재수강의 목적으로만 신청 가능합니다.</span><br/><br/>

                        아래 이수 이력을 확인하신 후 수강신청 확정하시길 바랍니다.<br/>
                      </span>
                      <p class="sub-text" style="font-size: 12px; font-weight: bold;">※ 재수강 가능기간 : 2024년,2025년(2년 간) / 1과목 당 1회 재수강 가능</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section v-else class="content-section">
                <div class="section-content">
                  <div class="content-item">
                    <div class="desc">
                      <div><span style="font-weight: bold">현재 신청하시는 과목은 직무 필수과정<br/> <span class="fw-bolder text-blue">[{{ courseDivCd }}]</span>의 <span class="fw-bolder text-red">[{{ params.crseNm }}]</span> 입니다.<br/><br/>

                  [공통A], [공통B], [선택]  中 해당 직급의 이수 필요개수를 채운 부문에서 추가 과목을 이수할 경우 ‘직무 필수과정’이 아닌 ‘일반 연수이력’으로 인정됩니다.<br/><br/>

                  아래 이수 이력을 확인하신 후 수강신청 확정하시길 바랍니다.</span></div>
                    </div>
                  </div>
                </div>
            </section>
            <MobileCapabilityGradeTable
                v-if="elements.includes('R')"
                :div-cds="elements"
                :items="gradeItems"
                :result="result"
                :disp-div="'reqsubjOnly'"
            />
            <CourseConsent
                v-if="courseDivCd.includes('舊직무필수')"
                v-model="checkedJikmu"
                :consents="consentsJikmu"
                :is-view="isView"
                :con-params="params"
            />
          </div>
          <div class="page-buttons">
            <button v-if="!isView" class="kb-btn kb-btn-primary" @click="confirmApply"><span class="text">신청</span></button>
          </div>
        </div>
      </div>
    </main>
  </div>


</template>

<script>


import {useAlert} from '@/assets/js/modules/common/alert';
import {
  getResult,
  isSuccess,
  timestampToDateFormat,
  numberComma,
  lengthCheck,
  setParams,
  getItem, getItems
} from '@/assets/js/util';
import CourseConsent from '@/components/common/CourseConsent';
import {computed, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {ACT_INSERT_CRSE_REG_APLY} from '@/store/modules/course/course';
import {useRoute, useRouter} from 'vue-router';
import {applyCourse, crseMndCertCd, pnslCd} from '@/assets/js/modules/course/course-common';
import {goBack} from '@/assets/js/modules/common/common';
import {getFunc, getListFunc} from '@/assets/js/ui.init';
import {
  ACT_GET_CRSE_DIST_APPLY, 
  ACT_GET_CRSE_DIST_OBJ_LRNER, 
  ACT_GET_CRSE_DIST_OBJ_APPLY,
  ACT_GET_CRSE_DIST_COND
} from '@/store/modules/course/course';
import {ACT_GET_LEARN_HISTORY_LIST} from '@/store/modules/learn/learn';
import {ACT_GET_LRN_SPRT_LCSN_LIST} from "@/store/modules/license/license";
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";
import MobileCapabilityGradeTable from '@/components/capability/mobile/MobileCapabilityGradeTable';
import {
  ACT_GET_ABILITY_REQSUBJ_LIST, ACT_GET_ABILITY_REQUIREMENT_LIST,
  ACT_GET_ABILITY_RESULT,
  MUT_SET_ABILITY_REQSUBJ_LIST
} from "@/store/modules/my/my";

export default {
  name: 'ApplyAgree',
  components: {CourseConsent, MobileCapabilityGradeTable},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const {showConfirm, showMessage, showLoading, hideLoading} = useAlert();
    const distCrseSn = computed(() => route.params.distCrseSn);

    const isLoading = ref(false);
    const checked = ref([]);
    const checkedJikmu = ref([]);
    // const consents = ['overtime', 'personal', 'communication', 'smart', 'training', 'retake'];
    const consents = [];
    const consentsJikmu = ['training_jikmu'];

    const isView = ref(false);
    const params = reactive({
      distCrseSn: 0,
      crseMstSn: 0,
      classCateSn: 0,
      classCateNm: '',
      mediaCateSn: 0,
      mediaCateNm: '',
      orgnzCdDcd: '',
      orgnzNm: '',
      prvdrSn: 0,
      prvdrNm: '',
      introDivCdDcd: '',
      introDivNm: '',

      crseNm: '',

      crseSumup: '',
      crseDtl: '',
      exptAblty: '',
      crseLvl: '',

      classUseYn: '',
      classCnt: 0,
      bgngDt: 0,
      endDt: 0,

      thumb: '',
      etcThumb: '',
      prevUrl: '',
      crseMlg: 0,

      totLrnPerid: 0,
      totLrnHr: 0,

      fnshCondYn: '', // 수료조건 여부
      rfltRateUseYn: '', // 반영 비율 사용 여부
      fnshCrtrUseYn: '', // 수료 기준 사용 여부
      fnshCrtrScore: 0, // 수료 기준 점수
      eduCost: 0, // 교육 비용
      unfiRmbrAmt: 0, // 미수료 환급 금액

      totBgt: 0, // 총 예산
      instrCost: 0, // 강사료

      lrnTrgtGrpMstSn: 0, // 학습 그룹

      eduTrnctCdDcd: '', // 연수원 DCD
      eduTrnctNm: '', // 연수원
      loc: '', // 장소

      peoplCnt: 0, // 인원 수
      aplyBgngDt: 0, // 신청 시작 일시
      aplyEndDt: 0, // 신청 종료 일시

      rtrcnPsbltYn: '', // 신청 취소 강능 여부
      rtrcnEndDt: 0, // 취소 가능 종료일시

      cntstCrseYn: '', // 공모여부과정
      selecProcYn: '', // 공모여부과정
      mstThumb: '',
      stayAplyRcptYn: '',
      befCrseCd: '',
      svyRsltCnt: 0,
      dpcnRegCrseYn: '',
      rtakeCrsePsbltYy: '',
      splCrseTyCdDcd:'',
    });
    const lrnerObjs = ref([]);
    const learns = ref([]);
    const objItems = ref([]);
    const licenses = ref([]);
    const exItems = ref([]);
    const grpExist = ref(false);
    const reApply = ref(false);
    const dupExist = ref(false);
    const grpDate = ref(0);
    const lifeInsr = ref(false);
    const jesam = ref(false);
    const lossInsr = ref(false);
    const capabilityModal = ref(false);

    const result = reactive({
      ficjkwbh: '',
      ficjkgup: '',
      jikmuReqCount: 0,
      jkgupReqBegin: 0,
      jkgupReqGrade: 0,
      jkgupReqEtest: 0,
      selfScore: 0,
      totalScore: 0,
      ficbujum: '',
      ficjikmu: '',
      ficjkwee: '',
      fidhjgsg: '',
      fidhssbr: '',
      jikmuReqCountDefault: 0,
      jikmuReqDefCount: 0,
      modifyDate: null,
      cdpResultId: '',
      createDate: null,
      addscoreLic: 0,
      addscoreSales: 0,
      addscoreEtc: 0,
      pilsupassCnt: 0,
      jkgupGradeBookingId: 0,
      jkgupGradeLrnMapngRsltSumupSn: 0,
      preclassname: '',
      l1PromotionYn: '',
    });

    const items = reactive({
      R: [],
    });

    const reqsubjGroup = reactive([]);

    const gradeItems = computed(() => [...items.R]);
    const courseDivCd = ref(null);
    const reqDivSubjList = ref(null);

    const getAbilityRequirementList = (key) => {
      // searchType => R : 직무필수, O : 신임과정
      let pObj,sObj;
      if(key==="R") {
        pObj = {
          name: "공통",
          maxCnt: 2,
          curCnt: 0
        };
        sObj = {
          name: "선택",
          maxCnt: result.jikmuReqCountDefault - 2,
          curCnt: 0
        };
      }

      store.dispatch(`my/${ACT_GET_ABILITY_REQUIREMENT_LIST}`, {
        cdpResultId: result.cdpResultId,
        params: {searchType: key},
      }).then(res => {
        if(lengthCheck(res)){
          items[key] = getItems(res);
          //신버전 직무필수 수료현황 update
          if(key==="R"){
            let pCnt = items[key].filter( x=> x.gjCdp.indexOf("공통")>=0 ).length;
            pObj.curCnt = pCnt;
            let sCnt = items[key].filter( x=> x.gjCdp.indexOf("선택")>=0 ).length;
            sObj.curCnt = sCnt;

            reqsubjGroup.push(pObj);
            reqsubjGroup.push(sObj);
          }
        }else{
          if(key==="R"){
            reqsubjGroup.push(pObj);
            reqsubjGroup.push(sObj);
          }
        }

      }).catch(err => {
        console.error(err)
      })
    }

    const validateAply = () => {
      const currentDate = new Date();

      let result = false;
      let msg = '';

      // 파생상품투자권유자문인력보수(2022년4차) 임시
      if (capabilityModal.value == true && consentsJikmu.length === checkedJikmu.value.length && courseDivCd.value.includes('舊직무필수')) {
        result = true;
      } else if (capabilityModal.value == true && consentsJikmu.length !== checkedJikmu.value.length && courseDivCd.value.includes('舊직무필수')) {
        msg = '모든 항목이 체크되었는지 확인해주세요.';
      } else if (consents.length !== checked.value.length) {
        msg = '모든 항목이 체크되었는지 확인해주세요.';
      } else if (params.aplyBgngDt > currentDate.getTime()) {
        msg = '수강신청 기간이 아닙니다.';
      } else if (params.aplyEndDt < currentDate.getTime()) {
        msg = '수강신청 기간이 아닙니다.';
      } else {
        result = true;
      }

      // 집합평가객체가 동일한 일자를 2개이상 가지고 있는 경우
      const grpEvlExist = lrnerObjs.value.filter(x => x.grpEvlCnt >= 2);
      if (grpEvlExist.length > 0) {
        const sameGrpEvlDate = grpEvlExist.filter(x => objItems.value.some(y => y.objBgngDt === x.objBgngDt));
        if (sameGrpEvlDate.length > 0) {
          grpExist.value = true;
          grpDate.value = timestampToDateFormat(sameGrpEvlDate[0].objBgngDt, 'yyyy.MM.dd');
        }
      }

      // 사이버평가객체가 동일한 일자를 2개이상 가지고 있는 경우
      const elseEvlExist = lrnerObjs.value.filter(x => x.gnrlEvlCnt >= 2);
      if (elseEvlExist.length > 0) {
        const sameElseEvlDate = elseEvlExist.filter(x => objItems.value.some(y => y.objBgngDt === x.objBgngDt));
        if (sameElseEvlDate.length > 0) {
          result = false;
          msg = '평가일자가 ['.concat(timestampToDateFormat(sameElseEvlDate[0].objBgngDt, 'yyyy.MM.dd'),'] 로 ' +
                '동일한 과정을<br>이미 2개 과정 신청하셨으므로<br>추가신청이 불가합니다.');
        }
      }

      const existed = learns.value.filter(x => x.crseMstSn === params.crseMstSn);
      if (existed.length > 0) dupExist.value = true;

      const finalDt = new Date(
          Math.max(
              ...learns.value
              .filter(x => x.crseMstSn === params.crseMstSn)
              .map(element => {
                return new Date(element.endDt);
              }),
          ),
      ).setMonth(params.rtakeCrsePsbltYy === '0Y' ? 12 :
          params.rtakeCrsePsbltYy === '1Y' ? 24 :
              params.rtakeCrsePsbltYy === '2Y' ? 36 :
                  params.rtakeCrsePsbltYy === '3Y' ? 48 : 0
      );

      if (params.dpcnRegCrseYn === 'Y') {
        if (dupExist.value) {
          if (timestampToDateFormat(currentDate.getTime(), 'yyyy') >= timestampToDateFormat(finalDt, 'yyyy')) {
            reApply.value = true;
          } else {
            result = false;
            msg = '재수강 가능기간이 아닙니다.';
          }
        }
      } else {
        if (dupExist.value) {
          result = false;
          msg = '본 과정은 이미 진행중(신청중)이거나<br>수료한 과정으로 재신청 하실 수 없습니다.';
        }
      }

      // 생명보험 신청, 생명+제3 이력이 없을시
      if (params.crseMstSn === 3092) {
        const otherLifeExisted = learns.value.filter(x => x.crseMstSn === 10955 ||x.crseMstSn === 10956)
        if (otherLifeExisted.length === 0) {
          lifeInsr.value = true;
        }
      }

      if (params.crseMstSn === 3093) {
        const otherLossExisted = learns.value.filter(x => x.crseMstSn === 10956||x.crseMstSn === 10955 )
        if (otherLossExisted.length === 0) {
          lossInsr.value = true;
        }
      }

      if (!result) {
        showMessage(msg);
      }

      return result;
    };

    const elements = computed(() => {
      const contains = [];
      const ficjkgup = result.ficjkgup && result.ficjkgup.length > 2 ? result.ficjkgup.substring(0, 2) : result.ficjkgup;
      if (['L0', 'L1', 'S1'].includes(ficjkgup)) {
        // S => 은실종
        contains.push('S');

        // L0 => L1 진급자
        if (result.l1PromotionYn === 'Y') contains.push('O');
      }

      if (['L2', 'L3', 'S2', 'S3'].includes(ficjkgup)) {
        // O => 신임
        contains.push('O');
      }

      // if (['L0', 'L1', 'L2', 'L3'].includes(ficjkgup)) {

      // 2023-02-20 구시스템 처럼 S 직군도 직무필수를 보여주지만 dash-line 처리
      if (['S1', 'S2', 'S3', 'L0', 'L1', 'L2', 'L3'].includes(ficjkgup)) {
        // R => 직무필수
        contains.push('R');
      }
      if (['L0', 'L1', 'L2', 'L3', 'S1', 'S2', 'S3'].includes(ficjkgup)) {
        // P => 승격예비
        contains.push('P');
      }

      return contains;

    });

    const getAbilityReqSubjList = () => {
      let output = [];
      store.dispatch(`my/${ACT_GET_ABILITY_REQSUBJ_LIST}`, {
        cdpResultId: result.cdpResultId,
        params: {},
      }).then(res => {

        if(lengthCheck(res)){
          output = getItems(res);
          if (output.find(x => x.crseMstSn === params.crseMstSn)) {
            courseDivCd.value = output.find(x => x.crseMstSn === params.crseMstSn).courseDivCd; // 여기서부터 작업

            if (courseDivCd.value.includes('직무필수-선택')) {
              courseDivCd.value = '직무필수-선택'
            }
          }
        }
      }).catch(err => {
        console.error(err)
      }).finally(()=>{
            reqDivSubjList.value =  output;
            store.commit(`my/${MUT_SET_ABILITY_REQSUBJ_LIST}`, output);
          }
      );
    }

    const getMyAbility = () => {
      store.dispatch(`my/${ACT_GET_ABILITY_RESULT}`).then(res => {
        if (lengthCheck(res)) {
          setParams(result, getItem(res));
          getAbilityReqSubjList();
          getAbilityRequirementList("R");
        }
        readies.result = true;
        // menuView.value = 'overallResult';
      }).catch(e => {
        console.error(e);
      })
    }

    getMyAbility();

    const readies = reactive({
      result: false
    });

    const confirmApply = () => {
      if (isLoading.value) return;
      isLoading.value = true;

      if (validateAply()) {
        let cMsg = '';

        if (licenses.value.filter(x => x.lcsnCd === 'WR77' || x.lcsnCd === 'WR78').length  !== 0) { //제3자격증 보유여부체크
          jesam.value = true;
        }
        if (grpExist.value) {
          cMsg += '집합평가일자가 ['.concat(grpDate.value,'] 로 동일한 과정을<br>이미 2개 이상 신청하셨습니다.<br>' +
                  '연수신청은 가능하나 수료에 차질이없는지<br>신중하게 결정하시기 바랍니다.<br>');
        }
        if (params.crseMlg>0 && reApply.value){
          cMsg += '본 연수과정은 현 직급에서 기수료한 과정으로,<br>중복수강을 하는 경우 마일리지가 부여되지 않습니다.<br>';
        }
        if (lifeInsr.value && !jesam.value) {

          cMsg += ' 제3보험 대리점 자격이 없습니다.<br>추후 제3보험 대리점 과정은 보험연수원을 통해<br>개인부담으로 이수하셔야 하므로,<br> 생명+제3보험을 신청해주시기 바랍니다.<br>';
        }
        if (lossInsr.value && !jesam.value) {
          cMsg += ' 제3보험 대리점 자격이 없습니다.<br>추후 제3보험 대리점 과정은 보험연수원을 통해<br>개인부담으로 이수하셔야 하므로,<br> 손해+제3보험을 신청해주시기 바랍니다.<br>';
        }
        // checkYn

        if (params.cntstCrseYn === 'Y' || params.splCrseTyCdDcd === '2066030') {

          checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 인재개발부<br>업무담당자(☎7880)에게<br>문의 부탁드립니다.`, () => {

            store.dispatch(`course/${ACT_INSERT_CRSE_REG_APLY}`, {distCrseSn: params.distCrseSn, params: {checkYn: 'Y'}}).
            then(res => {
              if (isSuccess(res)) {
                router.push({name: 'ApplyCntst', params: {distCrseSn: params.distCrseSn}});
              } else {
                showMessage(getResult(res).message);
                isLoading.value = false;
              }
            }).
            catch(e => {
              console.error(e);
              showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
              isLoading.value = false;
            });

          });
        } else {
          if (params.classCateSn == 9 && capabilityModal.value == false) {
            capabilityModal.value = true;
            isLoading.value = false;
            window.scrollTo(0, 0);
          } else {
            showConfirm({
              title: '수강신청',
              text: cMsg.concat('수강신청을 하시겠습니까?'),
              callback: () => {
                showLoading();
                applyCourse(
                    params.distCrseSn,
                    {},
                    () => {
                      hideLoading();

                      if(params.splCrseTyCdDcd=== '2066030'){
                        showMessage('수강신청이 완료되었습니다.');
                        router.push({name: 'TrainGlobal'});
                      }else {
                        showMessage('수강신청이 완료되었습니다.');
                        router.push({name: 'ApplyStatus'});
                      }
                      clickBack();
                    },
                    (res) => {
                      hideLoading();
                      // 수료시 신청불가 연수이력 존재시 에러코드
                      if (getResult(res).number === 319) {
                        const dupCrse = learns.value.filter(x => exItems.value.some(
                            y => y.regCrseCondCdDcd === '2111001' && x.crseMstSn === y.crseMstSn));
                        if (dupCrse.length > 0) {
                          showMessage(
                              getResult(res).message.concat('<br>[', dupCrse[0].crseNm, ']'));
                        } else {
                          showMessage(getResult(res).message);
                        }
                      } else
                          // 취득시 신청불가 자격증 존재시 에러코드
                      if (getResult(res).number === 320) {
                        const dupLcsn = licenses.value.filter(
                            x => exItems.value.some(y => y.regCrseCondCdDcd === '2111002' && x.lcsnCd === y.lcsnCd));
                        if (dupLcsn.length > 0) {
                          showMessage(
                              getResult(res).message.concat('<br>[', dupLcsn[0].lcsnCd, ':', dupLcsn[0].lcsnNm, ']'));
                        } else {
                          showMessage(getResult(res).message);
                        }
                      } else {
                        showMessage(getResult(res).message);
                      }
                      isLoading.value = false;
                    },
                    () => {
                      hideLoading();
                      showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                      isLoading.value = false;
                    },
                );
                hideLoading();
              },
              closeCallback: () => {
                isLoading.value = false;
              },
            });
          }
        }
      } else {
        isLoading.value = false;
      }
    };
    getFunc(`course/${ACT_GET_CRSE_DIST_APPLY}`, distCrseSn.value, params, () => {
      if (params.unfiRmbrAmt > 0) {
        consents.push('training');
      }
      if (params.stayAplyRcptYn == 'Y') {
        consents.push('stay');
      }
      if (params.mediaCateNm.startsWith('통신') && params.orgnzNm.startsWith('금융연수원')) {
        consents.push('communication');
      }
      if (crseMndCertCd ==='' && crseMndCertCd.includes(params.befCrseCd)) {
        consents.push('overtime');
      }
      if (pnslCd.includes(params.orgnzCdDcd)) {
        console.log("params.splCrseTyCdDcd = ", params.splCrseTyCdDcd)
        if (params.splCrseTyCdDcd === '2066030') {
          consents.push('global');
        } else {
          consents.push('personal');
        }
      }
    });

    getListFunc(`course/${ACT_GET_CRSE_DIST_OBJ_APPLY}`, distCrseSn.value, objItems, null);
    getListFunc(`course/${ACT_GET_CRSE_DIST_OBJ_LRNER}`, {}, lrnerObjs, null);
    getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null, () => {
      learns.value = learns.value
                        .map(x => ({...x, fnshYn: [3092, 3093, 10955, 10956].includes(x.crseMstSn) ? 'Y' : x.fnshYn}))
                        .filter(y => y.auditCfmtnCd === 'Y').filter(x => x.fnshYn === 'Y');
    });
    getListFunc(`license/${ACT_GET_LRN_SPRT_LCSN_LIST}`, {regYn: 'Y'}, licenses, null,()=>{});
    setTimeout(() =>
            getListFunc(`course/${ACT_GET_CRSE_DIST_COND}`, {crseMstSn: params.crseMstSn}, exItems, null, () => {})
    , 600);

    const clickBack = () => {
      goBack();
    };

    return {
      checked,
      checkedJikmu,
      consents,
      consentsJikmu,
      confirmApply,
      clickBack,
      timestampToDateFormat,
      numberComma,
      params,
      isView,
      capabilityModal,
      elements,
      gradeItems,
      result,
      courseDivCd
    };

  },
};
</script>
