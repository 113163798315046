<template>
  <button class="kb-btn-snb" @click.stop="toggleVisible('main')">
    <span class="text">{{ currentMainName }}</span>
    <i class="icon-arrow"></i>
  </button>
  <div class="kb-form-layer" :class="{'is-active': toggles.main}" @click="toggles.main = false;">
    <div class="layer-container">
      <div class="layer" style="padding-bottom: 72px;">
        <div class="kb-form-layer-options">
          <div class="layer-option">
            <ul class="layer-option-list" style="max-height: 500px;">
              <li class="layer-option-item">
                <router-link :to="{name: 'SSLHome'}" class="layer-option-link"><strong class="layer-option-text">SSL 홈</strong></router-link>
              </li>
              <li v-for="(mainRoute, idx) in mainRoutes" class="layer-option-item" :key="idx">
                <router-link :to="getMainPath(mainRoute)" class="layer-option-link" @click="toggles.main = false"><strong class="layer-option-text">{{ mainRoute.name }}</strong></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive} from 'vue';
import {getRouteLrnTrgtGrp, sslRoutes} from '@/assets/js/modules/ssl/ssl-common';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';

export default {
  name: 'SSLMobileNav',
  props: {
    childDistCrseCd: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();

    const distCrseCd = computed(() => {
      if (props.childDistCrseCd && props.childDistCrseCd.length > 0) return props.childDistCrseCd;
      return route.params.distCrseCd;
    });

    const lrnTrgtGrp = computed(() => getRouteLrnTrgtGrp(route.params.lrnTrgtGrpMstSn));
    const myLrnTrgtGrpMstSns = computed(() => store.state.ssl.myLearnTargets.map(x => x.lrnTrgtGrpMstSn));
    // const distCrseSns = computed(() => getDistCrseSns(route.params.distCrseCd));
    // const myRoutes = computed(() => {
    //   // 나의 차수 Get
    //   const myDistCrseSns = store.state.ssl.myLearnTargets.map(x => x.distCrseSn);
    //   return [...Object.keys(sslRoutes).map(key => ({...sslRoutes[key]})).filter(x => {return (x.distCrseSns.filter(y => myDistCrseSns.includes(y)).length > 0)})];
    // });

    // main route
    const mainRoutes = computed(() => {
      return Object.values(sslRoutes).filter(x=> x.key != 'total');
       // return Object.values(sslNavRoutes(myLrnTrgtGrpMstSns));
      // if (getTodayDate('yyyyMMdd') < '20240701'){
      //   return Object.values(sslRoutes).filter(x => x.key != 'book').filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
      // }else{
      //   return Object.values(sslRoutes).filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
      // }
    });

    const currentMainName = computed(() => {
      if(route.name === 'SSLHome' || route.name === 'SSLNotice' || route.name === 'SSLNoticeView'){
        // return {name: 'SSL 홈', key: 'home'};
        return 'SSL 홈';
      }
      else if(route.name === 'SSLTotalMy' || route.name === 'SSLTotalMyView'){
        return 'my SSL';
      }
      else if(route.name === 'SSLBestNote'){
        return '우수 노트 모아보기';
      }
      else if(distCrseCd.value && sslRoutes[distCrseCd.value]){
        if (distCrseCd.value === 'self') {
          return `${sslRoutes[distCrseCd.value].name}${lrnTrgtGrp.value.lrnTrgtGrpNm ? `-${lrnTrgtGrp.value.lrnTrgtGrpNm}` : ''}`
        }
        return sslRoutes[distCrseCd.value].name;
      }
      return {};
    });


    const toggles = reactive({
      main: false,
      // sub: false
    });

    const getMainPath = targetRoute => {
      let targetLrnTrgtGrpMstSn = targetRoute.lrnTrgtGrpMstSns[0];
      const targetLrnTrgtGrpMstSns = targetRoute.lrnTrgtGrpMstSns.filter(x => myLrnTrgtGrpMstSns.value.includes(x));

      if (targetLrnTrgtGrpMstSns.length > 0) targetLrnTrgtGrpMstSn = targetLrnTrgtGrpMstSns[0];

      if(route.name.indexOf('SSLMy') === 0){
        return {name: 'SSLMy', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn}}
      }else if(targetLrnTrgtGrpMstSn == 999999){
        return {name: 'SSLBestNote'};
      }else if(targetLrnTrgtGrpMstSn == 0){
        return {name: 'SSLTotalMy', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn:targetLrnTrgtGrpMstSn}};
      }else {
        return {name: 'SSLMain', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn}}
      }
    };


    const toggleVisible = target => {
      Object.keys(toggles).forEach(key => {
        if (key === target) {
          toggles[key] = !toggles[key];
        } else {
          toggles[key] = false;
        }
      });
    }

    const closeNav = () => {
      toggles.main = false;
    }

    return {
      distCrseCd,
      mainRoutes,
      currentMainName,
      toggles,
      getMainPath,
      toggleVisible,
      closeNav
    }
  }
}
</script>
