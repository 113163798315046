import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {computed, reactive, ref, watch} from 'vue';
import {
    checkSSLAccess,
    enrollSSLAlert,
    getExpsrLocCd,
    getRouteLrnTrgtGrpMstSn,
    isMyLrnTrgtGrpMstSn,
    noneRewardCds,
    sslRoutes,
} from '@/assets/js/modules/ssl/ssl-common';
import {
    getMainId,
    getVideModeIndex,
    mainViewModes,
} from '@/assets/js/modules/ssl/ssl-main';
import {
    ACT_GET_SOC_BOARD_LIST,
    ACT_GET_SSL_NTC_LIST,
    MUT_SET_LRNER_KEYWORD, MUT_SET_POSTSN_LIST,
} from '@/store/modules/ssl/ssl-index';
import {
    dateToDateFormat,
    getItems,
    getPaging,
    initProgressBar,
    lengthCheck,
} from '@/assets/js/util';
import {expsrLocCds} from '@/assets/js/modules/help/help-common';

export const sslMainSetup = () => {

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isMobile = computed(() => store.state.auth.isMobile);
    const currentName = computed(() => route.name);
    const distCrseCd = computed(() => route.params.distCrseCd);
    const distCrseNm = computed(() => sslRoutes[route.params.distCrseCd] ? sslRoutes[route.params.distCrseCd].name : '' );
    const lrnTrgtGrpMstSn = computed(() => {if(getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn) > 0){
        return getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn);
    }else{
        return sslRoutes[distCrseCd.value].group[0].lrnTrgtGrpMstSn;
    }});
    const isMyLrnTrgt = computed(() => isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value));

    const mainName = computed(() => {
        const target = sslRoutes[route.params.distCrseCd];
        if (target) {
            if (target.key === 'self') {
                const subTarget = target.group.find(x => x.lrnTrgtGrpMstSn === lrnTrgtGrpMstSn.value);
                return target.name + (subTarget ? ` - ${subTarget.lrnTrgtGrpNm}` : '');
            }
            return sslRoutes[route.params.distCrseCd].name
        }
        return '';
    });


    const expsrLocCd = computed(() => getExpsrLocCd(route.params.distCrseCd));


    const myGroup = computed(() => store.state.ssl.group);

    const isReady = ref(false);
    const isLoading = ref(true);

    const notices = ref([]);
    const keyword = ref('');


    // pc
    const isBlack = computed(() => ['selfIct','selfJa','selfGa','selfGi','selfDvl','group', 'trivia', 'college', 'live'].includes(route.params.distCrseCd));
    const isNoneReward = computed(() => noneRewardCds.includes(route.params.distCrseCd));

    //탭(최신, 주간, 원간, 내가신청한도서)
    const viewModes = computed(()=>{
        if(route.params.distCrseCd != 'book'){
            return mainViewModes.filter(y=>y.mode != 'mybook');
        }else{
            return mainViewModes;
        }
    });

    const viewModeIdx = computed(() => {
        if(route.query.view == 'undefined'){
            return getVideModeIndex('new');
        }else{
            return getVideModeIndex(route.query.view);
        }
    });
    const sortCd = computed(() => viewModes.value[viewModeIdx.value].mode);

    // mobile
    // 회원 검색용 키워드
    const memberKeyword = ref('');
    const toggles = reactive({
        member: false,
        search: false,
        nav: false
    });

    // const showLrner = computed(() => store.state.ssl.showLrner);
    const mainId = computed(() => getMainId(route.path));

    const items = ref([]);
    const paging = reactive(
        {pageNo: 1, pageSize: 0, totalCount: 0, hasMore: false}
    );

    if (isMobile.value) {
        paging.pageSize = 12;
    }else {
        paging.pageSize = (isNoneReward.value ? 15 : 12);
    }

    const moveBoard = socLrnPostSn => {
        const target = items.value.find(item => item.socLrnPostSn === socLrnPostSn);
        if (target) target.myViewCnt++;
    }

    const getNoticeList = () => {
        store.dispatch(`ssl/${ACT_GET_SSL_NTC_LIST}`, {
            nowDate: dateToDateFormat(new Date(), 'yyyy-MM-dd'),
            expsrLocCd: `${expsrLocCds.EXPSR_LOC_CD_SSL_COMMON},${expsrLocCd.value}`,
            pageNo: 1,
            pageSize: 10
        }).then(res => {
            if(lengthCheck(res)) notices.value = getItems(res);
        }).catch(() => {});
    }

    const getSocsBoardList = (isInit) => {
        if(isInit) items.value = [];
        store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`, {
            lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value,
            distCrseCd: distCrseCd.value,
            relationYn: 'N', // (route.params.distCrseCd === 'book' ? 'Y' : 'N'),
            title: keyword.value,
            sortCd: sortCd.value,
            pageNo: paging.pageNo,
            pageSize: paging.pageSize
        }).then(res => {
            if(lengthCheck(res)) items.value.push(...getItems(res));
            paging.hasMore = getPaging(res).hasMore;
            isLoading.value = false;

            const opt  = reactive({
                div:'all',
                title: keyword.value,
                sortC: sortCd.value
            });

            store.commit(`ssl/${MUT_SET_POSTSN_LIST}`, {paging : getPaging(res), items:items.value.map(x=>x.socLrnPostSn), opt:opt}); // 이전,다음페이지 이동을 위해 추가함.
        }).catch(e => {
            console.error(e);
            isLoading.value = false;
        });
    }

    const renderSocsBoardList = (isInit) => {
        paging.hasMore = false;
        isLoading.value = true;
        getSocsBoardList(isInit);
    }

    const moreFunc = () => {
        if(isLoading.value) return;
        paging.pageNo++;
        renderSocsBoardList();
        if (!isMobile.value) initProgressBar();
    }

    const searchFunc = (word) => {
        if(isLoading.value) return;
        paging.pageNo = 1;
        if (!isMobile.value) {
            keyword.value = word;
            initProgressBar();
        }
        renderSocsBoardList(true);
    }

    // const closeFunc = () => {
    //     toggles.search = false;
    //     paging.pageNo = 1;
    //     renderSocsBoardList(true);
    // }

    const goMySSL = () => {
        if (isMyLrnTrgt.value) {
            // router.push({name: 'SSLMy'}).then(() => {});
            router.push({name: 'SSLTotalMy'}).then(() => {});
        } else {
            if (noneRewardCds.includes(distCrseCd.value)) {
                // router.push({name: 'SSLMy'}).then(() => {});
                router.push({name: 'SSLTotalMy'}).then(() => {});
            } else {
                enrollSSLAlert(distCrseCd, distCrseNm, lrnTrgtGrpMstSn);
            }
        }
    }

    const acted = ({socLrnPostSn, act}) => {
        const found = items.value.find(x => x.socLrnPostSn === socLrnPostSn);
        if (found) {
            if (act === 'like') {
                found.likeCnt++;
            } else if (act === 'share') {
                found.sharedCnt++;
            }
        }
    }

    // Mobile Func
    const searchMember = () => {
        store.commit(`ssl/${MUT_SET_LRNER_KEYWORD}`, memberKeyword.value);
    }

    const closeMainToggle = key => {
        if (key === 'search') {
            paging.pageNo = 1;
            keyword.value = '';
            renderSocsBoardList(true);
        }

        toggles[key] = false;

    }


    watch(() => lrnTrgtGrpMstSn.value, () => {
        if (route.name === 'SSLMain') {
            paging.pageNo = 1;
            keyword.value = '';
            memberKeyword.value = '';
            searchMember();
            if (isMobile.value) {
                toggles.member = false;
                toggles.search = false;
                toggles.nav = false;
            } else {
                paging.pageSize = (isNoneReward.value ? 15 : 12);
            }

            checkSSLAccess(isMyLrnTrgt, distCrseCd, lrnTrgtGrpMstSn, myGroup, () => {
                if(lrnTrgtGrpMstSn.value > 0){
                    getNoticeList();
                    renderSocsBoardList(true);
                }
            });
        }
    });

    watch(() => route.query.view, () => {
        if (route.name === 'SSLMain') {
            paging.pageNo = 1;
            keyword.value = '';
            memberKeyword.value = '';
            if (!isMobile.value) {
                // 페이지 사이즈가 변경될 수 있으니 처리
                paging.pageSize = (isNoneReward.value ? 15 : 12);
            }
            if(lrnTrgtGrpMstSn.value > 0){
                initProgressBar();
                getNoticeList();
                renderSocsBoardList(true);
            }
        }
    });


    checkSSLAccess(isMyLrnTrgt, distCrseCd, lrnTrgtGrpMstSn, myGroup, () => {
        if(lrnTrgtGrpMstSn.value > 0){
            getNoticeList();
            renderSocsBoardList();
        }
        isReady.value = true;

        // 첨들어 왔을때 선택된게 없으면 처리
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
    });



    return {
        isReady,
        isLoading,
        distCrseCd,
        mainName,
        currentName,
        items,
        paging,
        notices,
        lrnTrgtGrpMstSn,
        viewModes,
        viewModeIdx,
        keyword,
        moreFunc,
        searchFunc,
        moveBoard,
        isMyLrnTrgt,
        myGroup,
        acted,

        // PC
        isBlack,
        isNoneReward,
        distCrseNm,
        goMySSL,

        // Mobile
        mainId,
        memberKeyword,
        toggles,
        searchMember,
        closeMainToggle,
        // openSearch,
    }
}
