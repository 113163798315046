<template>

  <!-- begin:popup-container -->
  <div class="popup-container" :class="{'is-active' : modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-subscription-settings">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">활동 피드</h3>
          </header>


          <div class="d-flex justify-content-center align-items-center" style="margin-top: var(--kb-spacer-4)">
            <div class="header-tab">
              <nav class="kb-nav-round-tabs">
                <ul class="kb-nav-list">
                  <li class="kb-nav-item">
                    <a href="javascript:" class="kb-nav-link" :class="{'is-active': actDiv === 'all'}" @click="getInitActFeedList('all')"><span class="kb-nav-text">전체</span></a>
                  </li>
                  <li class="kb-nav-item">
                    <a href="javascript:" class="kb-nav-link" :class="{'is-active': actDiv === 'cmntRec'}" @click="getInitActFeedList('cmntRec')"><span class="kb-nav-text">댓글</span></a>
                  </li>
                  <li class="kb-nav-item">
                    <a href="javascript:" class="kb-nav-link" :class="{'is-active': actDiv === 'likeGet'}" @click="getInitActFeedList('likeGet')"><span class="kb-nav-text">좋아요</span></a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div class="popup-content">

            <LoadingDiv v-if="isLoading"/>
            <section class="popup-section mt-5">
              <!--            <header class="section-header"><h4 class="title">구독채널 {{ items ? items.length : 0 }}</h4></header>-->
              <div class="kb-table kb-table-bordered">
                <table>
                  <colgroup>
                    <col style="width:15%;">
                    <col style="width:20%;">
                    <col style="width:auto;">
                    <col style="width:13%;">
                  </colgroup>
                  <tbody>
                  <tr v-for="(item, idx) in items" :key="idx">
                    <td><span class="td-text">{{item.distCrseNm}}</span></td>
                    <td class="text-overf">{{item.title}}</td>
                    <td class="text-overf"><span class="td-text">{{ item.lrnerNm }}<span v-if="item.deptNm != ''">({{item.deptNm}})</span>님이 <strong :class="[item.actDivCd == 'cmntRec' ? 'text-gold':'text-red']">{{item.actDivNm}}</strong><span v-if="item.actDivCd=='cmntRec'">을</span><span v-else>를</span> 남겼습니다<strong v-if="item.actDivCd=='cmntRec'" class="text-gold"> : {{item.cmnt}}</strong></span></td>
                    <td class="text-right"><a href="javascript:" @click="goBoardView(item.crseMstSn, item.lrnTrgtGrpMstSn, item.socLrnPostSn);">{{item.regYmd}}<i class="icon-arrow-next"></i></a></td>
                  </tr>
                  <tr v-if="items && items.length === 0 && !isLoading" class="search-container">
                    <td colspan="4" class="result-empty">
                      <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                      <p class="text">해당 데이터가 없습니다.</p>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <CommonPaginationFront :paging="paging" :page-func="pagingFunc" :divider="true" />
              </div>
            </section>

          </div>
          <!--          -->
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="$emit('update:modelValue',false)"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- end:popup-container -->
</template>

<script>
import {reactive, ref} from 'vue';
import {ACT_GET_ACT_FEED_LIST} from "@/store/modules/ssl/ssl-index";
import {getCrseSnToDistCrseCd} from "@/assets/js/modules/ssl/ssl-common";
import {useStore} from "vuex";
import {getItems, getPaging, lengthCheck} from "@/assets/js/util";
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {useRouter} from "vue-router";

export default {
  name: "SSLActFeedModal",
  components: {LoadingDiv, CommonPaginationFront},
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();
    const isLoading = ref(true);
    const actDiv = ref('all');
    const items = ref([]);

    const paging = reactive({
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    });

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const getActFeedList=(actDivCd)=>{

       actDiv.value = actDivCd;
      // paging.pageNo = 1;
      // paging.pageSize = 3;

      store.dispatch(`ssl/${ACT_GET_ACT_FEED_LIST}`, {actDivCd : actDivCd, params:paging}).then(res => {

        if (lengthCheck(res)) {
          items.value = getItems(res);
          paging.totalCount = getPaging(res).totalCount;
          isLoading.value=false;
        }else{
          items.value = [];
          isLoading.value=false;
        }

      });
    }

    const getInitActFeedList=(actDivCd)=>{
      paging.pageNo = 1;
      getActFeedList(actDivCd);

    }

    const pagingFunc = (pageNo) => {
      if (pageNo > 0) paging.pageNo = pageNo;
      getActFeedList(actDiv.value);
    };

    const goBoardView =(crseMstSn, lrnTrgtGrpMstSn, socLrnPostSn)=>{
      router.push({name: 'SSLView', params: {distCrseCd:getCrseSnToDistCrseCd(crseMstSn), lrnTrgtGrpMstSn:lrnTrgtGrpMstSn, socLrnPostSn: socLrnPostSn}}).then(() => {});
    };

    return{
      closeModal,
      actDiv,
      items,
      paging,
      pagingFunc,
      isLoading,
      getActFeedList,
      goBoardView,
      getInitActFeedList
    }
  }
}

</script>

<style>
.text-overf {overflow: hidden;text-overflow: ellipsis;white-space: nowrap}
</style>