<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container" :class="{'is-active': modelValue}">
    <!-- popup -->
    <div class="popup" id="popup-prep-n03">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">멤버 초대하기</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <div class="member-search-container">
              <div class="kb-form-fields field-search">
                <!-- kb-form-row -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">검색</span>
                    </label>
                  </div>
                  <template v-if="isSearch">
                    <div class="kb-form-column d-flex align-items-center">
                      <strong class="kb-form-text">{{ search.userInfo }}</strong>
                      <button class="kb-btn-delete" @click="clickReset"><i class="icon-delete">삭제</i></button>
                    </div>
                    <div class="kb-form-column column-last">
                      <!-- kb-form-search -->
                      <div class="kb-form-search d-flex align-items-center">
                        <button class="kb-btn-search"><i class="icon-search"></i></button>
                      </div>
                      <!-- //kb-form-search -->
                    </div>
                  </template>
                  <div v-else class="kb-form-column">
                    <!-- kb-form-search -->
                    <div class="kb-form-search">
                      <input v-model="search.userInfo" type="text" class="kb-form-control" placeholder="부서명 및 직원번호 / 직원명 검색" @keyup.enter="clickSearch"/>
                      <button class="kb-btn-search" @click="clickSearch"><i class="icon-search"></i></button>
                    </div>
                    <!-- //kb-form-search -->
                  </div>
                </div>
              </div>
              <div class="kb-form-buttons">
                <button v-if="isSearch" class="kb-btn kb-btn-outline kb-btn-lg" @click="clickReset"><span class="text">필터해제</span></button>
                <button v-else class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
              </div>

              <!-- search-results -->
              <div v-if="paging.totalCount > 0" class="search-results">
                <!-- popup-section-group -->
                <div class="popup-section-group">
                  <!-- popup-section:검색결과 -->
                  <section class="popup-section">
                    <header class="section-header">
                      <h4 class="title">검색결과 <strong class="text text-muted">{{ paging.totalCount }}</strong></h4>
                    </header>
                    <div class="section-content">
                      <div class="kb-table kb-table-striped member-tablelist member-scroll">
                        <table>
                          <colgroup>
                            <col style="width:104px;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                          </colgroup>
                          <thead>
                            <tr>
                              <th><span class="th-title">부점/부서명</span></th>
                              <th><span class="th-title">직원명</span></th>
                              <th><span class="th-title">직원번호</span></th>
                              <th><span class="th-title">직급</span></th>
                              <th class="text-center">
                                <button v-if="!checkAll" class="kb-btn-check" @click.stop="clickCheckAll"><span class="th-title text-primary">전체선택</span></button>
                                <button v-else class="kb-btn-check" @click.stop="clickUnCheckAll"><span class="th-title text-primary">전체해제</span></button>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, idx) in items" :key="`tr-${idx}`">
                              <td><strong class="td-text">{{ item.deptNm }}</strong></td>
                              <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                              <td><strong class="td-text">{{ item.lrnerId }}</strong></td>
                              <td><strong class="td-text">{{ item.jbgdCd }} {{ item.jbpsNm }}</strong></td>
                              <td class="text-center">
                                <div class="kb-form-check">
                                  <input v-model="checkUsers" type="checkbox" class="kb-form-check-input" name="chkMember" :value="item" />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  <div class="popup-section-sort">
                    <button class="kb-btn-sort kb-btn-plus" @click="clickPlusUser"><i class="icon-right"></i></button>
                    <button class="kb-btn-sort kb-btn-minus" @click="clickMinusUser"><i class="icon-left"></i></button>
                  </div>
                  <!-- popup-section:초대멤버 -->
                  <section class="popup-section">
                    <header class="section-header">
                      <h4 class="title">초대멤버 <strong class="text text-muted">{{ inviteUsers.length }}</strong></h4>
                    </header>
                    <div class="section-content">
                      <div class="kb-table kb-table-striped member-tablelist member-scroll">
                        <table>
                          <colgroup>
                            <col style="width:104px;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                          </colgroup>
                          <thead>
                          <tr>
                            <th><span class="th-title">부점/부서명</span></th>
                            <th><span class="th-title">직원명</span></th>
                            <th><span class="th-title">직원번호</span></th>
                            <th><span class="th-title">직급</span></th>
                            <th class="text-center">
                              <button class="kb-btn-check" @click.stop="clickCheckInvite">
                                <span class="th-title">전체선택</span>
                              </button>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(item, idx) in inviteUsers" :key="idx">
                            <td><strong class="td-text">{{ item.deptNm }}</strong></td>
                            <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                            <td><strong class="td-text">{{ item.lrnerId }}</strong></td>
                            <td><strong class="td-text">{{ item.jbgdCd }} {{ item.jbpsNm }}</strong></td>
                            <td class="text-center">
                              <div class="kb-form-check">
                                <input v-model="unInviteUsers" type="checkbox" class="kb-form-check-input" name="chkMember" :value="item"/>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
                <!-- //popup-section-group -->
                <!-- popup-section:초대 메시지 -->
                <section class="popup-section">
                  <header class="section-header">
                    <h4 class="title">초대 메시지</h4>
                  </header>
                  <div class="section-content">
                    <div class="kb-table kb-table-striped">
                      <table>
                        <colgroup>
                          <col>
                        </colgroup>
                        <tbody>
                        <tr>
                          <td>
                            <textarea v-model="notiCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="초대 메시지를 입력해주세요. "></textarea>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              </div>
              <!-- //search-results -->

            </div>
          </div>
          <!-- popup-inner > popup-buttons -->
          <div v-if="paging.totalCount > 0"  class="popup-buttons">
            <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="clickInvite"><span class="text">초대</span></a>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- [end::popup-container] -->
</template>

<script>
import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import {ACT_GET_USERS_LIST} from "@/store/modules/user/user";
import {getItems, getPaging, isSuccess, lengthCheck} from "@/assets/js/util";
import {notiPfmCds} from "@/assets/js/modules/noti/noti-common";
import {useAlert} from "@/assets/js/modules/common/alert";
import {ACT_INVITE_GROUP_MEMBER} from "@/store/modules/course/course";

export default {
  name: "GroupMemberInviteModal",
  props: {
    modelValue: Boolean,
    groupInfo: Object
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();

    const items = ref([]);
    const paging = ref({pageNo: 1, pageSize: 99999, totalCount: 0, hasMore: false});

    const notiCn = ref('');
    const notiPfmCd = ref([notiPfmCds.liiv , notiPfmCds.wiseNet]);

    const {showMessage, showConfirm} = useAlert();

    const params = reactive({
      notiMngSn: 0,
      notiTitle: '그룹러닝 초대',
      notiSbjt: '',
      notiDesc: '그룹 초대',
      sndrNm: '인재개발부',
      notiTrprCdDcd : '2039001',
      notiTyCdDcd: '2032001', //'2032002',
      retrInclYn: 'N', // 퇴직자 포함 여부
      rsvtTrsmYn: 'N', // 예약발송여부
      rsvtTrsmDt: 0,
      aprvrId: '',
      aprvrNm: '',

      smsNotiYn: 'Y',
      insdMemoYn: 'Y',
      pushNotiYn: 'N',
      wiseEmlNotiYn: 'N',
      emlNotiYn: 'N',
      kakaoNotiYn: 'N',
      chatBotNotiYn: 'N',
      liivNotiYn: 'Y',
      trnNotiYn: 'N',
      tmplYn: 'N',
      stt: '00',
      division: 'groupLrn' ,
      channelName: 'study',
      imgUrl: '',
      moveUrl: '',
      distCrseSn: 0,
      lrnTrgtGrpMstSn: 0,
      lrnTrgtGrpDtlSn: 0,
      notiMsgDomains : [],
      notiTrgtDomains: []
    })


    const search = reactive({
      userInfo: ''
    });

    const isSearch = ref(false);

    const checkUsers = ref([]);
    const inviteUsers = ref([]);
    const unInviteUsers = ref([]);

    const checkAll = computed(() => {
      let cnt = 0;
      items.value.map(item => {
        checkUsers.value.filter(user => {
          if(item.lrnerId === user.lrnerId) cnt++;
        })
      });
      return cnt === items.value.length;
    });


    const getUserList = () => {
      store.dispatch(`auth/${ACT_GET_USERS_LIST}`, {
        userInfo: search.userInfo,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        items.value = [];
        console.error(e);
      })
    };

    // 검색결과 사용자 전체 선택
    const clickCheckAll = () => {
      checkUsers.value = items.value;
    };

    // 초대 사용자 전체 선택
    const clickCheckInvite = () => {
      unInviteUsers.value = inviteUsers.value;
    }

    // 검색결과 사용자 전체 해제
    const clickUnCheckAll = () => {
      checkUsers.value = [];
    };

    // 검색 사용자 -> 초대 사용자 넣기
    const clickPlusUser = () => {
      if (inviteUsers.value.length > 0) {
        checkUsers.value.map(x => {
          if (inviteUsers.value.filter(y => x.lrnerId === y.lrnerId).length === 0) inviteUsers.value.push(x);
        });
      } else {
        inviteUsers.value = checkUsers.value;
      }
    }

    // 초대 사용자 빼기
    const clickMinusUser = () => {
      unInviteUsers.value.forEach(item => {
        inviteUsers.value = inviteUsers.value.filter(x => x.lrnerId !== item.lrnerId);
        checkUsers.value = inviteUsers.value;
      });
      unInviteUsers.value = [];
    }

    // 검색
    const clickSearch = () => {
      if(search.userInfo !== '') {
        getUserList();
        isSearch.value = true;
      }
    };

    // 필터해제
    const clickReset = () => {
      isSearch.value = false;
      search.userInfo = '';

      if(inviteUsers.value.length < 0) {
        paging.value.totalCount = 0;
      }
    };

    // 초대하기
    const clickInvite = () => {
      let result = false;
      if(inviteUsers.value.length === 0) {
        showMessage({
          text: '그룹 멤버로 초대할 직원을 1명 이상 선택해주세요.'
        });
      } else {
        result = true;
      }

      if(result) {
        inviteUsers.value.forEach(item => {
          params.notiTrgtDomains.push({
            trprId: item.lrnerId,
            trprSn: item.userSn,
            trprNm: item.lrnerNm,
            trprTelno: item.mblTelno
          })
        });

        if(notiPfmCd.value.length > 0) {
          notiPfmCd.value.forEach(x => {
            const lineBreak = x === notiPfmCds.wiseNet ? '<br/>' : '\\n';
            const customNotiCn = `[그룹러닝 초대] ${props.groupInfo.leaderNm}(${props.groupInfo.leaderDeptNm})님의 그룹에 초대합니다.${lineBreak}${lineBreak}- 그룹명: ${props.groupInfo.crseNm} ${lineBreak}- 초대 메시지: ${notiCn.value}`;
            params.notiMsgDomains.push({
              notiTitle: x === notiPfmCds.wiseNet ? '[그룹러닝] 그룹 초대' : params.notiTitle,
              notiPfmCdDcd : x,
              notiCn: customNotiCn
            })
          });
        }

        params.imgUrl = `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/group/GettyImages-1454136712.jpg`;
        params.distCrseSn = props.groupInfo.distCrseSn;
        params.lrnTrgtGrpMstSn = props.groupInfo.lrnTrgtGrpMstSn;

        showConfirm(`선택하신 멤버에게 리브똑똑과 메신저 쪽지로<br/>초대 메시지를 발송 하시겠습니까?<br/><span style="color: red">(그룹 비밀번호가 있는 경우 초대 문구에 작성해주세요.)</span>`,
            async () => {
              await store.dispatch(`course/${ACT_INVITE_GROUP_MEMBER}`, params).then(res => {
                if (isSuccess(res)) {
                  showMessage({
                    text: '그룹 초대가 완료되었습니다.',
                    callback: () => {
                      closeModal();
                    }
                  })
                }
              });
        }, () => {
          params.notiMsgDomains = [];
          params.notiTrgtDomains = [];
        });
      }
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    return {
      isSearch,
      search,
      items,
      notiCn,

      paging,
      checkUsers,
      inviteUsers,
      checkAll,
      unInviteUsers,

      clickCheckAll,
      clickUnCheckAll,
      clickCheckInvite,

      clickPlusUser,
      clickMinusUser,

      clickInvite,

      clickSearch,
      clickReset,
      closeModal
    }
  }
}
</script>
<style>
  .member-tablelist.member-scroll {
    height: 300px; overflow:scroll;
  }
</style>