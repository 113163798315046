<template>
  <div class="home-section-row" style="justify-content: space-between">
    <!-- section-card:note -->
    <SSLSelfGaStatus :ssl-division-cd="selfGa" />
    <SSLSelfGiStatus :ssl-division-cd="selfGi" />
    <SSLSelfJaStatus :ssl-division-cd="selfJa" />
    <SSLSelfIctStatus :ssl-division-cd="selfIct" />
    <SSLSelfDvlStatus :ssl-division-cd="selfDvl" />
<!--    <SSLStatus1/>-->
<!--    <SSLStatus4/>-->
<!--    <SSLStatus3/>-->
<!--    <SSLStatus2/>--> <!-- 그룹러닝 -->
<!--    <SSLStatus5/>-->
  </div>
</template>

<script>
import {ref} from 'vue';
// import SSLStatus1 from "@/components/ssl/home/status/SSLStatus1";
import SSLSelfGaStatus from "@/components/ssl/home/status/SSLSelfStatus.vue";
import SSLSelfGiStatus from "@/components/ssl/home/status/SSLSelfStatus.vue";
import SSLSelfJaStatus from "@/components/ssl/home/status/SSLSelfStatus.vue";
import SSLSelfIctStatus from "@/components/ssl/home/status/SSLSelfStatus.vue";
import SSLSelfDvlStatus from "@/components/ssl/home/status/SSLSelfStatus.vue";
// import SSLStatus2 from "@/components/ssl/home/status/SSLStatus2";
// import SSLStatus3 from "@/components/ssl/home/status/SSLStatus3";
// import SSLStatus4 from "@/components/ssl/home/status/SSLStatus4";
// import SSLStatus5 from "@/components/ssl/home/status/SSLStatus5";

export default {
  name: 'SSLHomeStatus',
  components: {
    SSLSelfGaStatus,
    SSLSelfGiStatus,
    SSLSelfJaStatus,
    SSLSelfIctStatus,
    SSLSelfDvlStatus,
    // SSLStatus5,
    // SSLStatus4,
    // SSLStatus3,
    // SSLStatus2,
    // SSLStatus1
  },
  setup() {
    const selfGa = ref('selfGa');
    const selfGi = ref('selfGi');
    const selfJa = ref('selfJa');
    const selfIct = ref('selfIct');
    const selfDvl = ref('selfDvl');

    return{
      selfGa,
      selfGi,
      selfJa,
      selfIct,
      selfDvl,
    }
  }
}
</script>
