<template>
  <div class="popup-container is-active" :class="{ 'is-active' : modelValue }">
    <div class="popup" id="popup-hrdcolud-h0301">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">강사 신청하기</h3>
        </header>
        <div class="popup-content">
          <section class="popup-section">
            <header class="section-header">
              <h4 class="title">정보</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields">
<!--                <RowInputText v-model="param.inqTitle" class-lst="column-whole" title="제목" placeholder="강사 신청하기" :disabled="true" />-->
                <InputSelect
                    v-model:value1="param.inqTyCdDcd"
                    v-model:toggle1="toggles.inqTyCdDcds"
                    title="강의타입"
                    :options1="inqTyCdDcds"
                    :useSubText="false"
                    sequence-key1="cd"
                    nameKey1="cdNm"
                    @update:toggle1="closeToggles(toggles, 'inqTyCdDcds')"
                />
                <div class="kb-form-bottomtext">
                  <div class="kb-table-bottom">
                    <div class="texticon"><i class="icon-notice"></i><strong class="subtext">스튜디오 Live : 여의도 KB국민은행 신관 스튜디오 생방송 / 자택 Live : 자택 생방송(필요시 지원기기 발송) / 혼합형(녹화&Live) : 강의(사전녹화) + Q&A(생방송)</strong></div>
                  </div>
                </div>
                <RowIsPastDatepicker
                    title="희망시기"
                    v-model="param.tuitFeePayDd"
                    v-model:toggle="toggles.tuitFeePayDd"
                />
                <div class="kb-form-bottomtext">
                  <div class="kb-table-bottom">
                    <div class="texticon"><i class="icon-notice"></i><strong class="subtext">희망시기의 경우 담당자와 일정조정 후 변동할 수 있음</strong></div>
                  </div>
                </div>

<!--                <Upload-->
<!--                    v-model="file"-->
<!--                    title="첨부파일"-->
<!--                    placeholder="첨부파일을 등록하세요"-->
<!--                    btn-title="첨부파일 등록하기"-->
<!--                    :max-qty="10"-->
<!--                    sequence-key="comFileAtchSn"-->
<!--                />-->
<!--                <RowDatepicker-->
<!--                    v-model="param.cardAprvDt"-->
<!--                    v-model:toggle="payToggles.cardAprvDt"-->
<!--                    title="승인 ・ 입금일"-->
<!--                    :disabled="disabled"-->
<!--                    @update:toggle="$emit('update:cardAprvDtToggle',payToggles.cardAprvDt)"-->
<!--                    :width="370"-->
<!--                />-->
              </div>
            </div>
          </section>
          <section class="popup-section">
            <header class="section-header">
              <h4 class="title">강의주제</h4>
            </header>
            <div class="section-content">
              <div class="segment-box">
                <textarea v-model="param.inqCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="강의 주제를 입력해주세요."></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{ cnSz }} / 500</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="popup-buttons">
          <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="clickBoardAdd"><span class="text">저장</span></a>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, toRefs} from 'vue';
// import RowInputText from '@/components/support/common/RowInputText';
// import Upload from '@/components/support/common/Upload';
import InputSelect from '@/components/support/common/InputSelect';
import {closeToggles} from '@/assets/js/ui.init';
import {initParams, isSuccess, isUploadSuccess} from '@/assets/js/util';
import {cttTableNm, insertFileAtch, insertQa} from '@/assets/js/modules/hrd/hrd-common';
import {useAlert} from '@/assets/js/modules/common/alert';
import store from '@/store';
import {MUT_SHOW_HRD_BADGE} from '@/store/modules/hrdcloud/hrdcloud';
import {getHrdBadgeList} from '@/assets/js/modules/hrd/hrd-badge';
import RowIsPastDatepicker from '@/components/support/common/RowIsPastDatepicker';
import {ACT_INSERT_LRN_BOARD} from "@/store/modules/board/board";


export default {
  name: 'HrdCloudOpinionModal',
  components: {InputSelect, RowIsPastDatepicker},
  props: {
    modelValue: Boolean,
    title: String,
    cttMstSn: Number,
    disabled: { type:Boolean,  default: false },
  },
  setup(props, {emit}) {
    // const {showMessage} = useAlert();
    const {showConfirm, showMessage} = useAlert();
    const {cttMstSn} = toRefs(props);

    const paging = reactive({
      pageNo: 1,
      pageSize: 8,
      // pageSize: 1,
      totalCount: 0
    });

    const inqTyCdDcds = [{ cd: '2113001', cdNm: '스튜디오Live' }, { cd: '2113002', cdNm: '자택Live' }, { cd: '2113003', cdNm: '혼합형(녹화&Live)' }];

    const payToggles = reactive({
      cardKinds: false,
      cardAprvDt: false
    });

    const param = reactive({
      tblNm: cttTableNm,
      tblSn: (cttMstSn.value ? cttMstSn.value : 0),
      comInqSn: 0,
      // inqTitle: '강사 신청하기',
      inqTyCdDcd: '2113001',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: '',
      tuitFeePayDd: '',
    });

    const file = ref({ files: [], removed: [], binaries: [] });

    const toggles = reactive({
      inqTyCdDcds: false
    });

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const closeModal = () => {
      init();
      emit('update:modelValue', false);
    };

    const validate = () => {
      if (!param.tuitFeePayDd) {
        showMessage('희망 시기를 선택해주세요.');
        return false;
      }
      if (!param.inqCn) {
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.inqCn.length > 500) {
        showMessage('내용을 500자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    const init = () => {
      initParams(param);
      initParams(file.value);
      param.tblNm = cttTableNm;
      param.tblSn = (cttMstSn.value ? cttMstSn.value : 0);
      param.inqTyCdDcd = '2091010';
    };

    const insertQaAndFiles = async () => {
      if (validate()) {
        let qa = await insertQa(param);
        if (isSuccess(qa)) {
          if (file.value.files.length > 0) {
            let fileRes = await insertFileAtch({comInqSn: qa.comInqSn}, file.value.files);
            if (isUploadSuccess(fileRes)) {
              showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
                closeModal();

                if (qa.badges && qa.badges.length > 0) {
                  store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
                  getHrdBadgeList();
                }
              });
            } else {
              showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
            }
          } else {
            showMessage("콘텐츠 개선의견이 접수되었습니다", () => {
              closeModal();
            });

            if (qa.badges && qa.badges.length > 0) {
              store.commit(`hrdcloud/${MUT_SHOW_HRD_BADGE}`,qa.badges);
              getHrdBadgeList();
            }
          }
        } else {
          showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
        }
      }
    };

    // const selectedValue1 = computed(() => {
    //   if (props.options1) {
    //     const target = props.options1.find(x => {
    //       if (x[props.sequenceKey1] === props.value1) return x;
    //     });
    //     if (target) {
    //       return target[props.nameKey1];
    //     }
    //   }
    //   return (props.placeholder1 ? props.placeholder1 : '');
    // });param.inqTyCdDcd

    const addBoard = () => {
      store.dispatch(`board/${ACT_INSERT_LRN_BOARD}`, {
        distCrseSn: '203057',
        distCrseGrpSeqSn: '14',
        cn: param.inqCn,
        files: [],
        title: '강사신청',
        divCdDcd: '2013012',
        divNm: '강사신청',
        refTyCdDcd: param.inqTyCdDcd,
        refTyNm: inqTyCdDcds.find(e => e.cd === param.inqTyCdDcd).cdNm,
        refPost: param.tuitFeePayDd,
      }).then(res => {
        if(isSuccess(res)) {
          param.inqCn = '';
          showMessage('신청이 완료되었습니다.');
          closeModal();
          // getBoardWithCommentList();
        } else {
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    // const getBoardWithCommentList = (isInit) => {
    //   getListFunc(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST}`, {distCrseGrpSeqSn: '178', ...paging}, items, paging, () => {
    //     if(isInit) loadings.board = true;
    //     items.value.forEach(x => {refineBoard(x);})
    //   });
    // }

    const clickBoardAdd = () => {
      if(validate()) {
        showConfirm({
          title: '강사신청',
          text: '작성된 내용을 등록하시겠습니까?',
          callback: addBoard
        });
      }
    }

    return {
      inqTyCdDcds, param, file, toggles, cnSz, payToggles,
      closeModal, closeToggles, insertQaAndFiles, clickBoardAdd, paging
    }
  }
};
</script>