<template>
  <div v-if="!isLoading" class="loadmap-roundedbox-wrap">
    <div class="roundedbox">
      <div class="roundedbox-top">
        <div style="display: flex;">
          <img :src='item.thumbUrl' alt="">
            <div>
                <div class="top-row">
                  <h5 class="roundedbox-title">{{item.hrdCloudCateNm}}</h5>
                  <div v-if="!item.isCompleted" class="roadmap-recmd">
                    <div v-if="item.upskillRcmdYn == 'Y'" class="badge badge-red"><span class="badge-text">UpSkill추천</span>
                    </div>
                    <div v-if=" item.reskillRcmdYn == 'Y'" class="badge badge-primary" style="align-items: center;"><span
                        class="badge-text">ReSkill추천</span>
                    </div>
                  </div>
                  <div class="roadmap-recmd">
                    <div v-if="item.isCompleted" class="badge badge-black"><span class="badge-text">완료</span>
                    </div>
                    <div v-else-if="item.cttCmptnCount > 0" class="badge badge-primary"><span
                        class="badge-text">진행</span>
                    </div>
                  </div>
                </div>
                <div v-if="roadmap.length > 1 && ![2047,2048].includes(roadmap[0].hrdCloudCateSn)" class="roundedbox-bottom">
                  <div v-for="(data,idx) in roadmap" :key="idx" @click="activeIdx = idx" style="margin-right: 5px;">
                    <a v-if="data.hrdCloudCateSn === 1984" class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                       :class="{'text-is-active': data.hrdCloudCateLevel == item.hrdCloudCateLevel}" href="javascript:"><span
                        class="text">{{partTimerCateLevels.find(x => x.level == data.hrdCloudCateLevel).label}}</span></a>
                    <a v-else-if="[2047,2048].includes(data.hrdCloudCateSn)" class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                       :class="{'text-is-active': data.hrdCloudCateLevel == item.hrdCloudCateLevel}" href="javascript:"><span
                        class="text">{{preCateLevels.find(x => x.level == data.hrdCloudCateLevel).label}}</span></a>
                    <a v-else class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                       :class="{'text-is-active': data.hrdCloudCateLevel == item.hrdCloudCateLevel}" href="javascript:"><span
                        class="text">{{hrdCloudRoadmapCateLevels.find(x => x.level == data.hrdCloudCateLevel).label}}</span></a>
                  </div>
                </div>
                <div v-else-if="[2047,2048].includes(roadmap[month].hrdCloudCateSn)" class="roundedbox-bottom">
                  <div style="margin-right: 1px;">
                    <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                       :class="{'text-is-active': roadmap[month].hrdCloudCateLevel == item.hrdCloudCateLevel}" href="javascript:"><span
                        class="text">{{preCateLevels.find(x => x.level == roadmap[month].hrdCloudCateLevel).label}}</span>
                    </a>
                  </div>
                  <div style="margin-right: 5px; text-decoration:underline;">
                    <a v-if="!isMobile" class="kb-btn kb-btn-wide rounded-lg loadmap-btn" style="border: 0px;"><span
                        class="text" @click="showRoadmapMainModal">더보기</span>
                    </a>
                    <a v-else class="kb-btn kb-btn-wide rounded-lg loadmap-btn" style="border: 0px;"><span
                        class="text" @click.stop="$router.push({name:'HrdCloudRoadmapMain'})" >더보기</span>
                    </a>
                  </div>
                </div>
            </div>
        </div>
      </div>
        <div class="roundedbox-bottom" id="mouse-over" @click="moveToDtl($router, item.hrdCloudCateSn, item.hrdCloudCateLevel)">
          <button class="roadmap-button">
            <span class="left">{{ item.roadmapTitle }}</span>
            <span class="right" style="text-align: right;">{{ item.cttCmptnCount }}/{{ item.cttCount }}개
              <div v-if="isMobile" style="font-size: 12px; text-align: right; text-decoration: underline;">
                학습하기
              </div>
            </span>
            <span v-if="!isMobile" class="center">학습하기</span>
          </button>
        </div>

<!--      <div class="roundedbox-bottom" id="mouse-over" @click="moveToDtl($router, item.hrdCloudCateSn, item.hrdCloudCateLevel)">-->
<!--          <a href="javascript:" class="kb-btn kb-btn-wide rounded-lg text-is-active">-->
<!--            <div class="hidden-hover">-->
<!--              <div style="display: flex !important; justify-content: space-between">-->
<!--                <span class="text" style="text-align: left;">{{ item.roadmapTitle }}</span>-->
<!--                <span class="text-count" style="text-align: right;">&nbsp;{{ item.cttCmptnCount }}/{{ item.cttCount }}개-->
<!--                  <div v-if="isMobile"  style="font-size: 12px; text-align: center; text-decoration: underline;">-->
<!--                    학습하기-->
<!--                  </div>-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="show-hover">-->
<!--              학습하기-->
<!--            </div>-->
<!--          </a>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {onMounted, ref, computed, watch} from "vue";
import {useStore} from "vuex";
import {moveToDtl} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";
import {
  hrdCloudRoadmapCateLevels
} from "@/assets/js/modules/cloud/cloud-common";

export default {
  name: 'HrdCloudRoadmapCardV2',
  components: {},
  props: {
    data: Object,
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore()
    const roadmap = computed(() => props.data)
    const isLoading = ref(true)
    const isMobile = computed(() => store.state.auth.isMobile)
    const item = ref(null)
    const activeIdx = ref(-1)
    const month = new Date().getMonth();

    const partTimerCateLevels = [{level:1,label:'영상'},{level:2,label:'실습'},{level:3,label:'교재'}];

    const showRoadmapMainModal = () => {
      emit('update:modelValue', true);
    }

    const preCateLevels = [
      {level:1,label:'1월'},{level:2,label:'2월'},{level:3,label:'3월'},
      {level:4,label:'4월'},{level:5,label:'5월'},{level:6,label:'6월'},
      {level:7,label:'7월'},{level:8,label:'8월'},{level:9,label:'9월'},
      {level:10,label:'10월'},{level:11,label:'11월'},{level:12,label:'12월'}];

    const getActiveRoadmap = () => {
      isLoading.value = true;
      roadmap.value.map(x => {
        if (x.cttCmptnCount > 0 && x.cttCmptnCount >= x.cttCount) {
          x.isCompleted = true;
        } else {
          x.isCompleted = false;
        }
      })
      // 최근 진행한 로드맵
      activeIdx.value = roadmap.value.findIndex(x => x.roadmapLastProgDt === Math.max(...roadmap.value.filter(y => y.isCompleted === false && y.cttCmptnCount > 0).map(z => z.roadmapLastProgDt)));
      // UpSkill추천 로드맵
      if (activeIdx.value < 0) {
        activeIdx.value = roadmap.value.findIndex(x => x.upskillRcmdYn == 'Y' && x.isCompleted === false);
      }
      // ReSkill추천 로드맵
      if (activeIdx.value < 0) {
        activeIdx.value = roadmap.value.findIndex(x => x.reskillRcmdYn == 'Y' && x.isCompleted === false);
      }
      // 완료한 로드맵의 최종 난이도
      if (activeIdx.value < 0) {
        const lastCmptnIdx = roadmap.value.findLastIndex(x => x.isCompleted === true);
        activeIdx.value = lastCmptnIdx >= 0 && lastCmptnIdx + 1 < roadmap.value.length ? lastCmptnIdx + 1 : 0;
      }

      if ([2047,2048].includes(roadmap.value[month].hrdCloudCateSn)) {
        activeIdx.value = month;
      }
    }
    watch(() => activeIdx.value, () => {
      if (activeIdx.value >= 0 && activeIdx.value < roadmap.value.length) {
        item.value = roadmap.value[activeIdx.value];
        isLoading.value = false;
      }
      // item.value.thumbUrl = getThumbImg(item.value.thumbUrl);
    })

    onMounted(getActiveRoadmap)

    return {
      roadmap,
      item,
      activeIdx,
      isLoading,
      isMobile,
      moveToDtl,
      hrdCloudRoadmapCateLevels,
      partTimerCateLevels,
      preCateLevels,
      month,
      showRoadmapMainModal
    }
  }
}
</script>

<style>
/* 웹 */
.lxp-layout #kb-hrdcloud .roadmap-card {
  position: relative;
  width: 300px;
  flex: 0 0 300px;
  border-radius: 24px;
  overflow: hidden;
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-visual {
  overflow: hidden;
  z-index: 2;
  position: relative;
  height: 500px;
  border-radius: 24px;
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-visual .chart-star {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 24px;
  background-color: var(--kb-yellow);
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-contents {
  z-index: var(--kb-zindex-dropdown);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 165px;
  padding: 48px 32px;
  background-color: var(--kb-background-brown);
  border-radius: 22px;
}

.lxp-layout #kb-hrdcloud .roadmap-card .content-title {
  font-family: var(--kb-font-KBFGTextB);
  color: #84888b;
  line-height: 19px;
  margin-bottom: 1rem;
}

.lxp-layout #kb-hrdcloud .roadmap-card .content-text {
  font: bold 24px/30px var(--kb-font-KBFGTextB);
}

.lxp-layout #kb-hrdcloud .roadmap-card .content-badge {
  font: bold 0.8rem/1rem var(--kb-font-KBFGTextB);
  margin-left: 1rem;
  padding: 0.5rem;
  border-radius: 1rem;
  color: var(--kb-yellow);
  background-color: var(--kb-black);
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-btn-area {
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: var(--kb-zindex-dropdown);
}

.lxp-layout #kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.lxp-layout #kb-hrdcloud .roadmap-card .content-info {
  width: 100%;
}

/* 모바일 */
.lxp-mobile-layout #kb-hrdcloud .roadmap-card {
  position: relative;
  width: 95%;
  margin: auto;
  border-radius: 24px;
  overflow: hidden;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-visual {
  overflow: hidden;
  z-index: 2;
  position: relative;
  height: 415px;
  border-radius: 24px
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .chart-star {
  position: relative;
  width: 100%;
  height: 415px;
  border-radius: 24px;
  background-color: var(--kb-yellow);
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-contents {
  z-index: var(--kb-zindex-dropdown);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding: 25px 32px;
  background-color: var(--kb-background-brown);
  border-radius: 22px;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-title {
  font-weight: bold;
  line-height: 20px;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-text {
  line-height: 20px;
  font-weight: 300;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-badge {
  font: normal 0.8rem/1rem var(--kb-font-KBFGTextB);
  margin-left: 0.5rem;
  padding: 5px 10px;
  border-radius: 1rem;
  color: var(--kb-yellow);
  background-color: var(--kb-black);
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-btn-area {
  position: absolute;
  top: 42px;
  right: 35px;
  z-index: var(--kb-zindex-dropdown);
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {
  width: 16px;
  height: 16px;
  border-radius: 8px;
}

.lxp-mobile-layout #kb-hrdcloud .roadmap-card .content-info {
  width: calc(100% - 48px);
}

/* 공통 */
#kb-hrdcloud .roadmap-card .card-header {
  z-index: var(--kb-zindex-dropdown);
  position: absolute;
  left: 0;
  top: 0;
  padding: 48px 32px 0;
}

#kb-hrdcloud .roadmap-card .card-header .title {
  font-size: 20px;
  font-family: var(--kb-font-KBFGDisplayB);
  white-space: nowrap;
  color: var(--kb-white);
  margin-right: 5px;
}

#kb-hrdcloud .roadmap-card .card-visual .card-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--kb-silver);
  opacity: 0.5;
  display: none;
  justify-content: center;
  z-index: var(--kb-zindex-dropdown);
}

#kb-hrdcloud .roadmap-card .card-visual .card-img {
  width: 100%;
  z-index: 2;
}

#kb-hrdcloud .roadmap-card .card-visual .card-shadow .play-btn {
  cursor: pointer;
  background-color: rgba(43, 51, 63, 0.7);
  width: 90px;
  height: 50px;
  margin-top: 50%;
  border: 0.06rem solid white;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

#kb-hrdcloud .roadmap-card .card-visual .card-shadow .icon-play {
  background-image: url(../../assets/lxp/images/common/ic_video_play.svg);
  width: 24px;
  height: 24px;
}

#kb-hrdcloud .roadmap-card .card-visual .chart-star .star-bg {
  width: 220px;
  height: 220px;
  border-radius: 110px;
}

#kb-hrdcloud .roadmap-card .card-visual .chart-star .star-active {
  position: absolute;
  left: 45px;
  top: 70px;
  width: 220px;
  height: 220px;
  background: url(../../assets/lxp/images/ssl/home/graph_star_active.png) no-repeat 0 0;
  background-size: 220px
}

#kb-hrdcloud .roadmap-card .card-visual:hover {
}

#kb-hrdcloud .roadmap-card .card-visual:hover .card-shadow {
  display: flex;
}

#kb-hrdcloud .roadmap-card .roadmap-recmd {
  position: absolute;
  top: 75px;
  left: 30px;
  z-index: var(--kb-zindex-dropdown);
}

#kb-hrdcloud .roadmap-card .content {
  display: flex;
  flex-direction: column;
}

#kb-hrdcloud .roadmap-card .content-badge.progress-badge {
  color: var(--kb-black);
  background-color: var(--kb-yellow);
}

#kb-hrdcloud .roadmap-card .content-btn img {
  object-fit: none;
}

#kb-hrdcloud .roadmap-card .card-btn-area .card-pagination {
  cursor: pointer;
  margin: 8px 0;
  background-color: rgba(132, 136, 139, 0.5);
  transition-property: height, background-color;
  transition-duration: 0.4s;
  z-index: var(--kb-zindex-dropdown);
}

#kb-hrdcloud .roadmap-card .card-btn-area .card-pagination.card-pagination-active {
  height: 50px;
  background-color: var(--kb-white);
}

#kb-hrdcloud .roadmap-card .content-btn {
  width: 48px;
  height: 48px;
}

.text-is-active {
  background-color: var(--kb-background-brown);
}

</style>
