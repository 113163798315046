<template>
  <div class="page-container">
    <!-- page-body -->
    <div class="page-body">
      <!-- board-view-container -->
      <div v-if="isReady" class="board-view-container">
        <div class="board-view-top view-component">
          <div class="top-column">
            <button class="kb-btn-type back" @click="goList">
              <span class="ic-bg"><i class="icon-type-back"></i></span>
              <span class="text">이전</span>
            </button>
          </div>

          <div class="top-column ssl-action">
            <div class="ssl-action-column">
              <button v-if="canPut" type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" :class="{'is-active': isPut}" @click="putSocBoard">
                <i class="icon-board-save"></i>
                <span class="text">{{ isPut ? '담기취소' : '담기' }}</span>
              </button>
              <button v-if="canSubscribe" type="button" class="btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg" :class="{'is-active': isSubscribe}" @click="toggleSubscribe">
                <i class="icon-board-follow"></i>
                <span class="text">{{ isSubscribe ? '구독중' : '구독' }}</span>
              </button>

              <button v-if="canShare" type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="shareToggle = true;">
                <i class="icon-board-share"></i>
                <span class="text">공유</span>
              </button>

              <button type="button" class="kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="opinionToggle = true;">
                <i class="icon-board-opinion"></i>
                <span class="text">수정의견</span>
              </button>
            </div>
          </div>
        </div>

        <div class="board-view-body">
          <div class="board-view-header view-component">
            <div class="page-avatar kb-mouse-cursor">
              <SSLProfileImg
                  :target="socBoard"
                  @clickImage="goLearner"
              />
            </div>
            <h6 class="page-title"><span class="author">{{ socBoard.lrnerNm }}</span><span>&nbsp;&nbsp;</span><span class="office">{{ isGroup ? socBoard.refTyNm : socBoard.deptNm }}</span></h6>
            <h2 class="title">{{ socBoard.title }}</h2>

            <div class="page-info">
              <span class="text">{{ dateToFormat(socBoard.pblcnDt) }}</span>
              <span class="text">{{ numberComma(socBoard.inqcnt) }} 조회</span>
              <span class="text">{{ numberComma(socBoard.sharedCnt) }} 공유</span>
            </div>
          </div>

          <div class="board-view-body view-component">
            <!-- editor contents -->
            <div class="editor-area">
              <div v-html="currentPage.cn"></div>
            </div>

            <SSLViewRel
                :hash-tags="hashTags"
                :ref-url="socBoard.refUrl"
                :ref-post="socBoard.refPost"
            />

            <!-- LIKE -->
            <div class="like-container" :style="canEdit ? {marginBottom: 0} : {}">
              <div class="like" style="width:45%" v-if="postSns.length > 0"></div>
              <div class="like">
                <img src="@/assets/lxp/images/ssl/view/ic_like.png" alt="">
                <button :class="{'is-active': (session.lrnerId !== socBoard.lrnerId && isLike)}" @click="toggleLike">
                  <span>좋아요</span>&nbsp;<span class="like-num">{{ socBoard.likeCnt }}</span>
                </button>
              </div>
              <template v-if="postSns.length > 0">
                <div class="like" style="width:27%"></div>
                <div class="like" style="margin-top: 103px;margin-left: 10px;">
                  <button @click="goPage('prv')">
                    <span>◀ 이전</span>
                  </button>
                </div>
                <div class="like" style="margin-top: 103px;margin-left: 5px;">
                  <button @click="goPage('nxt')">
                    <span>다음 ▶</span>
                  </button>
                </div>
              </template>
            </div>

            <div v-if="canEdit" class="like-container" style="margin-top: 8px; margin-bottom: 0">
              <div class="like">
                <button class="is-active" @click="goEdit">
                  <span>수정</span>
                </button>
              </div>
            </div>
          </div>

          <SSLComment :reg-dt="socBoard.pblcnDt"/>
        </div>
      </div>
    </div>

    <SSLShare
        v-if="shareToggle"
        v-model:toggle="shareToggle"
        :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
        :soc-lrn-post-sn="socBoard.socLrnPostSn"
        :title="socBoard.title"
        @acted="acted"
    />
    <SSLNoteOpinionModal
        v-if="opinionToggle"
        v-model:toggle="opinionToggle"
        :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
        :soc-lrn-post-sn="socBoard.socLrnPostSn"
        :title="socBoard.title"
    />

  </div>
</template>

<script>
import SSLComment from '@/components/ssl/view/SSLComment';
import SSLViewRel from '@/components/ssl/view/SSLViewRel';
import {sslViewSetup} from '@/assets/js/modules/ssl/setup/ssl-view-setup';
import SSLShare from '@/components/ssl/view/SSLShare';
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import SSLNoteOpinionModal from "@/components/ssl/view/SSLNoteOpinionModal.vue";

export default {
  name: 'SSLView',
  components: {
    SSLNoteOpinionModal,
    SSLProfileImg,
    SSLShare,
    SSLViewRel,
    SSLComment
  },
  emits: ['acted'],
  setup: sslViewSetup
}
</script>
