<template>
  <main class="kb-main" id="kb-hrdcloud" @click="closeToggles(toggles)">

      <lxp-main-header :show-back="true" :show-custom-title="true"/>

    <div class="main-content main-component">
      <div class="hrdcloud-list-container">

        <div class="list-top">
          <div class="top-column">
          </div>
          <div class="top-column">
            <div class="kb-form-search">
              <div class="kb-form-search-field">
                <input type="text" class="kb-form-search-input" v-model="search.keyword"  placeholder="검색" />
                <button class="kb-form-search-btn" @click="getCloudList"><i class="icon-search">검색</i></button>
              </div>
            </div>
          </div>
        </div>

        <!-- content-section:HRD 클라우드 -->
        <article class="content-section" :class="{'section-divider' : idx > -1}">
          <header class="section-header"><h3 class="title">HRD 클라우드</h3>
            <div class="header-sub">
              <router-link class="more-link">전체보기</router-link>
            </div>
          </header>
          <template v-if="items && items.length > 0">
            <HrdCloudSwiper :items="items" :show-put="true" :session="session" />
          </template>
          <template v-else-if="(items && items.length === 0) && !isLoading">
            <div class="search-container">
              <div class="result-empty">
                <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                <p  class="text">조회 된 콘텐츠가 없습니다.</p>
              </div>
            </div>
          </template>
          <template v-else-if="isLoading">
            <LoadingDiv v-model="isLoading" />
          </template>
        </article>

        <!-- content-section:SSL 학습노트-->
        <article class="content-section" :class="{'section-divider' : idx > -1}">
          <header class="section-header"><h3 class="title">SSL 학습노트</h3>
            <div class="header-sub">
              <router-link class="more-link">전체보기</router-link>
            </div>
          </header>
          <template v-if="items && items.length > 0">
            <HrdCloudSwiper :items="items" :show-put="true" :session="session" />
          </template>
          <template v-else-if="(items && items.length === 0) && !isLoading">
            <div class="search-container">
              <div class="result-empty">
                <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                <p  class="text">조회 된 콘텐츠가 없습니다.</p>

              </div>
            </div>
          </template>
          <template v-else-if="isLoading">
            <LoadingDiv v-model="isLoading" />
          </template>
        </article>

        <!-- content-section:Hot Tip[핫;팁]-->
        <article class="content-section" :class="{'section-divider' : idx > -1}">
          <header class="section-header"><h3 class="title">핫팁(Hot Tip)</h3>
            <div class="header-sub">
              <router-link class="more-link">전체보기</router-link>
            </div>
          </header>
          <template v-if="items && items.length > 0">
            <HrdCloudSwiper :items="items" :show-put="true" :session="session" />
          </template>
          <template v-else-if="(items && items.length === 0) && !isLoading">
            <div class="search-container">
              <div class="result-empty">
                <img src="../../../assets/lxp/images/common/img_empty.png" alt="">
                <p  class="text">조회 된 콘텐츠가 없습니다.</p>
              </div>
            </div>
          </template>
          <template v-else-if="isLoading">
            <LoadingDiv v-model="isLoading" />
          </template>
        </article>

      </div>
    </div>
  </main>

</template>


<script>
import {closeToggles} from "@/assets/js/ui.init";
import HrdCloudSwiper from "@/components/hrdcloud/HrdCloudSwiper.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {computed, onMounted, reactive, ref} from "vue";
import {useRoute} from "vue-router";
import {getItems,initPaging, lengthCheck} from "@/assets/js/util";
import {ACT_GET_CLOUD_LIST} from "@/store/modules/uniSearch/uniSearch";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";




export default {
  name:'unify',
  components:{LoadingDiv, HrdCloudSwiper},
  setup(){
    const store = useStore();
    const {showMessage} = useAlert();
    const search = reactive({
      keyword: ''
    });

    const route = useRoute();
    const items = ref([]);
    console.log("items + " ,items);
    const paging = ref(initPaging(route));
    const session = computed(() => store.state.auth.session);
    const isLoading = ref(false);
    const  getCloudList = () => {
      if(search.keyword == null || search.keyword == ''){
        showMessage('검색어를 입력해주세요');
        //return;
      }
        isLoading.value = true;
        store.dispatch(`uniSearch/${ACT_GET_CLOUD_LIST}`, search.keyword).then(res => {
          console.log("응답,.,,",res);
        if(lengthCheck(res)) {
          console.log("ture~~~",lengthCheck(res));
          //items 받아오는 부분
          items.value = getItems(res);
        } else {
          isLoading.value = false;
          items.value = [];
          console.log("items..... + " ,items);
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        console.error(e);
        isLoading.value = false;
      })
    }

    onMounted(() => {
      getCloudList();
    });

    return{
    closeToggles,
    search,
    items,
    session,
    isLoading,
    getCloudList,
    showMessage
    }
  }
}

</script>