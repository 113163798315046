<template>
    <SSLMobileSlotHeader
    >
      <template v-slot:title>수정 의견</template>
      <template v-slot:left>
        <div class="util util-history-back" @click="closeModal">
          <a href="javascript:" class="util-actions util-actions-history-back">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
      <template v-slot:right>
        <div class="util util-btn">
          <a href="javascript:" class="util-actions util-actions-btn text-primary" @click="insertQaAndFiles">저장</a>
        </div>
      </template>
    </SSLMobileSlotHeader>

    <main class="kb-main" id="kb-hrdcloud">
      <div class="main-content main-component">
        <article class="content-section">
          <div class="kb-form-fields">
            <div class="kb-form-field field-border-0">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">제목</span></label>
              </div>
              <div class="kb-form-row">
                <input v-model="param.inqTitle" type="text" class="kb-form-control" name="" placeholder="제목을 입력하세요" :disabled="true">
              </div>
            </div>

            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">파일첨부</span></label>
              </div>
              <div class="kb-form-row">
                <div v-for="(file, idx) in file.files" class="file-text" :key="idx">
                  <p class="text">{{file.name}}</p>
                  <div class="close">
                    <span class="close-text">{{ convertToStorageBytes(file.size) }} </span>
                    <button @click.stop="removeUpload(file, 'comFileAtchSn', idx)"><i class="icon-del"></i></button>
                  </div>
                </div>
              </div>
              <div class="kb-form-row row-buttons">
                <label class="kb-btn kb-btn-outline kb-btn-content-item kb-form-file-label" for="inp_file2"><span class="text">파일 첨부</span>
                  <InputUpload
                      v-model="file"
                      :max-qty="1"
                      sequence-key="comFileAtchSn"
                  />
                </label>
              </div>
            </div>
            <div class="kb-form-field">
              <div class="kb-form-row">
                <label class="kb-form-label"><span class="kb-form-label-text">노트 수정의견</span><span class="kb-form-byte-text">{{ cnSz }} / 500</span></label>
              </div>
              <div class="kb-form-row">
                <textarea v-model="param.inqCn" name="" class="kb-form-control" placeholder="내용을 입력해 주세요."></textarea>
              </div>
            </div>
          </div>
        </article>
      </div>
    </main>
</template>

<script>


import {sslNoteOpinion} from '@/assets/js/modules/ssl/setup/ssl-note-opinion';
import InputUpload from '@/components/common/InputUpload';
import SSLMobileSlotHeader from "@/components/ssl/main/mobile/SSLMobileSlotHeader.vue";

export default {
  name: 'SSLMobileNoteOpinionModal',
  components: {InputUpload, SSLMobileSlotHeader},
  props: {
    toggle: Boolean,
    lrnTrgtGrpMstSn: Number,
    socLrnPostSn: Number,
    title: String
  },
  emits: ['update:toggle'],
  setup: sslNoteOpinion,
};
</script>
