import LxpLayout from "@/views/layout/lxp/LxpLayout";
import navigationUtils from "@/assets/js/navigationUtils";
import LxpMobileLayout from "@/views/layout/lxp/mobile/LxpMobileLayout";
const isMobile = navigationUtils.any();

const sumgoRoutes = [
  {
    path: '/sinbak',
    component: isMobile ? LxpMobileLayout : LxpLayout,
    children: [
      {
        // path variable 을 생성해야 된다. 임시 라우터 처리
        path: '',
        name: "SumgoMain",
        // component: () => isMobile ? import('../../views/pages/sumgo/mobile/mobileSumgoMain') : import('../../views/pages/sumgo/SumgoMain')
        component: () => isMobile ? import('../../views/pages/sumgo/mobile/MobileSumgoMain'): import('../../views/pages/sumgo/SumgoMain')
      }
    ],
  }
];

export const setSumgoRoutes = (routes) => {
  routes.push(...sumgoRoutes);
}