import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {computed, nextTick, onMounted, reactive, ref} from 'vue';
import {closeToggles} from "@/assets/js/ui.init";
import {
    ACT_GET_MY_SSL_LST,
    // ACT_GET_TOTAL_BEST_SOC_BOARD_LIST,
    ACT_GET_SOC_EXCELLENT_BOARD_LIST,
    MUT_SET_POSTSN_LIST,
} from '@/store/modules/ssl/ssl-index';
import {
    getItems,
    getPaging,
    initProgressBar,
    lengthCheck,
    timestampToDateFormat,
} from '@/assets/js/util';
import {
    getCrseSnToDistCrseCd,
} from "@/assets/js/modules/ssl/ssl-common";
import {getMainId} from "@/assets/js/modules/ssl/ssl-main";

export const sslBestNoteSetup = () => {

    const store = useStore();
    const route = useRoute();

    const isMobile = computed(() => store.state.auth.isMobile);
    const currentName = computed(() => route.name);

    // const isReady = ref(false);
    const isLoading = ref(true);

    const mainId = computed(() => getMainId(route.path));
    const keyword = ref('');

    //Mobile
    const isSSLSearch = ref(false);// computed(()=>store.state.ssl.isSSLSearch); //검색실행
    const searchMode = ref(false); // true:입력창 활성/ssl메뉴(hide)/ '<' 버튼 (searchMode=false) | false:true:입력창 비활성/ssl메뉴(show)/ '<' 버튼가림
    const keywordEl = ref(null);
    //Mobile

    const currentDate = new Date();
    const filters = reactive({
        year: currentDate.getFullYear(),
        distCrseSn: '',
    });
    const toggles = reactive({
        year: false,
        distCrseSn: false,
    });

    const yearOptions = (() => {
        const options = [];
        for(let i = currentDate.getFullYear(); i >= 2023 ; i--){
            options.push({value: i, display: `${i}년`});
        }
        return options;
    })();

    const distOptions =ref([]);
    const getOptions= async ()=>{
        // console.log('year : ' + filters.year);

        await store.dispatch(`ssl/${ACT_GET_MY_SSL_LST}`, filters.year).then(res => {
            if (lengthCheck(res)) {
                distOptions.value = getItems(res);
                // initSearch();
                // console.log('distOptions length : ' + distOptions.value.length);
            }

        }).catch(e => {
            console.error(e);
        });
    }

    const boardItems = ref([]);
    const paging = reactive(
        {pageNo: 1, pageSize: 12, totalCount: 0, hasMore: false}
    );

    const moveBoard = socLrnPostSn => {
        const target = boardItems.value.find(item => item.socLrnPostSn === socLrnPostSn);
        if (target) target.myViewCnt++;
    }


    const getSocsBoardList = (isInit) => {
        if(isInit) boardItems.value = [];
        store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`, {
            // store.dispatch(`ssl/${ACT_GET_TOTAL_BEST_SOC_BOARD_LIST}`, {
            // distCrseSn : (filters.distCrseSn?filters.distCrseSn:0),
            // year : filters.year,
            title : keyword.value,
            // pageNo: paging.pageNo,
            // pageSize: paging.pageSize,
        }).then(res => {
            if(lengthCheck(res))
            // items.value.push(...getItems(res));
                boardItems.value = getItems(res).map(x => ({
                    ...x,
                    date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                    day: timestampToDateFormat(x.pblcnDt, 'dd'),
                    distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
                }));
            paging.hasMore = getPaging(res).hasMore;
            isLoading.value = false;

            const opt  = reactive({
                div:'all',
                title: keyword.value,
                sortC: ''
            });

            store.commit(`ssl/${MUT_SET_POSTSN_LIST}`, {paging : getPaging(res), items:boardItems.value.map(x=>x.socLrnPostSn), opt:opt}); // 이전,다음페이지 이동을 위해 추가함.
        }).catch(e => {
            console.error(e);
            isLoading.value = false;
        });
    }

    const initSearch=()=>{
        paging.pageNo= 1;
        paging.totalCount= 0;
        paging.hasMore=false;
        getSocsBoardList(true);
    }

    const cnclSearch=()=>{
        isSSLSearch.value = false;
        searchMode.value = false;
    }

    const goSearch = async () => {
        if (searchMode.value){
            isSSLSearch.value = true;
            initSearch();
        }else{
            searchMode.value = true;
            await nextTick();
            keywordEl.value.focus();
        }
    };

    const renderSocsBoardList = (isInit) => {
        paging.hasMore = false;
        isLoading.value = true;
        getSocsBoardList(isInit);
    }

    const moreFunc = () => {
        if(isLoading.value) return;
        paging.pageNo++;
        renderSocsBoardList();
        if (!isMobile.value) initProgressBar();
    }

    onMounted( () => {
        getOptions();
        initSearch();
    });



    return {
        filters,toggles,
        isLoading,
        currentName,
        boardItems,
        paging,
        keyword,
        moreFunc,
        moveBoard,
        yearOptions,
        getOptions,
        closeToggles,
        distOptions,
        initSearch,
        mainId,
        isSSLSearch, searchMode, keywordEl, goSearch, cnclSearch
    }
}
