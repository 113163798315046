<template>
  <main id="ssl-home" style="padding-bottom: 0;background-color:#fff">
    <SSLMobileSlotHeader
      :hideTitle="searchMode">
      <template v-slot:title>
        <SSLMobileNav />
      </template>
      <template v-slot:left>
          <div v-if="isSSLSearch && searchMode" class="util util-back">
            <a href="javascript:" class="util-actions util-actions-back" @click="goBack"><i class="icon-history-back"></i></a>
          </div>
          <template v-else>
            <div v-if="searchMode" class="util util-back">
              <a href="javascript:" class="util-actions util-actions-back" @click="searchMode=false"><i class="icon-history-back"></i></a>
            </div>
          </template>
          <div v-if="searchMode" class="util util-search-input">
            <input ref="keywordEl" v-model.trim="keyW" type="text" placeholder="학습노트 검색" @keyup.prevent.stop.enter="goSearch" />
          </div>
      </template>
      <template v-slot:right>
        <div class="util util-search">
          <a href="javascript:"
             class="util-actions util-actions-search"
             @click="goSearch"
             @keyup.prevent.stop.enter="goSearch"
          ><i class="icon-search"></i></a>
        </div>
      </template>
    </SSLMobileSlotHeader>


<!--    <SSLMobileBoardSearch v-if="isSSLSearch" v-model:kWord="keyW" />-->
<!--    <template v-else>-->
      <SSLEvent/>
      <SSLHomeProfile/>
      <!-- 공지사항 -->
      <SSLMobileHomeNotice/>

      <!-- 내 활동 -->
      <SSLMobileActivity/>

      <!-- 학습현황 -->
      <SSLMobileHomeStatus/>

      <!-- 내 배지 -->
      <SSLMobileHomeBadge/>

    <main class="kb-main" id="kb-home" style="padding-bottom: 0px;">
      <article class="content-section section-hrdcloud" style="border-top: 1px solid var(--kb-light-silver);">
        <header class="section-header d-flex">
          <h2 class="title">SSL 학습노트</h2>
          <div class="tabs">
            <a v-for="(item, idx) in sslList" :key="idx" href="javascript:" class="tab" :class="{'is-active' : sslCardFilter === item.name}" @click="sslCardFilter = item.name">
              {{ item.title }}
            </a>
          </div>
        </header>
        <!-- hrdcloud-list-wrapper -->
        <div class="hrdcloud-list-wrapper">
          <template v-if="sslLoading">
            <LoadingDiv v-model="sslLoading" />
          </template>
          <template v-else>
            <!-- board-list -->
            <template v-if="sslitems.length > 0">
              <SSLNoteCard :slides-per-view='"auto"'
                           :sslItems="sslitems"/>
            </template>
            <template v-else>
              <div class="search-container">
                <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                    src="../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p class="text">구독직원의 SSL 학습노트가 없습니다.</p>
                </div>
              </div>
            </template>
          </template>
        </div>
      </article>
    </main>


      <SSLMobileLink/>

      <SSLMobileUV/>
<!--    </template>-->
  </main>
</template>

<script>

import {getAllSubscribes, getCrseSnToDistCrseCd, sslRoutes} from '@/assets/js/modules/ssl/ssl-common';
import SSLHomeProfile from '@/components/ssl/home/SSLHomeProfile';
import SSLEvent from '@/components/ssl/home/SSLEvent';
import SSLMobileActivity from '@/components/ssl/home/mobile/SSLMobileActivity';
import SSLMobileHomeNotice from '@/components/ssl/home/mobile/SSLMobileHomeNotice';
import SSLMobileHomeStatus from '@/components/ssl/home/mobile/SSLMobileHomeStatus';
import SSLMobileLink from '@/components/ssl/home/mobile/SSMobileLLink';
import SSLMobileUV from '@/components/ssl/home/mobile/SSLMobileUV';
import SSLMobileHomeBadge from '@/components/ssl/home/mobile/SSLMobileHomeBadge';
import SSLMobileSlotHeader from '@/components/ssl/main/mobile/SSLMobileSlotHeader';
import SSLMobileNav from '@/components/ssl/mobile/SSLMobileNav';
import SSLNoteCard from "@/components/main/SSLNoteCard.vue";
// import SSLMobileBoardSearch from "@/components/ssl/home/mobile/SSLMobileBoardSearch"
import {nextTick, ref, watch} from "vue";
import {useRouter} from "vue-router";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {getItems, lengthCheck, stringCheck, timestampToDateFormat} from "@/assets/js/util";
import {
  ACT_GET_SOC_CNT,
  ACT_GET_SOC_EXCELLENT_BOARD_LIST,
  ACT_GET_SOC_SUBSCRIBE_BOARD_LIST
} from "@/store/modules/ssl/ssl-index";
import {useStore} from "vuex";

export default {
  name: 'SSLMobileHome',
  components: {
    LoadingDiv,
    SSLNoteCard,
    SSLMobileNav,
    SSLMobileSlotHeader,
    SSLMobileHomeBadge,
    SSLMobileUV,
    SSLMobileLink,
    SSLMobileHomeStatus,
    SSLMobileHomeNotice,
    SSLMobileActivity,
    SSLEvent, SSLHomeProfile,
    // SSLMobileBoardSearch
  },
  setup() {
    getAllSubscribes();
    const store = useStore();
    const isSSLSearch = ref(false);// computed(()=>store.state.ssl.isSSLSearch); //검색실행
    const searchMode = ref(false); // true:입력창 활성/ssl메뉴(hide)/ '<' 버튼 (searchMode=false) | false:true:입력창 비활성/ssl메뉴(show)/ '<' 버튼가림
    const router = useRouter();
    const keyW = ref(''),
    keywordEl = ref(null);

    const sslCardFilter = ref('excellent');
    const sslLoading = ref(false);
    const sslList = ref([
      { name: 'excellent', title: '인기', isLoading: true, items:[] },
      { name: 'subscribe', title: '구독', isLoading: true, items:[] },
    ]);
    const sslitems = ref([]);
    const excellentItems = ref([]);
    const subscribeItems = ref([]);

    // 쓸이 종료된 경우 구독 학습노트 숨김 처리
    store.dispatch(`ssl/${ACT_GET_SOC_CNT}`).then(res => {
      if(res && res.cnt === 0) {
        sslList.value[1].title = '';
      }
    });

    const getsslCardList = async (sslCardFilter) => {
      sslLoading.value = true;
      if (sslCardFilter.value === 'excellent') {
        if (!stringCheck(excellentItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
            if(lengthCheck(res)) {
              // console.log("res.value");
              // console.log(getItems(res));
              excellentItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
              }));
              // console.log("sslitems.value");
              // console.log(sslitems.value);
            }
          });
        }
        sslitems.value = excellentItems.value;
      } else if (sslCardFilter.value === 'subscribe') {
        if (!stringCheck(subscribeItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
            if(lengthCheck(res)) {
              // console.log("res.value");
              // console.log(getItems(res));
              subscribeItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
              }));
              // console.log("subscribe.value");
              // console.log(sslitems.value);
            }
          });
        }
        sslitems.value = subscribeItems.value;
      }
      sslLoading.value = false;
    };

    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter);
    });

    getsslCardList(sslCardFilter);

    const goSearch = async () => {
      if (searchMode.value){
        isSSLSearch.value = true;
        router.push({name: 'SSLBoardSearch', params: {keyword: keyW.value}})
        // await nextTick();
        // searchRes.value.getSocsBoardList();
      }else{
        searchMode.value = true;
        await nextTick();
        keywordEl.value.focus();
      }
    };

    const goBack=()=>{
      isSSLSearch.value = false;
      searchMode.value = false;
      keyW.value = '';
    }

    return {
      // getProfileImg,
      sslRoutes,
      isSSLSearch,
      goSearch,
      keywordEl,
      keyW,
      searchMode,
      goBack,
      sslCardFilter,
      sslList,
      sslitems,
      sslLoading,
    }
  }
}
</script>
