<template>
  <main class="kb-main" id="kb-tuition-support">
    <lxp-main-header
        :show-breadcrumb="true"
        :show-title="true"
        :file-down-key="guideFile" />
    <div class="main-content min-component">
      <div class="page-buttons">
        <a href="javascript:" class="kb-btn kb-btn-primary" @click="openAppPage"><span class="text">지원신청</span></a>
      </div>
      <article class="content-section">
        <header class="section-header">
          <h4 class="title">지원대상</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td><span class="td-text">지급일 현재 재직중인 직원 (일반/계약)<br>※ 지급일 현재 정직자 및 파트타이머 제외(단, 인천국제공항지점 환전매니저의 경우 외국어 분야에 한해 지원)</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 신청기한</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td><span class="td-text"><span class="fw-bolder">평가 응시일</span>로부터 <span class="fw-bolder text-red">2개월 이내</span></span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 지원절차 및 시기</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <!--              <tr>-->
              <!--                <td><span class="td-text">전산 신청일 기준 익월 15일경</span></td>-->
              <!--              </tr>-->
              <tr>
                <td class="d-flex justify-content-evenly">
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.1</div>
                    <div class="mt-lg-4 text-center">평가 응시 후<br>필요서류 준비</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.2</div>
                    <div class="mt-lg-4 text-center">스타런에서<br>신청</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.3</div>
                    <div class="mt-lg-4 text-center">인재개발부<br>심사</div>
                  </div>
                  <div style="float:left;width:100px;height:150px;padding-left: 35px;padding-top: 65px">
                    <i class="icon-arrow-next" style="background-size: 100px 40px;"></i>
                  </div>
                  <div style="float:left;border:1px solid rgba(252, 234, 194, 1); background-color: rgba(252, 234, 192, 1); border-radius:50%;width:150px;height:150px">
                    <div class="font-body16B mt-lg-4 text-center" style="color:rgba(148, 119, 31, 1)">Step.4</div>
                    <div class="mt-lg-4 text-center">급여계좌로 지급<br><span class="font-body12">(전산 신청일 기준<br><span class="fw-bolder">익월 15일</span>)</span></div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>


      <article class="content-section">
        <header class="section-header">
          <h4 class="title">지원내용</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-bordered-v2">
            <table>
              <colgroup>
                <col style="width:20%" />
                <col style="width:40%" />
                <col style="width:40%" />
              </colgroup>
              <thead>
                <tr>
                  <th><span class="th-title">지원 분야</span></th>
                  <th><span class="th-title">외국어</span></th>
                  <th><span class="th-title">IT</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span class="td-text">지원 부문</span></td>
                  <td><span class="td-text">TOEIC・ TOEFL・ JPT 등</span></td>
                  <td><span class="td-text">프로그래밍, 데이터, AI등<br>(등급자격증 外)</span></td>
                </tr>
                <tr>
                  <td><span class="td-text">지원 조건</span></td>
                  <td colspan="2"><span class="td-text">평가 응시 및 합격<sup class="text-red">주1)</sup></span></td>
                </tr>
                <tr>
                  <td><span class="td-text">지원 한도<sup class="text-red">주2)</sup></span></td>
                  <td colspan="2"><span class="td-text">연간 80만원</span></td>
                </tr>
                <tr>
                  <td><span class="td-text">지원 범위</span></td>
                  <td colspan="2"><span class="td-text">평가비 100%</span></td>
                </tr>
              </tbody>
            </table>
            <div class ="font-body14 mt-2">주1) 평가 결과가 점수(등급)로 표시되는 시험은 응시, 합격여부로 표시되는 시험은 합격인 경우에 지원</div>
            <div class ="font-body14 mt-2">주2) <span class="text-red font-body16B">연간 지원 한도 금액</span>은 <span class="fw-bolder">평가 응시일</span>을 기준으로 산정함</div>
          </div>
        </div>
      </article>

      <article class="content-section section-divider">
        <header class="section-header">
          <h4 class="title"> 필요서류</h4>
        </header>
        <div class="guide-table">
          <div class="kb-table kb-table-striped">
            <table>
              <tbody>
              <tr>
                <td>1. 평가 성적증명서(증명서 발급기관 날인 필수)</td>
              </tr>
              <tr>
                <td>
                  <div>
                    <div style="width:20%; float:left">2. 결제 영수증 </div>
                    <div style="width:80%; float:left">- 필수기재사항 : 카드번호, 승인번호, 승인일, 승인금액, 가맹점명<br>- 본인 명의 KB신용・체크카드(가족愛복지 포함) 결제분만 인정</div>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </article>

    </div>
    <SupportEvalFeeModal v-model="isEvalFeeModalActive"/>
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import SupportEvalFeeModal from '@/components/support/SupportEvalFeeModal';
// import FileDownLink from "@/components/common/FileDownLink";
import {computed, ref} from 'vue';
import {downloadGuideFiles, getClassDsjChk} from '@/assets/js/modules/support/support-common'; // , getRefundChk
import {getItems} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";

export default {
  name: 'SupportEval',
  components: {SupportEvalFeeModal, LxpMainHeader}, //, FileDownLink
  setup() {
    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'evaluation').fileKey);
    const isEvalFeeModalActive = ref(false);
    const {showMessage} = useAlert();

    const openAppPage = async() => {
      //지원가능 대상자 체크
      let chkVal = await getClassDsjChk();
      if (getItems(chkVal) > 0){
        showMessage('평가비 지원 신청 대상이 아닙니다.');
        isEvalFeeModalActive.value = false;
        return false;
      }else{
        checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 인재개발부<br>업무담당자(☎7880)에게<br>문의 부탁드립니다.`, evalFeeModalOpen );
      }
    }

    const evalFeeModalOpen = () =>{
        isEvalFeeModalActive.value=true;
    }

    return {
      guideFile,
      isEvalFeeModalActive,
      openAppPage
    }
  }
};
</script>