<template>
  <main class="kb-main" id="kb-support">
    <LxpMobileHeader title="평가비 지원신청">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
      <template v-slot:right>
        <div v-if="guideFile.length > 0 && isMainActive" class="util util-download">
          <a href="javascript:" class="util-actions util-actions-download" @click="isFileAtchListShow = true">
            첨부파일
            <i class="icon-download"></i>
          </a>
        </div>
      </template>
    </LxpMobileHeader>
    <div v-if="isMainActive" class="main-content main-component">
      <div class="support-view-container">
        <div class="page-buttons">
          <button class="kb-btn kb-btn-primary" @click="applyPrice"><span class="text">지원신청</span></button>
        </div>
        <div class="view-contents">

          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">평가비 지원안내</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <div class="title">지원대상</div>
                <div class="desc">
                  <p>지급일 현재 재직중인 직원 (일반/계약)</p>
                  <p class="sub-text">※ 지급일 현재 정직자 및 파트타이머 제외(단, 인천국제공항지점 환전매니저의 경우 외국어 분야에 한해 지원)</p>
                </div>
              </div>
            </div>
          </section>

          <section class="content-section">
            <header class="section-header">
              <h4 class="title">신청기한</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <div class="desc">
                  <span><span style="font-weight: bold">평가 응시일</span>로부터 <span class="text-red"> 2개월 이내</span></span>
                </div>
              </div>
            </div>
          </section>


          <section class="content-section">
            <header class="section-header">
              <h4 class="title">지원 절차 및 시기</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <h5 class="b-title">Step1.</h5>
                <p class="desc">평가 응시 후 필요서류 준비</p>
              </div>
              <div class="content-item">
                <h5 class="b-title">Step2.</h5>
                <p class="desc">스타런에서 신청</p>
              </div>
              <div class="content-item">
                <h5 class="b-title">Step3.</h5>
                <p class="desc">인재개발부 심사</p>
              </div>
              <div class="content-item">
                <h5 class="b-title">Step4.</h5>
                <p class="desc">급여계좌로 지급(전산 신청일 기준 <span class="text-red">익월 15일경</span>)</p>
              </div>
            </div>
          </section>

          <!-- content-section -->
          <section class="content-section">
            <header class="section-header">
              <h4 class="title">지원내용</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <h5 class="b-title">외국어</h5>
                <ul class="desc-type-title">
                  <li>
                    <h6>지원 부문</h6>
                    <p>TOEIC・ TOEFL・ JPT 등 평가</p>
                  </li>
                  <li>
                    <h6>지원 조건</h6>
                    <p>평가응시 및 합격<sub class="text-red">주1)</sub></p>
                  </li>
                  <li>
                    <h6>지원 한도<sub class="text-red">주2)</sub></h6>
                    <p>연간 80만원(IT합산)</p>
                  </li>
                  <li>
                    <h6>지원 범위</h6>
                    <p>평가비 100%</p>
                  </li>
                </ul>
              </div>
              <div class="content-item">
                <h5 class="b-title">IT</h5>
                <ul class="desc-type-title">
                  <li>
                    <h6>지원 부문</h6>
                    <p>프로그래밍, 데이터, AI등<br>(등급자격증 外)</p>
                  </li>
                  <li>
                    <h6>지원 조건</h6>
                    <p>평가응시 및 합격<sub class="text-red">주1)</sub></p>
                  </li>
                  <li>
                    <h6>지원한도<sub class="text-red">주2)</sub></h6>
                    <p>연간 80만원(외국어합산)</p>
                  </li>
                  <li>
                    <h6>지원범위</h6>
                    <p>평가비 100%</p>
                  </li>
                </ul>
              </div>
              <div class="content-item">
                <ul class="desc-context">
                  <li>
                    <p>주1 : <span class="text-red font-body16B">연간 지원 한도 금액</span>은 <span class="font-body16B">평가 응시일</span>을 기준으로 산정함</p>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <section class="content-section">
            <header class="section-header">
              <h4 class="title">필요서류</h4>
            </header>
            <div class="section-content">
              <div class="content-item">
                <div class="desc">
                  <p>1. 평가 성적증명서(증명서 발급기관 날인 필수)</p>
                  <p>2. 결제 영수증 - 필수기재사항 : 카드번호, 승인번호, 승인일, 승인금액, 가맹점명</p>
                  <p>              - 본인 명의 KB신용・체크카드(가족愛복지 포함) 결제분만 인정</p>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
    <SupportEvalFeeMobileModal v-if="!isMainActive"/>
  </main>
  <SupportMobileFileAtch v-if="isFileAtchListShow" v-model:isFileAtchListShow="isFileAtchListShow" :file-key="guideFile"/>
</template>

<script>
import {computed, ref, onMounted} from 'vue';
import {downloadGuideFiles, getClassDsjChk} from '@/assets/js/modules/support/support-common'; //, getRefundChk
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader";
import SupportMobileFileAtch from "@/views/pages/support/mobile/SupportMobileFileAtch";
import {useRouter} from "vue-router";
import {MUT_CLOSE_MAIN, MUT_SHOW_MAIN} from "@/store/modules/support/support";
import {useStore} from "vuex";
import SupportEvalFeeMobileModal from "@/components/support/mobile/SupportEvalFeeMobileModal";
import {getItems} from "@/assets/js/util";
import {useAlert} from "@/assets/js/modules/common/alert";
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";

export default {
  name: 'SupportMobileEval',
  components: {SupportEvalFeeMobileModal, SupportMobileFileAtch, LxpMobileHeader},
  setup() {
    const store = useStore();

    const router = useRouter();
    const guideFile = computed(() => downloadGuideFiles.find(x => x.page === 'mobileEval').fileKey);
    const isEvalFeeModalActive = ref(false);
    const isFileAtchListShow = ref(false);
    const isMainActive = computed(() => store.state.support.main.show);
    const {showMessage} = useAlert();

    const applyPrice = async() => {
      let chkVal = await getClassDsjChk();
      if (getItems(chkVal) > 0){
        showMessage('평가비 지원 신청 대상이 아닙니다. ');
        return false;
      }else{
        // chkVal = await getRefundChk(); // 과정미수료환입금 미처리내역 확인
        // if(getItems(chkVal) > 0){
        //   // showMessage('과정미수료 환입비용 미처리내역이 있습니다.<br>환입비용 처리 후 신청해주시기 바랍니다.');
        //   showMessage('미지급 또는 미환입 내역이 있습니다.<br>완료 후 신청해 주시기 바랍니다.');
        //   return false;
        // }else{
        //   store.commit(`support/${MUT_CLOSE_MAIN}`);
        // }
        checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 인재개발부<br>업무담당자(☎7880)에게<br>문의 부탁드립니다.`, evalModalOpen );
      }
    }

    const evalModalOpen=()=>{
        store.commit(`support/${MUT_CLOSE_MAIN}`);
    }

    const goBack = () => {
      router.go(-1);
    }

    onMounted(() => {
      store.commit(`support/${MUT_SHOW_MAIN}`);
    });

    return {
      guideFile, goBack, applyPrice, isMainActive,
      isEvalFeeModalActive,
      isFileAtchListShow
    }
  }
};
</script>