<template>
  <article v-if="key === 'book'" class="myssl-section section-myactivity">
      <header class="section-header">
        <h4 class="title">독서 루틴 <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button></h4>
        <div class="tool-tip" :class="{'is-active': tooltip}">
          <div class="tool-tip-desc is-left" style="left:80px; width: 510px;">
            나의 독서 루틴을 확인할 수 있는 지표입니다. <span style="color:rgb(255,0,0);">(수료 및 평가에 반영되지 않습니다.)</span><br>
            - 독서시간: 도서 표지 이미지 우측 하단의 '독서 타이머'를 통해 기록된 독서시간의 합입니다.<br>
            - 독서일수: 독서 타이머를 실행한 일수입니다.<br>
            - 독서메모: 독서 메모 작성건수입니다.<br>
            - 완료: 도서 표지 이미지 상단에 기록한 페이지수 기준으로 완료된 신청도서 권수입니다.<br>
<!--            진도율과 학습확인(Cold Call)을 모두 충족한 동영상<br> <span style="color:rgb(255,0,0);">학습완료건에 한해 합산</span>합니다.<br>-->
<!--            ※학습완료 시 마다 실제 재생시간을 기준으로 합산-->
          </div>
        </div>
      </header>
    <div :class="[isMobile ? 'symbol-item-wrap' : 'symbol-card-wrap']" data-content="row">
      <template v-for="(item, idx) in actItems" :key="idx">
        <SymbolBookRoutineCard :title="item.title" :type="item.type" :data="item.data" :show-detail="item.showDetail" :func="item.func" :is-mobile="isMobile" />
      </template>
    </div>
  </article>
  <section v-if="lrner == null" class="myssl-section section-myactivity">
    <header class="section-header">
      <h3 class="title">내 활동</h3>
    </header>

    <div class="myactivity-wrapper">
      <SSLMyActivityRel v-if="relActivity" :distCrseSn="distCrseSn"/>

      <article class="myactivity-box myactivity-written">
        <a href="javascript:" class="box-link"></a>
        <div class="box-icon">
          <i class="icon-written"></i>
        </div>
        <div class="box-content">
          <span class="text">{{ numberComma(socStat.boardCnt) }}{{ standardCount }}개</span>
        </div>
        <div class="box-title">
          <span class="text">내가 작성한 노트</span>
        </div>
      </article>
      <article class="myactivity-box myactivity-hit">
        <a href="javascript:" class="box-link"></a>
        <div class="box-icon">
          <i class="icon-hit"></i>
        </div>
        <div class="box-content">
          <span class="text">{{ numberComma(socStat.boardInqCnt) }}회</span>
        </div>
        <div class="box-title">
          <span class="text">내 노트 조회수</span>
        </div>
      </article>
      <article class="myactivity-box myactivity-like">
        <a href="javascript:" class="box-link"></a>
        <div class="box-icon">
          <i class="icon-like"></i>
        </div>
        <div class="box-content">
          <span class="text">{{ numberComma(socStat.likeGetCnt) }}개</span>
        </div>
        <div class="box-title">
          <span class="text">내 노트 좋아요수</span>
        </div>
      </article>
      <article class="myactivity-box myactivity-comment">
        <a href="javascript:" class="box-link"></a>
        <div class="box-icon">
          <i class="icon-comment"></i>
        </div>
        <div class="box-content">
          <span class="text">{{ numberComma(socStat.cmntReceiveCnt) }}개</span>
        </div>
        <div class="box-title">
          <span class="text">내 노트 댓글수</span>
        </div>
      </article>
      <article class="myactivity-box myactivity-share">
        <div class="box-icon">
          <i class="icon-share"></i>
        </div>
        <div class="box-content">
          <span class="text">{{ numberComma(socStat.sharedCnt) }}회</span>
        </div>
        <div class="box-title">
          <span class="text">내 노트 공유수</span>
        </div>
      </article>
    </div>
  </section>

</template>

<script>

import {getRouteLrnTrgtGrpMstSn, halfNo, sslStatusStandard} from '@/assets/js/modules/ssl/ssl-common';
import {useStore} from 'vuex';
import {computed, reactive, ref, toRefs, watch} from 'vue';
import {useRoute} from 'vue-router';
import {ACT_GET_MY_SOC_STAT} from '@/store/modules/ssl/ssl-index';
import {getCheckItem, getItem, lengthCheck, numberComma, setParams} from '@/assets/js/util';
import SSLMyActivityRel from '@/components/ssl/my/SSLMyActivityRel';
import SymbolBookRoutineCard from "@/components/history/module/SymbolBookRoutineCard.vue";
import {ACT_GET_BOOK_HISTORY_STATISTICS} from "@/store/modules/history/history";

export default {
  name: 'SSLMyActivity',
  components: {SymbolBookRoutineCard, SSLMyActivityRel},
  props: {
    focusFunc: Object,
    lrner: Object,
    distCrseSn : Number,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const {focusFunc} = toRefs(props);

    const tooltip = ref(false);
    // const relActivity = computed(() => ['group', 'knowledge'].includes(route.params.distCrseCd));
    const relActivity = computed(() => [].includes(route.params.distCrseCd));
    const isMobile = computed(() => store.state.auth.isMobile);

    const key = computed(()=>route.params.distCrseCd);

    const standardCount = computed(() => {
      let divisionKey = route.params.distCrseCd;
      if(divisionKey === 'book'){
        divisionKey = halfNo > 1 ?  divisionKey + '-second' : divisionKey + '-first';
      }
      const divisionStatusStandard = sslStatusStandard[divisionKey];
      if (divisionStatusStandard) {
        return `/${divisionStatusStandard.finish}`
      }
      return '';
    });

    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));

    const socStat = reactive({
      boardCnt: 0,
      boardInqCnt: 0,
      cmntReceiveCnt: 0,
      likeGetCnt: 0,
      sharedCnt: 0
    });

    const getMySocStat = () => {
      store.dispatch(`ssl/${ACT_GET_MY_SOC_STAT}`, {lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value})
      .then(res => {
        if(lengthCheck(res)){
          setParams(socStat, getItem(res));
        }
      })
      .catch(() => {});
    }

    watch(() => lrnTrgtGrpMstSn.value, () => {
      if(lrnTrgtGrpMstSn.value > 0){
        getMySocStat();
      }
    });

    if(lrnTrgtGrpMstSn.value > 0){
      getMySocStat();
    }

    const actItems = ref([
      { type: 'time', title: '독서시간', keys: ['bookLrnSec'], showDetail: true, data: '0', func: focusFunc.value ? focusFunc.value.openTimeHistoryModal : ''},
      { type: 'day', title: '독서일수', keys: ['bookLrnDayCnt'], showDetail: false, data: '0'},
      { type: 'memo', title: '독서메모', keys: ['bookMemoCnt'], showDetail: true, data: '0', func: focusFunc.value ? focusFunc.value.openMemoHistoryModal : '' },
      { type: 'complete', title: '완료', keys: ['bookCompleteCnt'], showDetail: false, data: '0' },
    ]);

    const getTimeFormatString = (time) => {
      let hour = parseInt(String(time / (60 * 60)));
      let min = parseInt(String((time - (hour * 60 * 60)) / 60));
      let sec = time % 60;


      let timeValue = '';

      if (hour > 0) {
        timeValue = hour + "시간 ";
      }
      if (min > 0) {
        timeValue = timeValue + min + "분 ";
      }
      timeValue = timeValue + sec + "초";
      return timeValue;
    }

    const getBookHistory = () => {

      store.dispatch(`history/${ACT_GET_BOOK_HISTORY_STATISTICS}`, {
        lrnTrgtGrpMstSn: lrnTrgtGrpMstSn.value,
        lrnerId: props.lrner ? props.lrner.lrnerId : ''
      }).then(res => {
        if (lengthCheck(res)) {
          let data = getCheckItem(res);
          actItems.value = actItems.value.map(x => {
            x.keys.forEach(key => {
              if (data[key] !== undefined) {
                if (key === 'bookLrnSec') {
                  x.data = getTimeFormatString(data[key]);
                } else if (key === 'bookLrnDayCnt') {
                  x.data = ' ' + data[key] + ' / ' + data['bookLrnTotalDayCnt'];
                } else if (key === 'bookCompleteCnt') {
                  x.data = ' ' + data[key] + ' / ' + data['bookTotalCnt'];
                } else {
                  x.data = ' ' + data[key];
                }
              }
            });
            return x;
          });
        }
      }).catch(() => {
      });
    }
    getBookHistory();


    return {
      relActivity,
      socStat,
      standardCount,
      numberComma,
      isMobile,
      actItems,
      tooltip,
      key
    }


  }
}
</script>
