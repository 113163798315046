<template>
  <img v-if="isShow" :src="profileImgUrl" @error="setDefaultImg" alt="" @click="clickImage"/>
</template>
<script>
 // import {getProfileImg} from "@/assets/js/modules/ssl/ssl-common"; getProfileImg(target.lrnerId)
import {useStore} from 'vuex';
import {ref, watch, computed} from 'vue';
import {ACT_GET_PROFILE_IMG_URL_BY_ID} from '@/store/modules/my/my';
 import {sslRoutes} from "@/assets/js/modules/ssl/ssl-common";

export default {
  name: 'SSLProfileImg',
  props: {
    target: Object,
  },
  emits: ['clickImage'],
  setup(props, {emit}) {
    const store = useStore();

    const isShow = ref(false);
    const setDefaultImg = (e) => {
      e.target.src = require('@/assets/images/profile/sample.png');
    }

    const profileImgUrl = ref(null);
    const lrnTrgtGrpMstSn = computed(()=>props.target.lrnTrgtGrpMstSn);

    const getProfileImg = () => {
      if(props.target && props.target.lrnerId) {
        const userProfileTyCdDcd = ref('2161001');

        if(sslRoutes['book'].lrnTrgtGrpMstSns[0] == lrnTrgtGrpMstSn.value){
          userProfileTyCdDcd.value = '2161002';
        }

        // console.log('lrnTrgtGrpMstSn : ' + lrnTrgtGrpMstSn.value);

        store.dispatch(`my/${ACT_GET_PROFILE_IMG_URL_BY_ID}`, {userProfileTyCdDcd: userProfileTyCdDcd.value, lrnerId:props.target.lrnerId})
        .then( res => {
          if(res.status && res.status.src) {
            profileImgUrl.value = res.status.src
          }
          isShow.value = true;
        });
      } else {
        profileImgUrl.value = null;
        isShow.value = true;
      }
    }

    const clickImage = () => {
      emit('clickImage', props.target);
    }

    watch(() => props.target.lrnerId, () => {
      getProfileImg();
    });

    watch(() => lrnTrgtGrpMstSn.value, () => {
      getProfileImg();
    });

    getProfileImg();


    return {
      isShow,
      // getProfileImg,
      profileImgUrl,
      setDefaultImg,
      clickImage
    }

  }
}
</script>
