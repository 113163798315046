<template>
  <template v-if="step === 'share'">
    <MobileGroupHeader
        title="초대하기"
        :show-back="true"
        :go-back-func="closeModal"
    />
  </template>
  <template v-else-if="step === 'search'">
    <MobileGroupHeader
        placeholder="부서명 및 직원번호 / 직원명 검색"
        :show-back="true"
        :show-search="true"
        :search-by-query="false"
        :go-back-func="closeModal"
        :active-search="true"
        v-model:keyword="userInfo"
        @update:keyword="getUserList"
    />
  </template>
  <template v-else-if="step === 'select'">
    <MobileGroupHeader
        title="초대하기"
        :show-back="true"
        :go-back-func="closeModal"
    />
  </template>
  <main class="kb-main" id="kb-hrdcloud">
    <!-- main-content -->
    <div class="main-content">
      <div>
        <template v-if="step === 'share'">
          <article class="content-section section-padding">
            <div class="kb-form-fields">
              <div class="kb-form-field field-first">
                <div class="kb-form-row">
                  <label class="kb-form-label"><span class="kb-form-label-text">초대멤버</span></label>
                </div>
                <div class="kb-form-row row-buttons">
                  <button class="kb-btn kb-btn-outline kb-btn-content-item" @click="step='search'"><span class="text text-silver">부서명 및 직원번호 / 직원명 검색</span></button>
                </div>
              </div>
            </div>
          </article>
          <div class="page-buttons">
            <button class="kb-btn kb-btn-primary" disabled>초대하기</button>
          </div>
        </template>
        <template v-if="step === 'search'">
          <article class="content-section mt-0">
            <div v-if="items && items.length > 0" class="kb-form-check-group">
              <div v-for="(item, idx) in items" class="kb-form-check" :key="idx">
                <input type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_1" v-model="checkUsers" :value="item">
                <label for="chk_01_1" class="kb-form-check-label">
                  <p class="text"><span class="text-wl">{{ item.lrnerId }}</span> {{ item.deptNm }} ・ {{ item.jbgdNm }} ・ {{ item.lrnerNm }} </p>
                </label>
              </div>
            </div>
          </article>
          <div class="page-buttons mt-0">
            <button class="kb-btn kb-btn-primary" :disabled="checkUsers.length === 0" @click="step='select'">다음</button>
          </div>
        </template>
        <template v-if="step === 'select'">
          <article class="content-section">
            <div class="kb-form-fields">
              <div class="kb-form-field field-border-0">
                <div class="kb-form-row">
                  <label class="kb-form-label"><span class="kb-form-label-text">초대멤버</span></label>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-check-group">
                    <div v-for="(chk, idx) in checkUsers"  class="kb-form-check" :key="idx">
                      <input type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_1" v-model="checkUsers" :value="chk">
                      <label for="chk_01_1" class="kb-form-check-label">
                        <p class="text">{{ chk.lrnerId }} ・ {{ chk.deptNm }} ・ {{ chk.jbgdNm }} ・ {{ chk.lrnerNm }}</p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb-form-field">
                <div class="kb-form-row">
                  <label class="kb-form-label"><span class="kb-form-label-text">초대 메시지</span></label>
                </div>
                <div class="kb-form-row">
                  <textarea v-model="notiCn" class="kb-form-control" placeholder="초대 메시지를 입력해주세요."></textarea>
                </div>
              </div>
            </div>
          </article>
          <div class="page-buttons mt-0">
            <button class="kb-btn kb-btn-primary" :disabled="checkUsers.length < 1" @click.stop="clickShare">초대하기</button>
          </div>
        </template>
      </div>
    </div>
  </main>

</template>

<script>
import {computed, reactive, ref} from "vue";
import {ACT_GET_USERS_LIST} from "@/store/modules/user/user";
import {compareArrays, getItems, isSuccess, lengthCheck, setPaging} from "@/assets/js/util";
import {useStore} from "vuex";
import {notiPfmCds} from "@/assets/js/modules/noti/noti-common";
import {useAlert} from "@/assets/js/modules/common/alert";
import MobileGroupHeader from "@/components/prep/group/mobile/MobileGroupHeader";
import {ACT_INVITE_GROUP_MEMBER} from "@/store/modules/course/course";


export default {
  name: "MobileGroupMemberInvite",
  components: {MobileGroupHeader},
  props: {
    modelValue: Boolean,
    groupInfo: Object
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const {showConfirm, showMessage} = useAlert();
    const session = computed(() => store.state.auth.session);
    const step = ref('share'),
        userInfo = ref(''),
        notiCn = ref(''),
        items = ref([]),
        checkUsers = ref([]),
        paging = reactive({pageNo: 1, pageSize: 99999, totalCount: 0, hasMore: false}),
        notiPfmCd = ref([notiPfmCds.liiv , notiPfmCds.wiseNet])
    ;

    const notiParams = reactive({
      notiMngSn: 0,
      notiTitle: '그룹러닝 초대',
      notiSbjt: '',
      notiDesc: '그룹 초대',
      sndrNm: '인재개발부',
      notiTrprCdDcd : '2039001',
      notiTyCdDcd: '2032001', //'2032002',
      retrInclYn: 'N', // 퇴직자 포함 여부
      rsvtTrsmYn: 'N', // 예약발송여부
      rsvtTrsmDt: 0,
      aprvrId: '',
      aprvrNm: '',

      smsNotiYn: 'Y',
      insdMemoYn: 'Y',
      pushNotiYn: 'N',
      wiseEmlNotiYn: 'N',
      emlNotiYn: 'N',
      kakaoNotiYn: 'N',
      chatBotNotiYn: 'N',
      liivNotiYn: 'Y',
      trnNotiYn: 'N',
      tmplYn: 'N',
      stt: '00',
      division: 'groupLrn' ,
      channelName: 'study',
      imgUrl: '',
      moveUrl:'',
      distCrseSn: 0,
      lrnTrgtGrpMstSn: 0,
      lrnTrgtGrpDtlSn: 0,
      notiMsgDomains : [],
      notiTrgtDomains: []
    });

    const checkAll = computed(() => {
      let cnt = 0;
      items.value.map(item => {
        checkUsers.value.filter(user => {
          if (item.lrnerId === user.lrnerId) cnt++;
        });
      });

      return cnt === items.value.length;
    });

    const clickCheckAll = () => {
      items.value.map(x => {
        if (checkUsers.value < 10 && checkUsers.value.filter(y => x.lrnerId === y.lrnerId).length === 0) checkUsers.value.push(x);
      });
    };

    const clickUnCheckAll = () => {
      checkUsers.value = [];
    };

    const unCheck = (idx) => {
      checkUsers.value.splice(idx, 1);
    };

    const getUserList = () => {
      if (userInfo.value.length === 0) {
        showMessage('부서명 및 직원번호/직원명을 입력해주세요.');
        return;
      }

      store.dispatch(`auth/${ACT_GET_USERS_LIST}`, {
        userInfo: userInfo.value,
        pageNo: paging.pageNo,
        pageSize: paging.pageSize
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res).filter(x => x.lrnerId !== session.value.lrnerId);
          setPaging(paging, res);
        } else {
          items.value = [];
          paging.totalCount = 0;
        }
      }).catch(e => {
        items.value = [];
        console.error(e);
      });
    };

    const validate = () => {
     if(!notiCn.value) {
        showMessage('초대메시지를 입력해주세요.');
        return false;
      } else{
        return true;
      }
    };

    const clickShare = () => {
      if(validate()) {
        if (checkUsers.value.length > 0) {
          checkUsers.value.forEach(item => {
            notiParams.notiTrgtDomains.push({
              trprId: item.lrnerId,
              trprSn: item.userSn,
              trprNm: item.lrnerNm,
              trprTelno: item.mblTelno
            });
          });
        }

        if (notiPfmCd.value.length > 0) {
          notiPfmCd.value.forEach(x => {
            const lineBreak = x === notiPfmCds.wiseNet ? '<br/>' : '\\n';
            const customNotiCn =  `[그룹러닝 초대] ${props.groupInfo.leaderNm}(${props.groupInfo.leaderDeptNm})님의 그룹에 초대합니다.${lineBreak}${lineBreak}- 그룹명: ${props.groupInfo.crseNm} ${lineBreak}- 초대 메시지: ${notiCn.value}`;
            notiParams.notiMsgDomains.push({
              notiTitle: x === notiPfmCds.wiseNet ? '[그룹러닝] 그룹 초대' : notiParams.notiTitle,
              notiPfmCdDcd: x,
              notiCn: customNotiCn
            })
          });
        }

        notiParams.imgUrl = `${process.env.VUE_APP_CLOUDFRONT_URL}/contents/group/GettyImages-1454136712.jpg`;
        notiParams.distCrseSn = props.groupInfo.distCrseSn;
        notiParams.lrnTrgtGrpMstSn = props.groupInfo.lrnTrgtGrpMstSn;

        if (notiParams.notiTrgtDomains.length > 0 && notiPfmCd.value.length > 0) {
          showConfirm(`선택하신 멤버에게 리브똑똑과 메신저 쪽지로<br/>초대 메시지를 발송 하시겠습니까?<br/><span style="color: red">(그룹 비밀번호가 있는 경우 초대 문구에 작성해주세요.)</span>`,() => {
              store.dispatch(`course/${ACT_INVITE_GROUP_MEMBER}`, notiParams).then(res => {
                if (isSuccess(res)) {
                  showMessage({
                    text: '그룹 초대가 완료되었습니다.',
                    callback: () => {
                      step.value = 'success';
                      closeModal();
                    }
                  })
                } else {
                  showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
                }
              });
            },
            () => {
              notiParams.notiMsgDomains = [];
              notiParams.notiTrgtDomains = [];
            })
        }
      }
    };

    const closeModal = () => {
      if (step.value === 'search') {
        step.value = 'share';
      } else if (step.value === 'select') {
        step.value = 'search';
      } else {
        step.value = 'share';
        emit('update:modelValue', false);
      }
    };

    return {
      step,
      notiPfmCds, notiPfmCd, notiParams, notiCn,
      userInfo, items, paging, checkAll, checkUsers,
      unCheck, getUserList,
      clickCheckAll, clickUnCheckAll,
      clickShare, closeModal, compareArrays
    }
  }
}
</script>
<style>
.section-content.member-scroll {
  height: 300px; overflow:scroll;
}
</style>