<template>
  <!-- page-container -->
  <div v-if="isReady" class="page-container">
    <div class="page-top">
      <div class="page-component">
        <div class="top-nav">
          <router-link :to="{name: 'SSLMain'}" class="top-kb-nav-link" v-if="filters.year == currYear && filters.distCrseSn">
            <span class="ic-bg"><i class="icon-type-back"></i></span>
            <span class="text">{{ distCrseNm }} 홈</span>
          </router-link>
        </div>
        <div class="top-links">
          <SSLTrial
              trial-class="top-link"
              text-class="text"
              :show-arrow="true"
          />
          <SSLManual
              manual-class="top-link"
              text-class="text"
              :show-arrow="true"
          />
        </div>
      </div>
    </div>

    <div class="page-body">

      <div class="myssl-container">
        <SSLMyTop :show-write="filters.year == currYear" :year="filters.year">
          <template v-slot:year-subj>
            <div class="my-category board-list-container">
              <!-- board-list-top -->
              <div class="board-list-top">
                <div class="top-column">
                  <SortButtonSelect
                      v-model="filters.year"
                      v-model:toggle="selToggles.year"
                      title="년도"
                      :options="yearOptions"
                      sequence-key="value"
                      name-key="display"
                      :is-num="true"
                      :is-all="false"
                      :textStyle="`font:normal 20px/25px var(--kb-font-KBFGDisplayB)`"
                      @update:toggle="closeToggles(selToggles, 'year')"
                      @selected="initPageYear()"
                  />
                </div>
                <div class="top-column ml_20">
                  <SortButtonSelect
                      v-model="filters.distCrseSn"
                      v-model:toggle="selToggles.distCrseSn"
                      title="과정"
                      :options="distOptions"
                      sequence-key="distCrseSn"
                      name-key="crseNm"
                      :is-all="true"
                      :width="300"
                      :textStyle="`font:normal 20px/25px var(--kb-font-KBFGDisplayB)`"
                      @update:toggle="closeToggles(selToggles, 'distCrseSn')"
                      @selected="initPageSubj()"
                  />
                </div>
              </div>
            </div>
          </template>
        </SSLMyTop>
      </div>
      <SSLMyStatus v-if="viewRoute === 'status'"  :distCrseSn=filters.distCrseSn />
      <SSLMyBoard
          v-else-if="['complete', 'temporary', 'save'].includes(viewRoute)"
          :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
      />
      <SSLMySubscribe
          v-else-if="['subscribe', 'subscribed'].includes(viewRoute)"
          :lrn-trgt-grp-mst-sn="lrnTrgtGrpMstSn"
      />
    </div>
    <!-- //page-footer -->
  </div>
  <!-- //page-container -->

</template>

<script>
import SSLManual from '@/components/ssl/common/SSLManual';
import SSLTrial from '@/components/ssl/common/SSLTrial';
import {sslMySetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';
import SSLMyStatus from '@/components/ssl/my/SSLMyStatus';
import SSLMyBoard from '@/components/ssl/my/SSLMyBoard';
import SSLMySubscribe from '@/components/ssl/my/SSLMySubscribe';
import SSLMyTop from "@/components/ssl/my/SSLMyTop.vue";
import SortButtonSelect from "@/components/common/SortButtonSelect.vue";

export default {
  name: 'SSLMy',
  components: {
    SSLMyTop,
    SSLMySubscribe,
    SSLMyBoard,
    SSLMyStatus,
    SSLTrial,
    SSLManual,
    SortButtonSelect,
  },
  setup: sslMySetup
}
</script>
