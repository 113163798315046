import store from '@/store';
import {computed} from 'vue';
import {createRouter, createWebHistory} from 'vue-router';
import {getToken} from '@/assets/js/localstorage.service';
import {
  clearTargetTimeout,
  getCheckItems,
  getItem,
  lengthCheck,
} from '@/assets/js/util';
import {goToExpired, goToSignin} from '@/assets/js/modules/common/auth';
import {
  ACT_GET_ROLE_MENU,
  MUT_SET_MENUS,
} from '@/store/modules/menu/menu';
import {
  ACT_GET_AUTH,
  ACT_RENEW_AUTH,
  MUT_SET_EXPTIME,
  MUT_SET_SESSION,
  MUT_SET_SESSION_ITN,
  TOKEN_KEY,
} from '@/store/modules/auth/auth';
import {setLxpRoutes} from '@/router/modules/lxp';
import {setTrainRoutes} from '@/router/modules/train';
import {setLearnRoutes} from '@/router/modules/learn';
import {setHelpRoutes} from '@/router/modules/help';
import {setApplyRoutes} from '@/router/modules/apply';
import {setHrdcloudRoutes} from '@/router/modules/hrdcloud';
import {setDailyRoutes} from '@/router/modules/daily';
import {setNotificationRoutes} from '@/router/modules/notification';
import {setHistoryRoutes} from '@/router/modules/history';
import {setMyRoutes} from '@/router/modules/my';
import {setPrepRoutes} from '@/router/modules/prep';
import {setSupportRoutes} from '@/router/modules/support';
import {setEvaluationRoutes} from '@/router/modules/evaluation';
import {setSSLRoutes} from '@/router/modules/ssl';
import {setLiveRoutes} from '@/router/modules/live';
import {
  getRouteLrnTrgtGrp,
  insertSocActPv,
} from '@/assets/js/modules/ssl/ssl-common';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
import {setDevRoutes} from '@/router/modules/dev';
import {insertHrdUvCrseAct} from '@/assets/js/modules/hrd/hrd-common';
import {setJuniorRoutes} from '@/router/modules/junior';
import {setTakeRoutes} from '@/router/modules/take';
import {setErrorRoutes} from '@/router/modules/error';
import {setDeptRoutes} from '@/router/modules/dept';
import {setSurveyRoutes} from '@/router/modules/survey';
import {setBridgeRoutes} from '@/router/modules/bridge';
import {MUT_SET_FROM_QUERY} from '@/store/modules/common/common-index';
import {setFcplRoutes} from '@/router/modules/fcpl';
import {setLinkRoutes} from "@/router/modules/link";
import {setBoardRoutes} from '@/router/modules/board';
import {setWiseRoutes} from '@/router/modules/wise';
import {setSumgoRoutes} from '@/router/modules/sumgo';
import {setWMPortfolioRoutes} from '@/router/modules/wmportfolio';
import {setBpccRoutes} from '@/router/modules/bpcc';
import {setEventRoutes} from '@/router/modules/event';
import {setMngRoutes} from "@/router/modules/mng";
import {setRemindRoutes} from '@/router/modules/remind';
import {setUniSearchRoutes} from "@/router/modules/uniSearch";

const sessionExpiredTime = computed(() => store.state.auth.sessionExpiredTime);

const routes = [
  {
    path: '',
    redirect: '/main',
  },
  {
    path: '/',
    redirect: '/main',
    component: () => import('../views/layout/BlankLayout'),
    children: [
      {
        path: 'sso',
        name: 'SSO',
        component: () => import('@/views/auth/SSO'),
      },
      {
        path: 'sso-with',
        name: 'SSOWith',
        component: () => import('@/views/auth/SSOWith'),
      },
      {
        path: 'sso-cp',
        name: 'SSOCp',
        component: () => import('@/views/auth/SSOCp'),
      },
      {
        path: 'test-journey',
        name: 'TestJourney',
        component: () => import('@/views/pages/test/TestJourney'),
      },
      {
        path: 'test-auth',
        name: 'TestAuth',
        component: () => import('@/views/pages/test/TestAuth'),
      },
      {
        path: "t/:sUrl",
        name: "ModalSSO",
        component: () => import("@/views/modal/ModalSSO")
      },
    ],
  },
  {
    path: '/',
    component: () => import('../views/layout/auth/AuthLayout'),
    children: [
      {
        path: 'signin',
        name: 'Signin',
        component: () => import('@/views/auth/Signin'),
      },
      {
        path: 'SigninDirect',
        name: 'SigninDirect',
        component: () => import('@/views/auth/SigninDirect'),
      },
      {
        path: 'goadmin',
        name: 'goadmin',
        component: () => import('@/views/pages/GoAdmin'),
      },
      {
        path: 'reissue',
        name: 'PasswordReissue',
        component: () => import('@/views/auth/PasswordReissue'),
      },
      {
        path: 'ehrd/install',
        name: 'EhrdAppInstall',
        component: () => import('@/views/auth/EhrdAppInstall')
      }
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/notFound',
  }
];

setLxpRoutes(routes);
setBridgeRoutes(routes);
setDevRoutes(routes);
setTrainRoutes(routes);
setLearnRoutes(routes);
setApplyRoutes(routes);
setHrdcloudRoutes(routes);
setDailyRoutes(routes);
setHelpRoutes(routes);
setNotificationRoutes(routes);
setHistoryRoutes(routes);
setMyRoutes(routes);
setPrepRoutes(routes);
setSupportRoutes(routes);
setEvaluationRoutes(routes);
setJuniorRoutes(routes);
setSSLRoutes(routes);
setTakeRoutes(routes);
setLiveRoutes(routes);
setErrorRoutes(routes);
setDeptRoutes(routes);
setSurveyRoutes(routes);
setFcplRoutes(routes);
setLinkRoutes(routes);
setBoardRoutes(routes);
setWiseRoutes(routes);
setSumgoRoutes(routes);
setWMPortfolioRoutes(routes);
setBpccRoutes(routes);
setMngRoutes(routes);
setEventRoutes(routes);
setRemindRoutes(routes);
setUniSearchRoutes(routes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const passNames = ['SSO', 'Signin', 'SigninDirect', 'Login', 'admLogin', 'Search', 'PasswordReissue', 'EhrdAppInstall', 'ModalSSO', 'BridgeSSO', 'SessionExpired','SSOWith', 'SSOCp'];
let renewDebounce = 0;

// const getQueryStringObject = () => {
//   var a = window.location.search.substr(1).split('&');
//   if (a == "") return {};
//   var b = {};
//   for (var i = 0; i < a.length; ++i) {
//     var p = a[i].split('=', 2);
//     if (p.length == 1)
//       b[p[0]] = "";
//     else
//       b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
//   }
//   return b;
// }


const scrollUpNext = (to, next) => {
  const isHrd = (to.name && to.name.indexOf('HrdCloud') === 0);
  const isSSL = (to.name && to.name.indexOf('SSL') === 0);

  if(isSSL){
    if (to.name === 'SSLHome' || to.name === 'SSLNotice' || to.name === 'SSLNoticeView') {
      insertSocActPv(actTyCdDcds.ACT_TY_CD_LEARN_HOME,{lrnTrgtGrpMstSn: 0, lrnTrgtGrpNm: null});
    } else {
      // 존재할 경우
      const lrnTrgtGrp = getRouteLrnTrgtGrp(to.params.lrnTrgtGrpMstSn);
      if (lrnTrgtGrp.lrnTrgtGrpMstSn > 0) {
        insertSocActPv(actTyCdDcds.ACT_TY_CD_NOTE_HOME, lrnTrgtGrp);
      }
    }
  }

  if(isHrd) {
    insertHrdUvCrseAct();
  }

  // 세션 갱신...
  clearTargetTimeout(renewDebounce);
  renewDebounce = setTimeout(() => {store.dispatch(`auth/${ACT_RENEW_AUTH}`).then(() => {});}, 500);
  next();
  // if(isSSL && to.name !== 'SSLMain') {
  //   window.scrollTo(0, 0);
  // }
};

router.beforeEach(async (to, from, next) => {
  if (from && from.name) {
    store.commit(`common/${MUT_SET_FROM_QUERY}`, from.query);
  }

  // SSL 은 스크롤 처리 안함
  if(to.name && !(to.name.indexOf('SSL') === 0)){
    window.scrollTo(0, 0);
  }

  if (passNames.includes(to.name)) {
    next();
  }else {
    const authToken = getToken(TOKEN_KEY);
    if (authToken) {
      if (!store.state.auth.session.lrnerId || sessionExpiredTime.value < new Date().getTime()) {
        const res = await store.dispatch(`auth/${ACT_GET_AUTH}`);
        if (lengthCheck(res)) {
          const auth = getItem(res);
          await store.commit(`auth/${MUT_SET_SESSION}`, auth);
          // 허용된 IP 체크후 itn 이 false 이면
          if(!store.state.auth.session.itn){
            //itn check
            //console.log("itn check...", auth, store.state.auth.session.itn);
            // 로컬스토리지 inside(내부망 체크)
            if('1' === getToken('kbInside') || process.env.NODE_ENV =="local"){
              await store.commit(`auth/${MUT_SET_SESSION_ITN}`, auth);
            }
          }
          await store.commit(`auth/${MUT_SET_EXPTIME}`, -1);

          // LXP 메뉴 처리
          const menuRes = await store.dispatch(`menu/${ACT_GET_ROLE_MENU}`,{stt: '00'});
          store.commit(`menu/${MUT_SET_MENUS}`, getCheckItems(menuRes));

          //로그인 했을 때 휴직자인 경우 휴직자 복직지원 페이지로 보냄.
          // if(from.name === 'Signin') {
          //   if(from.fullPath && from.fullPath.indexOf('redirect') > 0){
          //     const pathName = window.location.pathname.replace('/app', '');
          //     const queryStr = window.location.search ? JSON.stringify(getQueryStringObject(window.location.search)) : '';
          //     from.query = {'redirect': pathName , 'q' : window.encodeURIComponent(queryStr)};
          //     await router.push(from);
          //   }else {
          // 만약 로그인했을때 첫 페이지를 셋팅하고 싶을 때 아래 주석 제거
          //     const firstPageRes = await store.dispatch(`menu/${ACT_GET_FIRST_PAGE}`);
          //     if(lengthCheck(firstPageRes)){
          //       const firstPageUrl = getItem(firstPageRes).frstPageUrl;
          //       await router.push({path: firstPageUrl});
          //     }
          //   }
          // }

          scrollUpNext(to, next);
        } else {
          if (from && from.name) {
            goToExpired();
          } else {
            goToSignin(next);
          }
        }

      } else {
        scrollUpNext(to, next);
      }
    } else {
      if (from && from.name) {
        goToExpired();
      } else {
        goToSignin(next);
      }
    }
  }

});

export default router;
