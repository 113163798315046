export const intoNote = (tryCount, keySn) => {
    if(tryCount > 10) return;
    setTimeout(() => {
        const target = document.getElementsByClassName(`ssl-note-${keySn}`);
        if(target.length > 0){
            target[0].scrollIntoView();
        }else{
            tryCount++;
            intoNote(tryCount, keySn);
        }
    }, 500);
}

export const getMainId = (path) => {
    if(path.includes('selfGa')){
        return 'ssl-selfGa-learning';
    }
    else if(path.includes('selfGi')){
        return 'ssl-selfGi-learning';
    }
    else if(path.includes('selfJa')){
        return 'ssl-selfJa-learning';
    }
    else if(path.includes('selfIct')){
        return 'ssl-selfIct-learning';
    }
    else if(path.includes('selfDvl')){
        return 'ssl-selfDvl-learning';
    }
    else if(path.includes('group')){
        return 'ssl-group-learning';
    }
    else if(path.includes('knowledge')){
        return 'ssl-e-learning';
    }
    else if(path.includes('hrd')){
        return 'ssl-hrd-learning';
    }
    else if(path.includes('book')){
        return 'ssl-book-learning';
    }
    else if(path.includes('trivia')){
        return 'ssl-alssl-learning';
    }
    else if(path.includes('college')){
        return 'ssl-college-learning';
    }
    else if(path.includes('live')){
        return 'ssl-review-learning';
    }
    else if (path.includes('bestNote')){
        return 'ssl-bestNote';
    }
    return '';
}

export const mainViewModes = [
    {mode: 'new', tag: '최신', params: {name: 'SSLMain', view: 'new'}},
    {mode: 'weekly', tag: '주간', params: {name: 'SSLMain', view: 'weekly'}},
    {mode: 'monthly', tag: '월간', params: {name: 'SSLMain', view: 'monthly'}},
    {mode: 'mybook', tag: '내가 신청한 도서', params: {name: 'SSLMain', view: 'mybook'}},
    // {mode: 'yearly', tag: '연간', params: {name: 'SSLMain', view: 'yearly'}},
];

export const getVideModeIndex = (view) => {
    if(view === 'weekly'){
        return 1;
    }else if(view === 'monthly'){
        return 2;
    }else if(view === 'mybook'){
    // }else if(view === 'yearly'){
        return 3;
    }
    return 0;
}
