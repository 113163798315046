<template>
  <div ref="mysslTop" class="myssl-top my-profile">
    <div class="top-my">
      <div class="my-avatar">
        <div class="avatar">
          <SSLProfileImg :target="lrner"/>
        </div>
      </div>
      <div class="my-title">
        <h2 class="title">
          <img src="@/assets/lxp/images/ssl/myssl/ic_myssl.svg" alt/>
        </h2>
      </div>

      <slot name="year-subj"></slot>
<!--      <div class="my-category" :class="categoryClass">-->
<!--        <span v-for="(category, index) in categories" class="category" :key="index">{{ category }}</span>-->
<!--      </div>-->

      <div class="my-name">
        <span class="name" style="margin-right: 5px">{{ lrner.lrnerNm }}</span>
        <span class="name text-muted">{{ distCrseCd === 'group' ? myGroup.crseNm : lrner.deptNm }}</span>
      </div>

      <div v-if="distCrseCd === 'group'" class="page-intro page-intro-black">
        <div class="intro-content" style="z-index:0 !important;">
          <div class="intro-buttons">
            <router-link :to="{name: 'SSLMy', params: {distCrseCd: 'group', lrnTrgtGrpMstSn: 115617}}" class="link">
              <button class="kb-btn-intro"><span class="kb-btn-border" style="left:unset !important;"></span><i class="icon-my"></i><span class="text">SSL</span></button>
            </router-link>
            <button class="kb-btn-intro" @click="goGroup"><span class="kb-btn-border" style="left:unset !important;"></span><i class="icon-my"></i><span class="text">그룹</span></button>
          </div>
        </div>
      </div>

      <div v-if="distCrseCd === 'knowledge'" class="page-intro page-intro-black">
        <div class="intro-content">
          <div class="intro-buttons">
            <router-link :to="{name: 'SSLMy', params: {distCrseCd: 'knowledge', lrnTrgtGrpMstSn: 112121}}" class="link">
              <button class="kb-btn-intro"><span class="kb-btn-border" style="left:unset !important;"></span><i class="icon-my"></i><span class="text">SSL</span></button>
            </router-link>
            <button class="kb-btn-intro" @click="goKnowledge"><span class="kb-btn-border" style="left:unset !important;"></span><i class="icon-my"></i><span class="text">Test</span></button>
          </div>
        </div>
      </div>

      <div v-if="distCrseCd === 'book'" class="mt-3">
        <button class="link" @click="showMyPortraitModal"><span class="text text-gold" style="font:var(--kb-display16-font)">프로필 설정</span><i class="icon-arrow20-right-gold"/></button>
      </div>

    </div>
  </div>
</template>

<script>
import SSLProfileImg from '@/components/ssl/common/SSLProfileImg';
import {sslMyTopSetup} from '@/assets/js/modules/ssl/setup/ssl-my-setup';

export default {
  name: 'SSLMobileMyTop',
  components: {SSLProfileImg},
  props: {
    childDistCrseCd: {
      type: String,
      required: false
    }
  },
  setup: sslMyTopSetup
}
</script>
