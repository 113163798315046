<template>
  <div class="popup-container is-active"  :class="{'is-active': modelValue}">
    <div class="popup" id="popup-hrdcolud-h0205">
      <div class="popup-inner">
        <div>
          <header class="popup-header">
            <h3 class="title">강사 추천</h3>
          </header>
          <div class="popup-content">
            <div class="member-search-container">
              <div class="kb-form-fields field-01">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">부점/부서 </span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-search-field">
                      <input v-model="search.deptNm" type="text" class="kb-form-search-input" placeholder="부점 및 부서명 검색" @keyup.enter="clickSearch" />
                      <button class="kb-form-search-btn"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">직원</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-search-field">
                      <input v-model="search.lrnerNm" type="text" class="kb-form-search-input" placeholder="직원명 및 직원번호 검색" @keyup.enter="clickSearch" />
                      <button class="kb-form-search-btn"><i class="icon-search"></i></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kb-form-buttons">
                <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSearch"><span class="text">검색</span></button>
              </div>
              <div v-if="paging.totalCount > 0" class="search-results">
                <section class="popup-section">
                  <header class="section-header">
                    <h4 class="title">검색결과 <strong class="text text-muted">{{ paging.totalCount }}</strong></h4>
                  </header>
                  <div class="section-content">
                    <!-- TODO::스타일 추후 제거 -->
                    <div class="kb-table kb-table-striped member-tablelist" style="height: 300px; overflow:scroll;">
                      <table>
                        <colgroup>
                          <col style="width:auto;" />
                          <col style="width:auto;" />
                          <col style="width:auto;" />
                          <col style="width:auto;" />
                          <col style="width:160px;" />
                        </colgroup>
                        <thead>
                        <tr>
                          <th><span class="th-title">지점코드/지점명</span></th>
                          <th><span class="th-title">직원명</span></th>
                          <th><span class="th-title">직원번호</span></th>
                          <th><span class="th-title">직급</span></th>
                          <th class="text-center">
                            <span class="th-title">선택</span>
                          </th>
                        </tr>
                        </thead>
                        <tbody v-if="items && items.length > 0">
                        <tr v-for="(item, idx) in items" :key="`tr-${idx}`">
                          <td><strong class="td-text">{{ item.deptCd || item.deptNm ? `${item.deptCd || '-'} / ${item.deptNm || '-'}` : '-' }}</strong></td>
                          <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                          <td><strong class="td-text">{{ item.lrnerId }}</strong></td>
                          <td><strong class="td-text">{{ `${item.jbgdNm || '-'}` }}</strong></td>
                          <td class="text-center">
                            <div class="kb-form-check">
                              <input type="checkbox" class="kb-form-check-input" name="chkMember"
                                     v-model="checkUsers"
                                     :value="item"
                                     @click.stop="checkLength"
                              />
                            </div>
                          </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                        <tr>
                          <td><span class="td-text text-muted">검색 결과가 없습니다.</span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section class="popup-section">
                  <header class="section-header">
                    <h4 class="title">추천대상 <strong v-if="checkUsers && checkUsers.length > 0" class="text text-muted">{{ checkUsers.length }}</strong></h4>
                  </header>
                  <div class="section-content">
                    <div class="kb-table kb-table-striped member-tablelist" style="height: 300px; overflow:scroll;">
                      <table>
                        <template v-if="checkUsers && checkUsers.length > 0">
                          <colgroup>
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:auto;" />
                            <col style="width:160px;" />
                          </colgroup>
                          <thead>
                          <tr>
                            <th><span class="th-title">지점코드/지점명</span></th>
                            <th><span class="th-title">직원명</span></th>
                            <th><span class="th-title">직원번호</span></th>
                            <th><span class="th-title">직급</span></th>
                            <th class="text-center">
                              <span class="th-title">해제</span>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(chk, idx) in checkUsers" :key="`tr-${idx}`">
                            <td><strong class="td-text">{{ chk.deptCd || chk.deptNm ? `${chk.deptCd || '-'} / ${chk.deptNm || '-'}` : '-' }}</strong></td>
                            <td><strong class="td-text">{{ chk.lrnerNm }}</strong></td>
                            <td><strong class="td-text">{{ chk.lrnerId }}</strong></td>
                            <td><strong class="td-text">{{ `${chk.jbgdNm || '-'}` }}</strong></td>
                            <td class="text-center">
                              <div class="kb-form-check">
                                <input type="checkbox" class="kb-form-check-input" name="chkMember" :checked="true" @click.prevent.stop="unCheck(idx)"/>
                              </div>
                            </td>
                          </tr>
                          </tbody>
                        </template>
                        <tbody v-else>
                        <tr>
                          <td><span class="td-text text-muted">공유 대상이 없습니다.</span></td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>

                <section class="popup-section">
                  <header class="section-header">
                    <h4 class="title">추천사유</h4>
                  </header>
                  <div class="section-content">
                    <div class="segment-box">
                      <textarea v-model="param.inqCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="추천 사유를 입력해주세요."></textarea>
                    </div>
                  </div>
                </section>

<!--                <section class="popup-section">-->
<!--                  <header class="section-header">-->
<!--                    <h4 class="title">공유방법</h4>-->
<!--                  </header>-->
<!--                  <div class="section-content">-->
<!--                    <div class="kb-form-fields">-->
<!--                      <div class="kb-form-row">-->
<!--                        <div class="kb-form-column kb-form-column-title">-->
<!--                          <label class="kb-form-label">-->
<!--                            <strong class="kb-form-label-text">구분</strong>-->
<!--                          </label>-->
<!--                        </div>-->
<!--                        <div class="kb-form-column">-->
<!--                          <div class="kb-form-group">-->
<!--                            <div class="kb-form-check kb-form-check-inline">-->
<!--                              <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_01" :value="notiPfmCds.liiv" />-->
<!--                              <label for="chk_01_01" class="kb-form-check-label"><span class="text">리브똑똑</span></label>-->
<!--                            </div>-->
<!--                            <div class="kb-form-check kb-form-check-inline">-->
<!--                              <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_02" :value="notiPfmCds.wiseNet" />-->
<!--                              <label for="chk_01_02" class="kb-form-check-label"><span class="text">메신저 쪽지</span></label>-->
<!--                            </div>-->
<!--                            <div class="kb-form-check kb-form-check-inline">-->
<!--                              <input v-model="notiPfmCd" type="checkbox" class="kb-form-check-input" name="chk_01" id="chk_01_03" :value="notiPfmCds.wiseEml" />-->
<!--                              <label for="chk_01_03" class="kb-form-check-label"><span class="text">내부메일</span></label>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </section>-->
              </div>
            </div>
          </div>
          <div v-if="paging.totalCount > 0" class="popup-buttons">
            <a v-if="checkUsers.length < 1" href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" disabled><span class="text">추천하기</span></a>
            <a v-else href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click.stop="clickBoardAdd"><span class="text">추천하기</span></a>
          </div>
        </div>
      </div>

      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref, toRefs} from 'vue';
import {useStore} from 'vuex';
import {ACT_GET_USERS_LIST} from '@/store/modules/user/user';
import {compareArrays, getItems, getPaging, initParams, isSuccess, lengthCheck} from '@/assets/js/util';
import {notiPfmCds} from '@/assets/js/modules/noti/noti-common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {insertCourseAct} from '@/assets/js/modules/course/course-common';
import {actTyCdDcds} from '@/assets/js/modules/common/common';
// import store from "@/store";
import {ACT_INSERT_LRN_BOARD} from "@/store/modules/board/board";
import {cttTableNm} from "@/assets/js/modules/hrd/hrd-common";

export default {
  name: 'HrdCloudShareModal',
  props: {
    modelValue: Boolean,
    cttMstSn: Number,
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const store = useStore();
    const {showConfirm, showMessage} = useAlert();
    const {cttMstSn} = toRefs(props);

    const search = reactive({
      lrnerNm : '',
      deptNm: ''
    });

    const notiParams = reactive({
      notiMngSn: 0,
      notiTitle: '[HRD 클라우드] 콘텐츠 공유',
      notiSbjt: '',
      notiDesc: '강사 추천',
      sndrNm: '인재개발부',
      notiTrprCdDcd : '2039001',
      notiTyCdDcd: '2032001', //'2032002',
      retrInclYn: 'N', // 퇴직자 포함 여부
      rsvtTrsmYn: 'N', // 예약발송여부
      rsvtTrsmDt: 0,
      aprvrId: '',
      aprvrNm: '',
      smsNotiYn: 'Y',
      insdMemoYn: 'Y',
      pushNotiYn: 'N',
      wiseEmlNotiYn: 'Y',
      emlNotiYn: 'N',
      kakaoNotiYn: 'N',
      chatBotNotiYn: 'N',
      liivNotiYn: 'Y',
      trnNotiYn: 'N',
      tmplYn: 'N',
      stt: '00',
      division: 'HrdCloud',
      channelName: 'ehrd',
      lrnObjDtlDistSn: 0,
      notiMsgDomains : [],
      notiTrgtDomains: [],
    });

    const crseActParam = reactive({
      crseMstSn: 0,
      distCrseSn: 0,
      distCrseNm: '',
      distChapSn: 0,
      distChapNm: '',
      lrnObjDtlDistSn: 0,
      objSn: 0,
      objNm: '',
      shareTrgtLrnerIdLst: '',
      shareTrgtLrnerNmLst: '',
    });

    const notiCn = ref('');
    const items = ref([]);
    const paging = ref({pageNo: 1, pageSize: 99999, totalCount: 0, hasMore: false});
    const notiPfmCd = ref([notiPfmCds.liiv , notiPfmCds.wiseNet]);
    const session = computed(() => store.state.auth.session);

    const checkUsers = ref([]);

    const checkAll = computed(() => {
      let cnt = 0;
      items.value.map(item => {
        checkUsers.value.filter(user => {
          if (item.lrnerId === user.lrnerId) cnt++;
        });
      });

      if (cnt == items.value.length) return true;
      return false;
    });

    const clickCheckAll = () => {
      items.value.forEach(x => {
        if (checkUsers.value.length < 10 && checkUsers.value.filter(y => x.lrnerId === y.lrnerId).length === 0) {
          checkUsers.value.push(x);
        }
      });
    };

    const clickUnCheckAll = () => {
      checkUsers.value = [];
    };

    const unCheck = (idx) => {
      checkUsers.value.splice(idx, 1);
    };

    const getUserList = () => {
      store.dispatch(`auth/${ACT_GET_USERS_LIST}`, {
        lrnerNm: search.lrnerNm,
        deptNm: search.deptNm,
        pageNo: paging.value.pageNo,
        pageSize: paging.value.pageSize
      }).then(res => {
        if(lengthCheck(res)) {
          items.value = getItems(res).filter(x => x.lrnerId != session.value.lrnerId);
          paging.value = getPaging(res);
        } else {
          items.value = [];
          paging.value.totalCount = 0;
        }
      }).catch(e => {
        items.value = [];
        console.error(e);
      })
    };

    const clickSearch = () => {
      if (search.lrnerNm === '' && search.deptNm === '') {
        showMessage('검색할 키워드를 입력해주세요.');
        return;
      } else if (search.lrnerNm !== '' && search.lrnerNm.length < 2) {
        showMessage('키워드를 2자 이상 입력해주세요.');
        return;
      } else if (search.deptNm !== '' && search.deptNm.length < 2) {
        showMessage('키워드를 2자 이상 입력해주세요.');
        return;
      } else {
        getUserList();
      }
    };

    const clickShare = () => {
      let result = false;

      if(notiPfmCd.value.length < 1){
        showMessage('공유방법을 1개이상 선택해주세요.');
      } else if(!notiCn.value) {
        showMessage('공유메시지를 입력해주세요.');
      } else{
        result = true;
      }

      if(result) {
        if(checkUsers.value.length > 0) {
          checkUsers.value.forEach(item => {
            crseActParam.shareTrgtLrnerIdLst = (crseActParam.shareTrgtLrnerIdLst !== '' ? crseActParam.shareTrgtLrnerIdLst += `,${item.lrnerId}` : item.lrnerId);
            crseActParam.shareTrgtLrnerNmLst = (crseActParam.shareTrgtLrnerNmLst !== '' ? crseActParam.shareTrgtLrnerNmLst += `,${item.lrnerNm}` : item.lrnerNm);

            notiParams.notiTrgtDomains.push({
              trprId: item.lrnerId,
              trprSn: item.userSn,
              trprNm: item.lrnerNm,
              trprTelno: item.mblTelno
            });
          });
        }

        if(notiPfmCd.value.length > 0) {
          notiPfmCd.value.forEach(x => {
            notiParams.notiMsgDomains.push({
              notiTitle: notiParams.notiTitle,
              notiPfmCdDcd : x,
              notiCn: `${notiCn.value}`
            })
          });
        }

        if (notiParams.notiTrgtDomains.length > 0 && notiPfmCd.value.length > 0) {
          notiParams.lrnObjDtlDistSn = crseActParam.lrnObjDtlDistSn;
          showConfirm('공유 하시겠습니까?',async () => {
            insertCourseAct(actTyCdDcds.ACT_TY_CD_SHARE, {
              ...crseActParam,
              notiMngDomain: {...notiParams}
            }, res => {
              if (isSuccess(res)) {
                showMessage({
                  text: '공유 완료 되었습니다.',
                  callback: () => {
                    closeModal();
                  }
                });
              } else {
                showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
              }
            });
          }, () => {
            initParams(crseActParam);
            notiParams.notiMsgDomains = [];
            notiParams.notiTrgtDomains = [];
          })
        }
      }
    };

    const checkLength = (event) => {
      if (event.target.checked && checkUsers.value.length === 1) {
        showMessage({
          text: '추천은 한명만 가능합니다.'
        });
        event.preventDefault();
      }
    }



    const closeModal = () => {
      emit('update:modelValue', false);
      initParams(crseActParam);
    };

    const param = reactive({
      tblNm: cttTableNm,
      tblSn: (cttMstSn.value ? cttMstSn.value : 0),
      comInqSn: 0,
      // inqTitle: '강사 신청하기',
      inqTyCdDcd: '2091010',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: '',
      tuitFeePayDd: '',
    });

    // onMounted(() => {
    //   setParams(crseActParam, props.data);
    // });

    const validate = () => {
      // if (!param.inqTitle) {
      //   showMessage('제목을 입력해주세요.');
      //   console.log(param.tuitFeePayDd);
      //   return false;
      // }
      // if (!param.inqTyCdDcd ) {
      //   showMessage('강의 타입을 선택해주세요');
      //   return false;
      // }
      if (checkUsers.value.length == 0 ) {
        showMessage('추천 대상을 선택해주세요.');
        return false;
      }
      if (!param.inqCn) {
        console.log(checkUsers.value[0]);
        showMessage('내용을 입력해주세요.');
        return false;
      }
      if (param.inqCn.length > 500) {
        showMessage('내용을 500자 이하로 작성해주세요.');
        return false;
      }
      return true;
    };

    //checkUsers.value[0].

    const addBoard = () => {
      store.dispatch(`board/${ACT_INSERT_LRN_BOARD}`, {
        distCrseSn: '203057',
        distCrseGrpSeqSn: '14',
        cn: param.inqCn,
        files: [],
        title: '강사추천',
        divCdDcd: '2013013',
        divNm: '강사추천',
        refPost: checkUsers.value[0].lrnerId
      }).then(res => {
        if(isSuccess(res)) {
          param.inqCn = '';
          showMessage('추천이 완료되었습니다.');
          closeModal();
          // getBoardWithCommentList();
        } else {
          showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
        }
      }).catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      });
    }

    const clickBoardAdd = () => {
      if(validate()) {
        showConfirm({
          title: '강사추천',
          text: '작성된 내용을 등록하시겠습니까?',
          callback: addBoard
        });
      }
    }

    return {
      notiPfmCds, notiPfmCd, notiParams, notiCn,
      search, items, paging, checkAll, checkUsers,
      unCheck,
      clickCheckAll, clickUnCheckAll,
      clickShare, clickSearch, closeModal, compareArrays,
      checkLength, clickBoardAdd, param
    }
  }
};
</script>