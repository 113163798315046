<template>
  <section class="myssl-section section-mybadge">
    <header class="section-header">
      <h3 class="title">내 배지 <span class="text-muted">{{ myBadges.length }}/{{activeBadges.length}}</span></h3>
    </header>
    <div class="mybadge-wrapper">

      <div class="sbadge-wrapper my-sbadge-wrapper">
        <!-- badge -->
        <SSLBadgeTitle v-for="(badge, idx) in activeBadges"
                       :key="idx"
                       :badge-ty-cd-dcd="badge.badgeTyCdDcd"
                       :is-active="badge.isActive"
                       :is-new="badge.isNew"
                       :hidden-yn="badge.hiddenYn"
                       :badge-data="badge"
                       :show-title="true"
                       :show-tooltip="true"
        />
      </div>
    </div>
  </section>
</template>

<script>

import SSLBadgeTitle from "@/components/ssl/badge/SSLBadgeTitle";
import {computed, ref, watch} from "vue";
import {getListFunc} from "@/assets/js/ui.init";
import {ACT_GET_COM_BADGE_TY_LIST} from "@/store/modules/badge/badge";
import {ACT_GET_BADGE_LIST} from "@/store/modules/ssl/ssl-index";
import {useRoute} from "vue-router";

export default {
  name: 'SSLMyBadgeFlex',
  components: {
    SSLBadgeTitle
  },
  setup() {
    const route = useRoute();
    const currentYear = computed(()=> route.params.year?route.params.year.toString():new Date().getFullYear().toString());
    // const myBadges = computed(() => store.state.ssl.myBadges); -- 년도별 조회로 수정함
    const currentTimestamp = new Date().getTime();
    const newLimit = 1000 * 60 * 60 * 24 * 7;
    const myBadges = ref([]);
    const sslBadges = ref([]);

    getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear.value, badgeKindCdDcd: "2065001"}, sslBadges, null);
    getListFunc(`ssl/${ACT_GET_BADGE_LIST}`, {year: currentYear.value}, myBadges, null);

    const activeBadges = computed(() => {
      return sslBadges.value.filter(x => x.year === currentYear.value).map(x => {
        // return sslBadges.value.filter(x => !x.showYears || x.showYears.includes(currentYear)).map(x => {
        const targetBadge = {
          badgeTySn: x.badgeTySn,
          badgeTyCdDcd: x.badgeTyCdDcd,
          isActive: false,
          isNew: false,
          hiddenYn: x.hiddenYn,
          badgeName: x.badgeTyNm,
          badgeDesc: x.cdDesc
        };

        const found = myBadges.value.filter(y => x.badgeTyCdDcd === y.badgeTyCdDcd);

        if(found.length > 0){
          targetBadge.isActive = true;
          targetBadge.isNew = found.getDt + newLimit >= currentTimestamp;
        }else{
          if (targetBadge.hiddenYn === 'Y') {
            targetBadge.badgeName = x.hiddenBadgeTyNm;
            targetBadge.badgeDesc = x.hiddenBadgeCn;
          }
        }

        return targetBadge;
      }).sort((a,b) => {
        if (a.badgeTySn < b.badgeTySn) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isActive) { return -1; }
        else {return 1;}
      }).sort((a) => {
        if (a.isNew) { return -1; }
        else {return 1;}
      });
    });

    // getBadgeList();

    watch(()=>route.params.year, ()=>{
      // console.log('currentYear : ' + route.params.year);
      getListFunc(`badge/${ACT_GET_COM_BADGE_TY_LIST}`, {year: currentYear.value, badgeKindCdDcd: "2065001"}, sslBadges, null);
      getListFunc(`ssl/${ACT_GET_BADGE_LIST}`, {year: currentYear.value}, myBadges, null);
    });

    return {
      sslBadges,
      myBadges,
      activeBadges,
      currentYear
    }
  }
}
</script>
