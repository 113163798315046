<template>

  <div class="main-header">
<!--    <div class="header-title"><h2 class="title">Self & Social Learning (SSL) 신청</h2></div>-->
    <div class="header-title"><h2 class="title">북러닝(Book Learning) 신청</h2></div>

  </div>
  <!--    <div class="home-section-row">-->
  <section class="content-section" style="margin-top:1.5em;">
    <div class="segment-box">
      <p class="text" style="text-align:center; font-weight:bold;">선착순 신청이 마감되었습니다. 감사합니다.</p>
      <p class="text" style="text-align:center; font-weight:bold;">신청자 앞 도서신청 및 연수실시 관련 별도 안내 예정입니다.</p>
      <p class="text" style="text-align:center; font-weight:bold;">[문의] 권현진(☎ 5357), 김보희(☎ 5473)</p>
<!--      <p class="text" style="text-align:center; font-weight:bold;">신청기간: 3.11(월) ~ 3.24(일), 2주 ※신청기간 이후에도 자유롭게 신청 가능</p>-->
<!--      <p class="text" style="text-align:center; font-weight:bold;"><span class="color-red">3.25(월) 17시부터</span> 학습이 시작될 예정입니다!</p>-->
<!--      <p class="text" style="text-align:center; font-weight:bold;">신청인원:<span class="red">{{numberComma(totAppNonDupCnt)}}</span>명 / 목표인원:<span class="red">4,000</span>명</p>-->
    </div>
  </section>
  <div class="section-notice">
    <div class="home-section" style="justify-content:center;padding:0">
      <!--          <a href="javascript:" class="text-link" @click="downloadFile">-->
      <!--            <span class="text">시행문 보기</span><img src="@/assets/lxp/images/common/ic_download.svg" style="margin-top:-5px">-->
      <!--          </a>-->
      <div class="page-buttons">
<!--        <router-link to="/prep/hrdcloud/270223/detail" class=" kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><span class="aplytext">소개영상 보러가기</span><i class="icon-arrow-next"></i></router-link>-->
<!--        <button class=" kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="downloadFile('SSL_2023_Guide')"><span class="aplytext">학습가이드 보기</span><i class="icon-arrow-next"></i></button>-->
        <button class=" kb-btn kb-btn-secondary kb-btn-sm rounded-lg" @click="downloadFile('Book_2024_Paper')"><span class="aplytext">시행문 보기</span><i class="icon-arrow-next"></i></button>
      </div>

    </div>
  </div>
  <!--    </div>-->
  <div class="page-container">
    <div class="page-body">
<!--      <div class="page-component" style="max-width: 1300px;!important;">-->
<!--        <div class="section-content flex-column" style="display:flex;">-->
<!--          <img src="@/assets/lxp/images/ssl/home/book_apply_banner.jpg" alt="">-->
<!--        </div>-->
<!--      </div>-->

      <div class="page-component" style="max-width: 1300px;!important;">
        <div class="section-content" v-if="1==2"><!-- 콜리가리기 -->
            <ApplyGoal :apply-cnt="totAppNonDupCnt"/>
        </div>
        <div class="home-section-row" style="flex-wrap:wrap;width:100%; justify-content: space-around; align-items:center;height: 350px;" > <!-- height: 650px; -->
          <!-- home-section:section-learning -->
          <article v-for="(moveRoute, index) in sslMoveLink" class="home-section section-learning" :key="index" :style="moveRoute.dp" style="flex:0 0 370px !important;margin-left: 0px !important;">
            <div class="learning-box">
              <a href="javascript:" class="learning-link" @click="goLearn(moveRoute)"></a>
              <div class="learning-image" style="width:370px; height:290px">
                <img v-if="moveRoute.key === 'selfGa'" src="@/assets/lxp/images/ssl/home/img_quick_self.jpg" alt="">
                <img v-else-if="moveRoute.key === 'selfGi'" src="@/assets/lxp/images/ssl/home/img_quick_e.jpg" alt="">
                <img v-else-if="moveRoute.key === 'selfJa'" src="@/assets/lxp/images/ssl/home/img_quick_alssl.jpg" alt="">
                <img v-else-if="moveRoute.key === 'selfIct'" src="@/assets/lxp/images/ssl/home/img_quick_hrd.jpg" alt="">
                <img v-else-if="moveRoute.key === 'group'" src="@/assets/lxp/images/ssl/home/img_quick_group.jpg" alt="">
<!--&lt;!&ndash;                <img v-else-if="moveRoute.key === 'knowledge'" src="@/assets/lxp/images/ssl/home/img_quick_e.jpg" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                <img v-else-if="moveRoute.key === 'hrd'" src="@/assets/lxp/images/ssl/home/img_quick_hrd.jpg" alt="">&ndash;&gt;-->
                <img v-if="moveRoute.key === 'book'" src="@/assets/lxp/images/ssl/home/img_quick_book.jpg" alt="">
                <img v-else-if="moveRoute.key === 'trivia'" src="@/assets/lxp/images/ssl/home/img_quick_alssl.jpg" alt="">
              </div>
              <div class="learning-contents" :style="moveRoute.key==='group'?'top:145px !important;':'top:140px !important;'">
                <span class="title text-white" style="color:white !important; line-height:30px; font-size: 28px; top:0" v-html="moveRoute.name"></span>
                <p class="contents-text" style="font-size:20px" v-if="moveRoute.key === 'selfGa'">
                  가계여신</p>
                <p class="contents-text" style="font-size:20px" v-if="moveRoute.key === 'selfGi'">
                  기업여신/외환</p>
                <p class="contents-text" style="font-size:20px" v-if="moveRoute.key === 'selfJa'">
                  자산관리/퇴직연금</p>
                <p class="contents-text" style="font-size:20px" v-if="moveRoute.key === 'selfIct'">
                  ICT</p>
                <p class="contents-text" style="font-size:20px;text-align:left;margin-left:28%" v-else-if="moveRoute.key === 'group'">
                  - 가계여신<br>
                  - 기업여신/외환<br>
                  - 자산관리/퇴직연금<br>
                  - ICT<br>
                  - 자격증취득
                </p>
                <p class="contents-text" style="font-size:20px" v-if="moveRoute.key === 'book'">
                  선착순 2,700명
<!--                  7~8월 운영 예정<br>-->
<!--                  (6월 中 별도 시행 안내)-->
                </p>
              </div>
              <div class="onCount">
<!--                <span class="countTxt" v-if="moveRoute.key === 'selfGa'">{{ numberComma(selfAppGaCnt) }}명</span>-->
<!--                <span class="countTxt" v-if="moveRoute.key === 'selfGi'">{{ numberComma(selfAppGiCnt) }}명</span>-->
<!--                <span class="countTxt" v-if="moveRoute.key === 'selfJa'">{{ numberComma(selfAppJaCnt) }}명</span>-->
<!--                <span class="countTxt" v-if="moveRoute.key === 'selfIct'">{{ numberComma(selfAppIctCnt) }}명</span>-->
<!--                <span class="countTxt" v-else-if="moveRoute.key === 'group'">{{ numberComma(groupCnt) }}명</span>-->
<!--&lt;!&ndash;                <span class="countTxt" v-else-if="moveRoute.key === 'knowledge'">{{ numberComma(jisikeCnt) }}명</span>&ndash;&gt;-->
<!--&lt;!&ndash;                <span class="countTxt" v-else-if="moveRoute.key === 'hrd'">{{ numberComma(cloudCnt) }}명</span>&ndash;&gt;-->
                <span class="countTxt" v-if="moveRoute.key === 'book'">{{ numberComma(bookCnt)}}명</span>
<!--                <span class="countTxt" v-else-if="moveRoute.key === 'trivia'">신청 불필요       </span>-->
              </div>
              <div>
<!--                <div v-if="moveRoute.lrnTrgtGrpMstSn > 0 && moveRoute.key === 'self'" class="on"><span class="contents-text"><i class="icon-read"></i>신청완료({{moveRoute.lrnTrgtGrpNm}})</span></div>-->
                <div v-if="moveRoute.lrnTrgtGrpMstSn > 0" class="on"><span class="contents-text"><i class="icon-read"></i>신청완료</span></div>
<!--                <div v-if="moveRoute.key === 'trivia'" class="on2"> <span class="contents-text">SSL 참여직원 모두를 위한 코너</span> </div>-->

                <div v-if="moveRoute.lrnTrgtGrpMstSn > 0 && moveRoute.key !== 'trivia' && 1==2" class="onBtn">
                  <button  class="kb-btn-secondary kb-btn-sm rounded-lg kb-btn-ssl-aply">
                    <span class="aplytext" >신청 취소</span>
                  </button>
<!--                  <button  class="kb-btn-secondary kb-btn-sm rounded-lg kb-btn-ssl-aply"  style="" >-->
<!--                    <span class="aplytext">마 감</span>-->
<!--                  </button>-->
                </div>

                <div v-else-if="moveRoute.lrnTrgtGrpMstSn === 0 && bookCnt < bookLimitCnt" class="onBtn">
                  <button class="kb-btn-secondary kb-btn-sm rounded-lg kb-btn-ssl-aply">
                    <span class="aplytext" >신청 하기</span>
                  </button>
<!--                  <button   class="kb-btn-secondary kb-btn-sm rounded-lg kb-btn-ssl-aply" style="">-->
<!--                    <span class="aplytext" >마 감</span>-->
<!--                  </button>-->
                </div>
                <div v-else-if="moveRoute.key === 'book' " class="onBtn"> <!-- && bookCnt >= bookLimitCnt -->
                  <button class="kb-btn-secondary kb-btn-sm rounded-lg kb-btn-ssl-aply" >
                    <span class="aplytext" >마 감</span>
                  </button>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :stt="enroll.stt"
      :dist-crse-cd="enroll.key"
  />
</template>

<script>
import {sslApplySetup} from "@/assets/js/modules/ssl/setup/ssl-apply-setup";
import SSLEnroll from '@/components/ssl/apply/SSLEnroll';
import ApplyGoal from '@/components/ssl/apply/ApplyGoal';
import {numberComma} from "@/assets/js/util";

export default {
  name: "SSLApply",
  methods: {numberComma},
  components: {SSLEnroll, ApplyGoal},
  setup: sslApplySetup
}

</script>

<style scoped>
.icon-read {width:20px;height:20px; margin-bottom:10px;  background:url(../../assets/lxp/images/ssl/main/ic_check.svg)}

.on {  position: absolute; top: 25px; left:10px; font: normal 0.8rem/1.25 var(--kb-font-KBFGTextB);color:#444444;font-weight: 600; text-align: center}
.on2 {  position: absolute;  width:100%; top: 200px; font: normal 0.8rem/1.25 var(--kb-font-KBFGTextB);color:#444444;font-weight: 600; text-align: center}
.onCount { position: absolute; top: 20px; left:230px;}

.kb-btn-ssl-aply{
  background-color: var(--kb-light-silver);
  border-color: var(--kb-light-silver);
}
.contents-text{
  color:#444444;
  line-height:20px;
  font-size: 15px;
  padding:15px 10px;
  font-weight: 600;

}
.kb-btn-ssl-cancle{
  background-color:#545045;
  border-color:#545045;
}
.countTxt {display: inline-flex;
  align-items: center;
  justify-content: right;
  background-color: var(--kb-light-silver);
  border-color: var(--kb-light-silver);
  letter-spacing: 1px;
  height: 30px;
  width:110px;
  padding: 10px 18px;
  border-radius : 50px ;
  transition: var(--btn-transition);
  font: normal 0.8rem/1.25 var(--kb-font-KBFGTextB);
  font-weight:bold;
  color:#545045;
  vertical-align: middle;
  border: 1px solid transparent;
  transition: var(--kb-btn-transition);}
.onBtn{ position: absolute; top: 230px; left:140px; display:inline-block;}
.aplytext{
  align-items: center;
  justify-content: right;
  padding: 8px 8px;
  font: normal 0.9rem/1.25 var(--kb-font-KBFGTextB);
  font-weight:bold;
  color:#545045;
}
.cancletext{
  align-items: center;
  justify-content: right;
  padding: 8px 8px;
  font: normal 0.9rem/1.25 var(--kb-font-KBFGTextB);
  font-weight:bold;
  color:var(--kb-light-silver);
}
.uBtn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 35px;
  border-radius: var(--kb-border-radius-pill);
  transition: var(--btn-transition);
  font: normal 0.9rem/1.25 var(--kb-font-KBFGTextB);
  vertical-align: middle;
  border: 1px solid transparent;
  transition: var(--kb-btn-transition);
}
/*.icon-download {margin-left:4px;width:20px;height:20px;background-image:url(../../assets/lxp/images/common/ic_download.svg)}*/
</style>
