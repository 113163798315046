import {useAlert} from "@/assets/js/modules/common/alert";
import {computed, reactive, ref} from "vue";
import {insertFileAtch, removeUpload} from "@/assets/js/modules/hrd/hrd-common";
import {convertToStorageBytes, initParams, isSuccess, isUploadSuccess} from "@/assets/js/util";
import store from "@/store";
import {ACT_INSERT_NOTE_OPINION} from '@/store/modules/ssl/ssl-index';

export const sslNoteOpinion = (props, {emit}) => {
    const {showMessage} = useAlert();

    const param = reactive({
        tblNm: 'LMS_SOC_BOARD',
        tblSn: props.socLrnPostSn,
        comInqSn: 0,
        inqTitle: props.title,
        inqTyCdDcd: '2091017',
        inqTyNm: 'SSL노트 수정의견',
        inqCn: '',
        readYn: '',
        ansYn: '',
        ansCn: ''
    });

    const file = ref({ files: [], removed: [], binaries: [] });

    const cnSz = computed(() => {
        return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });

    const validate = () => {
        if (!param.inqTitle) {
            showMessage('제목을 입력해주세요.');
            return false;
        }
        if (!param.inqCn) {
            showMessage('내용을 입력해주세요.');
            return false;
        }
        if (param.inqCn.length > 500) {
            showMessage('내용을 500자 이하로 작성해주세요.');
            return false;
        }
        return true;
    };

    const init = () => {
        initParams(param);
        initParams(file.value);
        param.tblNm = 'LMS_SOC_BOARD';
        param.tblSn = props.socLrnPostSn;
        param.inqTyCdDcd = '2091017';
        param.inqTyNm = 'SSL노트 수정의견';
    };


    const closeModal = () => {
        emit('update:toggle', false);
    };

    const insertNoteOpinion=(params)=>{

        return store.dispatch(`ssl/${ACT_INSERT_NOTE_OPINION}`, params);
    }
    const insertQaAndFiles = async () => {
        if (validate()) {
            let qa = await insertNoteOpinion(param);
            if (isSuccess(qa)) {
                if (file.value.files.length > 0) {
                    let fileRes = await insertFileAtch({comInqSn: qa.comInqSn}, file.value.files);
                    if (isUploadSuccess(fileRes)) {
                        showMessage("노트 수정의견이 접수되었습니다", () => {
                            init();
                            closeModal();

                        });
                    } else {
                        showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
                    }
                } else {
                    showMessage("노트 수정의견이 접수되었습니다", () => {
                        init();
                        closeModal();
                    });
                }
            } else {
                showMessage("등록에 실패했습니다. 잠시 후 다시 시도해주세요.");
            }
        }
    };

    return{
        insertQaAndFiles,cnSz, param, file, closeModal, removeUpload, convertToStorageBytes
    }
}