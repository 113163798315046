import SSLApplyLayout from "@/views/layout/ssl/SSLApplyLayout";
import SSLMobileApplyLayout from "@/views/layout/ssl/mobile/SSLMobileApplyLayout";

import SSLLayout from "@/views/layout/ssl/SSLLayout";
import SSLMobileLayout from "@/views/layout/ssl/mobile/SSLMobileLayout";

import SSLHome from "@/views/ssl/SSLHome";
import SSLMobileHome from "@/views/ssl/mobile/SSLMobileHome";

import SSLApply from "@/views/ssl/SSLApply";
import SSLMobileApply from "@/views/ssl/mobile/SSLMobileApply";

import SSLMain from "@/views/ssl/SSLMain";
import SSLMobileMain from "@/views/ssl/mobile/SSLMobileMain";

import SSLMy from "@/views/ssl/SSLMy";
import SSLMobileMy from "@/views/ssl/mobile/SSLMobileMy";


import SSLTotalMy from "@/views/ssl/SSLMy";
import SSLMobileTotalMy from "@/views/ssl/mobile/SSLMobileMy";

import SSLTotalView from "@/views/ssl/SSLTotalView";
import SSLMobileTotalView from "@/views/ssl/mobile/SSLMobileTotalView";

import SSLWrite from "@/views/ssl/SSLWrite";
import SSLMobileWrite from "@/views/ssl/mobile/SSLMobileWrite";

import SSLNotice from "@/views/ssl/SSLNotice";
import SSLMobileNotice from "@/views/ssl/mobile/SSLMobileNotice";

import SSLNoticeView from "@/views/ssl/SSLNoticeView";
import SSLMobileNoticeView from "@/views/ssl/mobile/SSLMobileNoticeView";

import SSLLearner from "@/views/ssl/SSLLearner";
import SSLMobileLearner from "@/views/ssl/mobile/SSLMobileLearner";

import navigationUtils from "@/assets/js/navigationUtils";
import SSLMobileBoardSearch from "@/components/ssl/home/mobile/SSLMobileBoardSearch";
import SSLBoardSearch from "@/components/ssl/home/SSLBoardSearch";

import SSLMobileActFeed from '@/components/ssl/home/mobile/SSLMobileActFeed';
// import SSLActFeedModal from '@/components/ssl/home/SSLActFeedModal';
import SSLMobileShare from '@/components/ssl/home/mobile/SSLMobileShare'

import SSLBestNotes from '@/views/ssl/SSLBestNotes';
import SSLMobileBestNotes from '@/views/ssl/mobile/SSLMobileBestNotes';


const isMobile = navigationUtils.any();

const sslRoute = [
    {
        path: '/ssl',
        component: isMobile ? SSLMobileApplyLayout : SSLApplyLayout,
        children: [
            {
                path: "apply",
                name: "SSLApply",
                component: isMobile ? SSLMobileApply : SSLApply
            },
        ]
    },
    {
        path: '/ssl',
        component: isMobile ? SSLMobileLayout : SSLLayout,
        children: [
            {
                path: "home",
                name: "SSLHome",
                component: isMobile ? SSLMobileHome : SSLHome
            },
            {
                path: "notice",
                name: "SSLNotice",
                component: isMobile ? SSLMobileNotice : SSLNotice,
            },
            {
                path: ":distCrseCd/notice",
                name: "SSLCrseNotice",
                component: isMobile ? SSLMobileNotice : SSLNotice,
            },
            {
                path: "notice/:socLrnNtcSn",
                name: "SSLNoticeView",
                component: isMobile ? SSLMobileNoticeView : SSLNoticeView
            },

            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/learner/:lrnerId",
                name: "SSLLearner",
                component: isMobile ? SSLMobileLearner : SSLLearner,
            },
            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/learner/:lrnerId/board",
                name: "SSLLearnerBoard",
                component: isMobile ? SSLMobileLearner : SSLLearner,
            },
            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/my",
                name: "SSLMy",
                component: isMobile ? SSLMobileMy : SSLMy
            },
            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/my/:view",
                name: "SSLMyView",
                component: isMobile ? SSLMobileMy : SSLMy
            },
            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/totMy",
                name: "SSLTotalMy",
                component: isMobile ? SSLMobileTotalMy : SSLTotalMy
            },
            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/totMy/:year/:view",
                name: "SSLTotalMyView",
                component: isMobile ? SSLMobileTotalMy : SSLTotalMy
            },

            // {
            //     path: ":distCrseCd/:lrnTrgtGrpMstSn/my/subscribe",
            //     name: "SSLMySubscribe",
            //     component: isMobile ? SSLMobileMySubscribe : SSLMySubscribe
            // },
            // {
            //     path: ":distCrseCd/:lrnTrgtGrpMstSn/my/subscribed",
            //     name: "SSLMySubscribed",
            //     component: isMobile ? SSLMobileMySubscribe : SSLMySubscribe
            // },
            // {
            //     path: ":distCrseCd/:lrnTrgtGrpMstSn/my/board/:view",
            //     name: "SSLMyBoard",
            //     component: isMobile ? SSLMobileMyBoard : SSLMyBoard
            // },
            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/main",
                name: "SSLMain",
                component: isMobile ? SSLMobileMain : SSLMain
            },
            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/view/:socLrnPostSn",
                name: "SSLView",
                component: isMobile ? SSLMobileMain : SSLMain
            },
            {
                path: ":distCrseCd/totMy/:lrnTrgtGrpMstSn/view/:socLrnPostSn", //:distCrseCd/:lrnTrgtGrpMstSn/
                name: "SSLTotalView",
                component: isMobile ? SSLMobileTotalView : SSLTotalView
            },
            {
                path: ":distCrseCd/:lrnTrgtGrpMstSn/write/:socLrnPostSn",
                name: "SSLWrite",
                component: isMobile ? SSLMobileWrite : SSLWrite
            },
            {
                path: ":keyword/board",
                name: "SSLBoardSearch",
                component: isMobile ? SSLMobileBoardSearch : SSLBoardSearch
            },
            {
                path: ":div/actFeed",
                name: "SSLActFeed",
                component: SSLMobileActFeed // isMobile ? SSLMobileActFeed : SSLActFeedModal
            },
            {
                path: "share",
                name: "SSLShare",
                component: SSLMobileShare // isMobile ? SSLMobileActFeed : SSLActFeedModal
            },
            {
                path: "bestNote",
                name: "SSLBestNote",
                component: isMobile ? SSLMobileBestNotes : SSLBestNotes
            },
        ],
    }
];

export const setSSLRoutes = (routes) => {
    routes.push(...sslRoute);
}
