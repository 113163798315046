<template>
  <div class="header-column column-dropdown">
    <div class="dropdown" :class="{'is-active': toggles.main}">
      <button class="dropdown-btn" @click.stop="toggleVisible('main')"><strong class="text">{{ currentMainRoute.name }}</strong><i class="icon-arrow"></i></button>
      <div class="dropdown-target">
        <div class="dropdown-box">
          <div class="depthmenu">
            <ul class="dropdown-option-list">
              <li class="dropdown-option-item">
                <router-link :to="{name: 'SSLHome'}" class="dropdown-option-link" :class="{'is-active': !distCrseCd}">
                  <span class="dropdown-option-text">SSL 홈</span>
                </router-link>
              </li>

              <li v-for="(mainRoute, idx) in mainRoutes" class="kb-nav-item" :key="idx">
                <router-link :to="getMainPath(mainRoute)" class="dropdown-option-link" :class="{'is-active': distCrseCd === mainRoute.key}" @click="toggles.main = false">
                  <span class="dropdown-option-text">{{ mainRoute.name }}</span>
                </router-link>
              </li>
            </ul>
            <ul v-if="subRoutes.length > 0" class="dropdown-option-list">
              <li v-for="(subRoute, idx) in subRoutes" class="dropdown-option-item" :key="idx">
                <router-link :to="getSubPath(subRoute)" class="dropdown-option-link" :class="{'is-active': currentSubRoute.lrnTrgtGrpMstSn === subRoute.lrnTrgtGrpMstSn}" @click="toggles.main = false">
                  <span class="dropdown-option-text">{{ subRoute.lrnTrgtGrpNm }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-if="subRoutes.length > 0" class="dropdown">
      <a href="javascript:" class="dropdown-btn" @click.stop="toggleVisible('main')"><strong class="text">{{ currentSubRoute.lrnTrgtGrpNm }}</strong></a>
    </div>
  </div>
</template>

<script>
import {computed, reactive, watch} from 'vue';
import {getRouteLrnTrgtGrpMstSn, sslRoutes} from '@/assets/js/modules/ssl/ssl-common';
import {useRoute} from 'vue-router';
import {useStore} from 'vuex';
export default {
  name: 'SSLNav',
  props: {
    effectNav: {
      required: false,
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();

    const isMobile = computed(() => store.state.auth.isMobile);
    const distCrseCd = computed(() => route.params.distCrseCd);
    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));

    // const distCrseSns = computed(() => getDistCrseSns(route.params.distCrseCd));
    const myLrnTrgtGrpMstSns = computed(() => store.state.ssl.myLearnTargets.map(x => x.lrnTrgtGrpMstSn));

    // const myRoutes = computed(() => {
    //   // 나의 차수 Get
    //   const myDistCrseSns = store.state.ssl.myLearnTargets.map(x => x.distCrseSn);
    //   return [...Object.keys(sslRoutes).map(key => ({...sslRoutes[key]})).filter(x => {return (x.distCrseSns.filter(y => myDistCrseSns.includes(y)).length > 0)})];
    // });

    // main route
    const mainRoutes = computed(() => {
      return Object.values(sslRoutes).filter(x=> x.key != 'total');
      // return Object.values(sslNavRoutes(myLrnTrgtGrpMstSns));
      // if (getTodayDate('yyyyMMdd') < '20240701'){
      //   return Object.values(sslRoutes).filter(x => x.key != 'book').filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
      // }else{
      //   return Object.values(sslRoutes).filter(x=>(x.lrnTrgtGrpMstSns.filter(y=>myLrnTrgtGrpMstSns.value.includes(y)) != '' || x.key == 'trivia'));
      // }
    });

    const currentMainRoute = computed(() => {
      // console.log('route.path : ' + route.path);
      // console.log('route.name : ' + route.name);
      if(route.name === 'SSLHome' || route.name === 'SSLNoticeView' || route.name === 'SSLBoardSearch' || route.name === 'SSLNotice'){
        return {name: 'SSL 홈', key: 'home'};
      }
      else if(route.name === 'SSLTotalMy' || route.name === 'SSLTotalMyView'){
        return {name: 'my SSL', key: 'total'};
      }
      else if(route.name === 'SSLBestNote'){
        return {name: '우수 노트 모아보기', key: 'bestNote'};
      }
      else if(distCrseCd.value && sslRoutes[distCrseCd.value]){
        return sslRoutes[distCrseCd.value];
      }
      return {};
    });

    // sub route
    const subRoutes = computed(() => {
      if (route.name === 'SSLMain' && currentMainRoute.value && currentMainRoute.value.group && currentMainRoute.value.group.length > 1) {
        return currentMainRoute.value.group.map(x => ({...x, key: currentMainRoute.value.key}));
      }
      return [];
    });

    const currentSubRoute = computed(() => {
      if (lrnTrgtGrpMstSn.value > 0 && currentMainRoute.value && currentMainRoute.value.group && currentMainRoute.value.group.length > 0) {
        const found = currentMainRoute.value.group.find(x => lrnTrgtGrpMstSn.value === x.lrnTrgtGrpMstSn);
        if (found) return found;
      }
      return {};
    });

    const toggles = reactive({
      main: false,
      sub: false
    });

    const getMainPath = targetRoute => {
      let targetLrnTrgtGrpMstSn = targetRoute.lrnTrgtGrpMstSns[0];
      const targetLrnTrgtGrpMstSns = targetRoute.lrnTrgtGrpMstSns.filter(x => myLrnTrgtGrpMstSns.value.includes(x));
      if (targetLrnTrgtGrpMstSns.length > 0) targetLrnTrgtGrpMstSn = targetLrnTrgtGrpMstSns[0];
      if(route.name.indexOf('SSLMy') === 0){
        return {name: 'SSLMy', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn}}
      }else if(targetLrnTrgtGrpMstSn == 999999){
        return {name: 'SSLBestNote'};
      }else if(targetLrnTrgtGrpMstSn == 0){
        return {name: 'SSLTotalMy', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn:targetLrnTrgtGrpMstSn}};
      }else{
        return {name: 'SSLMain', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn}}
      }
    };

    const getSubPath = targetRoute => {
      if(route.name.indexOf('SSLTotalMy') > -1){
        return {name: 'SSLTotalMy', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetRoute.lrnTrgtGrpMstSn}};
      }else if(route.name.indexOf('SSLBestNote') > -1){
        return {name: 'SSLBestNote'};
      }else if(route.name.indexOf('SSLMy') === 0){
        return {name: 'SSLMy', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetRoute.lrnTrgtGrpMstSn}}
      }else{
        return {name: 'SSLMain', params: {distCrseCd: targetRoute.key, lrnTrgtGrpMstSn: targetRoute.lrnTrgtGrpMstSn}}
      }

    }

    const toggleVisible = target => {
      Object.keys(toggles).forEach(key => {
        if (key === target) {
          toggles[key] = !toggles[key];
        } else {
          toggles[key] = false;
        }
      });
    }

    const closeNav = () => {
      toggles.main = false;
      // isSubVisible.value = false;
    }

    watch(() => props.effectNav, () => {closeNav();});
    // watch(() => distCrseSns.value, () => {closeNav();});

    return {
      isMobile,
      distCrseCd,
      mainRoutes,
      subRoutes,
      currentMainRoute,
      currentSubRoute,

      // myRoutes,
      toggles,
      // isVisible,
      // isSubVisible,
      getMainPath,
      getSubPath,
      toggleVisible,
      closeNav
    }
  }
}
</script>
