<template>
  <div v-if="!isActive" class="main-content main-component">
    <div class="support-view-container">
      <div class="page-buttons">
        <button class="kb-btn kb-btn-primary" @click="clickSupport">
          <span class="text">등록/지원신청</span>
        </button>
      </div>
      <div class="view-contents">
        <!-- content-section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">지원대상</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="desc">
                <p>지급일 현재 재직중인 직원 (일반/계약)</p>
                <p class="sub-text">※ 지급일 현재 정직자 및 파트타이머 제외</p>
              </div>
            </div>
          </div>
        </section>
        <!-- content-section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">신청기한</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="desc">
                <div><span style="font-weight: bold">자격증 취득일</span>로부터 <span class="text-red">2년 이내</span></div>
                <p class="sub-text">입행 전 취득한 자격증은 신청기한과 관계없이 지원 불가</p>
              </div>
            </div>
          </div>
        </section>

        <section class="content-section">
          <header class="section-header">
            <h4 class="title">지원 절차 및 시기</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <h5 class="b-title">Step1.</h5>
              <div class="desc"><p>자격증 취득 후 필요서류 준비</p>
                <p class="sub-text">비용지원 대상이 아닌 단순히 자격증을 등록하는 경우에도 취득 지원절차와 동일한 방법으로 신청</p>
                <p class="sub-text">예시) 취득일로부터 2년이 경과한 자격증 등록, 입행 전 취득한 자격증 등록</p>
              </div>
            </div>
            <div class="content-item">
              <h5 class="b-title">Step2.</h5>
              <p class="desc">스타런에서 신청</p>
            </div>
            <div class="content-item">
              <h5 class="b-title">Step3.</h5>
              <div class="desc">
                <p>인재개발부 심사 및 등록</p>
                <p class="sub-text">담당자 승인 즉시 취득신고한 자격증이 연수시스템 내에 등록되며(비용 지급 전), 익영업일 인사시스템(HR) 내에 등록됨</p>
              </div>
            </div>
            <div class="content-item">
              <h5 class="b-title">Step4.</h5>
              <p class="desc">급여계좌로 지급(전산 신청일 기준 <span class="text-red">익월 15일경</span>)</p>
            </div>
          </div>
        </section>
        <!-- section -->

        <!-- content-section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">필요서류</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="title">기본 증빙서류</div>
              <div class="desc">
                <p>자격증(합격증) : 주관처에서 발급하는 자격증/합격증(사본, 인터넷 발급분 포함)</p>
              </div>
            </div>
            <div class="content-item">
              <div class="title">추가 증빙서류 (응시료, 교재비 등)</div>
              <div class="desc">
                <p>본인 명의의 KB신용·체크카드·직원복지카드로 결제한 매출전표만 인정</p>
                <p class="sub-text">※ 선불카드 및 타사 카드결제에 의한 증빙은 불가</p>
              </div>
              <div class="desc">
                <p>학원비 - 학원 수강증</p>
                <p class="sub-text">※ 환급 받는 과정, 사은품이 제공 되는 과정은 제외</p>
              </div>
              <div class="desc">
                <p>교재비 – 해당 자격증과 직접 연관된 도서명 확인 필요 (ex. 주문내역서 등)</p>
                <p class="sub-text">※ 등급외 자격증의 경우 학원비 지원이 되지 않으므로 동영상 강의에 포함된 교재비 지원 요청 시 교재비에 대한 금액 증빙을 별도로 제출</p>
              </div>
            </div>
          </div>
        </section>
        <!-- content-section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">유의사항</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="desc">
                <p>- 자격증 취득 지원금은 투명하고 정당한 절차에 의한 방법으로 지원받으시기 바랍니다.</p>
                <p>- 지급된 지원금은 기소득 처리 대상으로 인재개발부에서 일괄 과표 처리합니다.</p>
              </div>
            </div>
          </div>
        </section>
        <!-- section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">등급 외 자격증</h4>
            <p class="subtext">(펀드,파생,보험,영업점컴플라이언스 오피서)</p>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="title">지원내용</div>
              <div class="desc">
                <p>항목별 지원한도 이내에서 실비 지원</p>
                <p class="sub-text">※항목별 지원한도는 상단 안내문 참조</p>
              </div>
            </div>
            <div class="content-item">
              <div class="title">지원항목</div>
              <div class="desc">
                <p>투자자보호교육비, 응시료, 교재비</p>
                <p class="sub-text">학원비/동영상 강의비는 지원하지 않음</p>
              </div>
            </div>
          </div>
        </section>
        <!-- section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">등급 자격증</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="title">지원내용</div>
              <div class="desc">
                <p>등급별 지원한도 이내에서 소요된 비용의 80% 금액 지원</p>
                <p class="sub-text">최소 지원금액: 등급별 지원한도의 50%, 최대 지원금액: 지원한도 금액</p>
              </div>
            </div>
            <div class="content-item">
              <div class="title">지원항목</div>
              <div class="desc">
                <p>학원비, 응시료, 교재비</p>
                <p class="sub-text">연회비, 연체료 등은 제외</p>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="accordion" :class="{'is-active' : toggle.e}">
              <a href="javascript:" class="accordion-toggle-action" @click="clickToggle('partE')">
                <div class="accordion-row">
                  <div class="accordion-column">
                    <div class="accordion-title">등급 자격증 현황표</div>
                  </div>
                  <div class="accordion-icon"><i class="icon-arrow"></i></div>
                </div>
              </a>
              <div class="accordion-target">
                <div class="kb-table kb-table-v2">
                  <table>
                    <colgroup>
                      <col style="width:45px;"/>
                      <col style="width:17%;"/>
                      <col style="width:17%;"/>
                      <col />
                    </colgroup>
                    <thead>
                    <tr>
                      <th class="px-1"><span class="th-title fs-8">등급</span></th>
                      <th class="px-1"><span class="th-title fs-8">마일리지</span></th>
                      <th class="px-1"><span class="th-title fs-8">지원한도<br>(천원)</span></th>
                      <th><span class="th-title fs-8">자격증명</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><span class="td-text fs-8">A</span></td>
                      <td><span class="td-text fs-8">150</span></td>
                      <td><span class="td-text fs-8">3,000</span></td>
                      <td><span class="td-text fs-9">공인회계사<br>국제공인재무분석사(CFA)<br>기술사<br>변호사</span></td>
                    </tr>
                    <tr>
                      <td rowspan="2"><span class="td-text fs-8">B</span></td>
                      <td rowspan="2"><span class="td-text fs-8">100</span></td>
                      <td><span class="td-text fs-8">2,000</span></td>
                      <td><span class="td-text fs-9">감정평가사<br>건축사<br>공인국제제재전문가(CGSS)<br>공인노무사<br>공인대체투자분석사(CAIA)<br>관세사<br>국제공인내부감사사(CIA)<br>국제공인재무설계사(CFP)<br>美공인회계사(AICPA)<br>美변호사(LL.M)<br>법무사<br>보험계리사<br>세무사<br>자금세탁방지전문가(CAMS)<br>변리사</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text fs-8">1,500</span></td>
                      <td><span class="td-text fs-9">신용위험분석사(CRA)<br>프라이빗뱅커(CPB)</span></td>
                    </tr>
                    <tr>
                      <td rowspan="2"><span class="td-text fs-8">C</span></td>
                      <td rowspan="2"><span class="td-text fs-8">50</span></td>
                      <td><span class="td-text fs-8">1,500</span></td>
                      <td><span class="td-text fs-9">FDP(Financial Data Professional)<br>국제거래용은행보증서실무전문가(CSDG)<br>국제공인무역금융컴플라이언스(CTFC)<br>국제공인신용장전문가(CDCS)<br>美공인관리회계사(CMA)<br>美재무위험관리사(FRM/G.A.R.P)<br>정보시스템감리사<br>정보시스템감사사(CISA)</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text fs-8">700</span></td>
                      <td><span class="td-text fs-9">국제금융역<br>금융투자분석사<br>기술신용평가사 1급<br>여신심사역<br>자산관리사(FP,한국금융연수원)<br>재경관리사<br>재무위험관리사(FRM,금융투자협회)<br>정보보안기사<br>투자자산운용사<br>한국재무설계사(AFPK)<br>ISMS-P인증심사원</span></td>
                    </tr>
                    <tr>
                      <td rowspan="2"><span class="td-text fs-8">D</span></td>
                      <td rowspan="2"><span class="td-text fs-8">40</span></td>
                      <td><span class="td-text fs-8">1,000</span></td>
                      <td><span class="td-text fs-9">경영지도사<br>손해사정사<br>외환관리사<br>정보시스템보안전문가(CISSP)</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text fs-8">500</span></td>
                      <td><span class="td-text fs-9">AWS Solution Architect-Professional<br>SQL전문가(SQLP)<br>공인기능점수전문가(CFPS)<br>공인중개사<br>국제투자분석사(K-CIIA)<br>금융채권관리사<br>기술신용평가사2급<br>데이터분석전문가(ADP)<br>데이터아키텍처전문가(DAP)<br>빅데이터분석기사<br>신용분석사<br>애자일전문가(ACP)<br>정보처리기사<br>프로젝트관리전문가(PMP,P.M.I)<br>일반행정사</span></td>
                    </tr>
                    <tr>
                      <td rowspan="2"><span class="td-text fs-8">E</span></td>
                      <td rowspan="2"><span class="td-text fs-8">30</span></td>
                      <td><span class="td-text fs-8">300</span></td>
                      <td><span class="td-text fs-9">AWS Solution Architect-Associate<br>CCNA<br>LPIC-1<br>OCPJP<br>SQL개발자(SQLD)<br>Tableau Desktop Specialist<br>개인정보관리사(CPPG)<br>국제무역사1급<br>기술신용평가사3급<br>기술지도사<br>네트워크관리사2급<br>데이터분석준전문가(ADsP)<br>데이터아키텍처준전문가(DAsP)<br>마이데이터관리사<br>리눅스마스터1급<br>신용관리사<br>신용상담사<br>외환전문역1종<br>외환전문역2종<br>인터넷정보관리사1급<br>전자상거래관리사1급<br>증권투자권유자문인력<br>텔레마케팅관리사<br>한국자금세탁방지전문가(KCAMS)<br>사회조사분석사 2급</span></td>
                    </tr>
                    </tbody>
                  </table>
                  <p class="sub-text">※ <span class="text-red">신청일 당시</span> 등급기준에 따라 마일리지 및 비용 지급</p>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item">
            <div class="accordion" :class="{'is-active' : toggle.f}">
              <a class="accordion-toggle-action" @click="clickToggle('partF')">
                <div class="accordion-row">
                  <div class="accordion-column">
                    <div class="accordion-title">등급 자격증 지원 예시</div>
                  </div>
                  <div class="accordion-icon"><i class="icon-arrow"></i></div>
                </div>
              </a>
              <div class="accordion-target">
                <p class="desc mt-3 mb-2">E등급 자격증 기준</p>
                <div class="kb-table kb-table-bordered kb-table-bordered-all">
                  <table>
                    <colgroup>
                      <col style="width:16%" />
                      <col style="width:21%" />
                      <col style="width:21%" />
                      <col style="width:21%" />
                      <col style="width:21%" />
                    </colgroup>
                    <tbody>
                    <tr>
                      <td><span class="th-title fs-8">유형</span></td>
                      <td><span class="td-text fs-8">A</span></td>
                      <td><span class="td-text fs-8">B</span></td>
                      <td><span class="td-text fs-8">C</span></td>
                      <td><span class="td-text fs-8">D</span></td>
                    </tr>
                    <tr>
                      <td><span class="th-title fs-8">학원비</span></td>
                      <td><span class="td-text fs-8">0</span></td>
                      <td><span class="td-text fs-8">100,000</span></td>
                      <td><span class="td-text fs-8">200,000</span></td>
                      <td><span class="td-text fs-8">300,000</span></td>
                    </tr>
                    <tr>
                      <td><span class="th-title fs-8">응시료</span></td>
                      <td><span class="td-text fs-8">50,000</span></td>
                      <td><span class="td-text fs-8">50,000</span></td>
                      <td><span class="td-text fs-8">50,000</span></td>
                      <td><span class="td-text fs-8">50,000</span></td>
                    </tr>
                    <tr>
                      <td><span class="th-title fs-8">교재비</span></td>
                      <td><span class="td-text fs-8">23,000</span></td>
                      <td><span class="td-text fs-8">23,000</span></td>
                      <td><span class="td-text fs-8">45,000</span></td>
                      <td><span class="td-text fs-8">60,000</span></td>
                    </tr>
                    <tr>
                      <td><span class="th-title fs-8">소요금액</span></td>
                      <td><span class="td-text fs-8">73,000</span></td>
                      <td><span class="td-text fs-8">173,000</span></td>
                      <td><span class="td-text fs-8">295,000</span></td>
                      <td><span class="td-text fs-8">410,000</span></td>
                    </tr>
                    <tr>
                      <td><span class="th-title fs-8">지원금액</span></td>
                      <td><span class="td-text fs-8">150,000</span></td>
                      <td><span class="td-text fs-8">150,000</span></td>
                      <td><span class="td-text fs-8">236,000</span></td>
                      <td><span class="td-text fs-8">300,000</span></td>
                    </tr>
                    <tr>
                      <td><span class="th-title fs-8">비고</span></td>
                      <td class="p-2"><span class="td-text fs-9">최소지원금액<br>(지원한도의 50%)</span></td>
                      <td class="p-2"><span class="td-text fs-9">최소지원금액<br>(지원한도의 50%)</span></td>
                      <td class="p-2"><span class="td-text fs-9">소요비용의 80%</span></td>
                      <td class="p-2"><span class="td-text fs-9">최대지원금액<br>(지원한도의 100%)</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- section -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">기타</h4>
          </header>
          <div class="section-content">
            <div class="content-item">
              <div class="desc">
                <p>1. 아래 자격증은 동일 자격증으로 간주하여 복수 취득 시에도 자격증 취득지원금 및 마일리지 1회만 지원</p>
                <div class="desc-type-title">
                  <ul>
                    <li>FP 계열</li>
                    <li class="fs-8">◦ 프라이빗뱅커 (CPB, 한국금융연수원 ) = 국제공인재무설계사 (CFP, 한국 FPSB)</li>
                    <li class="fs-8">◦ 자산관리사 (FP, 한국금융연수원 ) = 한국재무설계사 (AFPK, 한국 FPSB)</li>
                  </ul>
                  <ul>
                    <li>FRM 계열</li>
                    <li class="fs-8">◦ 美재무위험관리사 (FRM, G.A.R.P) = 재무위험관리사 (FRM, 금융투자협회)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="content-item">
              <div class="desc">
                <p>2. FP/IT 분야 학원비를 지원 받은 후 자격증을 취득한 경우 자격증 취득 지원 시 응시료만 80% 지원</p>
              </div>
            </div>
            <div class="content-item">
              <div class="desc">
                <p>3. 은행에서 지원한 유관과정에 참가한 후 자격증을 취득한 직원은 자격증 취득 비용 지원 제외<sup style="display:contents;">주)</sup></p>
                <p class="my-2">◦ 단, 아래의 경우 응시료의 80% 지원</p>
                <div class="kb-table kb-table-striped">
                  <table>
                    <colgroup>
                      <col style="width:30%" />
                      <col style="width:70%" />
                    </colgroup>
                    <thead>
                    <tr>
                      <th><span class="th-title">구분</span></th>
                      <th><span class="th-title">적용기준일</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td><span class="td-text">CDCS</span></td>
                      <td><span class="td-text">2011년 이후</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">CSDG</span></td>
                      <td><span class="td-text">2017년 이후</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">CTFC</span></td>
                      <td><span class="td-text">2018년 이후</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">CFP</span></td>
                      <td><span class="td-text">2018년 이후</span></td>
                    </tr>
                    <tr>
                      <td><span class="td-text">CPB</span></td>
                      <td><span class="td-text">2020년 7월 이후</span></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <p class="sub-text">주) FP, 신용분석사 관련 연수과정에 참가한 경우는 지원 가능 (예비심사역 과정 수료 또는 참여 중인 연수생은 신용분석사 자격증 취득비용 지원불가)</p>
              </div>
            </div>
            <div class="content-item">
              <div class="desc">
                <p>▶ 학원비 기수혜 및 유관과정 수강여부 확인방법</p>
                <p class="mt-2">‘스타런 > 자기주도학습지원 자격증지원 신청 > 지원신청’ 화면에서 본인이 지원 신청하는 자격증 선택 시 기수혜 여부 확인 가능</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <SupportStatusMobileLicenseModal v-if="isActive"
    :jncmpYmd="jncmpYmd"
  />
</template>

<script>
import {computed, reactive, ref} from 'vue';
import SupportStatusMobileLicenseModal from "@/components/support/mobile/SupportStatusMobileLicenseModal";
import {useStore} from "vuex";
import {ACT_GET_JNCMP_YMD, MUT_SHOW_SUPPORT} from "@/store/modules/support/support";
import {useAlert} from "@/assets/js/modules/common/alert";
import {getItems, ymdStrToFormat} from "@/assets/js/util";
import {getDsjChk} from "@/assets/js/modules/support/support-common"; //, getRefundChk
import {checkRefundConsult} from "@/assets/js/modules/consult/consult-common";

export default {
  name: 'SupportLicenseAcquisitionMobileGuide',
  components: {SupportStatusMobileLicenseModal},
  setup() {
    const toggle = reactive({
      a: false,
      b: false,
      c: false,
      d: false,
      e: false,
      f: false,
    })

    const clickToggle = (part) => {
      if('partA'===part) toggle.a = !toggle.a;
      if('partB'===part) toggle.b = !toggle.b;
      if('partC'===part) toggle.c = !toggle.c;
      if('partD'===part) toggle.d = !toggle.d;
      if('partE'===part) toggle.e = !toggle.e;
      if('partF'===part) toggle.f = !toggle.f;
    }

    const store = useStore();
    const isActive = computed(() => store.state.support.isSupport);
    const {showMessage} = useAlert();

    const clickSupport = async() => {
      //지원가능 대상자 체크
      let chkVal = await getDsjChk();
      if (getItems(chkVal) > 0){
        showMessage('자격증 지원 신청 대상이 아닙니다. ');
        return false;
      }else{
        checkRefundConsult(`#lrnerNm#님은 연수과정 미수료에 의한 <br>교육훈련비 #rtnAmt#원(#count#건) 환입 대상자 입니다.<br>환입 완료 전까지 <span style=color:#FF0000!important;>연수신청(자기주도학습지원 포함)이 제한</span>됩니다.<br><br> 연수비용 환입은 인재개발)<br>최슬비대리(☎7880)에게<br>문의 부탁드립니다.`, licModalOpen );
      }
    }
    const licModalOpen=()=>{
      store.commit(`support/${MUT_SHOW_SUPPORT}`);
    }

    const clickTel = (number) => {
      document.location.href=`tel:02-2073-${number}`;
    }

    const jncmpYmd = ref('');
    const getJncmpYmd=() => {
      store.dispatch(`support/${ACT_GET_JNCMP_YMD}`).then(res=>{
        jncmpYmd.value = ymdStrToFormat(res.jncmpYmd, "-");
      });
    };
    getJncmpYmd();

    return {
      isActive,
      toggle,
      clickToggle, clickSupport, clickTel,
      jncmpYmd

    }
  }
};
</script>