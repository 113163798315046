import {computed, reactive, onMounted, watch, ref} from 'vue';
import {useStore} from "vuex";
import {getItems, getItem, lengthCheck, timestampToDateFormat, isSuccess, numberComma} from "@/assets/js/util";
import {useAlert} from '@/assets/js/modules/common/alert';
import {useRouter} from 'vue-router';
import {sslRoutes, enrollSSL, getMyLearnTargetList} from '@/assets/js/modules/ssl/ssl-common'; //,, noneRewardCds
import {ACT_CANCEL_SSL, ACT_GET_SSL_APPLY_CNT, MUT_SET_ENROLL_CNT, ACT_GET_MY_BJUGBNM} from '@/store/modules/ssl/ssl-index';
import {goBack} from '@/assets/js/modules/common/common';
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {expsrLocCds} from "@/assets/js/modules/help/help-common";
import {downloadFileDownMng} from "@/assets/js/modules/common/filedown";

export const sslApplySetup = () => {
    const {showMessage, showLoading, hideLoading, showConfirm} = useAlert();
    const router = useRouter();
    const store = useStore();
    const notices = ref([]);
    const getHelpNoticeList = () => {
        store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
            expsrLocCd: expsrLocCds.EXPSR_LOC_CD_MAIN,
            pageSize: 5
        }).then(res => {
            if (lengthCheck(res)) {
                notices.value = getItems(res).map(x => ({
                    ...x,
                    regDt: timestampToDateFormat(x.regDt, 'yyyy.MM.dd')
                }));
            } else {
                notices.value = [];
            }
        }).catch(e => {
            console.error(e);
        })
    };
    const enroll = reactive({
        key: null,
        toggle: false,
        stt:false,
    });

    // const selfCnt =  computed(() => store.state.ssl.selfAppCnt);
    const selfAppJaCnt =  computed(() => store.state.ssl.selfAppJaCnt);
    const selfAppGaCnt =  computed(() => store.state.ssl.selfAppGaCnt);
    const selfAppGiCnt =  computed(() => store.state.ssl.selfAppGiCnt);
    const selfAppIctCnt =  computed(() => store.state.ssl.selfAppIctCnt);
    const totAppNonDupCnt =  computed(() => store.state.ssl.totAppNonDupCnt);
    const groupCnt =  computed(() => store.state.ssl.groupAppCnt);
    const jisikeCnt =  computed(() => store.state.ssl.jisikeAppCnt);
    const cloudCnt =  computed(() => store.state.ssl.cloudAppCnt);
    const bookCnt =  computed(() => store.state.ssl.bookAppCnt);
    const bookLimitCnt = ref(2700);

    const myLrnTrgtGrpMstSns = computed(() => store.state.ssl.myLearnTargets.map(x => x.lrnTrgtGrpMstSn));
    const myLrnDistCrseSn = computed(() => store.state.ssl.myLearnTargets.map(x => x.distCrseSn));

    const getApplyCnt = () => {
        store.dispatch(`ssl/${ACT_GET_SSL_APPLY_CNT}`, {
            lrnerId: '',
        }).then(res => {
            store.commit(`ssl/${MUT_SET_ENROLL_CNT}`,getItems(res)[0]);
        });
    };

    const sslMoveLink = computed(() => {
        return Object.values(sslRoutes)
            .filter(z => z.key != 'trivia' && z.key != 'bestNote' && z.key != 'myssl')// z.key != "book" &&  2024년 북러닝 단독신청
            .map(x => {
                //target* : 내가 신청한 과정
                let targetLrnTrgtGrpMstSn = 0;
                let targetLrnTrgtGrpNm = "";
                const targetLrnTrgtGrpMstSns = x.lrnTrgtGrpMstSns.filter(x => myLrnTrgtGrpMstSns.value.includes(x));
                const targetLrnDistCrseSn = x.distCrseSns.filter(x => myLrnDistCrseSn.value.includes(x));

                let subjNm = "";
                if (x.key.indexOf('self') > -1) {
                    subjNm = "<span style=color:#545045!important;font-weight:bold>[셀프러닝]</span><br>"; // x.name;
                } else {
                    subjNm = "<span style=color:#545045!important;font-weight:bold>[" + x.name + "]</span><br>"; // x.name;
                }

                let display = "display:none";
                if (targetLrnTrgtGrpMstSns.length > 0) {
                    targetLrnTrgtGrpMstSn = targetLrnTrgtGrpMstSns[0];
                    // if (x.key == "self") { // ||x.key == "group"
                    //     targetLrnTrgtGrpNm = sslRoutes[x.key].group.find(x => x.lrnTrgtGrpMstSn === targetLrnTrgtGrpMstSn).lrnTrgtGrpNm;
                    // }
                }
                // if (x.key == "self" || x.key == "group" || x.key == "knowledge" || x.key == "hrd" || x.key == "book" || x.key == "trivia") display="";
                if (x.key == "selfGa" || x.key == "selfGi" || x.key == "selfJa" || x.key == "selfIct" || x.key == "selfDvl" || x.key == "group" || x.key == "book") display = "";

                // subjNm = "<span style=color:#545045!important;font-weight:bold>'+x.name+'</span><br>";

                // if(x.key == "book"){
                //     subjNm = "<span style=color:#545045!important;font-weight:bold>북러닝</span><br>";
                // }else if (x.key == "group"){
                //      subjNm = "<span style=color:#545045!important;font-weight:bold>그룹러닝</span><br>";
                //}else if (x.key == "knowledge"){
                //     subjNm = "<span style=color:#545045!important;font-weight:bold>지식e러닝</span><br>";
                // }else if (x.key == "trivia"){
                //     subjNm = "<span style=color:#545045!important;font-weight:bold;line-height:1.3;>알SSL신잡<br>러닝라이브리뷰<br>직무College</span><br>";
                // }else if (x.key == "self"){
                //     subjNm = "<span style=color:#545045!important;font-weight:bold>셀프러닝</span><br>";
                // }else if (x.key == "hrd"){
                //     subjNm = "<span style=color:#545045!important;font-weight:bold>HRD클라우드러닝</span><br>";
                // }

                // console.log('name : '+x.name);
                // console.log('key : '+x.key);
                // console.log('lrnTrgtGrpMstSn : '+targetLrnTrgtGrpMstSn);
                // console.log('lrnTrgtGrpNm : '+targetLrnTrgtGrpNm);
                // console.log('distCrseSn : '+targetLrnDistCrseSn);
                // console.log('firstLrnTrgtGrpMstSn : '+x.lrnTrgtGrpMstSns[0]);
                // console.log('display : '+display);
                // console.log('----------------------------------------------------');
                return {
                    name: subjNm,
                    key: x.key,
                    lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn,
                    lrnTrgtGrpNm: targetLrnTrgtGrpNm,
                    distCrseSn: targetLrnDistCrseSn,
                    firstLrnTrgtGrpMstSn: x.lrnTrgtGrpMstSns[0],
                    dp: display,
                }
            })

    });

    // const sslMoveLink = computed(() => {
    //     return Object.values(sslRoutes).map(x => {
    //         let targetLrnTrgtGrpMstSn = 0;
    //         let targetLrnTrgtGrpNm = "";
    //         const targetLrnTrgtGrpMstSns = x.lrnTrgtGrpMstSns.filter(x => myLrnTrgtGrpMstSns.value.includes(x));
    //         const targetLrnDistCrseSn = x.distCrseSns.filter(x => myLrnDistCrseSn.value.includes(x));
    //         let subjNm = x.name;
    //
    //         let display = "display:none";
    //         if (targetLrnTrgtGrpMstSns.length > 0) {
    //             targetLrnTrgtGrpMstSn = targetLrnTrgtGrpMstSns[0];
    //             if (x.key == "self") { // ||x.key == "group"
    //                 targetLrnTrgtGrpNm = sslRoutes[x.key].group.find(x => x.lrnTrgtGrpMstSn === targetLrnTrgtGrpMstSn).lrnTrgtGrpNm;
    //             }
    //         }
    //         if (x.key == "self" || x.key == "group" || x.key == "knowledge" || x.key == "hrd" || x.key == "book" || x.key == "trivia") display="";
    //
    //
    //         if(x.key == "book"){
    //             subjNm = "<span style=color:#545045!important;font-weight:bold>북러닝</span><br>";
    //         }else if (x.key == "group"){
    //             subjNm = "<span style=color:#545045!important;font-weight:bold>그룹러닝</span><br>";
    //         }else if (x.key == "knowledge"){
    //             subjNm = "<span style=color:#545045!important;font-weight:bold>지식e러닝</span><br>";
    //         }else if (x.key == "trivia"){
    //             subjNm = "<span style=color:#545045!important;font-weight:bold;line-height:1.3;>알SSL신잡<br>러닝라이브리뷰<br>직무College</span><br>";
    //         }else if (x.key == "self"){
    //             subjNm = "<span style=color:#545045!important;font-weight:bold>셀프러닝</span><br>";
    //         }else if (x.key == "hrd"){
    //             subjNm = "<span style=color:#545045!important;font-weight:bold>HRD클라우드러닝</span><br>";
    //         }
    //
    //         return {
    //             name: subjNm,
    //             key: x.key,
    //             lrnTrgtGrpMstSn: targetLrnTrgtGrpMstSn,
    //             lrnTrgtGrpNm:targetLrnTrgtGrpNm,
    //             distCrseSn:targetLrnDistCrseSn,
    //             firstLrnTrgtGrpMstSn: x.lrnTrgtGrpMstSns[0],
    //             dp:display,
    //         }
    //     })
    //
    // });

    // const myGroup = computed(() => store.state.ssl.group);

    // const goMain = (key, lrnTrgtGrpMstSn)  => {
    //     router.push({name: 'SSLMain', params: {distCrseCd: key, lrnTrgtGrpMstSn}}).then(() => {});
    // };

    const cancelConfirm =  (key, lrnTrgtGrpMstSn, lrnTrgtGrpNm)  =>{
        showConfirm({
            title: '신청 취소',
            text : `${sslRoutes[key].name}${lrnTrgtGrpNm !== "" ? `(${lrnTrgtGrpNm})` : ''} 과정을 취소하시겠습니까?`,
            callback: () => {goCancel(key, lrnTrgtGrpMstSn)}
        });
    }


    const goCancel=(key, lrnTrgtGrpMstSn) => {
        //alert("distCrseSn"+distCrseSn);
        // showLoading();
        store.dispatch(`ssl/${ACT_CANCEL_SSL}`, lrnTrgtGrpMstSn).then(res => {
            if(isSuccess(res)) {
                showMessage('취소되었습니다.');
                getMyLearnTargetList();
                getApplyCnt();
            }
        }).catch(e => {
            showLoading();
            console.error(e);
        });

    };

    const myBjuGbNm = ref('');

    const getMyBjuGbNm = () => {
        store.dispatch(`ssl/${ACT_GET_MY_BJUGBNM}`).then(res => {
            if(lengthCheck(res)){
                myBjuGbNm.value = getItem(res);
            }
        });
    }
    const goLearn = moveRoute => {

        // console.log('key : ' + moveRoute.key);
        // 이미 입과 되어 있다.
        if (moveRoute.lrnTrgtGrpMstSn > 0) {

            if (moveRoute.key === 'group'){

                showConfirm({
                    text : '그룹러닝 신청페이지로 이동합니다.',
                    callback: () => {router.push({name: 'GroupLearning'});}
                });

            }else{
                cancelConfirm(moveRoute.key, moveRoute.lrnTrgtGrpMstSn, moveRoute.lrnTrgtGrpNm);
            }


        } else {
            if (moveRoute.key === 'group'){

                showConfirm({
                    text : '그룹러닝 신청페이지로 이동합니다.',
                    callback: () => {router.push({name: 'GroupLearning'});}
                });

            }else if(moveRoute.key === 'self'){
                enroll.key = moveRoute.key;
                enroll.toggle = true;
                enroll.stt = false;
            }else{
                // alert(moveRoute.key);
                if (moveRoute.key === 'book'){
return;
                    // if(bookCnt.value >= bookLimitCnt.value){
                    //     return;
                    // }
                    //
                    // //alert(myBjuGbNm.value);
                    // if(myBjuGbNm.value === '해외지점'){
                    //     showMessage('아쉽지만 해외지점 근무직원은\n북러닝신청이 불가능합니다.');
                    //     return;
                    // }

                }

                showConfirm({
                    text : `${sslRoutes[moveRoute.key].name}${moveRoute.lrnTrgtGrpNm !== "" ? `(${moveRoute.lrnTrgtGrpNm})` : ''} 과정을 신청하시겠습니까?`,
                    callback: () => {enrollSSL(moveRoute.firstLrnTrgtGrpMstSn, result => {
                        if (result > 0) {
                            getMyLearnTargetList(() => {
                                hideLoading();
                                // showMessage('신청완료되었습니다. \n도서신청은 8.22(화) 부터 진행되며\n 도서신청 방법은 추후에 일괄 안내 예정입니다.');
                                showMessage('신청완료되었습니다.');
                                getApplyCnt();
                                getMyLearnTargetList();
                                //goMain(moveRoute.key, moveRoute.firstLrnTrgtGrpMstSn);
                            }).then(() => {});
                        }else if (result == -88){
                            hideLoading()
                            showMessage('17시부터 신청이 가능합니다.');
                            getApplyCnt();
                        }else if (result == -99){
                            hideLoading()
                            showMessage('아쉽지만 신청인원이 마감되었습니다.');
                            getApplyCnt();
                        } else {
                            hideLoading()
                            showMessage('서버처리 중 일시적인 장애가 발생하였습니다.<br>잠시 후 다시 시도 해 주시기 바랍니다.');
                        }
                    })}
                });

            }
        }
        // }
    };


    const downloadFile = (dfId) => {
        downloadFileDownMng(dfId);
    };

    getHelpNoticeList();

    onMounted(() => {
        getMyLearnTargetList();
        getApplyCnt();
        getMyBjuGbNm();
    });


    watch(() => enroll.stt, () => {
        if(enroll.stt){
            getMyLearnTargetList();
            getApplyCnt();
        }

    },{deep:true});

    return{
        sslMoveLink, enroll, goLearn, numberComma //, goCancel, cancelConfirm
        ,selfAppGaCnt, selfAppGiCnt, selfAppJaCnt, selfAppIctCnt, groupCnt, jisikeCnt, cloudCnt, bookCnt, bookLimitCnt,
        downloadFile, myBjuGbNm,goBack,sslRoutes,notices, totAppNonDupCnt
    }

}