<template>
  <!-- board-list -->
  <swiper
      :class="swiperClass"
      :modules="modules"
      :slides-per-view="slidesPerView"
      :slides-offset-after="20"
      effect="fade"
      :navigation="{
        nextEl: '.kb-btn-note-next',
        prevEl: '.kb-btn-note-prev'
      }"
  >
    <swiper-slide v-for="(item, idx) in sslItems" :key="idx" class="board-item">
      <article class="post-card" :data-read="item.readYn === 'Y' ? 'active' : ''" @click="goNote(item)">
        <a href="javascript:" class="post-link"></a>
        <div class="post-image" style="background-color: var(--kb-brown)">
          <img  v-if="item.thumb" :src="refineThumbUrl(item.thumb)" alt="">
        </div>
        <div class="post-deem"></div>
        <div class="post-content">
          <div class="post-content-date">
            <h6>{{ item.date }}</h6>
            <h1>{{ item.day }}</h1>
          </div>
          <section class="post-content-desc">
            <div class="post-title">
              <h3 class="title">{{ item.title }}</h3>
            </div>
            <div class="post-desc">
              <div class="post-content-date">
                <h6>{{ item.date }}</h6>
                <h1>{{ item.day }}</h1>
              </div>
              <span v-html="item.title"></span>
              <p v-html="(item.cn !=null && item.cn.length > 300 ? item.cn.substring(0,300) + '...':item.cn)"></p>
            </div>
<!--            <div class="post-desc">-->
<!--              <p>{{item.desc}}</p>-->
<!--            </div>-->
            <div class="post-profile">
              <div class="author">
                <span class="name">{{ item.lrnerNm }}&nbsp;</span>
                <span class="office">{{ item.deptNm }}</span>
              </div>
            </div>
          </section>
          <div class="post-content-read" :class="{'is-active' : item.readYn === 'Y'}">
            <i class="icon-read"></i>
            <span class="text">읽음</span>
          </div>
        </div>
      </article>
    </swiper-slide>
    <div class="arrow-nav">
      <button class="icon-arrow-prev kb-btn-note-prev is-active"></button>
      <button class="icon-arrow-next kb-btn-note-next is-active"></button>
    </div>
  </swiper>
  <!-- //board-list -->
<!--  <SSLNotePopupModal v-if="noteModal && !isMobile" v-model="noteModal" :soc-lrn-post-sn="socLrnPostSn" :dist-crse-cd="distCrseCd"/>-->
  <SSLEnroll
      v-if="enroll.toggle"
      v-model:toggle="enroll.toggle"
      :dist-crse-cd="enroll.key"
      :socLrnPostSn="enroll.socLrnPostSn"
  />
</template>

<script>
import {computed, reactive, ref} from "vue";
import {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
// import SSLNotePopupModal from "@/components/main/SSLNotePopupModal";

import navigationUtils from "@/assets/js/navigationUtils";
import {useRouter} from "vue-router";
// import {useStore} from "vuex";
import {getCrseSnToDistCrseCd, getNecessaries} from "@/assets/js/modules/ssl/ssl-common"; // ,isMyLrnTrgtGrpMstSn
import SSLEnroll from "@/components/ssl/common/SSLEnroll.vue";
import store from "@/store";
import {aws2ncloudUrl} from "@/assets/js/util";

export default {
  name: "SSLNoteCard",
  components: {
    SSLEnroll,
    // SSLNotePopupModal,
    Swiper, SwiperSlide
  },
  props: {
    slidesPerView: [Number,String],
    swiperClass: {type: String, default: 'board-card-type'},
    sslItems: Object
  },
  setup() {
    // const store = useStore();
    const router = useRouter();

    const isMobile = navigationUtils.any();
    const session = computed(() => store.state.auth.session);

    const noteModal = ref(false);
    const socLrnPostSn = ref(0);

    const distCrseCd = ref('');

    const enroll = reactive({
      key: null,
      toggle: false,
      socLrnPostSn:0,
    });

    const refineThumbUrl =  (thumb) => {
      if (session.value.itn) {
        return thumb;
      } else {
        // console.log('aws2ncloudUrl', aws2ncloudUrl(thumb))
        return aws2ncloudUrl(thumb);
      }
    }

    if(store.state.ssl.myLearnTargets == null || store.state.ssl.myLearnTargets == ''){
      console.log('myLearnTargets1-1 : ' + store.state.ssl.myLearnTargets);
      getNecessaries();
    }


    // const isMyT = computed(()=>{ return store.state.ssl.myLearnTargets});

    const goNote = async(item) => {
      socLrnPostSn.value = item.socLrnPostSn;
      // distCrseCd.value = getDistCrseCd(item.distCrseSn.toString());
      distCrseCd.value = getCrseSnToDistCrseCd(item.crseMstSn);

      // // console.log('myLearnTargets1 : ' + store.state.ssl.myLearnTargets);
      // // if(store.state.ssl.myLearnTargets == null || store.state.ssl.myLearnTargets == ''){
      // //   console.log('myLearnTargets1-1 : ' + store.state.ssl.myLearnTargets);
      // //   await getNecessaries();
      // // }
      // // console.log('myLearnTargets2 : ' + store.state.ssl.myLearnTargets);
      //
      // // console.log('item.lrnTrgtGrpMstSn : ' + item.lrnTrgtGrpMstSn);
      // // console.log('신청여부 : ' + isMyLrnTrgtGrpMstSn(item.lrnTrgtGrpMstSn));
      // // console.log('distCrseCd : ' + distCrseCd.value);
      //
      // // await nextTick();
      // if ( isMyLrnTrgtGrpMstSn(item.lrnTrgtGrpMstSn) || distCrseCd.value == 'trivia') { //신청한 과정인경우
      //
      //   // if (isMobile) {
      //   //   router.push({
      //   //     name: 'LxpMobileSSLView',
      //   //     params: {distCrseCd: item.distCrseSn, socLrnPostSn: socLrnPostSn.value}
      //   //   });
      //   // } else {

          router.push({
            name: 'SSLView',
            params: {
              distCrseCd: distCrseCd.value,
              lrnTrgtGrpMstSn: item.lrnTrgtGrpMstSn,
              socLrnPostSn: item.socLrnPostSn
            }
          });
      //   //  // noteModal.value = true;
      //   // }
      // }else{
      //
      //   enroll.key = distCrseCd.value;
      //   enroll.toggle = true;
      //   enroll.socLrnPostSn = item.socLrnPostSn;
      // }
    }

    return {
      noteModal,
      socLrnPostSn,
      distCrseCd,
      isMobile,
      goNote,
      modules: [Navigation],
      enroll,
      refineThumbUrl
    }
  }
}
</script>