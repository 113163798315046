<template>
  <div class="top-tabs">
    <template v-for="(item, idx) in items">
      <router-link
          v-if="isNoneReward ? (idx !== 4) : true"
          :to="item.to"
          :key="idx"
          class="tab" :class="{'is-active': item.key === activeKey}"
      >
        <span class="tab-text">{{ item.name }}</span>
      </router-link>
    </template>

    <template v-if="!isMobile">
      <router-link v-if="distCrseCd === 'hrd'" :to="{name: 'HrdCloud'}" class="link">
        <span class="link-text">HRD클라우드</span>
        <i class="icon-link"></i>
      </router-link>
    </template>
  </div>
</template>

<script>

import {computed, ref} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {getRouteLrnTrgtGrpMstSn, isMyLrnTrgtGrpMstSn, noneRewardCds, ssoKB} from '@/assets/js/modules/ssl/ssl-common';

export default {
  name: 'SSLMyRoute',
  props:{
    year : Number
  },
  setup(props){
    const store = useStore();
    const route = useRoute();

    const isMobile = computed(() => store.state.auth.isMobile);
    const session = computed(() => store.state.auth.session);

    const distCrseCd = computed(() => route.params.distCrseCd);
    const isNoneReward = computed(() => noneRewardCds.includes(route.params.distCrseCd));
    const lrnTrgtGrpMstSn = computed(() => getRouteLrnTrgtGrpMstSn(route.params.lrnTrgtGrpMstSn));
    const myGroup = computed(() => store.state.ssl.group);

    const activeKey = computed(() => {
      if (route.params.view) {
        return route.params.view;
      }
      return 'status';
    });

    const items = computed(() => {
      if (distCrseCd.value === 'group') {
        if (session.value.lrnerId === myGroup.value.leaderLrnerId) {
          return [
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'status'}}, name: '학습현황', key: 'status'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'complete'}}, name: '등록완료', key: 'complete'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'temporary'}}, name: '임시저장', key: 'temporary'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'save'}}, name: '담기', key: 'save'},
            // {to: {name: 'SSLTotalMyView', params: {view: 'subscribe'}}, name: '구독', key: 'subscribe'},
            // {to: {name: 'SSLTotalMyView', params: {view: 'subscribed'}}, name: '나를 구독', key: 'subscribed'},
          ];
        } else {
          return [
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'status'}}, name: '학습현황', key: 'status'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'save'}}, name: '담기', key: 'save'},
            // {to: {name: 'SSLTotalMyView', params: {view: 'subscribe'}}, name: '구독', key: 'subscribe'},
            // {to: {name: 'SSLMyView', params: {view: 'subscribed'}}, name: '나를 구독', key: 'subscribed'},
          ];
        }
      } else if (noneRewardCds.includes(distCrseCd.value)) {
        if (distCrseCd.value === 'college') {
          if (isMyLrnTrgtGrpMstSn(lrnTrgtGrpMstSn.value)) {
            return [
              {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'status'}}, name: '학습현황', key: 'status'},
              {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'complete'}}, name: '등록완료', key: 'complete'},
              {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'temporary'}}, name: '임시저장', key: 'temporary'},
              {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'save'}}, name: '담기', key: 'save'},
            ];
          } else {
            return [
              {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'status'}}, name: '학습현황', key: 'status'},
              {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'save'}}, name: '담기', key: 'save'},
            ];
          }
        } else {
          return [
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'status'}}, name: '학습현황', key: 'status'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'complete'}}, name: '등록완료', key: 'complete'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'temporary'}}, name: '임시저장', key: 'temporary'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'save'}}, name: '담기', key: 'save'},
          ];
        }
      } else {
        // if(distCrseCd.value == 'total'){
        //   return [
        //     {to: {name: 'SSLTotalMy'}, name: '학습현황', key: 'status'},
        //     {to: {name: 'SSLTotalMyView', params: {view: 'complete'}}, name: '등록완료', key: 'complete'},
        //     {to: {name: 'SSLTotalMyView', params: {view: 'temporary'}}, name: '임시저장', key: 'temporary'},
        //     {to: {name: 'SSLTotalMyView', params: {view: 'save'}}, name: '담기', key: 'save'},
        //   ];
        // }else {
          return [
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'status'}}, name: '학습현황', key: 'status'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'complete'}}, name: '등록완료', key: 'complete'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'temporary'}}, name: '임시저장', key: 'temporary'},
            {to: {name: 'SSLTotalMyView', params: {year:props.year, view: 'save'}}, name: '담기', key: 'save'},
            // {to: {name: 'SSLTotalMyView', params: {view: 'subscribe'}}, name: '구독', key: 'subscribe'},
            // {to: {name: 'SSLTotalMyView', params: {view: 'subscribed'}}, name: '나를 구독', key: 'subscribed'},
          ];
        // }
      }
    });

    const isLoading = ref(false);

    return {
      isLoading,
      isMobile,
      distCrseCd,
      items,
      isNoneReward,
      activeKey,
      ssoKB
    }
  }
}
</script>
