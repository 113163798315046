<template>
  <aside class="today-wrapper">
    <ul class="today-list">
      <li v-if="schedules.length === 0" class="today-item">등록된 일정이 없어요.</li>
      <template v-else v-for="(item, idx) in schedules" :key="idx">
        <li class="today-item" :class="{'is-active' : ((todayTime >= item.objBgngDt) && (todayTime <= item.objEndDt) && (item.objEndYn === 'N'))}">
          <div v-if="!item.cpCrseDistCd" class="today-inner kb-mouse-cursor" @click="clickMove(item)">
            <div class="today-content">
              <strong class="title">{{ item.objNm }}</strong>
              <p class="text">{{ item.lrnObjTyNm }}</p>
            </div>
            <div class="today-now animate__animated animate__fadeIn animate__repeat-3" v-if="item.lrnObjTyCd === '100031'" ></div>
            <div class="today-time">
              <div class="time" :class=" {'learning-time' : item.lrnObjTyCd === '100031'}" >{{ item.lrnTime }}</div>
            </div>
          </div>
          <div v-else class="today-inner">
            <div class="today-content">
              <strong class="title">{{ item.objNm }}</strong>
              <p class="text">{{ item.lrnObjTyNm }}</p>
            </div>
            <div class="today-time">
              <span class="time">{{ item.lrnTime }}</span>
            </div>
          </div>
        </li>
      </template>
    </ul>
    <img class="sc-gradation up" src="../../assets/lxp/images/main/scroll_gradation_24.png">
    <img class="sc-gradation bottom" src="../../assets/lxp/images/main/scroll_gradation.png">
  </aside>
</template>

<script>
import {ref} from "vue";
import {lrnObjTyCds} from "@/assets/js/modules/learn/learn-common";
import {ACT_GET_LEARN_OBJECT_TODAY_LIST} from "@/store/modules/learn/learn";
import {
  dateToDateFormat,
  dateToDatePart,
  getItems,
  getTodayDate,
  lengthCheck,
  timestampToDateFormat,
  timestampToDatePart
} from "@/assets/js/util";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "TodayNightList",
  props: {
    isKbBank: Boolean
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const schedules = ref([]);
    // 러닝 라이브(국민은행 계열만), 클라우드 나우(전행)
    const allScheduledDcds = [lrnObjTyCds.Evaluation, lrnObjTyCds.CloudNow, lrnObjTyCds.CloudNowLive, lrnObjTyCds.CloudOn, lrnObjTyCds.Survey,lrnObjTyCds.LearningTime];
    const scheduledDcds = [lrnObjTyCds.CloudNow];

    const currentYmd = dateToDatePart(new Date()).ymd;
    const todayTime = dateToDateFormat(new Date(),'yyyyMMddhhmm');

    const getLearnObjectScheduleList =  () => {
      store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_TODAY_LIST}`, {objTargetYmd: getTodayDate('yyyy-mm-dd')}).then(res => {
        if(lengthCheck(res)) {
          schedules.value = getItems(res).filter(x => (props.isKbBank ? allScheduledDcds : scheduledDcds).includes(x.lrnObjTyCd))
              .map(x => ({...x,
                objBgngYmd: timestampToDatePart(x.objBgngDt).ymd,
                objEndYmd: timestampToDatePart(x.objEndDt).ymd,
                objBgngDt: timestampToDateFormat(x.objBgngDt, 'yyyyMMddhhmm'),
                objEndDt: timestampToDateFormat(x.objEndDt, 'yyyyMMddhhmm'),
                lrnTime:  (timestampToDateFormat(x.objEndDt, 'yyyyMMddhhmm') < todayTime || x.objEndYn === 'Y' ? '종료' :`${timestampToDateFormat(x.objBgngDt, 'hh:mm')}~${timestampToDateFormat(x.objEndDt, 'hh:mm')}`),
                // lrnTime:  `${timestampToDateFormat(x.objBgngDt, 'hh:mm')}~${timestampToDateFormat(x.objEndDt, 'hh:mm')}`,
                lrnEnd : (timestampToDateFormat(x.objEndDt, 'yyyyMMddhhmm') < todayTime) ? 'Y' : 'N'
              }))
              .filter(x => (x.objBgngYmd === x.objEndYmd) &&(x.objBgngDt.substring(8)>=1800)) //  '객체 노출 타입은 평가, 비대면집합(NOW)만 노출'
              .sort((a, b)=>{
                if(a.lrnEnd === 'Y') return 1;
                else if(a.objBgngDt - b.objBgngDt) return -1;
                return  0;
              })
        } else {
          schedules.value = [];
        }
        console.log(schedules.value)
      })
    }

    const clickMove = (item) => {
      console.log(item);
      if(currentYmd === item.objBgngYmd){
        if (item.splCrseTyCdDcd === '2066010') {
          router.push({
            name: 'KnowledgeELearning',
            params: {distCrseSn: item.distCrseSn, view: 'index'},
            query: {learn: item.lrnObjDtlDistSn}
          });
        } else {
          if (item.lrnObjTyCd === lrnObjTyCds.CloudNow || item.lrnObjTyCd === lrnObjTyCds.CloudNowLive) {
            router.push({name: 'LiveCalendarMng'})
          } else if (item.lrnObjTyCd === lrnObjTyCds.CloudOn) {
            router.push({name: 'GroupView', params: {distCrseSn: item.distCrseSn}});
          }else if(item.lrnObjTyCd ===  lrnObjTyCds.LearningTime){
            router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn, mode: 'detail'}});
          } else {
            router.push({
              name: 'LearnMain',
              params: {distCrseSn: item.distCrseSn, view: 'index'},
              query: {learn: item.lrnObjDtlDistSn}
            });
          }
        }
      } else {
        router.push({name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'home' }});
      }
    }

    getLearnObjectScheduleList();

    return {
      todayTime,
      schedules,
      clickMove,
      timestampToDateFormat
    }
  }
}
</script>