<template>
  <main v-if="isMainActive" class="kb-main" id="kb-hrdcloud" >
    <LxpMobileHeader title="신박한 정리">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>

    </LxpMobileHeader>

    <div class="main-content main-component">
      <div class="kb-footer" id="kb-footer">
        <div v-if="session.lrnerId" class="footer-row footer-divider" style="border-top:0px;">
          <div class="footer-notice footer-component">
            <ul class="notice-list swiper-wrapper" style="width: 80%;">
              <swiper

                  :slides-per-view="1"
                  :modules="modules"
                  :navigation="{
                nextEl: '.kb-btn-notice-kb-nav-next',
                prevEl: '.kb-btn-notice-kb-nav-prev'
              }"
              >
                <swiper-slide v-for="(item, idx) in items" :key="idx" class="notice-item swiper-slide"
                              style="margin: 0;">
                  <div class="notice-date">
                    <a href="javascript:" class="link">{{ item.regDt }}</a>
                  </div>
                  <div class="notice-title">
                    <router-link :to="{name: 'HelpNoticeView', params: {ntcSn: item.comNtcSn}}" class="link">
                      {{ item.ntcTitle }}
                    </router-link>
                  </div>
                </swiper-slide>
                <!--            </swiper-slide>-->
              </swiper>
            </ul>
            <div class="notice-nav">
              <!--              <router-link to="" class="more-link">공지 전체보기</router-link>-->
              <div class="nav">
                <button class="kb-btn-nav kb-btn-kb-nav-prev kb-btn-notice-kb-nav-prev"><i class="icon-arrow-prev"></i></button>
                <button class="kb-btn-nav kb-btn-kb-nav-next kb-btn-notice-kb-nav-next"><i class="icon-arrow-next"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="tabMenu <= 0">
        <div class="intro-section ">
          <CommonBanner
              :banner-code=bannerCode
          />
          <article class="content-section section-my">
            <div class="my-info justify-content-evenly">
              <div class="avatar"><ProfileImg :target="session"/></div>
              <div class="info-content">
                <h4 class="name">{{ session.lrnerNm }}</h4>
                <p class="team">{{ session.deptNm }}</p>

              </div>
              <button class="kb-btn kb-btn-xs kb-btn-primary" @click="applyModalOpen"><span class="text">강사 참여하기</span></button>

          </div>
          </article>
        </div>
        <div class="kb-main" id="kb-home" style="padding-bottom: 10px !important;">
          <div class="intro-column section-my"  style="padding:36px 10px !important; border-top:8px solid #f7f4f0">
            <article class="content-section section-today" style="padding:0px 10px !important;">
              <header class="section-header d-flex">
                <h2 class="title" style="font-size: 20px">Today</h2>
                <div class="header-side">
                  <span class="text text-muted">{{ getTodayDate('년월일') }}</span>
                </div>
              </header>
              <TodayNightList :is-kb-bank="isKbBank"/>
            </article>

          </div>
        </div>

          <div class="kb-main" id ="kb-sdl-ssm">

            <div class="main-content min-component " style="border-top:8px solid #f7f4f0">
              <SumgoBoard
                  :target-item="targetItem2"
              />
            </div>
          </div>
  


      </template>

      <!--    <HrdCloudMap2 v-model="cloudMapModal"/>-->

      <section class="hrdcloud section">
        <template v-for="(item, idx) in mainList" :key="idx">
          <article class="content-section" >
            <header class="section-header"><h3 class="title">{{ item.title }}</h3>
              <div class="header-sub">
                <router-link :to="{name: 'HrdCloud', query:{sortTyCdDcd: '2073001',category1:'1',category2:'12',category3:'96' }}" class="more-link">전체보기</router-link>
              </div>
            </header>
            <template v-if="item.items && item.items.length > 0">
              <HrdCloudRow :items="item.items" :show-put="true" :session="session"/>
            </template>
            <template v-else-if="(item.items && item.items.length === 0) && !item.isLoading">
              <div class="search-container">
                <div class="result-empty">
                  <img src="../../../../assets/lxp/images/common/img_empty.png" alt="">
                  <p v-if="item.name === 'sumgo'" class="text">콘텐츠가 없습니다.</p>
                </div>
              </div>
            </template>
            <template v-else-if="item.isLoading">
              <LoadingDiv v-model="item.isLoading" />
            </template>
          </article>
        </template>
      </section>


    </div>

  </main>
  <MobileSumgoApplyModal v-if="!isMainActive" :cttMstSn="48510"/>
  <SumgoRecommandModal v-if="recommandModal" v-model="recommandModal" :cttMstSn="48510"/>
</template>


<script>
import {useStore} from 'vuex';
import {computed, reactive, ref} from 'vue';
import CommonBanner from '@/components/common/CommonBanner';
// import CommonTypeImage from '@/components/common/CommonTypeImage';
import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
// import SumgoEvent from '@/components/sumgo/SumgoEvent';
import {
  closeToggles,
  // convertCloudFrontUrl,
  getDcdOptions,
  getListFunc,
  pushQuery,
} from '@/assets/js/ui.init';
import {
  getCheckItems,
  getItems,
  initParams,
  initProgressBar,
  lengthCheck,
  stringCheck,
  timestampToDateFormat,
  getTodayDate,
} from '@/assets/js/util';
import {cttTableNm, getHrdCloudList, setMainItems, sortTyCdDcds} from '@/assets/js/modules/hrd/hrd-common';
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import TodayNightList from '@/components/main/TodayNightList';
import {useRoute} from 'vue-router';
import {
  ACT_GET_HRDCLOUD_SUMGO_LIST,
} from '@/store/modules/prep/prep';

import {ACT_GET_HRDCLOUD_CATE_SEARCH_LIST} from '@/store/modules/hrdcloud/hrdcloud';

import ProfileImg from '@/components/common/ProfileImg';
import {
  banerDivCdDcds,
  banerExpsrLocCdDcds,
  getBanerList,
} from '@/assets/js/modules/baner/common-baner';
import {Pagination} from "swiper";
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {ACT_GET_SUMGO_LEARNING_LIVE_LIST} from "@/store/modules/learn/learn";
// import InputSelect from '@/components/support/common/InputSelect';
import {expsrLocCds} from "@/assets/js/modules/help/help-common";
import LoadingDiv from '@/components/common/LoadingDiv';
import SumgoBoard from '@/components/sumgo/SumgoBoard';
import MobileSumgoApplyModal from "@/components/sumgo/mobile/MobileSumgoApplyModal";
import SumgoRecommandModal from "@/components/sumgo/SumgoRecommandModal";
// import RowIsPastDatepicker from '@/components/support/common/RowIsPastDatepicker';
import { goBack } from "@/assets/js/modules/common/common";
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {MUT_CLOSE_MAIN} from "@/store/modules/support/support";
export default {
  name: 'mobileSumgoMain',
  components: {
    CommonBanner,
    Swiper,
    SwiperSlide,
    // SumgoEvent,
    SumgoBoard,
    ProfileImg,
    // CommonTypeImage,
    // RowIsPastDatepicker,
    LoadingDiv,
    HrdCloudRow,
    MobileSumgoApplyModal,
    SumgoRecommandModal,
    // InputSelect,
    LxpMobileHeader,
    TodayNightList,
  },
  setup() {
    initProgressBar();
    const isMainActive = computed(() => store.state.support.main.show);
    const hrdLoading = ref(false);
    const store = useStore();
    const route = useRoute();
    const lochref = ref(location.href);
    const bannerCode = ref(banerExpsrLocCdDcds.sumgo);
    const cloudMapModal = ref(false);
    const items = ref([]);
    const items_tube = ref([]);
    const items_live = ref([]);
    const items_board = ref([]);
    const weekly_board_first = ref({});
    const weekly_board_second = ref({});
    const board_first = ref(0);
    const board_second = ref(0);
    const applyModal = ref(false)
    const recommandModal = ref(false);
    const showApplyModal = ref(false);
    const showRecommandModal = ref(false);
    const updateApplyModal = ref(false);
    const updateRecommandModal = ref(false);
    const show_board_item = ref({});
    const inqTyCdDcds = [{ cd: '2113001', cdNm: '스튜디오Live' }, { cd: '2113002', cdNm: '자택Live' }, { cd: '2113003', cdNm: '혼합형(녹화&Live)' }];
    const isKbBank = computed(() => session.value.susdyCdDcd && (session.value.susdyCdDcd === '2109001') && session.value.jbpsCd !=='A99');
    // const item = ref({});
    const mainList = ref([
      { name: 'sumgo', title: '강의 다시보기', params: {}, isLoading: true, items:[], url:  `prep/${ACT_GET_HRDCLOUD_SUMGO_LIST}` }
    ]);
    const isLoading = ref(false);
    const isSearching = computed(() => (route.query && route.query.keyword && route.query.keyword.length > 0));
    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));

    // 순서를 변경할때 getMainList의 idx값도 같이 바꿔줘야함

    const currentTs = ref(new Date().getTime());
    const menu = ref(1);
    const hrdCloudFilter = ref('sumgo');
    const myLikeTotalCnt = ref('0');

    const hrds = ref([]);
    const categories = ref([]);

    const param = reactive({
      tblNm: cttTableNm,
      tblSn: 0,
      comInqSn: 0,
      // inqTitle: '강사 신청하기',
      inqTyCdDcd: '2113001',
      inqCn: '',
      readYn: '',
      ansYn: '',
      ansCn: '',
      tuitFeePayDd: '',
    });
    const paging = reactive({
      pageNo: 1,
      pageSize: 12,
      totalCount: 0
    });
    const tubePaging = reactive({
      pageNo: 1,
      pageSize: 4,
      totalCount: 0
    });
    const paging2 = reactive({
      pageNo: 0,
      pageSize: 9999,
      totalCount: 0
    });
    const categoriesPaging = reactive({
      pageNo: 1,
      pageSize: 9999,
      totalCount: 0
    });

    const targetItem = computed(() => {
      return {
        distCrseSn: 203057,
        learners: []
      }
    });

     const applyModalOpen = () =>{
      store.commit(`support/${MUT_CLOSE_MAIN}`);
    }

    const targetItem2 = computed(() => {
      return {
        distCrseSn: 210391,
        learners: []
      }
    });
    //
    // const getDateDiff = (d1, d2) => {
    //   const date1 = new Date(d1);
    //   const date2 = new Date(d2);
    //
    //   const diffDate = date1.getTime() - date2.getTime();
    //
    //   return Math.abs(diffDate / (1000 * 60 * 60 * 24));
    // }
    //

    // const refineBoard = (item) => {
    //   const currentDate = new Date();
    //
    //   if (item.lrnerId == session.value.lrnerId) {
    //     myLikeTotalCnt.value = myLikeTotalCnt.value + item.likeCnt;
    //   }
    //   if (item.likeCnt > board_first.value && getDateDiff(currentDate, item.regDt) < 7) {
    //     board_second.value = board_first.value;
    //     weekly_board_second.value = weekly_board_first.value;
    //     weekly_board_first.value = item;
    //     board_first.value = item.likeCnt;
    //   }
    //   else if (item.likeCnt > board_second.value && getDateDiff(currentDate, item.regDt) < 7) {
    //     weekly_board_second.value = item;
    //   }
    //
    //   item.originCn = item.cn;
    //   item.comments = item.comments.map(y => ({...y, originCmnt: y.cmnt}));
    //   item.files = item.files.map(y => ({...y, url: convertCloudFrontUrl(session.value.itn, y, 'itnPath', 'etnPath')}));
    //   item.alreadyFiles = item.files;
    //   item.addedFiles = [];
    //   item.removedFiles = [];
    //   item.isLike = (item.myLikeCnt > 0);
    // }

    // const getBoardWithCommentList = () => {
    //   getListFunc(`board/${ACT_GET_LEARN_BOARD_WITH_COMMENT_LIST}`, {distCrseGrpSeqSn: distCrseGrpSeqSn.value, ...paging2}, items_board, paging2, () => {
    //     // if(isInit) loadings.board = true;
    //     myLikeTotalCnt.value = 0;
    //     board_first.value = 0;
    //     board_second.value = 0;
    //     items_board.value.forEach(x => {refineBoard(x);})
    //   });
    // }
    //
    // getBoardWithCommentList();

    // 콘텐츠명, 태그, 카테고리로 검색
    // 검색시 영문 대소문자 구분하지 않음
    const search = reactive({
      keyword: '',
      sortTyCdDcd: '',
      category1: 0,
      category2: 0,
      category3: 0,
      category4: 0,
      category5: 0
    });

    const params = ref({});
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });

    // KB Tube
    const uploadModal = reactive({
      isActive: false,
      toggle: () => {
        uploadModal.isActive = !uploadModal.isActive;
      },
    });

    const getList = async () => {
      let contentsList = await getHrdCloudList({...search, ...paging, ...params});
      hrds.value = getCheckItems(contentsList);
      paging.totalCount = contentsList && contentsList.paging ? contentsList.paging.totalCount : 0;
      if (stringCheck(search.keyword)) {
        let categoryList = await store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_SEARCH_LIST}`, {keyword: search.keyword, ...paging});
        categories.value = getCheckItems(categoryList);
        categoriesPaging.totalCount = categoryList && categoryList.paging ? categoryList.paging.totalCount : 0;
      }
      isLoading.value = false;
    };

    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    const getMainList = async () => {

      let sumgo = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_SUMGO_LIST}`, {...tubePaging});
      setMainItems(mainList, sumgo, 0);
    };

    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      if (pageNo) {
        pushQuery({...search, pageNo});
      } else {
        pushQuery({...search});
      }
    };

    const handleMenuClick = (tabMenu) => {
      hrds.value = [];
      initParams(search);
      search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
      if (tabMenu > 0) {
        search.category1 = tabMenu;
      }
      pagingFunc(1);
    };

    const cnSz = computed(() => {
      return (param.inqCn && param.inqCn.length ? param.inqCn.length : 0 );
    });





    const popups = ref([]);

    //location

    getBanerList({
      banerDivCdDcd: banerDivCdDcds.popup,
      banerExpsrLocCdDcd: banerExpsrLocCdDcds.hrdCloud,
      pageNo: 1,
      pageSize: 10
    }, popups);
    let nowDate = new Date();
    nowDate = nowDate.setTime(currentTs.value);

    const expirationPopup = computed(() => parseInt(localStorage.getItem('kb-popup-hrdcloud')) > nowDate);

    const getHelpNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_SUMGO_MAIN,
        pageSize: 5
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res).map(x => ({
            ...x,
            regDt: timestampToDateFormat(x.regDt, 'yyyy.MM.dd')
          }));
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }
    getHelpNoticeList();

    const getImgUrl = (lrnerId) => {
      return "https://lxpcms.kbstar.com/contents/emp_img/current/"+ lrnerId + ".jpg"
    };


    getListFunc(`learn/${ACT_GET_SUMGO_LEARNING_LIVE_LIST}`, {}, items_live, null, () => {
    });

    getMainList();

    return {
      isLoading, session, sortTypeDcd, hrds, paging, paging2, cloudMapModal, lochref,
      tabMenu, params, categories, categoriesPaging,
      handleMenuClick,
      mainList,
      search, pagingFunc, isSearching, getList,
      toggles, closeToggles,
      popups,
      expirationPopup,
      uploadModal,
      modules: [Pagination],
      items,
      items_tube,
      items_board,
      items_live,
      weekly_board_first,
      weekly_board_second,
      menu,
      myLikeTotalCnt,
      targetItem,
      targetItem2,
      hrdCloudFilter,
      board_first,
      board_second,
      getImgUrl,
      timestampToDateFormat,
      hrdLoading,
      bannerCode,
      applyModal,
      recommandModal,
      showApplyModal,
      updateApplyModal,
      updateRecommandModal,
      showRecommandModal,
      inqTyCdDcds,
      show_board_item,
      param,
      cnSz,
      isKbBank,
      getTodayDate,
      goBack,
      applyModalOpen,
      isMainActive,

    }
  }
};
</script>