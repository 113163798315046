<template>
  <div class="popup-container is-active">
    <div class="popup" id="popup-hrdcolud-h0301">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">노트 수정의견</h3>
        </header>
        <div class="popup-content">
          <section class="popup-section">
            <header class="section-header">
              <h4 class="title">노트 정보</h4>
            </header>
            <div class="section-content">
              <div class="kb-form-fields">
                <RowInputText v-model="param.inqTitle" class-lst="column-whole" title="제목" placeholder="제목을 입력하세요" :disabled="true" />
                <Upload
                    v-model="file"
                    title="첨부파일"
                    placeholder="첨부파일을 등록하세요"
                    btn-title="첨부파일 등록하기"
                    :max-qty="10"
                    sequence-key="comFileAtchSn"
                />
              </div>
            </div>
          </section>
          <section class="popup-section">
            <header class="section-header">
              <h4 class="title">노트 수정의견</h4>
            </header>
            <div class="section-content">
              <div class="segment-box">
                <textarea v-model="param.inqCn" name="" id="" cols="30" rows="6" class="kb-form-control" placeholder="내용을 입력해 주세요."></textarea>
                <div class="textarea-bottom divider">
                  <div class="bottom-column">
                    <span class="counter">{{ cnSz }} / 500</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="popup-buttons">
          <a href="javascript:" class="kb-btn kb-btn-primary kb-btn-lg" @click="insertQaAndFiles"><span class="text">저장</span></a>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click.stop="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>
<script>


import {sslNoteOpinion} from '@/assets/js/modules/ssl/setup/ssl-note-opinion';
import RowInputText from '@/components/support/common/RowInputText';
import Upload from '@/components/support/common/Upload';

export default {
  name: 'SSLNoteOpinionModal',
  components: {Upload, RowInputText},
  props: {
    toggle: Boolean,
    lrnTrgtGrpMstSn: Number,
    socLrnPostSn: Number,
    title: String
  },
  emits: ['update:toggle'],
  setup: sslNoteOpinion,
};
</script>
